.info-message-wrapper {
    display: none;
    text-align: center;
    background-color: $orange;
    position: fixed;
    bottom: 0;
    z-index: 5;
    width: 100%;

    
    &--localstorage {
        display: none;
        text-align: center;
        background-color: $black;
        position: fixed;
        bottom: 0;
        z-index: 5;
        width: 100%;
    }
}

.info-message-info {
    @include font-size(12px);
    font-weight: 600;
    color: $sand-lighter;
    padding: 15px  15px 10px;
    text-align: left;
    line-height: 1.3em;
    display: flex;
    flex-direction: column;
    
    @include bp(48) {
        @include font-size(15px);
        padding: 23px;
    }

    @include bp(75) {
        
        text-align: center;
        display: inherit;
    }
}

.info-message-info__content {
    font-style: italic;
    display: block;

    @include bp(75) {
        display: inline;
        padding-right: 20px;
    }
}

.info-message-link {
    color: $sand-lighter;
}



.info-message-info__button {
    font-weight: 600;
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    
    
    @include bp(48) {
        margin-top: 20px;
        display: block;
    }

    @include bp(75) {
        display: inline;
        
    }
}


.info-message-button-link {
    background-color: $orange-dark;
    color: $sand-lighter;
    border-radius: 5px;
    padding: 8px 7px;
    text-decoration: none;

    
    &:visited {
        color: $sand-lighter;
    }

    &--localstorage {
        border-radius: 5px;
        padding: 8px 10px;
        text-decoration: none;
        background-color: #41403d;
        color: $sand-lighter;
    }
}
