.my-settings{
    border-bottom: 1px solid $sand-light;
}
.my-settings__content{
    padding-top: 20px;
    @extend %page-content;

    @include bp(48){
        padding-top: 30px;
    }
    @include bp(75){
        padding-top: 50px;
    }
}
.my-settings__heading{
    @extend %my-pages-header__sub-heading;
    padding: 0 20px;
    margin-bottom: 0;
}
.profile-setting{
    transition: background-color 0.2s ease-in;

    &--active{
        background-color: $sand-light;
    }
}
.profile-setting__content{
    @extend %page-content;
    padding: 15px 20px;
}
.profile-setting__heading{
    @include font-size(15px);
    margin: 0;
    transition: font-size 0.2s ease-in;
    display: inline-block;

    @include bp(75){
        @include font-size(23px);
    }

    .profile-setting--active & {
        @include font-size(23px);

        @include bp(75){
            @include font-size(30px);
        }
    }
}
.profile-setting__item{
    //@extend %clearfix;
    @include font-size(15px);

    @include bp(75){
        @include font-size(23px);
    }
}
.profile-setting__text{
    @include bp(48){
        margin-right: 30px;
    }
    @include bp(75){
        margin-right: 45px;
    }
    &--no-margin{
        @include bp(48){
            margin-right: 0;
        }
        @include bp(75){
            margin-right: 0;
        }
    }
}

.profile-setting__change{
    color: $black;
    float: right;

    @include bp(48){
        float: none;
    }
}
.update-setting{
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s ease;

    .profile-setting--active & {
        max-height: 300px;
    }
}
.update-setting-wrapper{
    position: relative;
    margin-top: 15px;

    @include bp(48){
        float: left;
        width: 49%;
        margin: 30px 0 10px;
    }
    &--password{
        @include bp(48){
            margin-top: 15px;
        }
        @include bp(75){
            width: 32%;
            margin-left: 2%;
            margin-top: 30px;
        }
    }
}
.update-setting__submit{
    background: transparent;
    border: 2px solid $black;
    border-radius: 5px;
    display: block;
    font-weight: 600;
    height: 40px;
    padding: 5px;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    max-width: 100px;
    width: 100%;
    @include font-size(15px);

    @include bp(75){
        height: 55px;
        @include font-size(23px);
        border: 3px solid $black;
        max-width: 150px;
    }

    &:hover{
        background: #2B2A27;
        color: #FFFEF9;
    }
}
.update-setting__reset{
    @include font-size(15px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $black;
    right: 0;
    //transition: all 0.2s ease;

    @include bp(48){
        left: 120px;
        right: auto;
    }
    @include bp(75){
        left: 175px;
        @include font-size(23px);
    }
}
.setting-item{
    margin: 30px 0 10px;
    line-height: 1em;
    position: relative;

    @include bp(48){
        width: 49%;
        float: left;
    }
    @include bp(75){
        &--password{
            width: 32%;
        }
    }
}
.setting-item:first-of-type{
    @include bp(48){
        margin-right: 2%;
    }
}
.setting-item__input{
    background: $sand-lighter;
    border: 0;
    border-radius: 5px;
    height: 40px;
    padding: 0 80px 0 15px;
    width: 100%;
    @include font-size(15px);

    @include bp(75){
      height: 55px;
      padding: 0 90px 0 20px;
      @include font-size(23px);
    }

    &::-ms-reveal,
    &::-ms-clear{
        display: none;
    }

    &[value=""]{
        & + .setting-item__label{
            left: 15px;
            top: 50%;
            transform: translateY(-50%);

            @include bp(75){
                @include font-size(23px);
                font-weight: 600;
                left: 20px;
            }
            @include bp(75){
                padding-left: 35px;
            }
            &:before{
                top: 0px;
                @include bp(75){
                    top: -5px;
                }
            }
            &--pwd:before{
                @include bp(75){
                    height: 23px;
                    width: 23px;
                }
            }
            &--mobile:before{
                @include bp(75){
                    height: 26px;
                    width: 16px;
                }
            }
            &--email:before{
                @include bp(75){
                    height: 23px;
                    width: 23px;
                }
            }
        }
    }

    &:focus{
        & + .setting-item__label{
            @include font-size(15px);
            font-weight: 600;
            left: 0;
            top: -5px;
            transform: translateY(-100%);
            padding-left: 20px;

            @include bp(75){
                padding-left: 20px;
            }

            &:before{
                top: 0px;
                height: 15px;
                width: 15px;
            }
            &--mobile:before{
                width: 10px;
            }
        }
    }

    &--text{
        height: auto;
        resize: none;
        top: 15px;

        &[value=""]{
            &:focus{
                & + .setting-item__label{
                    top: -5px;
                    transform: translateY(-100%);
                }
            }
            & + .setting-item__label{
                top: 15px;
                transform: translateY(0);
            }
        }

        @include bp(48){
            width: 75%;
        }
    }
    &--error{
        background: $form-error-background;
        color: $red;
    }
}
.setting-item__label{
    color: $black;
    @include font-size(15px);
    font-style: italic;
    font-weight: 600;
    left: 0;
    position: absolute;
    top: -5px;
    transition: all 0.2s ease;
    transform: translateY(-100%);
    padding-left: 20px;

    &:before{
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100% auto;
        content: "";
        display: inline-block;
        height: 15px;
        left: 0;
        position: absolute;
        top: 0px;
    }
    &--pwd:before{
        background-image: url('../img/icons/key.svg');
        width: 15px;
    }
    &--email:before{
        background-image: url('../img/icons/envelop--black.svg');
        width: 15px;
    }
    &--mobile:before{
        background-image: url('../img/icons/mobile--black.svg');
        width: 10px;
    }
}
.profile-setting-errors{
    @include bp(75){
        width: 66%;
    }
}
.profile-setting-errors__item{
    background: $form-error-background;
    border-radius: 5px;
    color: $red;
    @include font-size(15px);
    line-height: 1.33333333em;
    padding: 15px;
    margin: 10px 0 0;
}
.update-setting-items{
    @extend %clearfix;
}
.profile-setting-success{
    display: none;
    color: $green;
    @include font-size(15px);
    margin-left: 5px;
    position: relative;
    font-weight: 600;

    @include bp(75){
        @include font-size(23px);
    }

    &:after{
        background-image: url('../img/icons/check.svg');
        background-repeat: no-repeat;
        content: "";
        width: 15px;
        height: 15px;
        display: inline-block;
        background-size: 100%;
        margin-left: 10px;
        background-position: center;

        @include bp(75){
            height: 20px;
            width: 20px;
        }
    }
    &--large{
        @include font-size(23px);

        @include bp(75){
            @include font-size(30px);
        }

        &:after{
            width: 20px;
            height: 20px;
        }
    }
}
#profile-member-password-form{
    .profile-setting:last-of-type{
        @include bp(48){
            margin-bottom: 15px;
        }
        @include bp(75){
            margin-bottom: 35px;
        }
    }
}
