
.book-list-carousel-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #41403d;
    text-decoration: none;
    width: 100%;
}

.book-list-carousel-item__badge {
    transition: background-color .2s linear, opacity .2s linear;
    opacity: 1;
    position: relative;
    z-index: 1;
}

.book-list-carousel-item__figure {
    margin: 0;
    position: relative;
}

.book-list-carousel-item__product-image {
    position: relative;
}

.book-list-carousel-item__number {
    bottom: 0;
    color: $black;
    @include font-size(60px);
    font-weight: 700;
    left: -15px;
    line-height: 0;
    position: absolute;
    z-index: 1;

    @include bp(48) {
        @include font-size(60px);
    }
    @include bp(75) {
        transform: translateX(-50%);
        left: 0;
    }
}

.book-list-carousel__info {
    margin-top: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .carousel-content--with-numbers & {
        margin-top: 30px;
    }
}

.book-list-carousel__title {
    @include font-size(15px);
    @include line-height(15);
    @include line-clamp(2);
    margin: 0;
    word-wrap: break-word;

    @include bp(75) {
        @include font-size(18px);
        @include line-height(18);
    }
    @include bp(120) {
        @include font-size(20px);
        @include line-height(20);
    }
}

.book-list-carousel__author {
    color: $black;
    @include font-size(13px);
    @include line-height(13);
    @include line-clamp(1);
    font-weight: 300;
    margin: 5px 0 0;
    word-wrap: break-word;

    @include bp(48) {
        margin: 5px 0 0;
    }
    @include bp(75) {
        @include font-size(15px);
        @include line-height(15);
    }
}

%book-list-carousel__price,
.book-list-carousel__price {
    display: block;
    @include font-size(18px);
    font-weight: 700;
    line-height: 1em;
    margin-top: 5px;
}

.book-list-carousel__price {
    &:last-child {
        margin-bottom: 35px;

        @include bp(48) {
            margin-bottom: 37px;
        }
    }
}

.book-list-carousel__price--special {
    .book-list-carousel__price-large {
        color: $red;
        position: relative;
        padding-left: 25px;
        line-height: 24px;
        display: inline-block;

        &--vanpris {
            color: $friend-price;

            &:before {
                background-image: url('../img/icons/heart.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
                content: '';
                display: inline-block;
                height: 24px;
                left: 0;
                margin-right: 10px;
                position: absolute;
                width: 24px;
                transform: translateY(-50%);
                top: 50%;
            }
        }

        &--studentpris,
        &--kampanjpris {
            color: $red;
            padding-left: 0;
        }
    }
}

.book-list-carousel__price-large {
    line-height: 24px;
}

.book-list-carousel__price-small {
    @extend %book-list-carousel__price;

    @include font-size(13px);
    font-weight: 300;
    line-height: 1em;
    display: inline-block;

    @include bp(120) {
        @include font-size(15px);
    }
}

.book-list-carousel__type {
    color: $black;
    @include font-size(13px);
    @include line-height(13);
    font-weight: 600;
    margin: 7px 0 0;
    min-height: 1px;

    @include bp(48) {
        margin-top: 5px;
    }
    @include bp(75) {
        @include font-size(15px);
        @include line-height(15);
    }
}

.book-list-carousel__stock-status-touch {
    display: none;
    margin-top: 7px;

    .touch & {
        display: block;
    }
}

.book-list-carousel__add-to-cart-touch {
    margin-top: 5px;
}


.book-list-carousel__overlay {
    display: flex;
    min-height: 150px;
    align-items: center;
    background: $sand-light;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: opacity .2s linear, visibility 0s linear .2s;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;

    .product-image-overlay__hoverer:hover & {
        visibility: visible;
        pointer-events: auto;
        opacity: 1;
        transition: opacity .2s linear;
    }

    .touch & {
        display: none;
    }
}

.book-list-carousel__overlay--coming {
    background: $orange-lighter;
}

.book-list-carousel__overlay--before-sale {
    background: $yellow;
}

.book-list-carousel__overlay-inner {
    height: 100%;
    width: 100%;

    .book-list-carousel__overlay--before-sale &,
    .book-list-carousel__overlay--coming & {
        border-top: none;
        border-bottom: none;
    }
}

.book-list-carousel__touch-add-to-cart {
    display: block;
    margin-top: 5px;
}

.book-list-carousel__store-only-product-info {
    display: block;
    position: relative;
    padding: 6px 11px;
    border-radius: 5px;
    text-align: center;
    margin-top: 5px;

    &:hover {
        background: inherit;
    }
}
