
.search-filter-form {
	background: $sand-light;
}

.search-filters {
	opacity: 0;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.6s ease-out, opacity 0.3s linear 0.2s;

    .search-filter-block--active & {
		display: block;
		opacity: 1;
		overflow: hidden;
		max-height: 9999px;
		transition: max-height 1.2s ease-in, opacity 0.3s linear 0.2s;
    }
}
.search-filter-wrapper {
	@include bp(48) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @include bp(75) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .search-filter-block--active & {
		border-top: 1px solid $border-product-filter;
    }
}

.search-filter-block{
	border-bottom:  1px solid $border-product-filter;
}

.search-filter-form {
    .search_result_page & {
		max-height: none;
		background-color: $sand-light;
		padding-bottom: 0;
    }

}
.search-filter-fieldset {
	@extend %page-content;
	padding: 0;
	cursor: pointer;

	@include bp(48) {
        padding: 0 20px;
    }

	&.top {
		padding-top: 20px;
		position: relative;
	}

	border:0;
}
.search-filter-fieldset__legend {
    display: inline-block;
	@include font-size(15px);
	font-weight: 300;
	line-height: 1.2em;
	padding: 20px;
    width: 100%;
    vertical-align: top;
    position: relative;

    @include bp(48) {
    	margin-left: 0;
		margin-bottom: 0;
		padding: 20px 0 7px;
		@include font-size(23px);
	}

	@include bp(75) {
		border: 0;
	}

	&:after {
		content: "";
		background-image: url(../img/icons/chevron_right_black.svg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: auto 100%;
		display: inline-block;
		position: absolute;
		height: 15px;
		width: 10px;
		transform: translateY(-50%);
		transform: rotate(90deg);
		top: 22px;
		left: calc(100% - 30px);
		transition: transform 0.2s ease-in;

		@include bp(48) {
			display: none;
		}

	}

	&.open {
		&:after {
			transform: rotate(-90deg);
			transition: transform 0.2s ease-in;
		}
	}
}

.search-filter-list-wrapper {
	transition: all 0.6s ease-in-out;
	max-height: 0;
	overflow: hidden;
	position: relative;

	@include bp(48) {
		max-height: none;
	}

	&--active {
		max-height: 2000px;

		@include bp(48) {
			max-height: none;
		}
	}
}

.search-filter-list {
	list-style: none;
	margin: 0;
	padding: 10px 0 10px 0;
	display: inline-block;
	font-size: 0;

	.search_result_page & {
		border-top: 0;
		padding: 0 20px 10px;
		overflow: hidden;

		@include bp(48) {
			padding: 0 0 10px 0;
		}
	}
}

.search-filter-list__item {
	display: inline-block;
    margin: 0 0 10px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	overflow: hidden;
	vertical-align: top;
	@include font-size(15px);

    &:nth-of-type(n+4) {
	    max-height: 0;
	    visibility: hidden;
	    margin: 0;
	    width: 0;

	    @include bp(75) {
		    max-height: 3000px;
		    visibility: visible;
		    display: inline-block;
		    width: auto;
		}
	}

	&:nth-of-type(n+6) {
		@include bp(75) {
		    max-height: 0;
		    visibility: hidden;
		    margin: 0;
		    width: 0;
		}
	}

	.show-more & {
		&:nth-of-type(n+4) {
			max-height: 3000px;
			visibility: visible;
			display: inline-block;
			margin-bottom: 10px;
			width: auto;
		}
	}

	&:last-child {
		margin-right: 10px;
	}
}

.search-filter-label {
	border: 2px solid $black;
	border-radius: 5px;
	color: $black;
	cursor: pointer;
	display: block;
	@include font-size(15px);
	font-weight: 600;
	margin-right: 10px;
	padding: 8px 10px;
	transition: all 0.2s ease-in;
	min-height: 40px;

	&.search-filter-label--bindings {
		padding-left: 20px;
		padding-right: 20px;

		.search-filter-label__title:after {
			background-position: center;
			background-repeat: no-repeat;
			background-size: auto 100%;
			content: "";
			display: inline-block;
			height: 15px;
			position: relative;
			top: 2px;
			left: 5px;
			width: 15px;
		}

	}

	&--2, &--Häftad {
		.search-filter-label__title:after {
			background-image: url(../img/icons/bindings.svg);
		}
	}

	&--1, &--Pocket {
		.search-filter-label__title:after {
			background-image: url(../img/icons/pocket.svg);
		}
	}

	&--4,&--Inbunden {
		.search-filter-label__title:after {
			background-image: url(../img/icons/hardcover.svg);
		}
	}

    /** Audio book **/
	&--10,&--Ljudbok {
		.search-filter-label__title:after {
			background-image: url(../img/icons/audiobook.svg);
		}
	}

	@include bp(75) {
		border: 3px solid $black;
	}

}

.no-touch .search-filter-label {

	&:hover {
		background-color: $black;
		color: $sand-lighter;
	}

	&--2, &--Häftad {
		&:hover {
			.search-filter-label__title:after {
				background-image: url(../img/icons/bindings--inverted.svg);
			}
		}
	}

	&--1, &--Pocket {
		&:hover {
			.search-filter-label__title:after {
				background-image: url(../img/icons/pocket--inverted.svg);
			}
		}
	}

	&--4,&--Inbunden {
		&:hover {
			.search-filter-label__title:after {
				background-image: url(../img/icons/hardcover--inverted.svg);
			}
		}
	}

    /** Audio book **/
	&--10,&--Ljudbok {
		&:hover {
			.search-filter-label__title:after {
				background-image: url(../img/icons/audiobook--inverted.svg);
			}
		}
	}
}

.search-filter-checkbox {
	@extend %visually-hidden;

	&:checked + .search-filter-label {
		background: $black;
		border-color: $black;
		color: $sand-lighter;

		.search-filter__count {
			display: none;
		}

		&--2, &--Häftad {
			.search-filter-label__title:after {
				background-image: url(../img/icons/bindings--inverted.svg);
			}
		}

		&--1, &--Pocket {
			.search-filter-label__title:after {
				background-image: url(../img/icons/pocket--inverted.svg);
			}
		}

		&--4, &--Inbunden {
			.search-filter-label__title:after {
				background-image: url(../img/icons/hardcover--inverted.svg);
			}
		}

        /** Audio book **/
		&--10, &--Ljudbok {
			.search-filter-label__title:after {
				background-image: url(../img/icons/audiobook--inverted.svg);
			}
		}

		&:after {
			display: inline-block;
			content: url(../img/icons/cross.svg);
			margin-left: 8px;
		}

		&.search-filter-label--bindings {
			&:after {
				margin-left: 12px;
			}
		}

	}
}
.search-filter-store__label {
	cursor: pointer;
	display: inline-block;
	@include font-size(15px);
	padding-left: 34px;
	position: relative;
    max-width: 300px;
    padding-top: 0px;

	&:before {
		border: 2px solid $black;
		border-radius: 5px;
		content: "";
		cursor: pointer;
		display: inline-block;
		height: 25px;
		left: 0;
		margin-right: 10px;
		position: absolute;
		width: 25px;
        transform: translateY(-50%);
        top: 50%;


		@include bp(75) {
			border: 3px solid $black;
			height: 30px;
			width: 30px;
		}
	}

	@include bp(48) {
        max-width: inherit;
        margin-bottom: 0;
        padding-top: 0;
	}

	@include bp(75) {
		line-height: 30px;
		padding-left: 38px;
	}
}

.search-filter-toggle {
	@extend %page-content;
}

.search-filter-toggle-section {
	background: $sand-light;
}
.search-filter-toggle-fieldset {
	border: 0;
	padding-top: 12px;
	padding-bottom: 8px;
	cursor: pointer;

	@include bp(48) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @include bp(75) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.search-filter-toggle__link {
	text-decoration: none;
	color: $black;
	display: inline-block;
	position: relative;
	line-height: 30px;
	padding-left: 20px;
	font-weight: 700;

	&:before {
		content: "";
		background-image: url(../img/icons/chevron_right_black.svg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: auto 95%;
		display: inline-block;
		right: -20px;
		position: relative;
		height: 15px;
		width: 9px;
		transform: translateY(-50%);
		transform: rotate(90deg);
		top: 3px;
		right: 7px;
		transition: transform 0.2s ease-in;
	}

	&.open {
		&:before {
			transform: rotate(-90deg);
			transition: transform 0.2s ease-in;
		}
	}
}
.search-filter-breadcrumbs {
    border-bottom: 1px solid $border-product-filter;
    display: none;

    &--wrapper {
        @extend %page-content;
        padding: 15px 20px 10px 20px;

        @include bp(48) {
	        padding-left: 30px;
	        padding-right: 30px;
	    }
    }

    &--active {
        display: block;
    }

    .filter-breadcrumb__item {

	    &:last-child {
	    	margin-right: 0;
	    }
	}
}


.search-list-show-more{
	margin: 0 0 30px;
	padding: 0 20px;
	text-align: center;

	@include bp(48){
		padding: 0 30px;
	}
	@include bp(75){
		padding: 0 15px;
	}
	@include bp(120){
		padding: 0 30px;
	}
}
.search-list-show-more__button{
	background: $sand-lighter;
	border: 3px solid $black;
	border-radius: 5px;
	color: $black;
	display: inline-block;
	@include font-size(15px);
	font-weight: 600;
	height: 40px;
	max-width: 330px;
	width: 100%;
}

.show-more-filters__item {
	display: inline-block;
	padding-right: 30px;
	vertical-align: top;
	@include font-size(15px);
}

.show-more-search-filters-toggle__link {
    text-decoration: none;
    color: $black;
    display: inline-block;
    position: relative;
    top: 10px;
    left:10px;
    outline: 0;
    font-weight: 700;
    @include font-size(15px);
    vertical-align: top;
    margin-bottom: 10px;

    &:after {
        content: "";
        background-image: url(../img/icons/chevron_right_black.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        display: inline-block;
        right: -20px;
        position: relative;
        height: 15px;
        width: 10px;
        transform: translateY(-50%);
        top: 3px;
        left: 10px;
        transform: rotate(-90deg);
        transition: transform 0.2s ease-in;
    }

    &.show-more-filters {
    	top: 11px;
    	left: 0;

    	@include bp(75) {
    		left: 0;
    		top: 8px;
	    }

	    &:after {
            transform: rotate(90deg);
            transition: transform 0.2s ease-in;
        }
    }

    @include bp(48) {
        left: 0;
    }

    @include bp(75) {
        line-height: 30px;
        top: 8px;
        left: 0;
        position: relative;
    }
}
