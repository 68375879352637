.member-info-section{
  @extend %page-content;
}
.member-info-section__content{
  padding: 30px 20px;

  @include bp(48){
    padding: 30px 30px 40px 30px;
  }
  @include bp(75){
    padding: 100px 15px 40px 15px;
  }
  @include bp(120){
    padding-top: 100px;
  }
}
.member{
  padding-top: 10px;
  @include bp(48){
    padding-top: 0;
  }
}
.member__name{
  margin: 0;
  @include font-size(23px);
  line-height: 1.304347826em;
  font-weight: 300;

  @include bp(75){
    @include font-size(37px);
    line-height: 1.216216216em;
  }
}
.member-info{
  @include font-size(15px);
  font-weight: 600;
  margin: 10px 0;

  @include bp(75){
    @include font-size(23px);
  }
}
.member-info__title{
  display: inline-block;
  font-weight: 600;
}
.member-info__description{
  display: inline-block;
  margin: 0;
  font-weight: 300;
}
.member-info-actions{
  @extend %clearfix;
  margin-top: 15px;
  max-width: 200px;
  color: $black;
  @include font-size(15px);
  font-weight: 300;

  @include bp(75){
    @include font-size(23px);
    max-width: 290px;
  }
}
.edit-profile{
  float: left;
  color: $black;

  &:visited{
    color: $black;
  }
}
.my-pages-logout{
  float: right;
  color: $black;

  &:visited{
    color: $black;
  }
}