.checkbox {
    position: relative;
    display: inline-block;
}

.checkbox__label {
    line-height: 25px;
    padding-left: 35px;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        height: 25px;
        width: 25px;
        top: 0;
        left: 0;
        display: inline-block;
        border: 2px solid $black;
        border-radius: 5px;
        cursor: pointer;
        margin-right: 10px;

        .checkbox__input:checked + & {
            background-image: url('../img/icons/0273-checkmark.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 80%;
        }

        .checkbox__input:focus + & {
            outline: 1px dotted #212121;
            outline: 5px auto -webkit-focus-ring-color;
        }

        .hide-outlines .checkbox__input:focus + & {
            outline: none;
        }
    }

}

.checkbox__input {
    @extend %visually-hidden;
}
