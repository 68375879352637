.stores-filter{
    background-color: $sand-light;
    margin: 25px 0 0;
    padding: 15px 0 20px;

    @include bp(48){
        margin-top: 25px;
        padding-bottom: 0;
        padding-top: 0;
    }
    @include bp(75){
        margin-top: 45px;
    }
    @include bp(120){
        margin-top: 65px;
    }
}
.stores-filter__content{
    @extend %page-content; 
}
.stores-filter-header{
    @extend %full-width-content;
    padding-bottom: 15px;

    @include bp(48){
        padding-bottom: 0;
    }
}
.stores-filter__heading{
    @include font-size(23px);
    font-weight: 300;
    margin: 0;

    @include bp(48){
        padding: 25px 0 5px;
    }
    @include bp(75){
        @include font-size(30px);
        padding: 15px 0 5px;
    }
}
.stores-filter-form-wrapper{
    @extend %full-width-content;
}
.stores-filter-fieldset{
    border: 0;
    margin: 0;
    padding: 0;

    @include bp(48){
        padding: 5px 0 30px;
    }
}
.stores-filter-input-wrapper{
    background-color: $sand-lighter;
    border-radius: 5px;
    height: 35px;
    max-width: 520px;
    position: relative;
    
    @include bp(48){
        width: 75%;
    }
    @include bp(75){
        height: 45px;
    }

}
.stores-filter-form__label{
    @include font-size(15px);
    font-style: italic;
    font-weight: 600;
    line-height: 35px;
    padding-left: 10px;
    position: absolute;

    @include bp(75){
        line-height: 45px;
    }
}
.stores-filter-form__input{
    background-color: transparent;
    border: 0;
    font-style: italic;
    height: 100%;
    padding-left: 50px;
    padding-right: 45px;
    width: 100%;

    @include bp(75){
        padding-left: 45px;
        padding-right: 50px;
    }
}
.stores-filter-form__button{
    background-color: $black;
    background-image: url(../img/icons/0135-search--inverted.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 55% auto;
    border: 0;
    border-radius: 0 5px 5px 0;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 35px;

    @include bp(75){
        width: 45px;
    }
}
