.video-teaser-section{
    background-color: $sand-light;

    &--no-videos{
        background-color: $sand-lighter;
    }
}

.video-teaser__content{
    padding: 25px 20px 15px 20px;
    width: 100%;
    margin: 0 auto;

	@include bp(48){
        padding: 30px;
        max-width: 768px;
	}
	@include bp(75){
		padding-top: 40px;
		padding-left: 0;
		padding-right: 0;
        max-width: 1020px;
	}
	@include bp(120){
        padding-bottom: 40px;
        max-width: 1200px;
	}

    &--no-videos{

    }
}

.video-teaser-heading-container{
    @include bp(48){
        padding: 0 20px;
    }
}

.video-teaser-section__heading{
    margin: 0 0 25px 0;
    position: relative;
    width: 100%;
    text-align: center;

    @include bp(75){
		margin-bottom: 50px;
	}

    &--no-videos{
        margin-bottom: 10px;
        @include bp(48){
            margin-bottom: 0;
        }
    }
}

.video-teaser-section__heading:before {
    border: 1px solid $border-product-filter;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
}

.video-teaser-section__span {
    padding: 5px 10px;
    background-color: $sand-light;
    z-index: 10;
    position: relative;
    font-weight: 300;
    font-family: $font-family--primary;
    @include font-size(24px);

    @include bp(48) {
        padding: 0 20px;
        @include font-size(26px);
    }
    @include bp(75) {
        @include font-size(30px);
    }
    @include bp(120) {
        @include font-size(38px);
    }

    &--no-videos{
        background-color: $sand-lighter;
    }
}

.video-teaser-link{
    color: $black;
    display: block;
    text-decoration: none;
}

.video-teaser-list {
    list-style: none;
    margin: 0;
    @extend %clearfix;
    padding: 0;
}

.video-teaser-list__item{
    width: 100%;
    cursor: pointer;
    opacity: 0;
    height: 0;
    margin: 0;
    overflow: hidden;
	transition: transform 0.5s ease-out, opacity 0.1s;

    @include bp(48){
        float: left;
        width: 50%;
        margin-left: 0;
        margin-bottom: 30px;
        opacity: 1;
        height: auto;
        transition: transform 0.5s ease-out, opacity 0.3s linear 0.2s;
        min-height: 330px;
    }

    @include bp(75){
		width: 25%;
        min-height: 320px;
	}

    @include bp(120){
        min-height: 315px;
	}

    &:nth-child(-n+3),
    .video-teaser-list__show-all &{
        margin-left: 0;
        margin-bottom: 30px;
        opacity: 1;
        height: auto;
        transition: transform 0.5s ease-out, opacity 0.3s linear 0.2s;
    }
}

.video-teaser-figure, .video-teaser-info{
    @include bp(48){
        padding: 0 20px;
    }
}

.video-teaser-info__heading{
    @include font-size(18px);
    margin-bottom: 10px;

    &:hover{
        text-decoration: underline;
    }
}

.video-teaser-info__preamble{
    margin-top: 5px;
}

.video-teaser-figure__img{
    width: 100%;
}

.video-teaser-figure{
    position: relative;
    &:after{
		background-image: url(../img/icons/play.svg);
        background-position: center center;
		background-repeat: no-repeat;
		background-size: auto 95%;
		content: " ";
        height: 50px;
        width: 50px;
        opacity: 0.7;
		display: inline-block;
		position: absolute;
		right: 0;
        left: 0;
        margin: 0 auto;
		top: 50%;
		transform: translateY(-50%);
	}
}

.video-list-show-more{
	margin: 0 0 30px;
	padding: 0 20px;
	text-align: center;

	@include bp(48){
		padding: 0 30px;
        display: none;
	}
}

.video-list-show-more__button{
	background: $sand-light;
	border: 3px solid $black;
	border-radius: 5px;
	color: $black;
	display: inline-block;
	@include font-size(15px);
	font-weight: 600;
	height: 40px;
	max-width: 330px;
    width: 100%;

    .video-list__show-more--more{
		display: inline-block;
	}
	.video-list__show-more--less{
		display: none;
	}

}

.video-list__show-more--expanded{
	.video-list__show-more--more{
		display: none;
	}
	.video-list__show-more--less{
		display: inline-block;
	}
}
