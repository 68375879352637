.product-grid-item {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
}

.product-grid-item__link {
    color: $black;
    display: block;
    text-decoration: none;
}

.product-grid-item__cover {
    position: relative;
    transition: opacity .2s ease-in-out;

    .product-grid-item__link:hover & {
        opacity: .7;
    }
}

.product-grid-item__cover-image {
    position: relative;
    width: 100%;
}

.product-grid-item__new-badge {
    background-color: $medium-green;
    border-radius: 50%;
    color: $sand-lighter;
    display: block;
    @include font-size(18px);
    font-weight: 700;
    height: 40px;
    left: 3px;
    line-height: 40px;
    position: absolute;
    text-align: center;
    top: 10px;
    transform: translate(-50%);
    width: 40px;
    z-index: 2;

    @include bp(48) {
        top: 20px;
	}
}

.product-grid-item__comming-badge {
    background-color: $product-cover-coming-plate;
    color: $sand-lighter;
    display: block;
    @include font-size(15px);
    font-weight: 600;
    letter-spacing: 1px;
    position: absolute;
    left: -15px;
    height: 35px;
    line-height: 35px;
    padding: 0 10px;
    top: 20px;
    z-index: 2;

    &:before {
        border-top: 15px solid $black;
        border-left: 15px solid transparent;
        top: 35px;
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        position: absolute;
        left: 1px;

    }
    @include bp(48) {
        top: 20px;
        @include font-size(18px);
        font-weight: 700;
        height: 40px;
        left: -20px;
        line-height: 40px;

        &:before {
            border-top: 20px solid $black;
            border-left: 20px solid transparent;
            top: 40px;
        }
    }
}

.product-grid-item__during-sale-badge {
    position: absolute;
    left: -6px;
    width: 50px;
    top: 10px;
    z-index: 2;

    @include bp(48) {
        left: -7px;
        width: 60px;
        top: 20px;
    }
}

.product-grid-item__before-sale-badge {
    position: absolute;
    left: -7px;
    width: 70px;
    top: 10px;
    z-index: 2;

    @include bp(48) {
        left: -10px;
        width: 90px;
        top: 20px;
    }
    @include bp(75) {
        left: -11px;
        width: 130px;
        top: 20px;
    }
    @include bp(120) {
        left: -11px;
        width: 100px;
        top: 20px;
    }
}

.product-grid-item__details {
    position: relative;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.product-grid-item__title {
    @include font-size(15px);
    @include line-clamp(2);
    font-weight: 700;
    line-height: 1.2em;
    margin: 0;
    word-wrap: break-word;

    @include bp(48) {
        @include font-size(19px);
    }

    @include bp(100) {
        @include font-size(23px);
    }
}

.product-grid-item__info {
    @include font-size(15px);
    @include line-clamp(1);
    font-weight: 300;
    line-height: 1.2em;
    margin: 5px 0 0;
    word-wrap: break-word;

    @include bp(75) {
        @include font-size(15px);
	}
}

.product-grid-item__price {
    display: block;
    @include font-size(18px);
    font-weight: 700;
    line-height: 1em;
    margin-top: 5px;

    @include bp(75) {
        @include font-size(23px);
        line-height: 1.2em;
    }

    &--special {
        .product-grid-item__price__large {
            color: $red;

            &--1 {
                color: $friend-price;
                display: inline-block;
                line-height: 24px;
                padding-left: 27px;
                position: relative;

                @include bp(75) {
                    line-height: 35px;
                    padding-left: 40px;
                }

                &:before {
                    background-image: url('../img/icons/heart.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    content: '';
                    display: inline-block;
                    height: 24px;
                    width: 24px;
                    left: 0;
                    position: absolute;

                    @include bp(75) {
                        height: 35px;
                        width: 35px;
                    }
                }
			}
		}
	}

    &__large {
        margin-right: 5px;
        line-height: 24px;

        @include bp(75) {
            line-height: 35px;
        }
    }

    &__small {
        display: inline-block;
        @include font-size(13px);
        font-weight: 300;
        line-height: 1em;

        @include bp(75) {
            @include font-size(15px);
        }
    }

    &:last-child {
        margin-bottom: 35px;

        @include bp(48) {
            margin-bottom: 37px;
        }
    }
}

.product-grid-item__type {
    @include font-size(15px);
    font-weight: 600;
    line-height: 1.2em;
    margin: 5px 0 0;
}

.product-grid-item--show-buy-buttons .product-grid-item__touch-saldo {
    display: none;
    // Allow some overflow to fit stock status
    width: calc(100% + 20px);
    margin: 10px 0 0;
    word-wrap: break-word;

    .touch & {
        display: block;
    }
}

.product-grid-item__recommendation {
    @include font-size(15px);
    font-weight: 600;
    position: relative;
    line-height: 1.5em;
    word-wrap: break-word;
    padding-left: 20px;
    margin: 5px 0;

    &:before {
        content: '';
        background-image: url('../img/icons/tips-icon.svg');
        background-repeat: no-repeat;
        height: 25px;
        width: 15px;
        display: inline-block;
        background-size: 100%;
        margin-right: 5px;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.product-grid-item__buy-overlay {
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s linear;
    background: $white;

    &:hover {
        visibility: visible;
        opacity: 1;
    }
}

/**
* Touch buy button
*/
.product-grid-item__touch-add-to-cart {
    display: block;
    margin-top: 5px;
}

.product-grid-item__details-overlay {
    background: $sand-lighter;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: opacity .2s linear, visibility 0s linear .2s;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;

    .product-grid-item:hover & {
        visibility: visible;
        pointer-events: auto;
        opacity: 1;
        transition: opacity .2s linear;
    }

    .touch & {
        display: none;
    }
}

.product-grid-item__store-only-product-info {
    display: block;
    position: relative;
    padding: 6px 11px;
    border-radius: 5px;
    text-align: center;
    margin-top: 5px;

    &:hover {
        background: inherit;
    }
}
