.no-touch{
	.booked-events-list__item{
		visibility: hidden;
	}
  .cancel-booked-event{
    &:hover{
      background-color: $sand-lighter;
      color: $black;
      &:before{
          content: url(../img/icons/cross--inverted.svg);
      }
    }
  }
}
.touch{

	.booked-event{
		overflow: visible;
	}
	.booked-events-list{
		@extend %clearfix;
	}
	.booked-events-list__item{
		visibility: visible;
	}
}
.my-pages-carousel-section{
    .my-events-heading{
      border-bottom: 2px solid $black;

      &--friends{
        position: relative;
        min-height: 50px;
        &:after{
          content: "";
          background-image: url(../img/icons/friends-event.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 130%;
          height: 40px;
          width: 40px;
          position: absolute;
          right: 0;
          top: -5px;

          @include bp(48){
            height: 50px;
            width: 50px;
          }
        }
        .my-events-heading__heading{
          max-width: 170px;
          word-wrap: break-word;
          @include bp(40){
            max-width: 190px;
          }
          @include bp(48){
            max-width: 210px;
          }
          @include bp(75){
            max-width: 245px;
          }
          @include bp(120){
            max-width: 300px;
          }
        }
      }
    }
    .my-events-heading__heading{
      @include bp(48){
        line-height: 1.233333333em;
      }
      @include bp(75){
        @include font-size(30px);
        line-height: 1.233333333em;
      }
    }
}
.booked-events-carousel{
  @extend %page-content;
}
.booked-event__content{
  padding: 20px;

    @include bp(120){
      padding: 30px;
    }
}
.booked-events-list{
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;

  &.slick-initialized{
		.booked-events-list__item{
			visibility: visible;
		}
	}
}
.booked-events-list__item{
    float: left;
	visibility: hidden;
    padding-right: 30px;

	&:first-child{
		visibility: visible;
	}
    @include bp(40){
		width: 50%;
		&:nth-child(-n+2){
			visibility: visible;
		}
	}
	@include bp(75){
		width: 33.3333333333%;

		&:nth-child(-n+3){
			visibility: visible;
		}
	}

	@include bp(120){
		width: 25%;

		&:nth-child(-n+4){
			visibility: visible;
		}
	}
}
.booked-event{
  background: $bg-event-module;
  border-radius: 6px;
  color: $sand-lighter;

}
.booked-event-meta-list{
  padding: 20px 0;
  margin: 0;
  list-style: none;
}
.booked-event-meta-list__item{
  display: inline-block;
  margin: 5px 20px 5px 0;
  font-weight: 600;
  @include font-size(15px);
  position: relative;
  padding-left: 25px;

  @include bp(75){
    max-width: inherit;
  }

  &:before{
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: auto 100%;
    content: "";
    display: inline-block;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &--date{
    &:before{
      background-image: url(../img/icons/calendar--inverted.svg);
      width: 20px;
    }
  }
  &--time{
    &:before{
      background-image: url(../img/icons/clock--inverted.svg);
      width: 20px;
    }
  }
  &--store{
    &:before{
      background-image: url(../img/icons/store--inverted.svg);
      width: 20px;
    }
  }
}
.cancel-booked-event{
  text-decoration: none;
  color: $sand-lighter;
  border-radius: 5px;
  border: 2px solid $sand-lighter;
  padding: 10px 20px;
  @include font-size(15px);
  font-weight: 600;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  display: inline-block;
  text-align: center;
  background: transparent;

  &:before{
      content: url(../img/icons/cross.svg);
      display: inline-block;
      margin-right: 7px;
      -webkit-transition: all 0.2s ease-in;
      transition: all 0.2s ease-in;
  }

  @include bp(75){
    border: 3px solid $sand-lighter;
  }
}
