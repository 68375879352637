.list-all-stores {
    background-color: $sand-lighter;
    border-radius: 5px;
    position: relative;
}

.list-all-stores__content {
    display: none;
    max-height: 208px;
    max-height: 40vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    .list-all-stores--expanded & {
        display: block;
    }
}

.list-all-stores__expander {
    @include clear-button-style();
    position: relative;
    width: 100%;
    padding: 20px;
    padding-left: 60px;
    text-align: left;

    &:before {
        background-image: url('../img/icons/0207-eye.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 95%;
        left: 20px;
        position: absolute;
        content: '';
        height: 30px;
        width: 30px;
        top: 50%;
        transform: translateY(-50%);
    }

    &:after {
        background-image: url('../img/icons/chevron_right_black.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 95%;
        right: 20px;
        position: absolute;
        content: '';
        height: 20px;
        width: 12px;
        transition: transform .2s ease-in-out;

        .list-all-stores--expanded & {
            transform: rotate(90deg);
        }
    }
}

.list-all-stores__only-in-stock {
    padding: 20px;
    padding-top: 10px;
}

.list-all-stores__no-results {
    padding: 20px;
    font-weight: 600;
    margin: 0;
}
