.global-nav--menu {
    background-color: $black;
    display: block;

    @include bp(75) {
        display: none;
    }
}

.global-nav--header {
    display: none;
    float: right;
    margin-top: 10px;

    @include bp(75) {
        line-height: 1;
        display: block;
    }
}

.global-nav__link {
    .global-nav--menu & {
        border-top: 1px solid $border-menu--first-lvl;
        color: $sand-lighter;
        display: block;
        @include font-size(15px);
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 1.2em;
        @include column_style(0, 2);
        padding-top: 28px;
        padding-bottom: 28px;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
            text-decoration: underline;
        }
    }

    .global-nav--header & {
        color: $black;
        @include font-size(13px);
        letter-spacing: 1px;
        line-height: 16px;
        margin-left: 15px;
        position: relative;
        text-decoration: none;
        display: inline-block;

        &.navigation-list__item--active {
            &:after {
                display: none;
            }
        }

        &:after {
            background-color: $black;
            bottom: -1px;
            content: '';
            display: block;
            height: 1px;
            left: 2px;
            position: absolute;
            right: 2px;
            transform: scaleX(0);
            transition: transform .2s ease-in;
        }

        &:hover {

            &:after {
                transform: scaleX(1);
            }
        }
    }
}

.global-nav__link--avatar, 
.global-nav__link--avatar_mypages  {
    .global-nav--header & {
        position: relative;
        margin-left: 30px;

        &:before {
            content: '';
            background-image: url('../img/icons/my-account.svg');
            height: 20px;
            width: 20px;
            background-repeat: no-repeat;
            background-size: 100%;
            position: absolute;
            left: -25px;
            top: -2px;
        }
    }
}

.global-nav__link--store-finder {
    .global-nav--header & {
        position: relative;
        margin-left: 30px;

        &:before {
            content: '';
            background-image: url('../img/icons/SVG/0492-geotag.svg');
            height: 20px;
            width: 20px;
            background-repeat: no-repeat;
            background-size: 100%;
            position: absolute;
            left: -25px;
            top: -2px;
        }
    }
}
