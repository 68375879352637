.category-top {
    border-top: 1px solid $sand-light;
    padding-top: 22px;
    padding-bottom: 22px;

    @include bp(75) {
        padding-top: 55px;
        padding-bottom: 20px;
    }
}

.category-top__content {
    @extend %page-content;

    @include bp(48) {
        margin-top: -10px;
    }
}

.category-top__right-content {
    @include column_style(0, 2);

    @include bp(48) {
        background-color: $sand-lighter;
        @include column_style(48, 3);
    }
    @include bp(75) {
        float: right;
        @include column_style(75, 8);
    }
    @include bp(120) {
        @include column_style(120, 11);
    }
}

.category-top__preamble {
    @include font-size(16px);
    line-height: 1.5;
    font-weight: 600;
    margin: 0;
    margin-top: 10px;
    max-width: 1000px;

    @include bp(75) {
        @include font-size(20px);
    }
}

.category-top__store-selector {
    display: inline-block;
    margin-top: 10px;
    width: 100%;

    @include bp(40) {
        width: auto;
    }

    @include bp(75) {
        max-width: 40%;
        float: right;
    }
}

.category-top-heading {
    color: $black;
    @include font-size(37px);
    font-weight: 800;
    line-height: 1.1em;
    margin: 0;

    @include bp(48) {
        @include font-size(60px);
        line-height: 1em;
    }
    @include bp(75) {
        @include font-size(75px);
        line-height: 1.13333333em;
    }
    @include bp(120) {
        @include font-size(106px);
        line-height: 1em;
    }
}

.category-top-heading__text {
    background-color: $sand-lighter;
    display: block;
    word-wrap: break-word;

    @include bp(48) {
        padding-left: 0;
    }
}
