.ad-container {
    @extend %clearfix;
    margin: 0 auto;
    margin-top: 30px;
    width: 100%;

    &--border-bottom {
        border-bottom: 1px solid $sand-light;
        margin-bottom: 30px;
        margin-top: 0;
    }
}

.ad-container__content {
    width: 100%;
    display: block;
    margin: 0 auto;

    @include bp(48) {
        display: flex;
        flex-wrap: wrap;
        max-width: $content-width__percentage;
        padding: 0 20px 10px;
    }

    @include bp(75) {
        padding: 0 15px 10px;
    }

}

.ad-container--full-width {
    width: 100%;
}

.ad__styles {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top: 1px solid $border-ad;
    border-bottom: 1px solid $border-ad;
    padding: 20px 10px 60px;
    position: relative;
    width: 100%;

    .ad--friends & {
        padding-top: 45px;
    }
    @include bp(75) {
        padding: 50px 30px 90px;
    }
}

.ad {
    // margin-top: 30px;
    margin-bottom: 30px;
    position: relative;
    @extend %clearfix;

    .ad-container--bottom & {
        margin-top: 0;
    }

    &--top {
        margin-bottom: 0;
        margin-top: 0;

        &.ad--friends {
            margin-top: 25px;
        }
    }

    &--friends {
        margin-top: 45px;

        .ad-container--bottom & {
            margin-top: 45px;
        }

        &:before {

            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            content: '';
            display: block;
            height: 100px;
            left: 50%;
            position: absolute;
            top: 0;
            transform: translate(-50%, -50%);
            width: 100px;
            z-index: 1;
        }

        &:before {
            background-image: url('../img/icons/friends-event.svg');
        }

    }

    @include bp(48) {
        display: flex;
        min-height: 280px;
        width: calc(50% - 15px);

        &:nth-child(odd) {
            margin-right: 30px;
        }
    }
}

.ad__heading {
    @include font-size(25px);
    margin: 0 0 10px;
    line-height: 1.5em;
    padding-left: 10px;

    @include bp(75) {
        @include font-size(30px);
    }
    @include bp(120) {
        @include font-size(45px);
    }
}

.ad__body {
    display: inline-block;
    padding-left: 10px;

    @include bp(48) {
        max-width: 75%;
    }

    p {
        color: inherit;
        @include font-size(15px);
        font-weight: 600;
        display: inline;
        padding: .1em 0;

        @include bp(120) {
            @include font-size(23px);
        }
    }

    ul {
        list-style: none;
        margin: 15px 0;
        padding: 0;

        li {
            color: inherit;
            @include font-size(15px);
            font-weight: 600;
            margin-top: 5px;
            margin-left: -10px;
            position: relative;

            @include bp(120) {
                @include font-size(23px);
            }

            span {
                display: inline-block;
                padding: 0 10px;
            }
        }
    }
}

.ad__action {
    bottom: 10px;
    color: inherit;
    display: inline-block;
    @include font-size(15px);
    font-weight: 600;
    max-width: calc(100% - 20px);
    padding: 5px 10px;
    position: absolute;
    right: 10px;
    text-decoration: none;

    @include bp(75) {
        bottom: 30px;
        padding: 10px;
        right: 30px;
    }
    @include bp(120) {
        @include font-size(23px);
    }
}

.ad__chevron {
    height: 10px;
    width: 10px;

    @include bp(75) {
        height: 15px;
        width: 15px;
    }

    .chevron--right {
        fill: currentColor;
    }
}

.ad__text {
    color: inherit;
    line-height: 1.6em;
    padding: .1em 0;
    word-wrap: break-word;
}
