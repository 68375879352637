.filter-expander {
    @extend %clearfix;
}

.filter-expander__title {
    @extend %clearfix;
    border-bottom: 1px solid $sand;
    margin: 0;

    .filter-expander--auto-expand-desktop & {
        @include bp(75) {
            display: none;
        }
    }
}

.filter-expander__expand {
    @extend %page-content;
    @include font-size(16px);
    padding: 15px 20px;
    padding-left: 40px;
    background: transparent;
    border: none;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: left;
    font-weight: 600;
    line-height: 1.2em;
    margin-top: 0;
    position: relative;

    &:after {
        content: '';
        background-image: url('../img/icons/chevron_right_black.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        display: block;
        position: absolute;
        left: 20px;
        height: 15px;
        width: 10px;
        top: 16px;
        transform: rotate(90deg);
        transition: transform .2s ease-in;

        .filter-expander--expanded & {
            transform: rotate(-90deg);
        }

        .filter-expander--auto-expand-desktop & {
            @include bp(75) {
                display: none;
            }
        }
    }
}
