.filter-section {
    border-bottom: 1px solid $sand;
}

.filter-section--sub-section {
    background: $sand;
    border-bottom: 1px solid $gray-light;
}

.filter-section--sub-section-last {
    border-bottom: 1px solid $sand;
}

.filter-section__inner {
    @extend %page-content;
    @extend %clearfix;
}

.filter-section__title {
    margin: 0;
}

.filter-section__expand {
    @include font-size(15px);
    padding: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: left;
    font-weight: 300;
    line-height: 1.2em;
    margin-top: 0;
    position: relative;

    .filter-section--sub-section & {
        @include font-size(15px);
        font-weight: 600;
        padding-left: 40px;

        @include bp(75) {
            padding-left: 20px;
        }
    }

    @include bp(75) {
        cursor: normal;
        pointer-events: none;
    }

    &:after {
        content: '';
        background-image: url('../img/icons/chevron_right_black.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        display: block;
        position: absolute;
        right: 20px;
        height: 15px;
        width: 10px;
        top: 21px;
        transform: rotate(90deg);
        transition: transform .2s ease-in;

        .filter-section--expanded & {
            transform: rotate(-90deg);
        }

        @include bp(75) {
            display: none;
        }
    }

    @include bp(75) {
        @include font-size(23px);
    }
}

.filter-section__content {
    padding: 20px;

    .filter-section--sub-section & {
        padding-left: 40px;

        @include bp(75) {
            padding-left: 20px;
        }
    }

    .filter-section--has-title & {
        padding-top: 0;
    }
}
