
.product-filter-block {
    background: $sand-light;
}

.product-filter {
    @extend %page-content;

    @include bp(48) {
        padding-left: 30px;
        padding-right: 30px;
    }
    @include bp(75) {
        padding-left: 10px;
        padding-right: 10px;
    }
    @include bp(120) {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.product-filter-form {
    padding-bottom: 20px;

    @include bp(48) {
        padding-bottom: 0;
    }

    @include bp(75) {
        padding-bottom: 20px;
    }

    .sort-select-section & {
        padding-bottom: 0;
    }

    .product-filter-wrapper & {
        display: inline-block;
    }

    .search_result_page & {
        max-height: none;
        background-color: $sand-light;
    }

    .custom-select {
        width: 100%;

        @include bp(48) {
            padding-left: 75px;
        }
    }
}

.product-filter-fieldset {
    &.top {
        padding-top: 20px;
        position: relative;
    }
    @extend %full-width-content;
    margin: 0;
    border: 0;

    @include bp(48) {
        padding: 0;
    }
    @include bp(75) {
        padding: 0 15px;
    }
}

.product-filter-fieldset__legend {
    float: left;
    display: none;
    @include font-size(23px);
    font-weight: 300;
    line-height: 1.2em;
    margin-right: 20px;
    margin-top: 23px;

    @include bp(48) {
        display: inline-block;
    }

    @include bp(75) {
        border: 0;
        @include font-size(30px);
    }
}

.product-filter-list {
    list-style: none;
    margin: 5px 0 0;
    padding: 0;
    display: inline-block;

    .search_result_page & {
        border-top: 0;
    }

    @include bp(48) {
        margin-top: 0;
    }
}

.product-filter-wrapper {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height .3s ease-in;
    transition: max-height .3s ease-in;

    &--active {
        max-height: 500px;

        &.product-filter-wrapper__border {
            border-top: 1px solid $border-product-filter;
        }
    }
}

.product-filter-list__item {
    display: inline-block;
    margin-top: 15px;

    @include bp(48) {
        margin-top: 20px;
    }
}

.touch .product-filter-label {

    &:hover {
        background-color: transparent;
        color: $black;
    }

    &--2 {
        &:hover {
            &:before {
                background-image: url('../img/icons/bindings.svg');
            }
        }
    }

    &--1 {
        &:hover {
            &:before {
                background-image: url('../img/icons/pocket.svg');
            }
        }
    }

    &--4 {
        &:hover {
            &:before {
                background-image: url('../img/icons/hardcover.svg');
            }
        }
    }

    /** Audio book **/
    &--10 {
        &:hover {
            &:before {
                background-image: url('../img/icons/audiobook.svg');
            }
        }
    }
}

.product-filter-label {
    border: 2px solid $black;
    border-radius: 5px;
    color: $black;
    cursor: pointer;
    display: block;
    @include font-size(15px);
    font-weight: 600;
    line-height: 1;
    margin-right: 10px;
    padding: 3px 10px;
    transition: all .2s ease-in;

    &:before {
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        content: '';
        display: inline-block;
        height: 15px;
        position: relative;
        top: 2px;
        width: 15px;
    }

    &:hover {
        background-color: $black;
        color: $sand-lighter;
    }

    &--2 {
        &:before {
            background-image: url('../img/icons/bindings.svg');
        }

        &:hover {
            &:before {
                background-image: url('../img/icons/bindings--inverted.svg');
            }
        }
    }

    &--1 {
        &:before {
            background-image: url('../img/icons/pocket.svg');
        }

        &:hover {
            &:before {
                background-image: url('../img/icons/pocket--inverted.svg');
            }
        }
    }

    &--4 {
        &:before {
            background-image: url('../img/icons/hardcover.svg');
        }

        &:hover {
            &:before {
                background-image: url('../img/icons/hardcover--inverted.svg');
            }
        }
    }

    /** Audio book **/
    &--10 {
        &:before {
            background-image: url('../img/icons/audiobook.svg');
        }

        &:hover {
            &:before {
                background-image: url('../img/icons/audiobook--inverted.svg');
            }
        }
    }

    @include bp(75) {
        border: 3px solid $black;
        padding: 7px 20px;
        height: 40px;
    }
}

.product-filter-checkbox {
    @extend %visually-hidden;

    &:checked + .product-filter-label {
        background: $black;
        border-color: $black;
        color: $sand-lighter;

        &--2 {
            &:before {
                background-image: url('../img/icons/bindings--inverted.svg');
            }
        }

        &--1 {
            &:before {
                background-image: url('../img/icons/pocket--inverted.svg');
            }
        }

        &--4 {
            &:before {
                background-image: url('../img/icons/hardcover--inverted.svg');
            }
        }

        /** Audio book **/
        &--10 {
            &:before {
                background-image: url('../img/icons/audiobook--inverted.svg');
            }
        }

        &:after {
            display: inline-block;
            background-image: url('../img/icons/cross.svg');
            content: '';
            margin-left: 5px;
            width: 10px;
            height: 10px;
        }
    }
}

.product-filter-store-fieldset {
    max-width: none;
    width: auto;
    padding: 0;
    line-height: 32px;

    @include bp(40) {
        float: left;
    }

    @include bp(48) {
        max-width: calc(100% - 84px);
    }

    @include bp(75) {
        line-height: 40px;
        max-width: calc(100% - 40% - 84px);
    }
}

.product-filter-store__checkbox,
.coming-books__checkbox {
    @extend %visually-hidden;

    &:checked + .product-filter-store__label,
    &:checked + .coming-books__label {

        &:before {
            background-image: url('../img/icons/0273-checkmark.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 80%;
        }
    }
}

.product-filter-store__label,
.coming-books__label {
    cursor: pointer;
    display: inline-block;
    @include font-size(15px);
    position: relative;
    padding-top: 0;
    line-height: 25px;
    padding-left: 34px;

    &:before {
        border: 2px solid $black;
        border-radius: 5px;
        content: '';
        cursor: pointer;
        display: inline-block;
        height: 25px;
        left: 0;
        margin-right: 10px;
        width: 25px;
        vertical-align: middle;
        position: absolute;

        @include bp(40) {
            top: 50%;
            transform: translateY(-50%);
        }

        @include bp(75) {
            border: 3px solid $black;
            height: 25px;
            width: 25px;
        }
    }

    @include bp(48) {
        max-width: inherit;
        padding-top: 0;
    }

    @include bp(75) {
        line-height: 30px;
        padding-left: 34px;
        height: auto;
        margin-bottom: 0;
    }
}

.coming-books__label {
    margin-bottom: 22px;

    @include bp(75) {
        margin-bottom: 0;
    }
}

.store-label--bold {
    font-weight: 600;
    margin-right: 10px;

    @include bp(48) {
        padding-top: 0;
        margin-right: 0;
    }
}

.product-filter-sort {
    @extend %clearfix;
    @extend %full-width-content;
}

.product-filter-sort__legend {
    @include font-size(15px);
    font-weight: 600;
    float: left;
    padding: 0 0 0 10px;
    transform: translateY(-50%);
    position: relative;
    top: 50%;
    line-height: 30px;
}

.custom-select-wrapper {
    border: 2px solid $black;
    border-radius: 5px;
    position: relative;
    height: 32px;
    margin-bottom: 20px;
    min-width: 186px;
    width: 66%;
    margin-right: 10px;
    float: left;
    margin-top: 0;
    overflow: hidden;

    .product-filter-sort__legend {
        display: none;
    }

    &:after {
        background-color: $black;
        background-image: url('../img/icons/chevron_down--inverted.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 40%;
        border-left: 2px solid $black;
        content: '';
        display: block;
        height: 100%;
        padding-top: 3px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 32px;
        z-index: 0;

        @include bp(75) {
            width: 40px;
        }
    }

    @include bp(48) {
        min-width: 50%;
        width: auto;

        .product-filter-sort__legend {
            display: block;
        }

        .calendar-filter & {
            min-width: 28%;
            margin-right: 20px;
            margin-bottom: 0;
        }
    }

    @include bp(75) {
        height: 46px;
        border: 3px solid $black;
        width: 38%;
        min-width: inherit;

        &:after {
            border-left: 3px solid $black;
        }
    }
}

.custom-select-overflow {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

.custom-select {
    appearance: none;
    background-color: transparent;
    border: 0;
    box-sizing: content-box;
    @include font-size(16px);
    font-weight: 600;
    position: absolute;
    z-index: 1;
    box-sizing: border-box;
    transform: translateY(-50%);
    top: 50%;
    width: 105%;
    padding: 0;
    margin-left: 10px;
    height: 100%;
    cursor: pointer;

    .sale-filter-fieldset & {
        @include font-size(14px);
    }


    @include bp(48) {
        margin-left: 10px;
        .sale-filter-fieldset & {
            @include font-size(16px);
        }
    }
}

.custom-select__option {
    padding-left: 5px;
}

.selected-store {
    position: relative;
    transform-style: preserve-3d;
}

.selected-store__external-link {
    color: $black;
    text-decoration: none;
    font-weight: 700;
    position: relative;
    white-space: nowrap;

    &:after {
        content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .';
        position: absolute;
        white-space: nowrap;
        overflow: hidden;
        bottom: -3px;
        left: 0;
        right: 0;
        font-weight: 700;
        font-size: 13px;
        line-height: 13px;
    }
}

.pick-store {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 700;
    white-space: nowrap;
    margin-right: 10px;

    @include bp(48) {
        margin-left: 10px;
        display: inline;
    }
}

.pick-store--not-selected {
    white-space: normal;
    text-decoration: none;
    background-color: $sand-lighter;
    font-weight: 400;
    display: block;
    margin-top: 10px;
    position: relative;
    left: -34px;
    margin-right: -34px;

    @include bp(40) {
        display: inline;
        margin-top: 0;
        margin-right: 0;
        left: 0;
    }

    &:hover {
        background: $sand;
    }
}

.pick-store--change {
    @include clear-button-style();
}

.pick-store__choose-store {
    text-decoration: underline;
    font-weight: 700;
}

.more-choices-wrapper {
    position: relative;
    display: inline-block;
    float: right;
    right: 0;
    margin-left: 15px;
    line-height: 32px;
    margin-bottom: 20px;
    margin-top: 10px;

    @include bp(40) {
        margin-top: 0;
    }

    @include bp(75) {
        line-height: 40px;
    }
}

.product-filter__more-choices {
    text-decoration: none;
    color: $black;
    display: inline-block;
    position: relative;
    font-weight: 700;
    @include font-size(15px);

    &:before {
        content: '';
        background-image: url('../img/icons/chevron_right_black.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 95%;
        display: inline-block;
        vertical-align: middle;
        right: -20px;
        position: relative;
        height: 20px;
        width: 12px;
        transform: rotate(90deg);
        right: 10px;
        transition: transform .2s ease-in;
    }

    &.open {
        &:before {
            transform: rotate(-90deg);
            transition: transform .2s ease-in;
        }
    }

    @include bp(75) {
        top: 50%;
        line-height: 30px;
    }
}

.selected-store--no-store {
    display: inline-block;
    @include font-size(15px);
    font-weight: 600;
}

.coming-books-wrapper {
    display: inline-block;
    vertical-align: top;
    margin: 0 20px;

    @include bp(48) {
        margin-top: 20px;
        margin-left: 0;
        margin-right: 0;
    }

    @include bp(75) {
        margin-top: 26px;
    }
}

.coming-books-fieldset {
    border: 0;
    margin: 0;
    padding: 0;
    position: relative;
    height: 100%;
}

.sort-select-wrapper {
    margin-left: 20px;
    border: 2px solid $black;
    border-radius: 5px;
    position: relative;
    height: 32px;
    min-width: 270px;
    width: auto;
    margin-right: 10px;
    float: left;
    overflow: hidden;

    .product-filter-sort__legend {
        display: none;
    }

    &:after {
        background-color: $black;
        background-image: url('../img/icons/chevron_down--inverted.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 40%;
        border-left: 2px solid $black;
        content: '';
        display: block;
        height: 100%;
        padding-top: 3px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 32px;
        z-index: 0;

        @include bp(75) {
            width: 40px;
        }
    }

    @include bp(48) {
        min-width: 50%;
        margin-left: 0;
        float: right;
        height: 40px;
        border: 3px solid $black;
        width: 50%;
        min-width: inherit;

        .calendar-filter & {
            min-width: 28%;
            margin-right: 20px;
            margin-bottom: 0;
        }
    }

    @include bp(75) {
        width: 38%;

        &:after {
            border-left: 3px solid $black;
        }
    }
}

.sort-select__legend {
    @include font-size(15px);
    font-weight: 600;
    float: left;
    padding: 0 0 0 10px;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    line-height: 30px;
    display: none;

    @include bp(48) {
        display: inline-block;
    }
}

.topic-filter-breadcrumbs  {
    display: none;

    &--wrapper {
        @extend %page-content;
        padding: 0 20px;

        @include bp(48) {
            padding-top: 10px;
            padding: 0 30px;
        }
        @include bp(75) {
            padding-left: 35px;
            padding-right: 35px;
        }
        @include bp(120) {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    &--active {
        display: block;
        margin-bottom: 5px;

        @include bp(48) {
            margin-bottom: 0;
        }
    }

    .filter-breadcrumb__label {
        margin-right: 10px;
    }

    .filter-breadcrumb__item {
        margin-bottom: 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    @include bp(48) {
        position: relative;
        width: 55%;
        top: 0;
        left: 15px;
        float: left;
    }
}

.topic-filter-choices-wrapper {
    position: relative;
    border-bottom: 1px solid $border-product-filter;
    padding: 15px 0;

    @include bp(48) {
        padding: 19px 0 20px;
    }
}
