.debug-info {
    pointer-events: none;
    background: $sand-light;
    color: $black;
    position: fixed;
    left: 0;
    bottom: 5px;
    padding: 3px 6px;

    &:after {
        content: '0';

        @include bp(40) {
            content: '40';
        }

        @include bp(48) {
            content: '48';
        }

        @include bp(75) {
            content: '75';
        }

        @include bp(120) {
            content: '120';
        }
    }
}