.show-more-toggle {
    position: relative;
}

.show-more-toggle__content-wrapper {
    overflow: hidden;
    position: relative;
    transition: height .2s ease-in-out;
}

.show-more-toggle__content {
    @extend %clearfix;
}

.show-more-toggle__expand-button {
    @include clear-button-style();
    position: absolute;
    bottom: 0;
    left: 0;
    appearance: none;
    width: 100%;
    color: $black;
    display: block;
    margin-top: -10px;
    text-align: right;
    height: 15px;
    display: block;

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        border-bottom: 1px solid $sand-light;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &:hover {
        text-decoration: underline;
    }

    @include bp(120) {
        @include font-size(18px);
    }
}

.show-more-toggle__overlay {
    bottom: -10px;
    left: 0;
    right: 0;
    position: absolute;
    height: 100px;
    background: linear-gradient(180deg, rgba($white, .1) 0%, $sand-lighter 75%);
    opacity: 1;
    transition: opacity .2s linear;

    .show-more-toggle--expanded & {
        opacity: 0;
        height: 20px;
        transition: opacity .2s linear, height 0s linear .2s;
    }
}


.show-more-toggle__expand-text {
    @include font-size(15px);
    font-family: $font-family--primary;
    background-color: $sand-lighter;
    z-index: 1;
    padding-left: 10px;
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 600;
    line-height: 15px;

    &:after {
        display: inline-block;
        content: url('../img/icons/chevron_down.svg');
        margin-left: 5px;
        transition: transform .2s ease-in-out .1s;

        .show-more-toggle--expanded & {
            transform: rotate(180deg);
        }
    }
}
