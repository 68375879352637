.reservations-banner__container {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: $sand-light;
    text-decoration: none;

    &--open {
        position: fixed;
        top: 55px;
        z-index: 10000;
        @include bp (48) {
            position: static;
        }
    }
}

.reservations-banner__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;

    @include bp(48) {
        max-width: 93%;
        padding: 0 0 0 30px;
    }

    @include bp(75) {
        padding: 0 25px;
    }
}

.reservations-banner__product-summary {
    font-weight: 800;
    margin: 12px 0;

    &:before {
        display: inline-block;
        content: '';
        width: 18px;
        height: 18px;
        background-size: contain;
        margin-right: 15px;
        position: relative;
        top: 3px;

    }

    &--in-stock {
        &:before {
            background-image: url('../img/icons/book--yes.svg');
        }
    }

    &--not-in-stock {
        &:before {
            background-image: url('../img/icons/book--no.svg');
        }
    }
}

.reservations-banner__button {
    margin: 0;
    text-decoration: none;
    font-weight: 550;

    &:after {
        display: inline-block;
        content: '';
        width: 15px;
        height: 15px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 10px;
        position: relative;
        top: 5px;
    }

    &--closed {
        
        &:after {
            background-image: url('../img/icons/chevron_down.svg');
        }
    }

    &--open {
        &:after {
            top: 0;
            transform: scaleY(-1);
            background-image: url('../img/icons/chevron_down.svg');
        }
    }
}