/* Base styling
* ----------------------------------------------------
*/


*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    @include font-size($font-size--base);
}

body {

    background-color: $sand-lighter;

    &.store-admin {
        color: $black-admin;
    }

    color: $black;
    position: relative;

    font-family: $font-family--primary;

    @include current-breakpoint('bp');

    @include bp(48) {
        @include current-breakpoint('bp-48');
    }

    @include bp(75) {
        @include current-breakpoint('bp-75');
    }
    @include bp(120) {
        @include current-breakpoint('bp-120');
    }

    .no-touch & {
        &.active-receipt {
            position: fixed;
            width: 100%;
        }
    }
}

button {
    background: transparent;
    border: none;
    text-decoration: underline;
    padding: 0;
}

.active-overlay {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.lock {
    overflow: hidden;
    position: fixed;
    width: 100%;
}

.visual-bar {
    background: $black;
    display: none;
    height: 10px;
    left: -20px;
    position: relative;
    top: 10px;
    z-index: -1;
    width: 100%;

    @include bp(48) {
        display: block;
        width: 50%;
    }
    @include bp(75) {
        height: 15px;
        top: 15px;
    }
    @include bp(120) {
        height: 20px;
        top: 20px;
    }

    &--hero {
        display: none;

        @include bp(48) {
            display: block;
            position: absolute;
            left: 0;
            top: 60px;
        }
        @include bp(75) {
            top: 90px;
        }
        @include bp(120) {
            top: 90px;
        }
    }
}

a[href^=tel] {
    color: inherit;
    text-decoration: none;
}

.filter-form {
    input[type='submit'] {
        display: none;
    }
}

.toggle-password {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.toggle-password__input {
    @extend %visually-hidden;
}

.toggle-password__label {
    background: transparent;
    border: 2px solid $sand-light;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    @include font-size(12px);
    font-weight: 600;
    line-height: 1;
    text-transform: capitalize;
    padding: 5px 25px 5px 5px;
    position: relative;
    z-index: 1;
    user-select: none;

    &:after {
        background-color: transparent;
        background-image: url('../img/icons/eye.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        height: 100%;
        content: '';
        display: inline-block;
        position: absolute;
        right: 5px;
        top: 0;
        width: 15px;

        @include bp(75) {
            right: 10px;
        }
    }

    &--hide {
        background: $sand-light;

        &:after {
            background-image: url('../img/icons/eye-blocked.svg');
        }
    }

    @include bp(75) {
        padding: 10px 30px 10px 10px;
    }
}
