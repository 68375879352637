.calendar-filter-section{
    background-color: $sand-light;
}
.calendar-filter{
    border-bottom: 1px solid $border-calendar-filter;
}
.calendar-filter__content{
    @extend %page-content;
    padding: 30px 20px;

    @include bp(48){
        padding: 30px 30px;
    }
    @include bp(75){
        padding: 30px 15px;
    }
}
.calendar-filter__heading{
    float: left;
    @include font-size(23px);
	font-weight: 300;
    margin: 1px 20px 15px 0;

    @include bp(48){
        margin-bottom: 0;
    }
    @include bp(75){
		@include font-size(30px);
        margin-top: 5px;
	}
}
.search-event-fieldset {
  border: 0;
  margin: 0;
  padding:0;
  position: relative;
  transition: transform 0.3s ease-in;
  height: 100%;

  &--active {

    .search-input-event__label:before {
      opacity: 0;
      transform: translateX(50%);
      transition-delay: 0s;
    }

    .search-event__button {
      transition-delay: 0.2s;
    }
  }
}
.search-event__input {
  background-color: transparent;
  border: 0;
  position: absolute;
  width: 90%;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 20px 0 0;
  margin-left: -5px;
  @include bp(75){
    margin-left: 0;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    cursor: auto;
  }

  &:focus + .search-button {
    opacity: 1;
    transform: translateX(0%);
  }

  &::-webkit-input-placeholder {
    color: $black;
    font-weight: 600;
    font-style: italic;
  }

  &:-moz-placeholder {
    color: $black;
    font-weight: 600;
    font-style: italic;
  }

  &::-moz-placeholder {
    color: $black;
    font-weight: 600;
    font-style: italic;
  }

  &:-ms-input-placeholder {
    color: $black;
    font-weight: 600;
    font-style: italic;
  }

  @include font-size(23px);
}

.search-event__button {
  font-weight: 600;
  position: relative;
  z-index: 1;
  display: inline-block;
  border: 0;
  width: 40px;
  height: 100%;
  background: transparent;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;

   @include bp(75)  {
    width: 50px;
    background: $black;
    position: absolute;
    right: 0;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  &:hover {
    cursor: pointer;
  }
  &:after {
    content: '';
    display: inline-block;
    background-image: url(../img/icons/0135-search.svg);

     @include bp(75)  {
      position: absolute;
       border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      background-image: url(../img/icons/0135-search--inverted.svg);
    }
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 20px;
    transition: all 0.3s ease-in;
    transition-delay: 0.2s;
    width: 20px;
    left: 50%;
    top: 50%;
    margin-top: -10px;
    margin-left: -10px;
    position: absolute;
  }
}
.search-event-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  background: $sand-lighter;
  > label {
    margin-left: 50px;
  }
  border-radius: 5px;
  height: 32px;
  float: left;
  margin-bottom: 20px;

  @include bp(48){
      margin-bottom: 0;
      width: 40%;
  }
  @include bp(75) {
    height: 46px;
    width: 35%;
    padding-left:10px;
  }

  .search-event__input,
  .search-event__label {
    @include font-size(16px);
    &:before {
      background-size: 50% auto;
    }
  }
}



.calendar-stores__content{
    @extend %page-content;
    padding: 30px 0;
}
.calendar-stores__heading{
    float: left;
    @include font-size(23px);
	font-weight: 300;
    line-height: 32px;
    margin: 5px 20px 0 20px;

    @include bp(48){
        margin: 5px 20px 0 30px;
    }
    @include bp(75){
		@include font-size(30px);
        line-height: 44px;
        margin: 5px 20px 0 15px;
	}
}
.event-stores{
    list-style: none;
    margin: 0;
    padding: 0 20px;

    @include bp(48){
        padding: 0 30px;
    }
    @include bp(75){
        padding: 0 15px;
    }
}
.event-stores__item{
    border: 2px solid $black;
	border-radius: 5px;
    color: $black;
	cursor: pointer;
	display: inline-block;
	@include font-size(15px);
	font-weight: 600;
	line-height: 1.2em;
	margin-top: 5px;
	margin-bottom: 5px;
    margin-right: 8px;
	padding: 5px 10px;
    text-decoration: none;
    transition: all 0.2s ease-in;

    .no-touch & {
        &:hover{
            background: $black;
            color: $sand-lighter;
        }
    }

    @include bp(75){
    	border: 3px solid $black;
    	padding: 10px 20px;
    }

    &.selected{
        background: $black;
		color: $sand-lighter;

        &:after{
			display: inline-block;
			content: url(../img/icons/cross.svg);
			margin-left: 5px;
		}
    }
}
.calendar {
  .custom-select-wrapper {
    width: 100%;
    margin-bottom: 0;

    @include bp(48){
      width: 50%;
    }
    @include bp(75){
      width: 38%;
    }
  }
}
