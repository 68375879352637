.slide-down-expander {
    overflow: hidden;
    max-height: 0;
    visibility: hidden;
    transition: max-height 0s linear .2s, visibility 0s linear .2s;

    &.slide-down-expander--expanded {
        transition-property: none;
        max-height: 9999px;
        visibility: visible;
    }

    &.slide-down-expander--auto-expand-desktop {
        @include bp(75) {
            transition-property: none;
            max-height: 9999px;
            visibility: visible;
        }
    }
}

.slide-down-expander__inner {
    transition: opacity .2s linear, transform .2s ease-out;
    opacity: 0;
    transform: translateY(-80%);

    .slide-down-expander--expanded > & {
        transition: opacity .2s linear, transform .2s ease-out;
        opacity: 1;
        transform: translateY(0);
    }

    .slide-down-expander--auto-expand-desktop > & {
        @include bp(75) {
            transition-property: none;
            opacity: 1;
            transform: translateY(0);
        }
    }
}
