
.book-list-carousel {
    overflow: hidden;
    padding-top: 20px;
    border-top: 1px solid $sand-light;
    display: flex;
    justify-content: center;
    
    .slick-track {
        display: flex;
    }
    &--campaign-page-view {
        padding-top: 0;
    }

    @include bp(48) {
        padding-bottom: 20px;
    }
}

.book-list-carousel__content {
    @include column_style(0, 2);

    @include bp(48) {
        @include column_style(48, 3);
        padding-bottom: 20px;
    }
    @include bp(75) {
        @include column_style(75, 9);
        padding-bottom: 20px;
    }
    @include bp(120) {
        @include column_style(120, 12);
        padding-bottom: 40px;
    }
}

.book-list-carousel--no-border-top {
    border-top: none;
}

.book-list-carousel__inner {
    @extend %page-content;
    @include bp(48) {
        @include column_style(48, 3);
        width: 90%;
    }

    &--campaign-page-view {
        @include bp(100) {
            width: 80%;
        }
        &:before {
            content: ''; 
            display: block; 
            margin: 0 auto; 
            width: 100%; 
            border-top: 1px solid $sand-light;
            padding-bottom: 20px;
        }
    }    
}

.book-list-carousel__header {
    position: relative;
    color: $black;
    @extend %clearfix;
    @extend %full-width-content;

    //padding: 0 15px;
    margin-bottom: 15px;
}

.book-list-carousel__heading {
    @include font-size(18px);
    line-height: 1.2em;
    margin: 0;

    @include bp(40) {
        float: left;
    }

    @include bp(48) {
        @include font-size(23px);
    }
    @include bp(75) {
        @include font-size(30px);
    }
}

.book-list-carousel__heading-link {
    color: $black;
    text-decoration: none;

    &:visited {
        color: $black;
    }
}

.book-list-carousel__show-all {
    color: $black;
    display: inline-block;
    @include font-size(13px);
    font-weight: 600;
    margin-top: 3px;
    padding-right: 16px;
    position: relative;
    text-decoration: none;

    &:after {
        background-image: url('../img/icons/chevron_right.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 95%;
        content: ' ';
        display: inline-block;
        height: 10px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;

        @include bp(48) {
            height: 20px;
            width: 22px;
        }
    }

    @include bp(40) {
        float: right;
        margin-top: 0;
    }

    @include bp(48) {
        @include font-size(18px);
        line-height: 25px;
        padding-right: 30px;
    }
    @include bp(75) {
        line-height: 35px;
    }
}

.book-list-carousel__list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    // All other slick-specific styles used by .book-list-carousel are the generic ones,
    // defined in the 'Custom slick styles' section in _.carousel.scss.

    &:not(.slick-slider) {
        display: flex;
    }
}

.book-list-carousel__item {
    display: flex;
    float: left;
    width: 50%;
    opacity: .2;
    margin-right: 30px;
    
    @include bp(48) {
        visibility: hidden;
    }
    
    &:nth-child(n + 3) {
        display: none;
    }

    @include bp(40) {
        width: 33.3333%;

        &:nth-child(n + 3) {
            display: flex;
        }

        &:nth-child(n + 4) {
            display: none;
        }
    }

    @include bp(64) {
        width: 25%;

        &:nth-child(n + 4) {
            display: flex;
        }

        &:nth-child(n + 5) {
            display: none;
        }
    }

    @include bp(75) {
        width: 20%;

        &:nth-child(n + 5) {
            display: flex;
        }

        &:nth-child(n + 6) {
            display: none;
        }
    }

    @include bp(100) {
        width: 14.2%;

        &:nth-child(n + 7) {
            display: flex;
        }

        &:nth-child(n + 6) {
            display: none;
        }
    }

    @include bp(120) {
        width: 11.1%;

        &:nth-child(n + 7) {
            display: flex;
        }

        &:nth-child(n + 9) {
            display: none;
        }
    }

    .slick-initialized & {
        display: flex;
        height: auto;
    }
}

.slick-active {
    opacity: 1;
    @include bp(40) {
        visibility: visible;
    }
    
}

.slick-dots {
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin-top: 40px;
    padding: 0;
}

.slick-dots li {
    margin: 0 5px;
    display: none;
    &:hover {
        cursor: pointer;
    }
    &:before {
        content: ' ';
        display: block; 
        margin: 0 auto; 
        width: 10px;
        height: 10px;
        border-radius: 50%; 
        background-color: $black;
        opacity: .5;
    }

    @include bp(48) {
        display: block;
    }
}

.slick-dots li.slick-active {
    &:before {
        opacity: 1;
    }
}






