.select {
    position: relative;
    border: 1px solid $border-total;
    border-radius: 5px;
    height: 40px;
    width: auto;
    display: inline-block;
    line-height: 40px;
    background-color: $sand-lighter;

    &:after {
        background-image: url('../img/icons/chevron_down.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 40%;
        border: 2px solid $black;
        border-radius: 0 5px 5px 0;
        content: '';
        display: block;
        height: calc(100% + 2px);
        padding-top: 3px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 40px;
        z-index: 0;
        margin: -1px;
    }
}

.select--small {
    height: 30px;
    line-height: 23px;

    &:after {
        width: 23px;
    }
}

.select__input {
    @include font-size(18px);
    display: inline-block;
    appearance: none;
    border: 0;
    cursor: pointer;
    background-color: transparent;
    font-weight: 600;
    height: 100%;
    left: 0;
    padding: 0 50px 0 10px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 105%;
    min-width: 90px;

    .select--small & {
        padding: 2px 30px 0 10px;
        min-width: 60px;
    }

    @-moz-document url-prefix() {
        text-indent: 2px;
    }

    &::-ms-expand {
        display: none;
    }
}
