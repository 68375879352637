.tabs {
    border-bottom: 1px solid map-get($project-color, color-12);
}

.tabs__content {
    @extend %page-content;
}

.tabs__title {
    @include font-size(18px);
    border-bottom: 1px solid map-get($project-color, color-12);
    font-weight: 300;
    margin-bottom: 0;
    padding-bottom: 15px;
    text-align: center;

    @include bp(48) {
        float: left;
        border-bottom: none;
        padding-bottom: 0;
        text-align: left;
        line-height: 50px;
        margin-top: 0;
        margin-right: 30px;
        margin-left: 20px;
    }
}

.tabs__tabs {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    display: flex;

    @include bp(48) {
        border-left: 1px solid map-get($project-color, color-12);
        float: left;
        width: auto;
    }
}

.tabs__tab {
    border-right: 1px solid map-get($project-color, color-12);
    display: inline-block;
    flex: 1 1 auto;

    &:last-child {
        border-right: none;
    }

    @include bp(48) {
        &:last-child {
            border-right: 1px solid map-get($project-color, color-12);
        }
    }
}

.tabs__tab-title {
    display: none;

    @include bp(48) {
        display: inline;
    }
}

.tabs__tab-short-title {
    @include bp(48) {
        display: none;
    }
}

.tabs__tab {
    transition: background-color .1s linear;
}

.tabs__tab--selected,
.tabs__tab:hover {
    background-color: map-get($project-color, color-3);;
}

.tabs__tab-button {
    @include font-size(16px);
    background: none;
    border: none;
    line-height: 20px;
    padding: 15px 17px;
    font-weight: 600;
    width: 100%;
    height: 100%;
    display: block;
}
