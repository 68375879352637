.event-module {
    box-sizing: border-box;
    color: $black;
    display: block;
    float: left;
    padding: 0 20px;
    text-align: center;
    text-decoration: none;

    @include bp(48) {
        padding: 0 30px;
    }
    @include bp(75) {
        padding: 0 15px;
    }

    .calendar-events & {
        padding: 50px 20px 10px;
        width: 100%;

        @include bp(40) {
            padding: 50px 30px 10px;
            width: 50%;
        }
        @include bp(75) {
            padding: 50px 15px 10px;
            width: 33.33333333%;
        }
        @include bp(120) {
            padding: 50px 15px 10px;
            width: 20%;
        }
    }

    .event-carousel & {
        transition: transform .2s ease-in;

        .touch & {
            padding: 50px 10px 0;
        }

        &:focus {
            transform: scale(1.05);
        }
    }

}

.event-module__content {
    background-color: $bg-event-module;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 440px;
    padding: 60px 20px 30px;
    position: relative;
    border-radius: 5px;

    &:before {

        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        content: '';
        display: block;
        height: 70px;
        left: 50%;
        position: absolute;
        top: 0;
        transform: translate(-50%, -50%);
        width: 70px;
    }

    &--friend {
        &:before {
            background-image: url('../img/icons/friends-event.svg');
        }
    }
}

.event-vertical {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.event-module-details {
    margin: 0;
}

.event-module-details__date {
    display: block;
    @include font-size(20px);
    font-weight: 800;
    margin-top: 5px;

    &--short-title {
        @include font-size(25px)
    }
}

.event-module-details__place {
    display: block;
    @include font-size(15px);

    // @include bp(120){
    //     @include font-size(23px);
    // }
}

.event-module__heading {
    @include font-size(22px);
    font-weight: 800;
    margin: 0 0 5px;

    &--short-title {
        @include font-size(30px)
    }

    // @include bp(120){
    //     @include font-size(30px);
    // }
}

.event-module__time {
    @include font-size(15px);
    font-weight: 700;
    margin: 10px 0 0;
}

.event-people {
    margin-top: 20px;
    text-align: center;
}

.event-people__person {
    border-radius: 100%;
    height: 60px;
    margin: 0 2px;
    width: 60px;

    @include bp(40) {
        margin: 0 4px;
    }

    @include bp(120) {
        height: 75px;
        margin: 0 6px;
        width: 75px;
    }

    .slick-slide & {
        display: inline-block;
    }
}
