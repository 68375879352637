.book_module {
    position: relative;
    @include bp(48) {
        margin-top: 15px;
    }

    @include bp(120) {
        margin-top: 40px;
    }
}

.book_module__hero {
    position: relative;
    width: 100%;
}

.module-blur {
    height: 70%;
    left: 0;
    line-height: 0;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    &:before {
        background: rgba(0, 0, 0, .1);
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.module-blur__effect {
    background-color: $black;
    height: 100%;
    width: 100%;
}

.book_module__content {
    padding-bottom: 15px;
    position: relative;

    @include bp(75) {
        padding-bottom: 30px;

        &--non-book {
            padding-bottom: 30px;
        }
    }
    @include bp(120) {
        padding-bottom: 40px;
    }
}

.module-item {
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    text-align: center;
    z-index: 1;

    @include bp(48) {
        padding: 0 30px;
    }

    @include bp(75) {
        padding: 0 15px;
    }
}

.module-item-img-wrapper {
    display: inline-block;
    position: relative;
    max-width: 65%;

    @include bp(48) {
        max-width: 100%;
    }
}

.module-item__img {
    box-shadow: 0 5px 35px 0 rgba(0, 0, 0, .1);
    max-width: 100%;
    width: 60%;


}

.module-item__img-non-book {
    width: 400px;
    max-width: 100%;
}

.module-item__title {
    background-color: $sand-lighter;
    @include font-size(23px);
    line-height: 1.2em;
    margin: 20px 0 0;

    @include bp(48) {
        @include font-size(45px);
        line-height: 1em;
        margin: 30px 0 0;
    }

    @include bp(120) {
        @include font-size(53px);
        line-height: 1em;
    }
}

.module-item__author {
    background-color: $sand-lighter;
    @include font-size(15px);
    font-weight: 300;
    line-height: 1.2em;
    margin: 5px 0 0;

    @include bp(48) {
        @include font-size(23px);
        margin: 10px 0 0;
    }

    @include bp(120) {
        @include font-size(37px);
    }
}

.module-item-price {
    background-color: $sand-lighter;
    border-top: 1px solid $sand-light;
    border-bottom: 1px solid $sand-light;
    @include font-size(23px);
    font-weight: 700;
    margin: 15px 0 0;
    padding: 10px 0;
    text-align: center;

    &--special {
        .module-item-price__large {
            color: $red;

            &--1 {
                color: $friend-price;
                display: inline-block;
                line-height: 35px;
                padding-left: 40px;
                position: relative;

                @include bp(75) {
                    line-height: 60px;
                    padding-left: 65px;
                }

                &:before {
                    background-image: url('../img/icons/heart.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    content: '';
                    display: inline-block;
                    height: 35px;
                    left: 0;
                    margin-right: 10px;
                    position: absolute;
                    width: 35px;

                    @include bp(75) {
                        height: 60px;
                        width: 60px;
                    }
                }
            }
        }
    }

    &__large {
        margin-right: 5px;
    }

    &__small {
        @include font-size(15px);
        font-weight: 300;

        @include bp(120) {
            @include font-size(23px);
        }
    }

    @include bp(48) {
        margin: 20px 0 0;
        @include font-size(30px);
    }

    @include bp(120) {
        @include font-size(37px);
    }
}

/* Double module */

.double-module {
    @extend %page-content;
}

.double-module__content {
    @extend %page-content;
    @extend %clearfix;
    padding: 15px 0;
    max-width: 1650px;

    @include bp(48) {
        display: flex;
        justify-content: center;
    }

    @include bp(75) {
        padding: 30px 0;
    }

    @include bp(120) {
        padding: 40px 0;
    }
}

.double-module__item {
    @extend %clearfix;
    padding: 0 20px;
    width: 100%;
    margin-bottom: 30px;

    @include bp(48) {
        width: 50%;
        padding: 0 30px;
        margin-bottom: 0;
    }

    @include bp(75) {
        padding: 0 15px;
    }

    @include bp(120) {
        padding: 0 15px;
    }
}

.double-module__item:last-of-type {
    margin-bottom: 0;
}

.double-module-figure {
    float: left;
    position: relative;
    width: 40%;

    @include bp(40) {
        width: 25%;
    }

    @include bp(48) {
        width: 40%;
    }

    @include bp(64) {
        width: 30%;
    }

    @include bp(75) {
        width: 25%;
    }

    @include bp(120) {
        width: 30%;
    }
}

.module-figure__img {
    width: 100%;
    box-shadow: 0 5px 35px 0 rgba(0, 0, 0, .1);
}

.double-module-info {
    float: left;
    padding: 0 15px;
    width: 60%;

    @include bp(48) {
        padding: 0 0 20px 20px;
        width: 55%;
    }

    @include bp(75) {
        padding: 0 25px 30px;
        width: 60%;
    }

    @include bp(120) {
        padding-top: 0;
        width: 55%;
    }
}

.double-module-info__title {
    color: $black;
    @include font-size(23px);
    line-height: 1.08695652174em;
    margin: 0;
    word-wrap: break-word;

    @include bp(48) {
        @include font-size(23px);
        line-height: 1.304347826em;
    }

    @include bp(75) {
        line-height: 1.166666667em;
    }

    @include bp(120) {
        @include font-size(45px);
        line-height: 1.1111111111em;
    }
}

.double-module-info__author {
    color: $black;
    @include font-size(15px);
    font-weight: 300;
    margin: 5px 0 0;
    word-wrap: break-word;

    @include bp(48) {
        @include font-size(15px);
        margin: 5px 0 0;
    }

    @include bp(75) {
        @include font-size(20px);
        line-height: 1.304347826em;
        margin: 5px 0 0;
    }

    @include bp(120) {
        @include font-size(30px);
        line-height: 1.166666667em;
    }
}

.double-module-info-price {
    display: block;
    @include font-size(23px);
    font-weight: 700;
    line-height: 1em;
    margin-top: 7px;

    &--special {
        .double-module-info-price__large {
            color: $red;
            position: relative;
            padding-left: 35px;
            display: inline-block;

            @include bp(120) {
                padding-left: 65px;
            }

            &--1 {
                color: $friend-price;

                &:before {
                    background-image: url('../img/icons/heart.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    content: '';
                    display: inline-block;
                    height: 30px;
                    left: 0;
                    margin-right: 10px;
                    position: absolute;
                    width: 30px;
                    transform: translateY(-50%);
                    top: 50%;

                    @include bp(75) {
                        height: 30px;
                        width: 30px;
                    }

                    @include bp(120) {
                        height: 60px;
                        width: 60px;
                    }
                }
            }

            &--3,
            &--2 {
                padding-left: 0;
            }
        }
    }

    &__large {
        margin-right: 5px;
    }

    &__small {
        @include font-size(15px);
        font-weight: 300;
        line-height: 1em;
        display: inline-block;


        @include bp(120) {
            @include font-size(23px);
        }
    }

    @include bp(48) {
        margin-top: 10px;
    }
    @include bp(75) {
        margin-top: 20px;
    }

    @include bp(120) {
        @include font-size(37px);
    }
}

.double-module-info__type {
    color: $black;
    @include font-size(15px);
    font-weight: 600;
    margin: 7px 0 0;

    @include bp(48) {
        margin-top: 5px;
    }

    @include bp(75) {
        margin-top: 15px;
    }
}
