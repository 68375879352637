.add-to-cart__button {
    .add-to-cart--has-error & {
        display: none;
    }
}

.add-to-cart__error {
    margin: 0;
    display: none;
    @include font-size(13px);
    @include line-height(13);

    .add-to-cart--has-error & {
        display: block;
    }
}

.add-to-cart__button--with-label {
    &:hover {
        background-color: #f1efe6;
        color: inherit;
    }
}
