.product-recommendation {
    @include font-size(15px);
    font-weight: 600;
    margin: 5px 0 0;
    position: relative;
    line-height: 1.5em;
    padding-left: 25px;
    word-wrap: break-word;

    &:before {
        content: '';
        background-image: url('../img/icons/tips-icon.svg');
        background-repeat: no-repeat;
        height: 30px;
        width: 20px;
        display: inline-block;
        background-size: 100%;
        margin-right: 5px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .book_module & {
        display: inline-block;
        margin: 15px 0 0;
    }

    .product-grid & {
        padding-left: 28px;
        margin: 10px 0 0;
    }

    .book-list-section &,
    .double-module & {
        @include bp(48) {
            margin-top: 5px;
        }

        @include bp(75) {
            margin-top: 15px;
        }
    }
}
