.store-selector {
    @include font-size(15px);
    @include line-height(15);
    @extend %clearfix;
    width: calc(100% + 40px);
    background: $sand-light;
    min-height: 45px;
    min-width: 150px;
    line-height: 25px;
    margin: 0 -20px;
    padding: 10px 20px;

    @include bp(40) {
        width: auto;
        float: left;
        margin: 0;
        border-radius: 5px;
        padding: 10px;
    }
}

.store-selector--store-choosen {
    @include bp(40) {
        padding: 10px 15px;
    }
}

.store-selector__label {
    margin-right: 5px;
    display: block;

    @include bp(40) {
        display: inline-block;
    }
}

.store-selector__store {
    color: $black;
    text-decoration: none;
    position: relative;
    white-space: nowrap;
    display: block;
    float: left;
    font-weight: 700;
    margin-right: 15px;

    @include bp(40) {
        display: inline-block;
        float: none;
    }

    &:after {
        content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .';
        position: absolute;
        white-space: nowrap;
        overflow: hidden;
        bottom: -3px;
        left: 0;
        right: 0;
        font-weight: 700;
        font-size: 13px;
        line-height: 13px;
    }
}

.store-selector__change-store {
    @include clear-button-style();
    text-decoration: underline;
    font-weight: 700;
    float: right;

    @include bp(40) {
        display: inline-block;
        float: none;
    }
}

.store-selector__change-store-text-small-screens {
    @include bp(40) {
        display: none;
    }
}

.store-selector__change-store-text-large-screens {
    display: none;

    @include bp(40) {
        display: inline;
    }
}

.store-selector__choose-store {
    background: $sand-lighter;
}

.store-selector__choose-store-strong {
    font-weight: 700;
    text-decoration: underline;
}
