.live-details {
    @extend %clearfix;
    @extend %page-content;
    padding: 30px 0;
    display: block;

    @include bp(48) {
        padding: 30px 0;
        
    }

    @include bp(75) {
        padding: 60px 0;
        flex-direction: row;
    }

    @include bp(120) {
        padding: 80px 0;
        max-width: 93%;
        margin: 0 auto;
    }
}

.live-image {
    margin: 0 auto 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;

    @include bp(48) {
        width: 40%;
        margin: 0 30px 30px 0;
        padding: 0 30px 0 60px;
    }
    @include bp(75) {
        float: left;
        padding: 0 15px 0 60px;
    }
    @include bp(120) {
       // @include column_style(120, 3);
    }
}

.live-image-wrapper{
    position: relative;
    max-width: 200px;
    margin: 0 auto;
 
     @include bp(48){
          max-width: 100%;
      }
      
      @include bp(120){
          max-width: 90%;
      }
  
  }

.live-image__img {
    border-radius: 100%;
    max-width: 100%;
}

.live-image-byline {
    color: $color-byline;
    display: inline-block;
    @include font-size(10px);
    font-weight: 700;
    margin: 3px;
    padding: 0 5px;
    position: absolute;
    right: -25px;
    transform: rotate(90deg) translate(50%, 0%);
    top: 50%;
    transform-origin: top right;
  
    &--light {
      font-weight: 300;
    }
  }

.live-info {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
    margin-left: 30px;

    @include bp(48) {
        padding: 0 60px;
    }
    @include bp(75) {
        padding: 0 80px 0 15px;
    }

    &.no-image-view {
        @include bp(48){
            width: 60%;
        }
    }
  
}

.live-info__intro {
    @include font-size(23px);
    font-weight: 300;
    line-height: 1.08695652em;
    margin: 0;

    @include bp(120) {
        @include font-size(30px);
        line-height: 1.16666667em;
        margin: 0 0 5px;
    }
}

.live-info__heading {
    @include font-size(30px);
    font-weight: 700;
    margin: 0 0 10px;

    @include bp(48) {
        @include font-size(53px);
        line-height: 1.13207547em;
        margin: 0 0 10px;
    }
    @include bp(75) {
        @include font-size(60px);
        line-height: 1.08333333em;
    }
    @include bp(120) {
        @include font-size(75px);
    }
}

.live-info__description {
    text-align: left;
    line-height: 1.5625em;
    font-family: $font-family--secondary;
    max-width: 100%;

    @include bp(48){
        @include font-size(17px);
        line-height: 1.76470588em;
        margin-bottom: 20px;
        }

    @include bp(75){
        @include font-size(18px);
        line-height: 1.94444444em;
        margin-bottom: 40px;
        }

    @include bp(120){
        @include font-size(20px);
        line-height: 1.7em;
        margin: 0;
        }
    
    a{
        color: $black;
        font-weight: 600;
        text-decoration: underline;

        &:hover{
            color: $red;
        }
    }

    img {
        width: 100%;
    }
   
}

.live-time__time{
    @include font-size(20px);
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 1.30434783em;
    margin: 0;
}

.live-time__weekday {
    text-transform: capitalize;
}

.live-time__label {
    text-decoration: underline;
}
