.search-stores {
    margin-bottom: 20px;
    background-color: $sand-lighter;
    border-radius: 5px;
    position: relative;
}

.search-stores__search {
    padding: 20px;
}

.search-stores__results {
    max-height: 208px;
    max-height: 40vh;
    overflow-y: auto;

    @include bp(75) {
        padding: 0 20px;
    }
}

.search-stores__no-result {
    border-top: 1px solid $sand-light;
    padding: 20px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0;
}
