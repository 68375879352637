/**
 * Since the selector [value=''] doesnt seem to work on react inputs,
 * there is a input__field--blank selector inside _mixins.scss.
 * Butt ugly but didnt find a better way.
 */

.input {
    line-height: 1em;
    padding: 20px 0;
    position: relative;
    color: $black;
}

.input--email {
    padding-top: 40px;

    .input__label {
        padding-left: 25px;

        &:before {
            background-image: url('../img/icons/envelop--black.svg');
        }
    }

    &.input--has-error .input__label:before {
        background-image: url('../img/icons/envelop--error.svg');
    }
}

.input--phone {
    padding-top: 40px;

    .input__label {
        
        padding-left: 25px;

        &:before {
            background-image: url('../img/icons/mobil--black.svg');
        }
    }

    &.input--has-error .input__label:before {
        background-image: url('../img/icons/mobil--error.svg');
    }
}

.input--password {
    .input__label {
        padding-left: 25px;

        &:before {
            background-image: url('../img/icons/key.svg');
        }
    }

    &.input--has-error .input__label:before {
        background-image: url('../img/icons/key--error.svg');
    }
}

.input--text {
    
    .input__label {
        padding-left: 25px;

        &:before {
            background-image: url('../img/icons/employee_default_avatar.svg');
        }
    }

    &.input--has-error .input__label:before {
        background-image: url('../img/icons/employee_default_avatar--error.svg');
    }
}

.input--has-error {
    color: $red;

    .input__field {
        background-color: $form-error-background;
    }
}

.input__field-wrapper {
    position: relative;
}

.input__field {
    background: $sand-lighter;
    border: 0;
    border-radius: 5px;
    height: 40px;
    padding: 0 15px;
    width: 100%;
    border: 1px solid $sand-light;
}

.input__label {
    @include font-size(15px);
    font-style: italic;
    font-weight: 600;
    left: 15px;
    position: absolute;
    transition: all 200ms ease;
    top: 50%;
    transform: translateY(-50%);

    &:before {
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 15px 15px;
        content: '';
        display: inline-block;
        height: 15px;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        position: absolute;
        width: 15px;
    }
}

.reservation-form__input .input__label {
    @media (max-width: 768px) {
        transition: none;

        &:before {
            transition: all 200ms ease-in-out;
        }
    }
}

.input__messages {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -5px;
    padding-top: 5px;

    .input--has-info & {
        justify-content: flex-start;
    }

    .input--has-error & {
        justify-content: flex-end;
    }

    .input--has-error.input--has-info & {
        justify-content: space-between;
    }
}

.input--has-content .input__label,
.input--no-animate .input__label,
.input__field:focus + .input__label {
    left: 0;
    top: -5px;
    transform: translateY(-100%);
}

.reservation-form__input .input__field:focus + .input__label,
.reservation-form__input.input--has-content .input__label {
    @media (max-width: 767px) {
        top: 50%;
        transform: translateY(-50%);
        text-indent: -9999px;

        &:before {
            text-indent: 9999px;
            left: -27px;
    
        }
    }
}

.input__error,
.input__info {
    @include font-size(14px);
    margin-bottom: 5px;
}

.input__info {
    font-weight: 300;
    color: $black;
    font-style: italic;
}

.input__toggle-password {
    right: 2px;
}
