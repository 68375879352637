.bambuser-landing__container {
    display: flex;
    flex-direction: column;
    padding: 30px;
}

.bambuser-landing__heading-container {
    position: relative;
    text-align: center;
}
.bambuser-landing__heading {
    color: $black;
    background-color: $sand-lighter;
    display: inline;
    padding: 0 20px;
    @include font-size(30px);

    &:before {
        content: '';
        display: block;
        height: 2px;
        width: 100%;
        background-color: $sand-light;
        position: absolute;
        top: 50%;
        z-index: -1;
    }
}

.bambuser-landing__list-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}