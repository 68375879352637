.member-events-slider--loading,
.member-orders-slider--loading,
.member-receipts-slider--loading,
.member-coupons-slider--loading {
    height: 25vh;
    max-height: 768px;
    &:after{
        @include spinner(40px, $spinner);
        content: "";
        left: calc( 50% - 20px );
        top: calc( 50% - 20px );
        position: relative;
        transform: translate(-50%, -50%);

        @include bp(48){
            @include spinner(70px, $spinner);
            left: calc( 50% - 35px );
            top: calc( 50% - 35px );
        }
    }
    border-top: 1px solid $sand-light;
}
.touch{
  .my-pages-carousel-section__content{
		overflow-x: auto;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
	}
}
.my-pages-carousel-section{
    padding-top: 30px;
    border-top: 1px solid $sand-light;
    overflow: hidden;

    @include bp(48){
		overflow: visible;
	}
}
.my-pages-carousel-header{
    @extend %page-content;
}
.my-pages-carousel-header__content{
    @extend %clearfix;
    color: $black;
    padding: 0 20px 30px 20px;

    @include bp(48){
      padding: 0 30px 30px;
    }
    @include bp(75){
      padding: 0 15px 30px 15px;
    }
}
.my-pages-carousel-header__heading{
    @include font-size(30px);
    line-height: 1.166666667em;
    margin: 0;

    @include bp(48){
        float: left;
    }
    @include bp(75){
        @include font-size(37px);
        line-height: 1.216216216em;
    }
    @include bp(120){
        @include font-size(45px);
        line-height: 1.177777778em;
    }
}
.my-pages-carousel-header__show-all{
    color: $black;
    display: inline-block;
    @include font-size(15px);
    font-weight: 600;
    margin-top: 10px;
    padding-right: 16px;
    position: relative;
    text-decoration: none;

    @include bp(48){
        float: right;
        @include font-size(23px);
        line-height: 1.304347826em;
        margin-top: 0;
        padding-right: 37px;
    }
    @include bp(75){
        line-height: 45px;
    }
    @include bp(120){
        @include font-size(30px);
        line-height: 54px;
        padding-right: 46px;
    }

    &:after{
        background-image: url(../img/icons/chevron_right.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 95%;
        content: " ";
        display: inline-block;
        height: 10px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;

        @include bp(48){
            height: 34px;
            width: 22px;
        }
        @include bp(120){
            height: 42px;
            width: 26px;
        }
    }

    &:visited{
      color: $black;
    }
}
.my-pages-carousel-section__content{
    overflow-x: hidden;
    overflow-y: hidden;
}
.my-pages-carousel__content{
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    padding: 0 20px 30px 20px;

    @include bp(48){
      padding: 0 30px 40px 30px;
    }
    @include bp(75){
      padding: 0 15px 40px 15px;
    }
}
