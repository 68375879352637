.search-field {
    position: relative;
    display: inline-block;
    background: $sand-lighter;
    border-radius: 5px;
    height: 32px;
    width: 100%;

    @include bp(75) {
        height: 40px;
    }
}

.search-field--bordered {
    border: 3px solid $black;
    height: 38px;

    @include bp(75) {
        height: 46px;
    }

    .search-field__submit {
        right: -3px;
    }
}

.search-field__input {
    @include font-size(16px);
    box-sizing: border-box !important;
    background-color: transparent;
    border: 0;
    padding: 0;
    width: 100%;
    line-height: 32px;
    height: 32px;
    padding-left: 32px;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        cursor: auto;
    }

    &::-webkit-input-placeholder {
        color: $black;
        font-weight: 600;
        font-style: italic;
    }

    &:-moz-placeholder {
        color: $black;
        font-weight: 600;
        font-style: italic;
    }

    &::-moz-placeholder {
        color: $black;
        font-weight: 600;
        font-style: italic;
    }

    &:-ms-input-placeholder {
        color: $black;
        font-weight: 600;
        font-style: italic;
    }

    @include bp(75) {
        padding-right: 50px;
        padding-left: 10px;
        width: 100%;
        line-height: 40px;
        height: 40px;
    }
}

.search-field__submit {
    font-weight: 600;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    border: 0;
    width: 32px;
    height: 32px;
    background: transparent;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;

    @include bp(75)  {
        position: absolute;
        right: 0;
        left: auto;
        width: 50px;
        height: 40px;
        background: $black;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    &:hover {
        cursor: pointer;
    }

    &:after {
        content: '';
        display: inline-block;
        background-image: url('../img/icons/0135-search.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        height: 20px;
        transition: all .3s ease-in;
        transition-delay: .2s;
        width: 20px;
        left: 50%;
        top: 50%;
        margin-top: -10px;
        margin-left: -10px;
        position: absolute;

        @include bp(75)  {
            position: absolute;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            background-image: url('../img/icons/0135-search--inverted.svg');
        }
    }
}

