.loader {
    z-index: 2;
    visibility: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    background: rgba($sand-lighter, .5);
    transition: opacity linear .2s;
}

.loader__spinner {
    @include spinner(50px);
    content: '';
    z-index: 30;
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    bottom: 0;
    margin: -25px auto 0;
}

.loader--loading {
    visibility: visible;
}

.loader--visible {
    opacity: 1;
}

