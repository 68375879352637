.non-book-description__body {
    @include font-size(17px);
    line-height: 1.7647em;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: Arial, sans-serif;
        font-weight: bold;
        font-family: $font-family--primary;
    }

    h3 {
        @include font-size(23px);
        line-height: 1.30434782609em;
        margin: 45px 0 0;

        @include bp(48) {
            margin-top: 45px;
        }
        @include bp(120) {
            @include font-size(30px);
            line-height: 1.16666666667em;
        }
    }

    p {
        margin-bottom: 20px;

        @include bp(48) {
            @include font-size(17px);
            line-height: 1.76470588235em;
        }

        @include bp(75) {
            @include font-size(18px);
            line-height: 1.94444444444em;
            margin-bottom: 40px;
        }

        @include bp(120) {
            @include font-size(20px);
            line-height: 1.7em;
        }
    }

    a {
        color: $black;
        font-weight: 600;
        text-decoration: underline;

        &:hover {
            color: $red;
        }
    }

    img {
        max-width: 100%;
    }

    ul {
        @include font-size(16px);
        list-style: none;
        padding: 0 20px;

        @include bp(48) {
            @include font-size(17px);
            line-height: 1.76470588235em;
            padding: 0;
        }

        @include bp(75) {
            @include font-size(18px);
            line-height: 1.94444444444em;
        }

        @include bp(120) {
            @include font-size(20px);
            line-height: 1.7em;
        }

        ul,
        ol {
            padding: 0 0 0 20px;
            list-style-type: circle;
        }

        li {
            position: relative;

            &:before {
                content: '\2022 ';
                display: inline-block;
                position: absolute;
                left: -20px;
            }

            li {
                &:before {
                    display: none;
                }
            }
        }
    }

    ol {
        @include font-size(16px);
        padding: 0 20px;

        @include bp(48) {
            @include font-size(17px);
            line-height: 1.76470588235em;
            padding: 0;
        }
        @include bp(75) {
            @include font-size(18px);
            line-height: 1.94444444444em;
        }
        @include bp(120) {
            @include font-size(20px);
            line-height: 1.7em;
        }

        ol,
        ul {
            padding: 0 0 0 20px;
        }
    }


    blockquote > p {
        font-style: italic;
        @include font-size(30px);
        font-weight: 700;
        line-height: 1.16666666667em;
        margin: 45px 0 15px;
        text-align: center;
        font-family: $font-family--primary;

        &:before,
        &:after {
            content: '\201D';
            display: inline-block;
        }


        @include bp(48) {
            @include font-size(37px);
            line-height: 1.08108108108em;
        }

        @include bp(75) {
            @include font-size(45px);
            line-height: 1.11111111111em;
        }

        @include bp(120) {
            @include font-size(60px);
            line-height: 1.08333333333em;
            margin-top: 60px;
        }

    }

    @include bp(48) {
        @include column_style(48, 3);
        padding: 0;
    }

    @include bp(75) {
        @include column_style(75, 7);
        max-width: 900px;
        padding: 0;
    }

    @include bp(120) {
        box-sizing: border-box;
        padding: 0;
        max-width: 900px;
        width: 100%;
    }
}

.double-module-figure {
    &--non-book {
        background: $white;
        border-radius: 10px;
        border: 1px solid $sand-light;
        box-shadow: 0 5px 35px 0 rgba(0, 0, 0, .1);
        padding: 20px;

        .module-figure__img {
            box-shadow: none;
            vertical-align: bottom;
        }
    }
}

.module-item-img-wrapper {
    &--non-book {
        background: $white;
        border-radius: 10px;
        border: 1px solid $sand-light;
        box-shadow: 0 5px 35px 0 rgba(0, 0, 0, .1);
        padding: 20px;

        .module-item__img {
            box-shadow: none;
            vertical-align: bottom;

            &--non-book {
                /* non-book image needs a width (since module ProductImage.html has width 100%) */
                width: 300px;
            }
        }
    }
}

.carousel-figure {
    &--non-book {
        background: $white;
        box-shadow: 0 5px 35px 0 rgba(0, 0, 0, .1);
        border-radius: 10px;
        border: 1px solid $sand-light;
        padding: 10px;

        .carousel-figure__img {
            vertical-align: bottom;
            box-shadow: none;
        }
    }
}
