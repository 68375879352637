.stock-status {
    @include font-size(13px);
    @include line-height(13);
}

.stock-status__store-title {
    font-weight: 700;
}

.stock-status__label {
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    position: relative;
    word-wrap: break-word;

    &:before {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -2px;
    }

    &--yes {
        color: $green;

        &:before {
            content: url('../img/icons/book--yes.svg');
        }
	}

    &--no {
        color: $red;

        &:before {
            content: url('../img/icons/book--no.svg');
        }
    }

    &--nja {
        color: $orange;

        &:before {
            content: url('../img/icons/book--nja.svg');
        }
    }
}

.stock-status__label--yes {
    color: $green;

    &:before {
        content: url('../img/icons/book--yes.svg');
    }
}

.stock-status__label--no {
    color: $red;

    &:before {
        content: url('../img/icons/book--no.svg');
    }
}

.stock-status__label--nja {
    color: $orange;

    &:before {
        content: url('../img/icons/book--nja.svg');
    }
}

.stock-status__label--missing {
    color: $gray-dark;

    &:before {
        content: url('../img/icons/book--missing.svg');
    }
}
