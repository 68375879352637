/**
* _ProductGridItem.scss does override and modify some classes in
* this component for hover effects.
*
* Please check it out before making changes.
*/


.product-img {
    overflow: hidden;

    &:hover {
        cursor: pointer;
    }
}

.product-img--non-book {
    background: $white;
    border: 1px solid $sand-light;
    border-radius: 5px;
}

.product-img--no-border {
    border-radius: 0;
    border: none;
}

.product-img__img {
    /* visually hide image. Still available for screen readers */
    @extend %visually-hidden;
    height: 1px; width: 1px;
    margin: -1px; padding: 0; border: 0;
}

/**
* NOTE: this class is used by _ProductGriditem.scss for some overlay trickery.
* Please check it out before making changes.
*/
.product-img__contain-wrapper {
    padding: 10px;
    background: $white;

    .product-img--thumbnail & {
        padding: 2px;
    }
}

.product-img__contain {
    display: block;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 8/7 * 100%;

    .product-img--tilted-mobile & {
        padding-bottom: 9/16 * 100%;
        @include bp(48) {
            padding-bottom: 8/7 * 100%;
        }
    }
}

.product-img__book {
    width: 100%;
    display: block;
}

.product-img__zoomed-image {
    display: none;
    background-size: 150%;
    background-size: 180%;
    background-position: center;
    background-repeat: no-repeat;
    background-origin: content-box;
    position: absolute;
    left: 50%;
    z-index: 1000;
    transform: translateX(50%);
    border: 1px solid $sand-light;
    background-color: white;
    padding: 30px;
}

.product-details--book .product-img__zoomed-image {
    left: 100%;
}