.my-tracked-items-header {
    padding: 20px;

    @include bp(48) {
        max-width: 768px;
    }
    @include bp(75) {
        max-width: 1000px;
    }
    @include bp(120) {
        max-width: 1200px;
    }
}

.my-tracked-items-header__heading {
    @include font-size(20px);
    font-weight: 700;
    line-height: 1.1666666667em;
    margin: 0;
    word-wrap: break-word;


    @include bp(48) {
        @include font-size(34px);
    }
    @include bp(75) {
        @include font-size(45px);
    }

    &:before {
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: auto 100%;
        content: '';
        display: inline-block;
        height: 20px;
        width: 20px;
        margin-right: 8px;
        position: relative;
        top: 1px;
        background-image: url('../img/icons/SVG/0134-binoculars.svg');

        @include bp(48) {
            height: 34px;
            width: 34px;
        }
        @include bp(75) {
            height: 45px;
            width: 45px;
        }
    }

}

.my-tracked-items-header__preamble {
    @extend %my-pages-header__preamble;
}


.my-tracked-items-list-item {
    @include font-size(15px);
    background-color: $sand-lighter;
    border-radius: 5px;
    box-shadow: 0 0 20px $border-product-filter;
    margin: 30px 10px;
    padding: 20px;
    position: relative;

    @include bp(48) {
        border: none;
        padding: 30px;
    }

    @include bp(120) {
        margin: 50px 0;
        @include font-size(23px);
    }

    &--loading {
        .my-tracked-items-list-item__loader {
            display: block;
        }

        .my-tracked-items-list-item__gauze {
            display: block;
        }

    }

    &--deleted {
        .my-tracked-items-list-item__gauze {
            display: block;
        }

        .my-tracked-items-list-item__response-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .my-tracked-items-list-item__deleted-text {
            display: block;
        }
    }

    &--error {
        .my-tracked-items-list-item__gauze {
            display: block;
        }

        .my-tracked-items-list-item__response-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .my-tracked-items-list-item__error-text {
            display: block;
        }
    }
}

.my-tracked-items-list-item__info-link {
    color: $black;
    text-decoration: none;
    width: 100%;
}

.my-tracked-items-list-item__left-hand-wrapper {
    display: flex;
    max-width: 90%;

    @include bp(48) {
        max-width: 85%;
    }
}

.my-tracked-items-list-item__right-hand-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.my-tracked_items-list-item__image {
    width: 100px;

    @include bp(75) {
        width: 150px;
    }

}

.my-tracked-items-list-item__header {
    overflow: hidden;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include bp(48) {
        padding-bottom: 20px;
        border-bottom: 1px solid $disabled-element;
    }
}

.my-tracked-items-list-item__product-info {
    
    @include font-size(15px);
    margin-left: 10px;

    @include bp(75) {
        @include font-size(23px);
        margin-left: 15px;
    }

    &--label {
        font-weight: 300;
    }

    &--title {
        display: inline-block;
        font-weight: 700;
        margin: 0;

        @include bp(48) {
            display: inline;
        }
    }
}

.my-tracked-items-list-item__date {
    font-weight: 700;
    margin-top: 5px;
    margin-left: 10px;
    @include font-size(15px);

    @include bp(75) {
        margin-left: 15px;
        @include font-size(23px);
    }

    &--date {
        display: inline-block;
    }

    &--time {
        display: inline-block;
    }

    &--date:before {
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-image: url('../img/icons/0084-calendar.svg');
        content: '';
        height: 15px;
        width: 15px;
        display: inline-block;
        margin-right: 8px;
        position: relative;
        top: 3px;

        @include bp(48) {
            width: 20px;
            height: 20px;
        }

        @include bp(120) {
            height: 25px;
            width: 25px;
            top: 5px;
        }

    }
}

.my-tracked-items-list-item__status {
    font-weight: 700;
    margin-top: 5px;
    margin-left: 10px;
    @include font-size(15px);

    @include bp(75) {
        margin-left: 15px;
        @include font-size(23px);
    }

    &--available {
        color: green;
    }
}

.my-tracked-items-list-item__remove-button {
    padding: 3px 10px;
    text-decoration: none;
    color: $black;
    @include font-size(15px);
    font-weight: 600;
    transition: all .2s ease-in;

    &--desktop {
        display: none;
        border-radius: 5px;
        border: 2px solid $black;
        line-height: 28px;

        @include bp(48) {
            display: flex;
        }

        @include bp(75) {
            border: 3px solid $black;
            padding: 3px 15px;
        }

        &:hover {
            cursor: pointer;
            background-color: $black;
            color: $sand-lighter;

            &:before {
                filter: invert(1);
                -webkit-filter: invert(1);
            }
        }

        &:before {
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            content: '';
            display: inline-block;
            margin-right: 5px;
            position: relative;
            top: 3px;
            background-image: url('../img/icons/SVG/0174-bin2.svg');
            transition: all .2s ease-in;
            width: 20px;
            height: 20px;
    
        }
    }

    &--mobile {
        display: inline-block;
        background-image: url('../img/icons/cross-rounded--small.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 15px;
        width: 15px;


        @include bp(48) {
            display: none;
        }
    }

    &--error {
        text-decoration: underline;
        padding: 0;
        font-size: inherit;
        font-weight: inherit;
    }

}

.my-tracked-items-list-item__add-to-cart {
    width: 100%;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.my-tracked-items-list-item__loader {
    display: none;
    width: 100px;
    height: 100px;
    border: 16px solid grey;
    border-top: 16px solid powderblue;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 300;
}

.my-tracked-items-list-item__gauze {
    display: none;
    position: absolute;
    z-index: 100;
    background: rgba(235, 235, 217, .9);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.my-tracked-items-list-item__response-container {
    display: none;
    position: absolute;
    text-align: center;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 300;

    a {
        color: $black;
    }
}

.my-tracked-items-list-item__error-text {
    display: none;
}

.my-tracked-items-list-item__deleted-text {
    display: none;
}