.subject-filter__sort-select--wrapper {
    border: 2px solid $black;
    border-radius: 5px;
    position: relative;
    height: 32px;
    min-width: 270px;
    width: 40%;
    overflow: hidden;
    margin-top: 15px;

    .subject-filter__sort-slect--legend {
        display: none;
    }

    &:after {
        background-color: $black;
        background-image: url('../img/icons/chevron_down--inverted.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 40%;
        border-left: 2px solid $black;
        content: '';
        display: block;
        height: 100%;
        padding-top: 3px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 32px;
        float: right;
        z-index: 0;

        @include bp(75) {
            width: 40px;
        }
    }

    @include bp(48) {
        min-width: 50%;
        height: 40px;
        border: 3px solid $black;
        width: 50%;
        min-width: inherit;
    }

    @include bp(75) {
        width: 38%;

        &:after {
            border-left: 3px solid $black;
        }
    }
}

.subject-filter__sort-select--legend {
    @include font-size(15px);
    font-weight: 600;
    float: left;
    padding: 0 0 0 10px;
    transform: translateY(-50%);
    position: relative;
    top: 50%;
    line-height: 30px;
}

.subject-filter__sort-select--overflow {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

.subject-filter__sort-select {
    appearance: none;
    background-color: transparent;
    border: 0;
    box-sizing: content-box;
    @include font-size(16px);
    font-weight: 600;
    position: absolute;
    z-index: 1;
    box-sizing: border-box;
    transform: translateY(-50%);
    top: 50%;
    width: 105%;
    padding: 0;
    margin-left: 10px;
    height: 100%;
    cursor: pointer;

    @include bp(48) {
        margin-left: 10px;


    }
}

.subject-filter__sort-select--option {
    padding-left: 5px;
} 