.category-filter {
    position: relative;
}

.category-filter__filter {
    background: $sand-light;

    .category-filter--loading & {
        height: 342px;
    }
}

.category-filter__breadcrumbs {
    border-bottom: 1px solid $sand;
}

.category-filter__breadcrumbs-inner {
    @extend %page-content;
    padding: 20px;
}

.category-filter__upper-section {
    @extend %clearfix;
}

.category-filter__search-field {
    width: 100%;
    margin-bottom: 15px;

    @include bp(75) {
        width: calc(40% - 32px);
        float: left;
        margin-right: 30px;
        margin-bottom: 0;
    }
}

.category-filter__store-filter {
    @include bp(48) {
        max-width: calc(100% - 84px);
    }

    @include bp(75) {
        max-width: calc(100% - 40% - 84px);
        float: left;
    }
}

