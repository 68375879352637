.error-page {
    @extend %clearfix;
    @extend %page-content;
}

.error-page-content {
    @extend %full-width-content;
    padding-top: 60px;
    padding-bottom: 60px;

    @include bp(120) {
        padding-top: 120px;
        padding-bottom: 120px;
    }
}

.error-heading {
    @include font-size(23px);
    line-height: 1.30434782609em;
    margin: 0;
    word-wrap: break-word;

    @include bp(48) {
        @include font-size(53px);
        line-height: 1.1320754717em;
    }

    @include bp(75) {
        @include column_style(75, 7);
        @include font-size(60px);
        line-height: 1.08333333333em;
        
    }
    @include bp(120) {
        @include column_style(120, 8); 
        @include font-size(75px);
        line-height: 1.06666666667em;
    }
}

.error-preamble {
    color: $black;
    @include font-size(15px);
    font-weight: 600;
    line-height: 1.66666666667em;
    margin: 15px 0 0;
    
    @include bp(48) {
        @include font-size(23px);
        line-height: 1.30434782609em;
        margin-top: 30px;
        
    }
    @include bp(75) {
        @include column_style(75, 7); 
    }
    @include bp(120) {
        @include column_style(120, 8); 
        @include font-size(30px);
        margin-top: 30px;
    }
}

.error-solutions {
    @include bp(75) {
        @include column_style(75, 7); 
    }
    @include bp(120) {
        @include column_style(120, 8);
    }
}

.solutions-list {
    @include font-size(16px);
    list-style: none;
    padding: 0 20px;

    @include bp(48) {
        @include font-size(17px);
        line-height: 1.76470588235em;
        padding: 0;
    }
    @include bp(75) {
        @include font-size(18px);
        line-height: 1.94444444444em;
    }
    @include bp(120) {
        @include font-size(20px);
        line-height: 1.7em;
    }

    ul, 
    ol {
        padding: 0 0 0 20px;
        list-style-type: circle; 
    }
}

.solutions-list__item {
    position: relative;

    &:before {
        content: '\2022 ';
        display: inline-block;
        position: absolute;
        left: -20px;

    }

    li {
        &:before {
            display: none;
        }
    }

    a {
        color: $black;
    }
}

//404 Page styling

.page-not-found {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    @include bp(64) {
        margin-top: 100px;
    }
}

.page-not-found__content {
    text-align: center;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page-not-found__heading {
    max-width: 60%;
    margin-bottom: 20px;
}

.page-not-found__text {
    margin: 0;
    @include font-size(20px);

    &--line-end {
        display: inline-block;
        margin-top: 20px;
    }
}

.page-not-found__links-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;

    @include bp(64) {
        margin-top: 40px;
    }
}

.page-not-found__links {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: $black;
    margin-bottom: 10px;
    position: relative;

    @include bp(75) {
        width: 22%;
    }

    &:hover .page-not-found__links-text {
        text-decoration: underline;
    }
    
}

.page-not-found__links-text {
    font-weight: 600;
}

.page-not-found__links-colour {
    position: absolute;
    padding: 50px;
    border-radius: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    background-color: pink;

    &--green {
        background-color: palegreen;
    }

    &--orange {
        background-color: #ffdac0;
    }

    &--purple {
        background-color: #cdc0ff;
    }
}