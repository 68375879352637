.age-filter{
	margin-bottom: 30px;

	@include bp(75){
		margin-bottom: 0;
		border-top: 1px solid $sand-light;
	}

}
.age-filter-form{
	@extend %page-content;

    @include bp(75) {
        padding-left: 10px;
        padding-right: 10px;
    }
    @include bp(120) {
        padding-left: 5px;
        padding-right: 5px;
    }
}
.age-filter-list{
	list-style: none;
	margin: 0;
	padding-top: 15px;
	padding-bottom: 15px;
	@extend %full-width-content;
	text-align: left;

	@include bp(48){
		text-align: left;
	}

	@include bp(75){
		padding-top: 25px;
		padding-bottom: 25px;
	}
}
.age-filter-list__item{
	display: inline-block;
	margin-right: 10px;
}
.age-filter-radio{
	@extend %visually-hidden;

	&:checked + .age-filter-label{
		background: $black;
		color: $sand-lighter;
	}
}
.age-filter-label{
	border: 2px solid $black;
	border-radius: 5px;
    color: $black;
	cursor: pointer;
	display: block;
	@include font-size(15px);
	font-weight: 600;
	line-height: 1.2em;
	margin-top: 5px;
	margin-bottom: 5px;
	padding: 5px 10px;
    text-decoration: none;
    transition: all 0.2s ease-in;

    &:hover{
    	background: $black;
		color: $sand-lighter;
    }

    @include bp(75){
    	border: 3px solid $black;
    	padding: 10px 20px;
    }
}
.age-filter-label--selected {
    background: $black;
    color: $sand-lighter;
}
