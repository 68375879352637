.login{
    border-bottom: 1px solid $sand-light;
}
.login__content{
    padding-top: 20px;
}

.login-header{
    @extend %page-content;
    padding: 20px;

    @include bp(120){
        max-width: 1500px;
    }
}
.login-header__heading{
    @extend %my-pages-header__heading;
}
.login-header__preamble{
    @extend %my-pages-header__preamble;
}
.login-form{
    background: $sand-light;
    @extend %clearfix;
    margin: 0 auto;
    padding: 20px 20px 20px;
    position: relative;

    @include bp(48){
        padding: 30px;
    }
    @include bp(75){
        padding: 30px 30px;
    }
}
.login-form__content{
    @extend %clearfix;
    @include bp(48){
        margin: 0 auto;
        width: 93%;
    }
    @include bp(120){
        max-width: 1500px;
        padding: 0 20px;
    }
}
.login-item{
    margin: 30px 0 10px;
    line-height: 1em;
    position: relative;

    &--email{
        @include bp(48){
            float: left;
            margin-right: 20px;
            width: calc(40% - 20px);
        }
        @include bp(75){
            margin-right: 30px;
            width: calc(40% - 30px);
        }
    }

    &--forgot{
        @include bp(48){
            float: left;
            margin-right: 20px;
            width: calc(40% - 20px);
        }
    }
}
.login-password{
    @include bp(48){
        float: left;
        width: 40%;
        padding-right: 20px;
    }
    @include bp(75){
        padding: 0 30px 0 0;
        width: 40%;
    }
}
.login-item__input{
    background: $sand-lighter;
    border: 0;
    border-radius: 5px;
    height: 40px;
    padding: 0px 80px 0px 15px;
    width: 100%;

    @include bp(75){
      height: 55px;
      @include font-size(23px);
      padding: 0px 90px 0px 20px;
    }

    .login-item--email & {
        padding-right: 15px;

        @include bp(75){
          padding-right: 20px;
        }
    }

    &::-ms-reveal,
    &::-ms-clear{
        display: none;
    }

    &[value=""]{
        & + .login-item__label{
            left: 15px;
            padding-left: 25px;
            top: 50%;
            transform: translateY(-50%);

            @include bp(75){
                @include font-size(23px);
                font-weight: 600;
                left: 20px;
                line-height: 1em;
                padding-left: 35px;
            }

            &:before{
                top: 0;
                height: 15px;
                width: 15px;

                @include bp(75){
                    height: 23px;
                    width: 22px;
                }
            }
        }
    }

    &:-webkit-autofill{
        -webkit-box-shadow: 0 0 0px 1000px $sand-lighter inset;
        & + .login-item__label{
            @include font-size(15px);
            font-weight: 600;
            left: 0;
            padding-left: 20px;
            top: -5px;
            transform: translateY(-100%);

            &:before{
                top: 0px;
                height: 15px;
                width: 15px;
            }
        }
    }

    &:focus{

        & + .login-item__label{
            @include font-size(15px);
            font-weight: 600;
            left: 0;
            padding-left: 20px;
            top: -5px;
            transform: translateY(-100%);

            &:before{
                top: 0px;
                height: 15px;
                width: 15px;
            }
        }
    }

    &--text{
        height: auto;
        resize: none;
        top: 15px;

        &[value=""]{
            &:focus{
                & + .login-item__label{
                    top: 0;
                    transform: translateY(-100%);
                }
            }
            & + .login-item__label{
                top: 15px;
                transform: translateY(0);

            }
        }

        @include bp(48){
            width: 75%;
        }
    }
}
.login-item__label{
    color: $black;
    @include font-size(15px);
    font-style: italic;
    font-weight: 600;
    left: 0;
    line-height: 1em;
    padding-left: 20px;
    position: absolute;
    top: -5px;
    transition: all 0.2s ease;
    transform: translateY(-100%);

    &:before{
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100% auto;
        content: "";
        display: inline-block;
        height: 15px;
        left: 0;
        position: absolute;
        top: 0px;
        width: 15px;
    }
    &--pwd:before{
        background-image: url('../img/icons/key.svg');
    }
    &--email:before{
        background-image: url('../img/icons/envelop--black.svg');
    }

}
.login-form__info{
    @include font-size(15px);
    font-style: italic;
    font-weight: 300;
    margin: 0;
}
.login-form__actions{
    display: inline-block;

    @include bp(48){
        margin-top: 30px;
        float: left;
        width: 20%;
    }
    @include bp(75){
        margin-top: 0px;
        position: static;
    }
}
.login-form__submit{
    background: transparent;
    border: 2px solid $black;
    border-radius: 5px;
    display: inline-block;
    @include font-size(15px);
    font-weight: 600;
    height: 40px;
    margin: 15px 5px 0 0;
    padding: 5px;
    transition: all 0.2s ease-in;
    max-width: 150px;
    width: 50%;

    @include bp(48){
        margin-top: 0;
        width: 100%;
    }
    @include bp(75){
        border: 3px solid $black;
        @include font-size(23px);
        height: 55px;
        max-width: 200px;
        margin-top: 30px;
    }

    &:hover{
        background: $black;
        color: $sand-lighter;
    }
}
.login-form__forgot{
    color: $black;
    display: inline-block;
    @include font-size(15px);
    font-weight: 300;
    margin-top: 10px;
}
.errorlist{
    list-style: none;
    margin: 0 0 10px;
    padding: 0;

    @include bp(48){
        margin: 0px auto 10px;
        width: 93%;
    }
    @include bp(120){
        max-width: 1500px;
        padding: 0 20px;
    }

}
.errorlist__item{
    background: $form-error-background;
    border-radius: 5px;
    color: $red;
    @include font-size(15px);
    line-height: 1.33333333em;
    padding: 15px;


    a{
        color: $red;
        font-weight: 600;
    }

    @include bp(75){
        max-width: calc(80% - 30px);
    }
}
