/* Footer
 * ----------------------------------------------------
 */

.main-footer {
    margin-top: 45px;
    background: $black-dark;
    color: $sand-lighter;

    @include bp(75) {
        margin-top: 60px;
    }
    @include bp(120) {
        margin-top: 70px;
    }

    .profile & {
        margin-top: 0;

        @include bp(75) {
            margin-top: 0;
        }
        @include bp(120) {
            margin-top: 0;
        }
    }
}

.main-footer__content {
    @extend %clearfix;
    @extend %page-content;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 32px;

    @include bp(75) {
        flex-direction: row;
        padding-top: 32px;
        margin-top: 0;
        padding-bottom: 30px;
    }
}

.main-footer__misc {
    @extend .main-footer__content;
    border-top: $border-footer 1px solid;
}

.footer-column {
    @include column_style(0, 2);
    float: left;
    text-align: center;

    @include bp(75) {
        text-align: left;
        @include column_style(48, 1);
    }

    &--contact {
        margin-top: 30px;

        @include bp(75) {
            margin-top: 0;
            max-width: 41.6666666667%;
        }
    }

    &--social {
        @include bp(75) {
            max-width: 25%;
        }
    }

    &--klarna {
        @include bp(75) {
            max-width: 100%;
        }
    }

    &__list {
        list-style: none;
        padding: 0;
    }

    &__list-item-link {
        @extend .footer-link__anchor;
        @include font-size(15px);
        text-decoration: none;
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;

        @include bp(75) {
            height: auto;
            display: list-item;
            line-height: normal;
            padding-top: 0;
            padding-bottom: 14px;
        }
    }

    @include bp(75) {
        @include column_style(75, 3);
    }

    @include bp(120) {
        max-width: 25%;
        padding: 0 15px;
    }
}

.footer-column__heading {
    border-top: 1px solid $border-footer;
    color: $color-footer-heading;
    @include font-size(23px);
    line-height: 1.2em;
    margin: 0;
    padding-top: 30px;

    @include bp(75) {
        border: 0;
        padding-top: 0;
    }
}

.footer-column:first-child .footer-column__heading {
    border: 0;
}

.footer-logo-link {
    display: inline-block;
    text-decoration: none;
}

.footer-column__logo {
    max-width: 150px;
    width: 100%;
    margin-top: 40px;

    @include bp(75) {
        margin: 0;
        max-width: 180px;
    }
}

.footer-link {
    color: $sand-lighter;
    @include font-size(15px);
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 40px;
    text-decoration: none;
    display: block;
    margin: 0 8px;

    @include bp(75) {
        margin: 0 15px 0 0;
        @include font-size(15px);
    }

    &:before {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 50% auto;
        border: 2px solid $sand-lighter;
        border-radius: 50%;
        content: '';
        display: block;
        height: 50px;
        letter-spacing: 1px;
        line-height: 40px;
        vertical-align: middle;
        margin: 0 auto;
        width: 50px;

        @include bp(75) {
            height: 65px;
            line-height: 65px;
            width: 65px;
        }

    }

    &--contact {
        margin: 0 10px;
        display: inline-block;

        &:before {
            background-image: url('../img/icons/envelop.svg');
            @include bp(75) {
                margin-right: 10px;
            }
        }

    }

    &--phone {
        margin: 0 10px;

        &:before {
            margin-bottom: 10px;
            background-image: url('../img/icons/mobile.svg');
            background-size: auto 50%;

            @include bp(75) {
                margin-right: 10px;
                margin-bottom: 0;
            }
        }

        @include bp(75) {
            display: flex;
        }
    }
 }

.footer-link__anchor {
    color: $sand-lighter;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }


 }

.footer-social-link {
    width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 5px;

    @include bp(75) {
        width: 60px;
        height: 60px;
    }
}

.footer-social-icon {
    width: 100%;
}

.cookie-text {
    @include font-size(15px);
    float: left;
    margin: 10px 0 20px;
    text-align: center;
    width: 100%;

    @include bp(75) {
        text-align: left;
        margin: 10px 0 0;
    }
 }

.cookie-text__link {
    color: $sand-lighter;
    font-weight: 600;
}

.copyright {
    display: block;
    @include font-size(10px);
 }

.sub-footer {
    position: relative;
    background: #faf8f2;
    color: $color-subfooter;
    @include font-size(15px);
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 1.2em;
    padding: 30px 20px;
    text-align: center;
    text-transform: uppercase;

    @include bp(75) {
        @include font-size(23px);
        letter-spacing: 7.6px;
    }

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: .15;
        background-image: url('../img/icons/akb-logo-no-text.svg');
        background-position: center 40%;
        background-repeat: no-repeat;
        background-size: 230% auto;

        @include bp(75) {
            background-size: 125% auto;
        }
    }
 }

.sub-footer__inner {
    position: relative;
}

/* Klarna logotype */
.footer-klarna-logo {
    display: block;
    margin: 64px auto;
    max-width: 100%;

    @include bp(75) {
        margin: 0;
    }
}
