.bp-confirmation {
    @extend %base-article-grid;
    margin-bottom: 30px;
    padding-left: 0;
    padding-right: 0;

    @include bp(48) {
        padding-left: inherit;
        padding-right: inherit;
    }
}

.bp-confirmation__header {
    @extend %article-header;
}

.bp-confirmation__ingress {
    @extend %article-preamble;
}

.bp-confirmation__body {
    @extend %article-body;
}

.bp-confirmation__list {
    @extend %base-article-grid;
    padding-left: 40px;
}

.bp-confirmation__list-item {
    margin-bottom: 10px;
}

.bp-confirmation__list-disclaimer {
    @extend %base-article-grid;
    display: block;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 300;
    font-style: italic;
    margin-bottom: 20px;
}

.bp-confirmation__app-section {
    @extend %base-article-grid;
    text-align: center;
}

.bp-confirmation__app-header {
    @include font-size(23px);
}

.bp-confirmation__app-download {
    text-indent: -9999px;
    display: inline-block;
    // width: 273px;
    width: 182px;
    height: 54px;

    background-size: contain;
    background-repeat: no-repeat;

    margin-bottom: 20px;

    &--ios {
        background-image: url('/static/img/app-store-badge.png');
        // height: 82px;
    }

    &--android {
        background-image: url('/static/img/google-play-badge.png');
        // height: 81px;
    }

}

.bp-confirmation__paragraph {
    @extend %base-article-grid;
}

