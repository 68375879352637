.store-filter {
    @include font-size(15px);
    line-height: 23px;

    @include bp(75) {
        line-height: 40px;
    }
}

.store-filter__checkbox {
    @extend %visually-hidden;

    &:checked + .store-filter__label {
        &:before {
            background-image: url('../img/icons/0273-checkmark.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 80%;
        }
    }

    &:disabled + .store-filter__label {
        &:before {
            cursor: auto;
        }
    }
}

.store-filter__label {
    @include font-size(15px);
    display: inline-block;
    padding-left: 34px;
    position: relative;
    padding-top: 0;
    line-height: 25px;

    &:before {
        border: 2px solid $black;
        border-radius: 5px;
        content: '';
        cursor: pointer;
        display: inline-block;
        height: 25px;
        left: 0;
        margin-right: 10px;
        position: absolute;
        width: 25px;

        @include bp(40) {
            transform: translateY(-50%);
            top: 50%;
        }

        @include bp(75) {
            border: 3px solid $black;
            height: 25px;
            width: 25px;
        }
    }

    @include bp(48) {
        max-width: inherit;
        padding-top: 0;
    }

    @include bp(75) {
        padding-left: 34px;
        margin-bottom: 0;
    }
}

.store-filter__store {
    color: $black;
    font-weight: 600;
    text-decoration: none;
    margin-right: 15px;
    position: relative;
    white-space: nowrap;

    @include bp(75) {
        margin-right: 30px;
    }

    &:after {
        content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .';
        position: absolute;
        white-space: nowrap;
        overflow: hidden;
        bottom: -3px;
        left: 0;
        right: 0;
        font-weight: 700;
        font-size: 13px;
        line-height: 13px;
    }
}

.store-filter__change-store {
    @include clear-button-style();
    font-weight: 700;
    text-decoration: underline;
}

.store-filter__select-store {
    background: $sand-lighter;
    font-weight: 400;
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    position: relative;
    left: -34px;
    margin-right: -34px;

    @include bp(40) {
        display: inline;
        margin-top: 0;
        margin-left: 10px;
        left: 0;
        margin-right: 0;
    }

    &:hover {
        background: $sand;
    }
}

.store-filter__select-store-inner-bold {
    font-weight: 700;
    text-decoration: underline;
}
