.product-badge {
    position: relative;
}

.product-badge__coming {
    background-color: $black;
    color: $sand-lighter;
    display: block;
    @include font-size(15px);
    font-weight: 600;
    letter-spacing: 1px;
    position: absolute;
    left: -15px;
    height: 35px;
    line-height: 35px;
    padding: 0 10px;
    top: 20px;
    z-index: 2;

    &:after {
        border-top: 15px solid $black;
        border-left: 15px solid transparent;
        top: 35px;
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        position: absolute;
        left: 1px;
    }

    .product-badge--compact & {
        @include font-size(11px);
        padding: 0 10px;
        line-height: 25px;
        height: 25px;
        left: -10px;
        top: 10px;

        &:after {
            border-top-width: 10px;
            border-left-width: 10px;
            position: relative;
            top: 0;
            margin-left: -10px;
            display: block;
        }
    }

    @include bp(48) {
        top: 20px;
        @include font-size(18px);
        font-weight: 700;
        height: 40px;
        left: -20px;
        line-height: 40px;

        &:after {
            border-top: 20px solid $black;
            border-left: 20px solid transparent;
            top: 40px;
        }
    }
}

.product-badge__new {
    background-color: $medium-green;
    border-radius: 50%;
    color: $sand-lighter;
    display: block;
    @include font-size(18px);
    font-weight: 700;
    height: 40px;
    left: 3px;
    line-height: 40px;
    position: absolute;
    text-align: center;
    top: 10px;
    transform: translate(-50%);
    width: 40px;
    z-index: 2;

    @include bp(48) {
        top: 20px;
    }

    .product-badge--compact & {
        @include font-size(14px);
        line-height: 30px;
        height: 30px;
        width: 30px;

        transform: none;
        left: -10px;
        top: 10px;
    }

}

%product-badge__sale {
    left: -7px;
    line-height: 0;
    position: absolute;
    top: 20px;
    width: 60px;

    & > svg {
        background-color: $yellow;
    }

    @include bp(48) {
        left: -9px;
        width: 80px;
        height: 63px;
    }

    @include bp(120) {
        left: -10px;
        width: 90px;
        height: 70px;
    }

    .recommendation-figure & {
        display: none;

        @include bp(40) {
            display: block;
        }
    }
}

.product-badge__sale-started {
    @extend %product-badge__sale;

    .product-badge--compact & {
        width: 60px;
        top: 10px;
    }

    @include bp(48) {
        width: 80px;
        height: 63px;

        .product-badge--compact & {
            width: 60px;
            height: 47px;
        }
    }

    @include bp(120) {
        width: 90px;
        height: 70px;

        .product-badge--compact & {
            width: 60px;
            height: 47px;
        }
    }

}

.product-badge__sale-not-started {
    @extend %product-badge__sale;
    height: auto;
    width: auto;
}


