.player-button {
    border: 1px solid black;
    border-radius: 500px;
    padding: 5px 15px;
    background-color: $black;
    color: white;
    text-decoration: none;
    font-weight: 500;
}

.player-wrapper {
    margin-top: 20px;
}