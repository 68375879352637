.reservation-form__container {
    background: $sand-light;
    position: relative;
    display: none;
    justify-content: space-between;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 350px;

    @media (min-width: 400px) {
        max-height: none;
   }

    &--expanded {
        display: flex;
        padding: 10px 20px 10px 0;
    }
}

.reservation-form__inner-container {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.reservation-form__form-form {
    margin-top: 10px;
}

.reservation-form__method-buttons {
    margin-bottom: 10px;
}

.reservation-form__method-btn {
    margin-right: 10px;
}

.reservation-form__input {
    padding: 5px 0;

    @include bp(48) {
        max-width: 380px;
        padding: 15px 0;
    }
}

.reservation-form__submit-button {
    width: 110%;
    position: relative;
    left: -20px;

    @include bp(48) {
        left: -35px;
    }

    &:disabled {
        opacity: .7;
    }
}

.reservation-form__info-text {
    position: relative;
    left: -10px;
    width: 105%;
    margin: 5px 0;
}