.store-status__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid $gray;
    padding: 5px 0 10px;

    @include bp(40) {
        padding: 20px 0;
    }
}

.store-status__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 90%;
}

.store-status__left {
    display: flex;
    align-items: flex-end;
    width: 75%;
}

.store-status__number {
    margin: 0;
}

.store-status__details {
    display: flex;
    align-items: flex-end;
    width: 100%;
}

.store-status__heading {
    font-weight: 700;
    margin-bottom: 0;
}

.store-status__store {
    font-weight: 550;
    margin-bottom: 0;
    margin-top: 5px;
    display: inline-block;
}

.store-status__message {
    position: relative;

    &:before {
        position: relative;
        top: 2px;
        margin-right: 5px;
        width: 15px;
        height: 15px;
        content: '';
        display: inline-block;
        background-size: contain;

    }

    &--green {
        &:before {
            background-image: url('../img/icons/book--yes.svg');
        }
    }

    &--red {
        &:before {
            background-image: url('../img/icons/book--no.svg');
        }
    }
}

.store-status__btn {
    font-weight: 550;
}