.overlay-buy-module {
    @include font-size(13px);
    @include line-height(13);
    text-align: center;
}

.overlay-buy-module--compact {

    .overlay-buy-module__buy-label {
        display: none;
    }

    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    .overlay-buy-module__buy-section {
        flex-grow: 1;
    }

    .overlay-buy-module__stock-section {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-grow: 1;

        &.overlay-buy-module__stock-section--no-buy-online {
            align-items: center;
        }
    }

}

.overlay-buy-module__message {
    @include font-size(13px);
    @include line-height(13);
    padding: 20px 10px;
}

.overlay-buy-module__message--not-published {
    background: $orange-lighter;
}

.overlay-buy-module__message--sale {
    background: $yellow;
}

.overlay-buy-module__message--preorder {
    .add-to-cart {
        margin-top: 10px;
    }
}

.overlay-buy-module__message-header {
    @include font-size(15px);
    @include line-height(15);
    margin: 0;
}

.overlay-buy-module__stock-section {
    padding: 15px 10px;
    background: $sand-light;
    padding-bottom: 13px;
}

.overlay-buy-module__hide-store-stock-info {
    line-height: 15px;
    font-weight: 700;
    margin: 0;
}

.overlay-buy-module__choose-store-info {
    line-height: 15px;
    font-weight: 700;
    margin: 0;

    &:before {
        content: '';
        background-image: url('../img/icons/store.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        display: inline-block;
        height: 15px;
        width: 15px;
        vertical-align: middle;
        position: relative;
        top: -2px;
        margin-right: 5px;

        .overlay-buy-module--booklist & {
            display: block;
            margin: 0 auto;
        }
    }
}

.overlay-buy-module__buy-section {
    padding: 15px 10px;
    background: $sand;
    padding-top: 13px;
}

.overlay-buy-module__buy-label {
    margin: 0;
    font-weight: 700;
    margin-bottom: 5px;
}
