.recommendation-overlay {
    background: rgba(255, 254, 249, .95);
    min-height: 100vh;
    max-height: 100vh;
    left: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
}

.overlay-card {
    @extend %page-content;
    box-shadow: 0 10px 20px $overlay-shadow;
    margin-top: 15px;
    padding: 5px;
    position: relative;
    @include bp(75) {
        max-width: 75%;
    }
    @include bp(120) {
        max-width: 1200px;
    }
}

.overlay-card__close {
    background-color: transparent;
    background-image: url('../img/icons/cross--inverted.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    border: 0;
    height: 40px;
    line-height: 1em;
    padding: 0;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 40px;
    @include bp(75) {
        background-size: 30px;
        height: 65px;
        width: 65px;
    }
    @include bp(120) {
        background-size: 30px;
        height: 85px;
        width: 85px;
    }
}

.overlay-card__content {
    background-color: #fff;
    @extend %clearfix;

    @include bp(40) {
        display: flex;
        align-items: stretch;
    }
}

.overlay-card__book {
    @extend %clearfix;
    border-bottom: 1px solid $border-overlay-book;
    padding: 15px;
    @include bp(40) {
        box-sizing: content-box;
        border-bottom: 0;
        float: left;
        flex-basis: 160px;
        min-width: 0;
        padding: 30px;
        width: 40%;
    }
    @include bp(75) {
        flex-basis: 180px;
        padding: 40px;
    }
    @include bp(120) {
        flex-basis: 260px;
        padding: 60px;
    }
}

.overlay-product-link {
    color: $black;
    display: block;
    text-decoration: none;
}

.recommendation-figure {
    float: left;
    max-width: 25%;
    position: relative;
    @include bp(40) {
        float: none;
        max-width: 100%;
    }
}

.recommendation-figure__img {
    max-width: 100%;
}

.recommendation-product {
    float: left;
    max-width: 75%;
    padding: 0 0 0 15px;
    @include bp(40) {
        float: none;
        max-width: 100%;
        padding: 0;
        width: 100%;
    }
}

.recommendation-product__title {
    @include font-size(15px);
    font-weight: 700;
    margin: 0;
    word-wrap: break-word;

    @include bp(40) {
        @include font-size(20px);
        line-height: 1.25em;
        margin-top: 10px;
    }

    @include bp(75) {
        @include font-size(23px);
        line-height: 1.30434783em;
    }

    @include bp(120) {
        @include font-size(37px);
    }
}

.recommendation-product__author,
.recommendation-product-price__course-literature {
    @include font-size(15px);
    font-weight: 300;
    margin: 0;

    @include bp(75) {
        @include font-size(23px);
    }
}

.recommendation-product-price {
    display: block;
    @include font-size(15px);
    font-weight: 700;
    line-height: 1em;
    margin-top: 7px;

    @include bp(40) {
        @include font-size(23px);
    }

    @include bp(75) {
        @include font-size(30px);
    }
}

.recommendation-product-price--friend {
    @include bp(48) {
        margin-top: 12px;
    }
}

.recommendation-product-price__large {
    margin-right: 5px;

    .recommendation-product-price--special & {
        color: $red;
        position: relative;
        padding-left: 35px;
        display: inline-block;
        @include bp(48) {
            padding-left: 45px;
        }
        @include bp(75) {
            padding-left: 50px;
        }
    }

    &--1 {
        &:before {
            .recommendation-product-price--special & {
                background-image: url('../img/icons/heart.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
                content: '';
                display: inline-block;
                height: 30px;
                left: 0;
                margin-right: 10px;
                margin-bottom: 10px;
                position: absolute;
                width: 30px;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                top: 50%;
                @include bp(48) {
                    height: 40px;
                    width: 40px;
                }
                @include bp(75) {
                    height: 45px;
                    width: 45px;
                }
            }
        }
    }

    &--2 {
        .recommendation-product-price--special & {
            padding-left: 0;
        }
    }

    &--3 {
        .recommendation-product-price--special & {
            padding-left: 0;
        }
    }
}

.recommendation-product-price__small {
    @include font-size(15px);
    font-weight: 300;
    line-height: 1em;
    display: inline-block;

    @include bp(75) {
        @include font-size(19px);
    }
}

.recommendation-product__type {
    @include font-size(15px);
    font-weight: 600;
    margin: 5px 0 0;

    @include bp(75) {
        @include font-size(23px);
    }

    &--friend {
        margin: 10px 0 0;
    }
}

.recommendation-product__saldo {
    @include font-size(15px);
    font-weight: 600;
    line-height: 1.33333333em;
    margin: 5px 0 0;
    padding-top: 3px;
    padding-left: 28px;
    position: relative;
    word-wrap: break-word;

    &:before {
        margin-right: 5px;
        position: absolute;
        top: 0;
        left: 0;
    }

    &--yes {
        color: $green;

        &:before {
            content: url('../img/icons/book--yes.svg');
        }
    }

    &--no {
        color: $red;

        &:before {
            content: url('../img/icons/book--no.svg');
        }
    }

    &--nja {
        color: $orange;

        &:before {
            content: url('../img/icons/book--nja.svg');
        }
    }
}

.overlay-card__recommendation {
    padding: 15px;

    @include bp(40) {
        border-left: 1px solid $border-overlay-book;
        float: left;
        flex-grow: 1;
        padding: 30px 0 0;
        width: 60%;
    }

    @include bp(75) {
        padding: 40px 0 0;
    }

    @include bp(120) {
        padding: 60px 0 0;
    }
}

.recommendation-heading {
    @include font-size(23px);
    font-style: italic;
    font-weight: 700;
    margin: 0;

    @include bp(40) {
        @include font-size(30px);
        padding: 0 30px;
    }

    @include bp(75) {
        padding: 0 40px;
        @include font-size(30px);
        line-height: 1.16666667em;
    }

    @include bp(120) {
        padding: 0 60px;
    }
}

.recommendation-comment {
    @include font-size(15px);
    line-height: 1.66666667em;

    @include bp(40) {
        @include font-size(17px);
        line-height: 1.7647058824em;
        padding: 0 30px;
    }

    @include bp(75) {
        @include font-size(18px);
        line-height: 1.66666667em;
        padding: 0 40px;
    }

    @include bp(120) {
        padding: 0 60px;
    }
    font-family: $font-family--secondary;

    .reco-card__recommendation & {
        padding: 0;
    }

    .product-page & {
        @include font-size(14px);
        line-height: 20px;
    }
}

.recommendation-employee {
    @extend %clearfix;

    @include bp(40) {
        padding: 0 30px 15px;
    }

    @include bp(75) {
        padding: 0 40px 20px;
    }

    @include bp(120) {
        padding: 0 60px 30px;
    }
}

.recommendation-employee-figure {
    float: left;
    max-width: 25%;
}

.recommendation-employee-figure__img {
    border-radius: 50%;
    max-width: 100%;
}

.recommendation-employee-info {
    float: left;
    max-width: 75%;
    padding: 0 0 0 15px;
}

.recommendation-employee-info__name {
    @include font-size(20px);
    font-weight: 700;
    line-height: 1.25em;
    margin: 0;

    @include bp(75) {
        @include font-size(37px);
    }
}

.recommendation-employee-info__store {
    @include font-size(15px);
    font-weight: 300;
    line-height: 1.33333333333em;
    margin: 0;

    @include bp(75) {
        @include font-size(23px);
    }
}

.recommendation-employee-info__link {
    color: $black;
    display: inline-block;
    @include font-size(15px);
    font-weight: 300;

    @include bp(75) {
        @include font-size(23px);
    }
}

.all-recommendations {
    @extend %clearfix;
    display: none;

    @include bp(40) {
        border-top: 1px solid $border-overlay-book;
        display: block;
        padding: 20px 30px 30px;
    }

    @include bp(75) {
        padding: 20px 40px 40px;
    }

    @include bp(120) {
        padding: 30px 60px 60px;
    }
}

.all-recommendations__heading {
    @include font-size(23px);
    font-weight: 300;
    margin: 0;

    &--bold {
        font-weight: 700;
    }

    @include bp(75) {
        @include font-size(30px);
        line-height: 1.16666667em;
    }
}

.all-recommendations-list {
    float: left;
    list-style: none;
    margin: 0;
    padding: 5px 0;
}

.all-recommendations-list__item {
    border-radius: 50%;
    display: inline-block;
    height: 75px;
    margin-right: 15px;
    padding: 5px;
    width: 75px;

    &--active {
        background: $bg-recommendation-plate;
    }
}

.all-recommendations__link {
    display: block;
    text-decoration: none;
}

.all-recommendations-img {
    border-radius: 50%;
    max-width: 100%;
}

.more-recommendations {
    color: $black;
    display: inline-block;
    @include font-size(15px);
    font-weight: 300;
    margin-top: 10px;
    white-space: nowrap;

    @include bp(75) {
        @include font-size(23px);
    }
}

.recommendation-figure__new {
    background-color: $medium-green;
    border-radius: 50%;
    color: $sand-lighter;
    display: block;
    @include font-size(10px);
    font-weight: 700;
    height: 20px;
    left: 3px;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: 10px;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    width: 20px;

    @include bp(40) {
        display: block;
        @include font-size(18px);
        height: 40px;
        left: 6px;
        line-height: 40px;
        top: 20px;
        width: 40px;
    }
}

.recommendation-figure__coming {
    background-color: $product-cover-coming-plate;
    color: $sand-lighter;
    display: none;
    @include font-size(15px);
    font-weight: 600;
    letter-spacing: 1px;
    position: absolute;
    left: -15px;
    height: 35px;
    line-height: 35px;
    padding: 0 10px;
    top: 20px;

    @include bp(40) {
        display: block;
    }

    @include bp(48) {
        top: 20px;
        @include font-size(18px);
        font-weight: 700;
        height: 40px;
        left: -20px;
        line-height: 40px;
    }

    &:before {
        border-top: 15px solid #2b2a27;
        border-left: 15px solid transparent;
        top: 35px;
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        position: absolute;
        left: 1px;

        @include bp(48) {
            border-top: 20px solid #2b2a27;
            border-left: 20px solid transparent;
            top: 40px;
        }
    }
}
