.recev-header {
    @extend %page-content;

    padding: 20px;

    @include bp(48) {
        padding-top: 50px;
        padding-bottom: 20px;
    }

    @include bp(75) {
        padding-top: 80px;
    }

    @include bp(120) {
        padding-top: 100px;
    }
}

.recev-header__heading {
    @extend %my-pages-header__heading;
}

.recev-header__preamble {
    @extend %my-pages-header__preamble;

    @include bp(48) {
        max-width: 768px;
    }

    @include bp(75) {
        max-width: 1000px;
    }

    @include bp(120) {
        max-width: 1200px;
    }
}

.recev-section {
    @extend %page-content;

    @include bp(48) {
        padding-bottom: 30px;
    }

    @include bp(75) {
        padding-bottom: 100px;
    }

    @include bp(120) {
        padding-bottom: 120px;
    }
}

.recev-list {
    padding: 0;
    margin: 0;
    list-style: none;

    @include bp(48) {
        padding: 0 20px;
        max-width: 768px;
    }

    @include bp(75) {
        max-width: 1000px;
    }

    @include bp(120) {
        max-width: 1200px;
    }
}

.recev-list__item {
    border-top: 5px solid $sand-light;

    @include bp(48) {
        border: 3px solid $black;
        margin-bottom: 30px;
        border-radius: 6px;
    }

    @include bp(75) {
        border: 5px solid $black;
        border-radius: 10px;
    }
}

.recev-list__item:last-of-type {
    @include bp(48) {
        margin-bottom: 0;
    }
}

.recev__content {
    padding: 20px;

    @include bp(120) {
        padding: 30px;
    }
}

.recev-meta-action__container {
    @extend %clearfix;

    @include bp(48) {
        padding-top: 20px;
    }

    @include bp(120) {
        padding-top: 30px;
    }
}

.recev-meta-list {
    padding: 20px 0;
    margin: 0;
    list-style: none;

    @include bp(48) {
        float: left;
        padding: 0;
    }
}

.recev-meta-list__item {
    display: inline-block;
    margin: 5px 20px 5px 0;
    font-weight: 600;

    @include font-size(15px);

    position: relative;
    padding-left: 25px;

    @include bp(48) {
        vertical-align: text-top;
        max-width: 220px;
        margin-top: 15px;
    }

    @include bp(75) {
        max-width: inherit;
    }

    @include bp(120) {
        @include font-size(23px);

        padding-left: 40px;
        margin-right: 30px;
    }


    &:before {
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: auto 100%;
        content: '';
        display: inline-block;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
    }

    &--date {
        &:before {
            background-image: url('../img/icons/calendar.svg');
            width: 20px;

            @include bp(120) {
                width: 30px;
                height: 30px;
            }
        }
    }

    &--time {
        &:before {
            background-image: url('../img/icons/clock.svg');
            width: 20px;

            @include bp(120) {
                width: 30px;
                height: 30px;
            }
        }
    }

    &--store {
        &:before {
            background-image: url('../img/icons/store.svg');
            width: 20px;

            @include bp(120) {
                width: 30px;
                height: 30px;
            }
        }
    }
}

.recev-actions {
    @include bp(48) {
        float: right;
    }
}

/*Receipt specific styles*/

.receipt-overlay {
    background: rgba(0, 0, 0, .5);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 200;
}

.receipt-pop-up {
    margin: 5vh auto;
    max-width: 450px;
    position: relative;
    width: 85%;
}

.receipt-wrapper {
    display: block;
    height: 80vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    width: 100%;
}

.receipt__img {
    width: 100%;
}

.receipt__close {
    background-color: $black;
    border-radius: 50%;
    border: 0;
    line-height: 1em;
    padding: 0;
    position: absolute;
    left: -10px;
    top: -10px;
    width: 30px;
    height: 30px;
    z-index: 2;

    @include bp(48) {
        width: 40px;
        height: 40px;
        top: -15px;
        left: -15px;
    }

    img {
        margin-left: auto;
        margin-right: auto;
        display: block;

        @include bp(48) {
            width: 15px;
            height: 15px;
        }
    }
}

.receipt-price {
    padding-bottom: 15px;
    border-bottom: 2px solid $sand-light;

    @extend %clearfix;

    @include bp(48) {
        border-bottom: 1px solid $black;
    }


    @include bp(120) {
        padding-bottom: 25px;
    }
}

.receipt-price__description {
    float: left;

    @include font-size(15px);

    line-height: 30px;
    font-weight: 600;

    @include bp(48) {
        margin-right: 15px;
        float: none;
        display: inline-block;
        vertical-align: top;
    }


    @include bp(75) {
        line-height: 40px;
    }


    @include bp(120) {
        @include font-size(23px);
    }
}

.receipt-price__price {
    @include font-size(30px);

    font-weight: 700;
    float: right;
    line-height: 30px;

    @include bp(48) {
        float: none;
        display: inline-block;
    }


    @include bp(75) {
        @include font-size(37px);

        line-height: 40px;
    }


    @include bp(120) {
        @include font-size(45px);
    }
}

.receipt-btn {
    text-decoration: none;
    color: $black;
    border-radius: 5px;
    border: 2px solid $black;
    padding: 5px 15px;
    line-height: 28px;

    @include font-size(15px);

    font-weight: 600;
    transition: all .2s ease-in;
    display: inline-block;

    @include bp(75) {
        border: 3px solid $black;
    }


    &:before {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        content: '';
        display: inline-block;
        height: 20px;
        margin-right: 5px;
        position: relative;
        top: 5px;
    }

    &--receipt {
        margin-right: 20px;

        &:before {
            transition: all .2s ease-in;
            background-image: url('../img/icons/eye--base-black.svg');
            width: 20px;
        }
    }

    &--email {
        &:before {
            transition: all .2s ease-in;
            background-image: url('../img/icons/mail-arrow.svg');
            width: 15px;
        }
    }
}

.no-touch {
    .receipt-btn {
        &:hover {
            background-color: $black;
            color: $sand-lighter;
        }

        &--receipt {
            &:hover {
                &:before {
                    transition: all .2s ease-in;
                    background-image: url('../img/icons/eye--base-white.svg');
                }
            }
        }

        &--email {
            &:hover {
                &:before {
                    transition: all .2s ease-in;
                    background-image: url('../img/icons/mail-arrow--inverted.svg');
                }
            }
        }
    }

    .cancel-booked-event-popup__custom-button {
        &:hover {
            background: $black;
            color: $sand-lighter;
        }
    }

    .my-events-cancel {
        &:hover {
            background-color: $black;
            color: $sand-light;

            &:before {
                content: url('../img/icons/cross.svg');
            }
        }
    }
}

/* Event specific styles */
.my-events-heading {
    padding-bottom: 15px;
    border-bottom: 2px solid $sand-light;

    @include bp(48) {
        border-bottom: 1px solid $black;
    }
}

.my-events-heading__heading {
    @include font-size(23px);

    font-weight: 700;
    margin: 0;
    line-height: 30px;

    @include bp(48) {
        @include font-size(30px);

        line-height: 35px;
    }


    @include bp(75) {
        @include font-size(37px);

        line-height: 43px;
    }
}

.my-events-cancel {
    text-decoration: none;
    color: $black;
    border-radius: 5px;
    border: 2px solid $black;
    padding: 10px 20px;

    @include font-size(15px);

    font-weight: 600;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    display: inline-block;
    text-align: center;
    background: transparent;

    &:before {
        content: url('../img/icons/cross--inverted.svg');
        display: inline-block;
        margin-right: 7px;
        -webkit-transition: all .2s ease-in;
        transition: all .2s ease-in;
    }

    @include bp(75) {
        border: 3px solid $black;
    }
}

body {
    &.cancel-booked-event-overlay {
        overflow: hidden;

        &:before {
            content: '';
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 100;
            background-color: rgba(65, 64, 61, .6);
        }
    }
}

.cancel-booked-event-popup {
    display: none;
    position: fixed;
    top: 50%;
    background: $sand-lighter;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    z-index: 100;
    left: 50%;
    max-width: 600px;
    width: 90%;

    p {
        margin: 0;
    }

    &__custom-button {
        width: 100%;
        max-width: 100px;
        background: transparent;
        border: 2px solid $black;
        border-radius: 5px;
        display: inline-block;

        @include font-size(15px);

        font-weight: 600;
        height: 40px;
        padding: 9px;
        -webkit-transition: all .2s ease-in;
        transition: all .2s ease-in;
        margin: 0;
        text-decoration: none;
        text-align: center;
        color: $black;
        cursor: pointer;
        line-height: 20px;

        &:first-of-type {
            margin-right: 20px;
        }

        @include bp(48) {
            line-height: 0;
            padding: 25px 15px;
            border: 3px solid $black;
            max-width: 150px;

            @include font-size(23px);

            &:first-of-type {
                margin-right: 35px;
            }
        }
    }
}

.remove-event-actions {
    padding: 20px;

    @include bp(48) {
        padding: 30px;
    }
}

.remove-event__confirm-text {
    font-weight: 600;

    @include font-size(15px);
    @include line-height(15);

    color: $black-admin;
    padding: 20px;
    border-bottom: 2px solid $sand-light;

    @include bp(48) {
        @include line-height(23);
        @include font-size(23px);

        padding: 30px 35px;
    }


    @include bp(75) {
        @include font-size(30px);
        @include line-height(30);
    }


    .event-name {
        font-weight: 300;
    }
}

.no-booked-events__heading {
    @include line-height(23);
    @include font-size(23px);

    font-weight: 700;
    margin: 20px 0;

    @include bp(48) {
        @include font-size(30px);
        @include line-height(30);

        margin: 30px 0;
    }


    @include bp(75) {
        @include font-size(37px);
        @include line-height(37);
    }
}

.my-receipts-header__heading {
    @include font-size(20px);

    font-weight: 700;
    margin: 0;
    word-wrap: break-word;

    @include bp(48) {
        @include font-size(34px);
    }


    @include bp(75) {
        @include font-size(45px);
    }
}
