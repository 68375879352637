.friends{
    background-image: url('../img/logo_big.svg');
    background-position: top 50px right 200%;
    background-repeat: no-repeat;
    background-size: 130% auto;

    @include bp(48){
        background-position: top 50px right -50%;
        background-size: 70% auto;
    }

    @include bp(75){
        padding-bottom: 40px;
        background-position: top 100px right -50%;
    }
    @include bp(120){
        padding-bottom: 60px;
    }
}
.friends-hero{
    margin: 0 20px;
    overflow: hidden;
    padding: 0;
    text-align: center;

    @include bp(48){
        margin: 0 50px;
    }
    @include bp(75){
        display: none;
    }
}
.friends-hero__figure{
    display: inline-block;
    position: relative;

    &:before,
    &:after{
        background: $sand-light;
        content: "";
        display: block;
        height: 1px;
        position: absolute;
        top: 50%;
        width: 100vw;
    }

    &:before{
        right: 100%;
        transform: translate(-20px, -50%);
    }
    &:after{
        left: 100%;
        transform: translate(20px, -50%)
    }
}
.friends-hero__icon{
    display: block;
    margin: 0 auto;
    width: 60px;
}
