.my-offers-header {
    @extend %page-content;
    padding: 20px;

    @include bp(48) {
        padding-top: 50px;
        padding-bottom: 20px;
    }
    @include bp(75) {
        padding-top: 80px;
    }
    @include bp(120) {
        padding-top: 100px;
    }
}

.my-offers-header__heading {
    @extend %my-pages-header__heading;
}

.my-offers-header__preamble {
    @extend %my-pages-header__preamble;

    @include bp(48) {
        max-width: 768px;
    }
    @include bp(75) {
        max-width: 1000px;
    }
    @include bp(120) {
        max-width: 1200px;
    }
}

.discount-section {
    @extend %clearfix;
    margin: 0 auto;
    margin-top: 30px;
    width: 100%;

    border-bottom: 1px solid $sand-light;
    margin-bottom: 30px;
    margin-top: 0;
}

.discount-section__content {

    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 0 20px;

    @include bp(48) {
        display: flex;
        flex-wrap: wrap;
        max-width: $content-width__percentage;

    }

}

.discount-item {

    width: 100%;
    margin-bottom: 30px;

    @include bp(48) {
        width: calc(50% - 15px);
        margin-right: 30px;

        &:nth-child(even) {
            margin-right: 0;
        }
    }
}

.discount-item__content {
    height: 100%;
    border: $red 2px dotted;
    border-radius: 5px;
    padding: 20px;
}

.discount-item__discount {
    color: $red;
    @include font-size(30px);
    line-height: 35px;
    margin: 0;

    @include bp(75) {
        @include font-size(37px);
        line-height: 1.216216216em;
    }
}

.discount-item__price {
    @include font-size(23px);
    font-weight: 300;
    color: $black;
    margin: 0;
}

.discount-item__description {
    @include font-size(23px);
    line-height: 1.304347826em;
    margin: 10px 0;
}

.discount-item__asterisk {
    @include font-size(10px);
    line-height: 1.5em;
    position: relative;
    margin: 0 0 10px;

    &:before {
        content: '*';
        position: absolute;
        left: -8px;
        top: 3px;
        @include font-size(20px);
    }

    @include bp(48) {
        @include font-size(15px);
        line-height: 1.333333em;
    }
}

.discount-item__end-date {
    @include font-size(15px);
    line-height: 1.333333em;
    margin: 0;

    @include bp(48) {
        @include font-size(23px);
        line-height: 1.304347826em;
    }
}

.friends-booklist-header {
    @extend %page-content;
}

.friends-booklist-header__content {
    padding: 0 20px 20px;

    @include bp(48) {
        padding: 0 30px 15px;
    }

    @include bp(75) {
        padding: 0 15px 20px;
    }

    @include bp(120) {
        padding: 0 15px 15px;
    }
}

.friends-booklist-header__heading {
    @include font-size(23px);
    line-height: 1.2em;
    margin: 0;

    @include bp(48) {
        @include font-size(30px);
    }

    @include bp(75) {
        @include font-size(37px);
    }

    @include bp(120) {
        @include font-size(45px);
    }
}

.friends {
    .book-list-section {
        background: none;
    }
}
