.teaser-current-wrapper {
    margin-bottom: 0;
    padding-top: 20px;
    border-top: 1px solid $sand-light;

    @include bp(120) {
        padding-top: 40px;
    }
}

.teaser-current {
    @extend %clearfix;
    overflow: hidden;
    margin: 0 auto;

    padding-bottom: 20px;

    @include bp(75) {
        padding-bottom: 25px;
    }
}

.teaser-current-header {
    @extend %page-content;
}

.teaser-current-header__content {
    color: $black;
    @extend %clearfix;
    @extend %full-width-content;
    padding-top: 0;
    padding-bottom: 15px;

    @include bp(48) {
        padding-bottom: 15px;
    }
    @include bp(75) {
        padding-bottom: 25px;
    }
}

.teaser-current__content {
    @extend %clearfix;
    margin-bottom: 20px;
    box-sizing: border-box;

    @include bp(48) {
        float: left;
        width: 50%;
        border-left: 2px solid $sand-light;
        padding-left: 15px;
    }

    @include bp(75) {
        width: 33.333%;
        margin-bottom: 0;
        padding-left: 15px;
    }

    @include bp(120) {
        padding-left: 20px;
    }
}

.teaser-current-figure {
    float: left;
}

.teaser-current-figure__img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
}

.teaser-current-info {
    padding-left: 15px;
    padding-right: 10px;
    max-width: 200px;

    @include bp(75) {
        padding-left: 15px;
        max-width: 265px;
    }

    @include bp(120) {
        max-width: 330px;
        padding-left: 20px;
    }

    span {
        font-weight: 300;
        @include font-size(13px);

        @include bp(120) {
            @include font-size(18px);
        }
    }
}

.teaser-current-info.no-img {
    padding-left: 0;
    max-width: 100%;
    padding-right: 0;

    @include bp(48) {
        padding-right: 0;
    }
    @include bp(75) {
        padding-right: 20px;
    }
}

.teaser-current-info__link {
    color: $black;
    display: block;
    @include font-size(15px);
    line-height: 20px;
    padding-bottom: 15px;
    position: relative;
    text-decoration: none;
    font-weight: 600;
    word-wrap: break-word;

    &:after {
        content: url('../img/icons/pil_puff_320.svg');
        display: block;
        left: 0;
        position: absolute;
        transition: transform .2s ease-in;
        margin-top: 5px;
    }

    &:hover {
        text-decoration: underline;

        &:after {
            transform: translateX(15%);
        }
    }

    @include bp(48) {
        line-height: 1.30434782609em;
        margin-top: 0;
        padding-bottom: 15px;
    }

    @include bp(75) {
        padding-bottom: 25px;
    }

    @include bp(120) {
        @include font-size(23px);
        @include line-height(23)
    }
}

//Carousel
.teaser-current-carousel {
    list-style: none;
    margin: 0;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    overflow: visible;
    @include column_style(0, 2);
    padding: 0 15px;

    @include bp(48) {
        @include column_style(48, 3);
    }

    @include bp(75) {
        @include column_style(75, 9);
    }

    @include bp(120) {
        @include column_style(120, 12);
    }

    &.slick-initialized {
        .teaser-current-carousel__item {
            visibility: visible;
        }
    }

    .touch & {
        @extend %clearfix;
        max-width: none;
    }
}

.teaser-currrent-info__container {
    display: flex;
    align-items: center;
    min-height: 85px;
}

.teaser-current-carousel__item {
    float: left;
    visibility: hidden;
    width: 100%;
    padding-left: 80px;
    position: relative;
    min-height: 85px;

    &:first-child {
        visibility: visible;
    }

    @include bp(40) {
        width: 50%;

        &:nth-child(-n+2) {
            visibility: visible;
        }
    }

    @include bp(75) {
        width: 33.3333333333%;

        &:nth-child(-n+3) {
            visibility: visible;
        }
    }

    @include bp(120) {
        width: 25%;

        &:nth-child(-n+4) {
            visibility: visible;
        }
    }
}

.teaser-current-carousel__content {
    @extend %page-content;
}

.touch {
    .teaser-current-carousel {
        @extend %clearfix;
        overflow: visible;

        @include bp(48) {
            padding-bottom: 30px;
        }
    }

    .teaser-current-carousel-wrapper {
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch; // sass-lint:disable-line
    }

    .teaser-current-carousel__item {
        visibility: visible;
    }

    .teaser-current-carousel__content {
        @include bp(48) {
            padding-bottom: 0;
        }
    }
}

.teaser-current-carousel {
    .slick-prev {
        height: 85px;
        top: 50%;
    }

    .slick-next {
        top: 50%;
        height: 85px;
    }
}
