
.store-detail__content {
  padding: 20px;
  @extend %page-content;

  @include bp(48){
    padding: 30px;
  }
  @include bp(75){
    padding: 60px 0px 20px 30px;
  }

  &--employee {
    @include bp(48){
      padding-bottom: 30px;
    }
  }
  &--social {
    padding-bottom: 10px;
    padding-top: 30px;

    @include bp(48){
      padding-bottom: 20px;
    }
    @include bp(120){
      padding-top: 60px;
      padding-bottom: 50px;
    }
  }
}
.store-hero {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 50%;
  width: 270px;
  height: 270px;
  margin: 0 auto;

  @include bp(48){
    width: 307px;//330px;
    height: 307px;//330px;
  }
  @include bp(75){
    width: 270px;
    height: 270px;
  }
  &--default {
    background-size: 100%;
  }
}
.store-social-section {
  border-top: 1px solid $sand-light;
  &--all-social {
    @include bp(48) {
      .social-section-circle-wrapper {
        float: left;
        width: 50%;

        &--instagram {
          padding-right: 30px;

          @include bp(75){
            border-right: 1px solid $sand-light;
            padding-right: 0;
          }
          @include bp(120){
            padding-right: 60px;
          }
        }
        &--facebook {
          padding-left: 30px;

          @include bp(75){
            padding-left: 0;
          }

          @include bp(120){
            padding-left: 60px;
          }
        }
        @include bp(120){
          width: auto;
        }
      }
      .social-section__circle {

        &--instagram {
          margin: 0 0 10px auto;

          @include bp(75){
            //margin: 0 auto 10px 0;
            margin: 0 auto 10px auto;
          }
           @include bp(120){
            margin: 0 0 10px auto;
          }
        }
        &--facebook {
          margin: 0 auto 10px 0;

          @include bp(75){
            //margin: 0 0 10px auto;
            margin: 0 auto 10px auto;
          }
           @include bp(120){
            margin: 0 auto 10px 0;
          }
        }
      }
    }
  }
}

.social-section__circle {
  width: 280px;
  height: 280px;
  border-radius: 50%;
  border: 5px solid $facebook-blue;
  position: relative;
  text-align: center;
  margin: 0 auto 20px auto;

  @include bp(48){
    width: 330px;
    height: 330px;
    margin: 0 auto 10px auto;
  }

  @include bp(75){
    width: 350px;
    height: 350px;
    margin: 0 auto 10px 0;
  }
  &--instagram {
    border-color: $instagram-blue;
  }
}
.social-section__container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 40px;

  @include bp(48){
    padding: 0 20px;
  }
  &--facebook {
    @include bp(48){
      transform: translateY(-60%);
    }
  }
}
.social-section__link {
  color: $black;
  margin: 20px auto 0 auto;
  word-wrap: break-word;
  display: block;
  @include font-size(15px);

  @include bp(48){
    @include font-size(23px);
    font-weight: 300;
  }

  &--instagram {
    margin-top: 0;
  }
}
.social-section__icon {
  margin: 0 auto;
  display: block;

  &--instagram {
    width: 180px;

    @include bp(48){
      width: 220px;
    }
  }
  &--facebook {
    width: 50px;
  }
}

.employee-carousel-section {
  @include bp(48){
    .carousel-content {
      max-width: 100%;
    }
  }
  .slick-next, .slick-prev {
    height: 65px;
    width: 85px;
  }
}
.employee-carousel-list__item {
  @extend %clearfix;
}
.employee__name {
  @include font-size(15px);
  @include line-height(15);
  padding: 0 15px;
  margin-top: 23px;
  font-weight: 600;
  word-wrap: break-word;

  @include bp(120){
    @include font-size(23px);
    @include line-height(23);
    margin-top: 14px;
    padding: 0 20px;
  }
}
.employee-avatar {
  float: left;
  margin-right: 10px;
}
.employee-avatar__figure {
  width: 85px;
  height: 85px;
  border-radius: 50%;

  &--default {
    border: 1px solid $black;
  }
}
.employee__recommendations {
  display: block;
  font-weight: 300;
  color: $black;
  @include font-size(13px);
  @include line-height(13);

  @include bp(120){
    @include font-size(18px);
    @include line-height(18);
  }
}
