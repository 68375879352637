.my-account{
    border-bottom: 1px solid $sand-light;
}
.my-account__content{
    @extend %page-content;
    padding: 20px;

    @include bp(48){
        padding: 30px 20px;
    }
    @include bp(75){
        padding: 50px 20px;
    }
}
.my-account__heading{
    @extend %my-pages-header__sub-heading;
    margin-bottom: 15px;
}
.account-info{
    margin: 0;
}
.account-info__title{
    @include font-size(15px);
    font-weight: 700;
    line-height: 1.33333333em;
    margin: 15px 0 0;

    @include bp(75){
        @include font-size(23px);
    }
}
.account-info__description{
    @include font-size(15px);
    font-weight: 400;
    line-height: 1.33333333em;
    margin: 0;

    @include bp(75){
        @include font-size(23px);
    }
}
.my-account__info{
    @include font-size(15px);
    font-style: italic;
    font-weight: 300;
    line-height: 1.33333333em;
    margin: 15px 0 0;

    @include bp(75){
        @include font-size(23px);
    }
}
