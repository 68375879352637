.my-orders-section{
    border-top:1px solid $sand-light;
    margin-bottom: 0;
    padding-bottom: 0;
}

.my-orders-header{
    padding: 20px;

    @include bp(48){
        max-width: 768px;
    }
    @include bp(75){
        max-width: 1000px;
    }
    @include bp(120){
        max-width: 1200px;
    }
}

.my-orders-header__heading{
    @include font-size(20px);
    font-weight: 700;
    line-height: 1.1666666667em;
    margin: 0;
    word-wrap: break-word;


    @include bp(48){
        @include font-size(34px);
    }
    @include bp(75){
        @include font-size(45px);
    }

    &:before{
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: auto 100%;
        content: "";
        display: inline-block;
        height: 20px;
        width: 20px;
        margin-right: 8px;
        position: relative;
        top: 1px;
        background-image: url(../img/icons/cart.svg);

        @include bp(48){
            height: 34px;
            width: 34px;
        }
        @include bp(75){
            height: 45px;
            width: 45px;
        }
    }

}

.my-orders-header__preamble{
    @extend %my-pages-header__preamble;
}

.my-orders-list-item{
    @include font-size(15px);
    background-color: $sand-lighter;
    border-radius: 5px;
    box-shadow: 0 0 20px $border-product-filter;
    margin: 0;
    padding: 0;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.1s ease-out, opacity 0.1s;
    height: 0;
    overflow: hidden;

    &:first-child,
    .my-orders-list__show-all &{
        opacity: 1;
        transform: scaleY(1);
        margin: 30px 10px;
        height: auto;
        padding: 30px;
        transition: transform 0.5s ease-out, opacity 0.3s linear 0.2s;

        @include bp(48) {
            padding: 30px;
        }

        @include bp(120) {
            margin: 50px 0;
        }
    }

    &:first-child{
        margin-top: 0;

        @include bp(120) {
            margin-top: 0;
        }
    }

    @include bp(48) {
        border:none;
    }

    @include bp(120) {
        @include font-size(23px);
    }
}
.my-orders-list-item__header-link{
    color: $black;
    text-decoration: none;
}

.my-orders-list-item__header{
    overflow: hidden;
    margin-bottom: 20px;


    @include bp(48) {
        border-bottom: 1px solid $disabled-element;
        padding-bottom: 20px;
    }
}

.my-orders-list-item__order-number{
    border-bottom: 1px solid $disabled-element;
    padding-bottom: 20px;

    @include bp(48){
        text-align: left;
        float:left;
        border: none;
        padding-bottom: 0;
    }
    @include bp(75) {
        @include font-size(23px);
    }

    &--label{
        font-weight: 300;
    }

    &--number{
        display: inline-block;
        font-weight: 700;

        @include bp(48){
            display: inline;
        }
    }

}

.my-orders-list-item__date{
    font-weight: 700;
    margin-top:15px;


    @include bp(48){
        text-align: left;
        float:right;
        margin-top: 0;
    }

    &--date{
        margin-right: 30px;
        display: inline-block;
    }

    &--time{
        display: inline-block;
    }

    &--date:before,
    &--time:before{
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: auto 100%;
        content: "";
        height: 20px;
        width: 20px;
        display: inline-block;
        margin-right: 8px;
        position: relative;
        top: 3px;

        @include bp(120) {
            height: 25px;
            width: 25px;
            top: 5px;
        }

    }

    &--date:before{
        background-image: url(../img/icons/0084-calendar.svg);
    }
    &--time:before{
        background-image: url(../img/icons/clock.svg);
    }
}

.my-orders-list-item__content{
    overflow: hidden;
    float:left;

    .my-orders-list__has-tracking &{
        float:none;

        @include bp(48){
            float:left;
        }
    }
}

.my-orders-list-item--status{
    padding: 11px;
    background-color: $sand-light;
    border-radius: 5px;
    text-align: center;
    display: inline-block;
    margin-right: 20px;


    @include bp(48){
        padding: 20px;
        display: inline-block;
        margin-top: 0;
    }
    @include bp(120) {
        padding: 15px 30px;
    }

    .my-orders-list__has-tracking &{
        display: block;
        margin-right: 0;

        @include bp(48){
            display: inline-block;
        }
    }

}
.my-orders-list-item--status-text{
    font-weight: 700;
}

.my-orders-list-item__footer{
    text-align: left;
    float: left;

    @include bp(48){
        text-align: left;
        float: right;
        margin-top: 9px;
    }

    .receipt-btn{
        padding: 3px 15px;
        margin-right: 0;
        margin-bottom: 10px;

        @include bp(48){
            margin-bottom: 0;
        }

    }

    .my-orders-list__has-tracking &{
        text-align: center;
        float: none;
        margin-top: 20px;

        @include bp(48){
            float: right;
            margin-top: 9px;
        }
    }
}
.my-orders-list-item__footer .my-orders-list-item,
.my-orders-list-item {
    &--track {
        margin-left: 20px;
        background-color: $sand-lighter;

        &:before {
            background-image: url(../img/icons/paket--black.svg);
            transition: all 0.2s ease-in;
        }

    }

    &--loading {
        pointer-events: none;
        background: $black;
        color: $sand-lighter;
        position: relative;
        padding-right: 40px;

        &:after {
            content: '';
            background: url(../img/icons/spinner-inverted.svg);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            position: absolute;
            margin-left: 10px;
            display: inline-block;
            height: 12px;
            width: 12px;
            top: 11px;

            @include bp(75) {
                top: 7px;
                height: 20px;
                width: 20px;
            }
        }
    }
}

.order-detail-section__content{
    .my-orders-list-item {
        &--track {
            margin-top: 20px;
            margin-left: 0;
        }
    }
}
.no-touch .my-orders-list-item--track.my-orders-list-item--loading,
.no-touch .my-orders-list-item--track:hover{
    background-color: $black;
    color: $sand-lighter;

    &:before{
        transition: all 0.2s ease-in;
        background-image: url(../img/icons/paket--white.svg);
    }
}

.my-orders-list__load-more{
    @extend .receipt-btn;
    background-color: transparent;
    margin-left: 30px;
    width: 250px;

    .my-orders-list__load-more--more{
        display: inline-block;
    }
    .my-orders-list__load-more--less{
        display: none;
    }
}

.my-orders-list__load-more--expanded{
    .my-orders-list__load-more--more{
        display: none;
    }
    .my-orders-list__load-more--less{
        display: inline-block;
    }
}