.teaser-section-wrapper {
    border-top: 1px solid $sand-light;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;

    @include bp(48) {
        flex-direction: row;
        padding: 0;
    }
}

.teaser-section {
    @extend %clearfix;
    @extend %page-content;
    margin: 0 auto;
    padding: 15px 0;

    @include bp(48) {
        padding: 40px 0;
    }

    @include bp(75) {
        padding: 30px 0;
    }
}

.teaser-section-content {
    @extend %clearfix;
    margin-bottom: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-right: 10px;

    @include bp(48) {
        float: left;
        width: 33%;
        border-left: 2px solid $sand-light;
        padding-left: 15px;
    }
    @include bp(75) {
        width: 16.5%;
        margin-bottom: 0;
        padding-left: 5px;
    }
    @include bp(120) {
        padding-left: 20px;
    }
}

.teaser-figure {
    float: left;
}

.teaser-figure__img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    @include bp(75) {
        width: 60px;
        height: 60px;
    }
    @include bp(120) {
        width: 108px;
        height: 108px;
    }
}

.teaser-info {
    float: left;
    padding-left: 10px;
    max-width: 170px;

    @include bp(120) {
        max-width: 200px;
        padding-left: 15px;
    }
}

.teaser-info.no-img {
    padding-left: 0;
    max-width: 100%;
    padding-right: 0;
    @include bp(48) {
        padding-right: 0;
    }
    @include bp(75) {
        padding-right: 20px;
    }
}

.teaser-info__link {
    color: $black;
    display: block;
    @include font-size(20px);
    line-height: 25px;
    margin-top: 5px;
    padding-bottom: 40px;
    position: relative;
    text-decoration: none;
    font-weight: 600;
    word-wrap: break-word;

    &:after {
        content: url('../img/icons/pil_puff_320.svg');
        display: block;
        left: 0;
        position: absolute;
        transition: transform .2s ease-in;
        margin-top: 5px;
    }

    &:hover {

        text-decoration: underline;

        &:after {
            transform: translateX(15%);
        }
    }

    @include bp(48) {
        line-height: 1.30434782609em;
        margin-top: 10px;
        padding-bottom: 55px;
    }
    @include bp(75) {
        margin-top: 5px;
        padding-bottom: 30px;
        max-width: 90%;
        @include font-size(16px);
    }

    @include bp(120) {
        @include font-size(20px);
        line-height: 40px;
        margin-top: 10px;

        &:after {
            content: url('../img/icons/pil_puff.svg');
        }
    }
}

.no-arrow {
    padding-bottom: 0;
    margin-top: 0;

    &:after {
        display: none;
    }
}
