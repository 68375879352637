.member-panel {
    border-top: 1px solid #f1efe6;
}

.member-panel__text {
    @include font-size(15px);
    @include line-height(15);
    margin-top: 0;
    font-weight: 300;

    @include bp(75) {
        @include font-size(23px);
        @include line-height(23);
    }
}

.member-panel__content {
    @extend %page-content;

    padding: 20px 20px 0;

    @include bp(48) {
        padding-top: 30px;
    }

    @include bp(75) {
        padding-top: 50px;
    }
}

.member-panel__heading {
    @extend %my-pages-header__sub-heading;

    margin-bottom: 15px;
}

.member-panel__application {
    max-width: 900px;
}

.member-panel__email {
    margin: 20px 0;
}

.member-panel__submit {
    @include font-size(15px);

    margin-top: 20px;
    background: transparent;
    border: 2px solid $black;
    border-radius: 5px;
    display: inline-block;
    font-weight: 600;
    height: 40px;
    padding: 5px;
    transition: all .2s ease-in;
    max-width: 100px;
    width: 100%;
    text-decoration: none;

    @include bp(75) {
        @include font-size(23px);

        border: 3px solid $black;
        height: 55px;
        max-width: 150px;
    }

    &:hover {
        background: $black;
        color: $sand-lighter;
    }
}

.member-panel__message {
    margin-top: 30px;
}

.member-panel__terms-title,
.member-panel__terms-text {
    display: none;
}

.member-panel__email-label {
    @include font-size(15px);
    font-weight: 700;
    line-height: 1.33333333em;
    margin: 15px 0 0;

    @include bp (75) {
        @include font-size(23px);
    }
}

.member-panel__email-value {
    @include font-size(15px);
    font-weight: 400;
    line-height: 1.33333333em;
    margin: 0;

    @include bp(75) {
        @include font-size(23px);
    }
}

.member-panel__checkbox-container {
    margin: 0 0 20px;
}

.member-panel__checkbox {
    @extend %visually-hidden;

    &:checked + .member-panel__label {
        &:before {
            background-image: url('../img/icons/0273-checkmark.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 80%;
        }
    }
}

.member-panel__label {
    cursor: pointer;
    display: inline-block;
    @include font-size(15px);
    padding-left: 34px;
    position: relative;
    max-width: 300px;

    &:before {
        border: 2px solid $black;
        border-radius: 5px;
        content: '';
        cursor: pointer;
        display: inline-block;
        height: 25px;
        left: 0;
        margin-right: 10px;
        position: absolute;
        width: 25px;
        transform: translateY(-50%);
        top: 50%;

        @include bp(75) {
            border: 3px solid $black;
            height: 35px;
            width: 35px;
            top: -3px;
            transform: none;
        }
    }
    @include bp(48) {
        max-width: inherit;
        padding-right: 15px;
    }
    @include bp(75) {
        @include font-size(23px);
        padding-left: 44px;
        padding-right: 20px;
    }
}
