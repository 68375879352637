$v-padding: 6px;
$h-padding: 11px;

%btn,
.btn {
    @include font-size(15px);
    position: relative;
    background: transparent;
    padding: $v-padding $h-padding;
    text-decoration: none;
    border: 2px solid $black;
    border-radius: 5px;
    color: $black;
    display: inline-block;
    font-weight: 600;
    line-height: 24px;
    transition: background .2s ease-in-out, color .2s ease-in-out, border-color .2s ease-in-out, opacity .2s ease-in-out;
    cursor: pointer;

    @include bp(48) {
        border-width: 3px;
    }

    &:visited {
        color: $black;
    }

    &:hover,
    &.btn--selected {
        background: $black;
        color: $sand-lighter;
    }

    &.disabled {
        pointer-events: none;
        cursor: none;
        opacity: .3;
    }
}

/**
 * Styles
 */
%btn--cta,
.btn--cta {
    background: $red;
    color: $sand-lighter;
    border-color: $red;

    &:visited {
        color: $sand-lighter;
    }

    &:hover,
    &.btn--selected {
        background: darken($red, 10%);
        border-color: darken($red, 10%);
    }
}

%btn--dotted,
.btn--dotted {
    border-style: dotted;
    border-color: $gray-light;
    background: transparent;
    color: $black;

    @include bp(48) {
        border-width: 2px;
        line-height: 26px;
    }

    &.btn--big-120 {
        @include bp(120) {
            line-height: 30px;
        }
    }

    &:visited {
        color: $black;
    }

    &:hover,
    &.btn--selected {
        background: $sand-light;
        border-style: dotted;
        border-color: $gray-light;
        color: $black;
    }
}

%btn--outline,
.btn--outline {
    border-width: 1px;
    border-color: $gray-light;
    color: $black;
    padding: ($v-padding + 3) $h-padding;
    font-weight: 200;
    background: transparent;

    &:hover {
        cursor: not-allowed;
        background: inherit;
        color: inherit;
    }
}

%btn--centered,
.btn--centered {
    text-align: center;
}

%btn--no-border,
.btn--no-border {
    border-color: transparent;
    background: transparent;
    padding-left: 0;
    padding-right: 0;
    border-left: 0;
    border-right: 0;
    font-weight: 700;

    &:hover,
    &:focus,
    &.btn--selected {
        background: transparent;
        color: $black;
    }
}

%btn--underline,
.btn--underline {
    text-decoration: underline;
}

%btn--inverted,
.btn--inverted {
    background: $black;
    color: $sand-lighter;

    &:visited {
        color: $sand-lighter;
    }

    &:hover,
    &.btn--selected {
        opacity: .7;
    }
}

%btn--bevaka,
.btn--blue {
    background: $button-blue;
    border-color: $button-blue;
    color: white;

    &:hover,
    &.btn--selected {
        opacity: .7;
        background: $button-blue;
    }
}

/**
 * Sizes
 */
%btn--big-120,
.btn--big-120 {
    @include bp(120) {
        @include font-size(18px);
        line-height: 28px;
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

%btn--small,
.btn--small {
    @include font-size(13px);
    line-height: 18px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.btn--non-shop-list-view {
    padding: 5px 11px;
    @include bp(48) {
        padding: 6px 11px;
    }
}

/**
 * Misc
 */
%btn--block,
.btn--block  {
    display: block;
    width: 100%;
}

%btn--nowrap,
.btn--nowrap {
    white-space: nowrap;
}

%btn--selected,
.btn--selected {
    background: $black;
    color: $sand-lighter;

    &.btn--tag-style {
        &:after {
            display: inline-block;
            content: url('../img/icons/cross.svg');
            margin-left: 10px;
        }
    }
}

%btn--loading,
.btn--loading {
    min-width: 100px;
    pointer-events: none;

    &:after {
        @include spinner(24px);
        content: '';
        vertical-align: middle;
        margin-left: 7px;
    }

    &.btn--inverted {
        &:after {
            @include spinner(24px, $white);
        }
    }

    &.btn--blue {
        &:after {
            @include spinner(24px, $white);
        }
    }

    &.btn--small {
        &:after {
            @include spinner(18px);
        }

        &.btn--inverted {
            &:after {
                @include spinner(18px, $white);
            }
        }
    }
}

/**
 * Button icons
 */
%btn__icon,
.btn__icon {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 16px 16px;

    // Replace background images on use
    background-image: url('../img/icons/0032-book.svg');

    .btn--big-120 & {
        @include bp(120) {
            width: 24px;
            height: 24px;
            margin-left: 10px;
            margin-right: 0;
            background-size: 22px 22px;
        }
    }
}

%btn__icon--left,
.btn__icon--left {
    margin-left: 0;
    margin-right: 5px;

    .btn--big-120 & {
        @include bp(120) {
            margin-right: 10px;
            margin-left: 0;
        }
    }
}

.btn__icon--Inbunden {
    background-image: url('../img/icons/hardcover.svg');

    .btn--selected &,
    .btn:hover & {
        background-image: url('../img/icons/hardcover--inverted.svg');
    }
}

.btn__icon--Häftad {
    background-image: url('../img/icons/bindings.svg');

    .btn--selected &,
    .btn:hover & {
        background-image: url('../img/icons/bindings--inverted.svg');
    }
}

.btn__icon--Pocket {
    background-image: url('../img/icons/pocket.svg');

    .btn--selected &,
    .btn:hover & {
        background-image: url('../img/icons/pocket--inverted.svg');
    }
}

.btn__icon--Ljudbok {
    background-image: url('../img/icons/audiobook.svg');

    .btn--selected &,
    .btn:hover & {
        background-image: url('../img/icons/audiobook--inverted.svg');
    }
}

.btn__icon--cart-inverted {
    background-image: url('../img/icons/cart--inverted.svg');
    position: relative;
    top: -2px;

    .btn--selected &,
    .btn:hover & {
        background-image: url('../img/icons/cart--inverted.svg');
    }
}

.btn__icon--watch-item {
    background-image: url('../img/icons/SVG/0082-bell.svg');
    position: relative;
    top: -1px;
}

.btn__icon--store {
    background-image: url('../img/icons/store.svg');
    margin-top: -2px;

    .btn--selected &,
    .btn:hover & {
        background-image: url('../img/icons/store--inverted.svg');
    }
}

.btn__icon--email {
    background-image: url('../img/icons/envelop--black.svg');

    .btn--selected &,
    .btn:hover & {
        background-image: url('../img/icons/envelop.svg');
    }
}

.btn__icon--phone {
    background-image: url('../img/icons/mobil--black.svg');

    .btn--selected &,
    .btn:hover & {
        background-image: url('../img/icons/mobil--inverted.svg');
    }
}

.btn__icon--store {
    background-image: url('../img/icons/store.svg');

    .btn--selected &,
    .btn:hover & {
        background-image: url('../img/icons/store--inverted.svg');
    }
}

.btn__icon--store {
    background-image: url('../img/icons/store.svg');

    .btn--selected &,
    .btn:hover & {
        background-image: url('../img/icons/store--inverted.svg');
    }

    .btn--dotted:hover & {
        background-image: url('../img/icons/store.svg');
    }
}

.btn__icon--price-tag {
    background-image: url('../img/icons/price-tag.svg');
    margin-top: -2px;

    .btn--selected &,
    .btn:hover & {
        background-image: url('../img/icons/price-tag--inverted.svg');
    }
}

.btn__icon--check {
    background-image: url('../img/icons/0273-checkmark.svg');
}
