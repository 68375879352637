.sale-filter{
    background: $sand-light;
}
.sale-filter__content{
    @extend %page-content;
}
.sale-filter-form{
    @extend %full-width-content;
    padding:20px;

    @include bp(75){
        padding:20px 15px;
    }
}
.sale-filter-fieldset{
    border: 0;
    padding: 0;
    margin: 0;

    &--checkboxes{
        border-top: 1px solid $border-sale-filter;
        max-height: 800px;
        padding: 20px 0 0;
        margin-top: 20px;

        .sale-filter__checkbox-option {
            display: none;

            &--show {
                display: inline-block;
            }
        }

        &.sale-filter-fieldset--active{


            .sale-filter__checkbox-option {
                display: inline-block;
            }
        }
    }
}

.sale-filter-search{
    float: left;
    height: 30px;
    position: relative;
    width: 100%;
    margin-bottom:20px;

    @include bp(75){
        margin-bottom: 0;
        height: 46px;
        margin-right: 20px;
        width: 48%;
    }
}
.sale-filter-search__input{
    border: 0;
    border-radius: 5px;
    height: 100%;
    padding: 0 35px 0 10px;
    width: 100%;

    @include bp(75){
        padding: 0 40px 0 10px;
    }

    &::-webkit-input-placeholder {
        color: $black;
        font-weight: 600;
        font-style: italic;
    }

    &:-moz-placeholder {
        color: $black;
        font-weight: 600;
        font-style: italic;
    }

    &::-moz-placeholder {
        color: $black;
        font-weight: 600;
        font-style: italic;
    }

    &:-ms-input-placeholder {
        color: $black;
        font-weight: 600;
        font-style: italic;
    }
}
.sale-filter-search__button{
    background-color: $black;
    background-image: url(../img/icons/0135-search--inverted.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50%;
    border: 0;
    border-radius: 0 5px 5px 0;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;

    @include bp(75){
        width: 46px;
    }
}
.toggle-sale-filter{
    display: inline-block;
    float:left;
    height:32px;
    position: relative;

    @include bp(48){
        float:right;
        right: 11px;
    }
    @include bp(75){
        height: 46px;
    }
}
.toggle-sale-filter__anchor{
    color: $black;
    display: inline-block;
    line-height: 30px;
    position: relative;
    text-decoration: none;
    top: 50%;
    transform: translateY(-50%);

    &:after{

        background-image: url(../img/icons/chevron_right_black.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 95%;
        content: "";
        display: inline-block;
        right: -20px;
        position: relative;
        height: 20px;
        width: 12px;
        transform: translateY(-50%);
        transform: rotate(90deg);
        top: 6px;
        left: 10px;
        transition: transform 0.2s ease-in;
    }

    &--active {
        &:after{
            transform: rotate(-90deg);
            transition: transform 0.2s ease-in;
        }
    }
}

.sale-filter-select{
    float: left;
    margin-bottom: 0;

    .custom-select {
        width: 100%;

        @include bp(48) {
            padding-left: 75px;
        }
    }
}
.sale-filter-select__label{
    @include font-size(15px);
    font-weight: 600;
    line-height: 30px;
    padding-left: 10px;
    display: none;

    @include bp(48){
      display: inline;
    }
    @include bp(75){
        line-height: 40px;
    }
}
.sale-filter__legend{
    display: inline-block;
    float: left;
    @include font-size(15px);
    font-weight: 300;
    line-height: 27px;
    margin-right: 10px;

    @include bp(75){
        @include font-size(23px);
        line-height: 40px;
    }
}
.sale-filter__checkbox{
    @extend %visually-hidden;
}
.sale-filter__label{
    color: $black;
    cursor: pointer;
    border: 2px solid $black;
    border-radius: 5px;
    display: inline-block;
    @include font-size(13px);
    font-weight: 600;
    letter-spacing: 0.5px;
    margin: 0 5px 10px 0;
    padding: 2px 6px;
    transition: background 0.2s ease-in, color 0.2s ease-in;

    @include bp(75){
        border: 3px solid $black;
        @include font-size(15px);
        padding: 8px 20px;
        margin: 0 10px 10px 0;
    }

    &:hover{
        background: $black;
        color: $sand-lighter;

        .touch & {
            background: transparent;
            color: $black;
        }
    }

    .sale-filter__checkbox:checked + & {
        background: $black;
        color: $sand-lighter;

        @include bp(75){
            padding-right: 10px;
        }

        &:after{
            background-image: url(../img/icons/cross.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            content: "";
            display: inline-block;
            height: 8px;
            margin-left: 5px;
            vertical-align: middle;
            width: 8px;

            @include bp(75){
                margin-left: 10px;
            }
        }
    }
}

.sale-filter-store{
    display: none;
    padding: 10px 0 5px 0;
    @include bp(75){
      padding: 20px 0 5px 0;
    }

    &--show {
        display: block;
    }
}
.sale-filter-store__checkbox{
    @extend %visually-hidden;

    &:checked + .sale-filter-store__label{

        &:before{
            background-image: url(../img/icons/0273-checkmark.svg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 80%;
        }
    }
}
.sale-filter-store__label{
    cursor: pointer;
    display: inline-block;
    @include font-size(15px);
    position: relative;
    padding-top: 0;
    padding-left: 34px;
    line-height: 25px;

    &:before{
        border: 2px solid $black;
        border-radius: 5px;
        content: "";
        cursor: pointer;
        display: inline-block;
        height: 25px;
        left: 0;
        margin-right: 10px;
        width: 25px;
        vertical-align: middle;
        position: absolute;

        @include bp(40) {
            top: 50%;
            transform: translateY(-50%);
        }

        @include bp(75) {
            border: 3px solid $black;
            height: 30px;
            width: 30px;
        }
    }

    @include bp(48){
        max-width: inherit;
        margin-bottom: 0;
        padding-top: 0;
    }

    @include bp(75){
        line-height: 30px;
        padding-left: 38px;
    }
}
.sale-breadcrumbs{
    @extend %page-content;
}
.filter-breadcrumb{
    margin-top: 30px;
    padding: 0 20px;

    @include bp(48){
        padding: 0 30px;
    }
    @include bp(75){
        padding: 0 15px;
    }
}
.filter-breadcrumb__label{
	@include font-size(15px);
	font-weight: 600;
	margin-right: 5px;

    @include bp(48){
        margin-right: 10px;
    }
}
.filter-breadcrumb__item{
    background: transparent;
    border: none;
    color: $black;
    display: inline-block;
    @include font-size(15px);
    font-weight: 600;
    margin: 0 20px 5px 0;
    padding: 0;
    position: relative;

    &:after{
        display: inline-block;
        background-image: url(../img/icons/cross--inverted.svg);
        content: '';
        margin-left: 5px;
        width: 10px;
        height: 10px;
    }
    &:before{
        background-color: $black;
        bottom: -1px;
        content: "";
        display: block;
        height: 2px;
        left: 0px;
        position: absolute;
        right: 0px;
        transform: scale(0);
        transition: transform .2s ease-in;
    }

    &:hover{

        &:before{
            transform: scale(1);
        }

    }
}



