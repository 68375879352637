.product-image-overlay {
    position: relative;
}

.product-image-overlay .product-img__contain-wrapper,
.product-image-overlay .product-img__book {
    transition: opacity .2s ease-in-out;
}

// product-image-overlay__hoverer is a special class that can be put on parent element to activate the hover effect when hovering outside this component
.product-image-overlay:hover,
.product-image-overlay__hoverer:hover {
    .product-img__contain-wrapper,
    .product-img__book {
        opacity: .7;

        .touch & {
            opacity: 1;
        }
    }

    .product-img--non-book {
        border-radius: 5px 5px 0 0;
    }
}


.product-image-overlay__cover {
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    transition: background-color .2s linear, opacity .2s linear, visibility 0s linear .2s;
    opacity: 0;
    z-index: 1;

    // uses css filter "brightness" on product image instead
    .cssfilters & {
        background: transparent;
    }

    .touch & {
        display: none;
    }

    .product-image-overlay:hover &,
    .product-image-overlay__hoverer:hover & {
        transition: background-color .2s linear, opacity .2s linear;
        opacity: 1;
        visibility: visible;
    }
}

.product-image-overlay__text {
    @include font-size(23px);
    @include line-height(23);
    font-weight: 700;
    max-width: 200px;
    color: $white;
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 0 20px;
    margin: 0;
    transition: transform .2s ease-in-out;

    .product-image-overlay--compact & {
        @include font-size(13px);
        @include line-height(13);
        padding: 0 10px;
    }

    .product-image-overlay:hover & {
        text-decoration: underline;
    }
}
