.did-you-mean-section {
    background: $sand-light;
    text-align: center;
    padding: 20px 0;

    @include bp(40) {
        padding: 31px 0 27px;
    }

    @include bp(48) {
        margin-bottom: 15px;
    }
}

.did-you-mean-list__label {
    @include font-size(15px);
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-weight: 300;

    @include bp(40) {
        @include font-size(23px);
    }
}

.did-you-mean-list {
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    list-style: none;
}

.did-you-mean-list__item {
    float: left;
    padding-right: 15px;

    &:before {
        content: '\2022';
        margin-right: 15px;
        display: inline-block;
        @include font-size(15px);
        vertical-align: baseline;

        @include bp(40) {
            @include font-size(23px);
        }
    }

    &:first-child {
        &:before {
            content: '';
            margin-right: 0;
        }
    }

    &:last-child {
        padding-right: 0;
    }
}

.did-you-mean-list-link {
    color: $black;
    text-decoration: none;
    position: relative;
    display: inline-block;

    &:after {
        background-color: $black;
        bottom: 3px;
        content: '';
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        right: 0;

        @include bp(40) {
            bottom: 5px;
        }
    }
}

.list-did-you-mean__title {
    @include font-size(15px);
    margin: 0;

    @include bp(40) {
        @include font-size(23px);
    }
}