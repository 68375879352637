.challenge-content{
    background-color: $sand-light;
    padding: 40px 20px 40px 20px;
    margin-top: 30px;

    @include bp(48) {
      max-width: 648px;
      margin: 30px auto;
      padding: 60px 40px 40px 40px;
    }
    @include bp(75) {
      max-width: 970px;
      margin: 45px auto 0;
      padding: 80px;
    }

    @include bp(120) {
      max-width: 1200px;
      padding: 100px;
    }
}
.questions{
    list-style: none;
    margin: 0;
    padding: 0;
}
.questions__item{
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    input[type="radio"]{
        @extend %visually-hidden;
    }
    input[type="text"]{
        border: 0;
        border-radius: 5px;
        resize: none;
        padding: 0 10px;
        width: 100%;

        @include bp(48){
            padding: 0 15px;
            width: 75%;
        }
        @include bp(75){
            height: 55px;
            @include font-size(23px);
            padding: 0 20px;
        }
    }
    textarea{
        border: 0;
        border-radius: 5px;
        resize: none;
        padding: 10px;
        width: 100%;

        @include bp(48){
            padding: 15px;
            width: 75%;
        }
        @include bp(75){
            @include font-size(23px);
            padding: 20px;
        }

        &::-webkit-input-placeholder{
            color: $black;
            font-style: italic;
            font-weight: 600;
        }
        &:-moz-placeholder {
           color: $black;
           font-style: italic;
           font-weight: 600;
           opacity:  1;
        }
        &::-moz-placeholder {
           color: $black;
           font-style: italic;
           font-weight: 600;
           opacity:  1;
        }
        &:-ms-input-placeholder{
           color: $black;
           font-style: italic;
           font-weight: 600;
        }
    }
}
.input-wrapper{
    margin-bottom: 15px;

    @include bp(75){
        margin-bottom: 30px;
    }
}
.questions__title{
    margin: 0 0 10px;
    @include font-size(15px);

    @include bp(75){
        @include font-size(23px);
    }
}
.radio{
    label{
        cursor: pointer;
        display: block;
        font-weight: 300;
        line-height: 26px;
        padding-left: 26px;
        position: relative;

        &:before{
            border: 2px solid $black;
            border-radius: 50%;
            content: "";
            display: block;
            height: 20px;
            left: 0;
            position: absolute;
            top: 3px;
            width: 20px;

            @include bp(75){
                border: 3px solid $black;
                height: 35px;
                width: 35px;
            }
        }

        @include bp(75){
            @include font-size(23px);
            line-height: 45px;
            padding-left: 45px;
        }

        &.current-choice{
            &:before{
                background-image: url('../img/icons/circle.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 75%;
            }
        }
    }
}
.challenge-form__submit{
    background: transparent;
    border: 2px solid $black;
    border-radius: 5px;
    color: $black;
    display: block;
    @include font-size(15px);
    font-weight: 600;
    margin-top: 30px;
    padding: 9px;
    text-align: center;
    text-decoration: none;
    transition: all 0.2s ease-in;
    max-width: 150px;
    width: 100%;

    @include bp(75){
        border: 3px solid $black;
        @include font-size(23px);
        height: 55px;
        max-width: 200px
    }

    &:hover{
        background: $black;
        color: $sand-lighter;
    }

}
