.reco-carousel-section {
    border-top: 1px solid $sand-light;
    overflow: hidden;
    padding-top: 20px;
    padding-bottom: 20px;

    &.reco-carousel-section--no-border-top {
        border-top: none;
    }
}

.reco-carousel-header {
    @extend %page-content;
}

.reco-carousel-header__content {
    color: $black;
    @extend %clearfix;
    @extend %full-width-content;
    padding-top: 0;
    padding-bottom: 20px;
}

.reco-carousel-header__heading {
    @include font-size(18px);
    line-height: 40px;
    margin: 0;
    padding-left: 30px;
    position: relative;

    &:before {
        background-image: url('../img/icons/tips-icon.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
        content: '';
        display: block;
        height: 40px;
        left: 0;
        position: absolute;
        width: 20px;
    }

    @include bp(48) {
        float: left;
        @include font-size(23px);
        line-height: 40px;
    }
}

.reco-carousel {
    .touch & {
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }
}

.reco-carousel-content {
    @extend %page-content;
}

.reco-carousel-list {
    list-style: none;
    margin: 0;
    padding: 0;
    @include column_style(0, 2);

    @include bp(48) {
        @include column_style(48, 3);
    }

    .touch & {
        @extend %clearfix;
        max-width: none;
    }
}

.reco-carousel-list__item {
    float: left;
    padding-right: 30px;
    width: 100%;

    @include bp(40) {
        width: 50%;
    }
}

.reco-card {
    box-shadow: 0 10px 20px #e3e0d2;
    padding: 5px;
}

.reco-card__content {
    @extend %clearfix;
    background: #fff;
    padding: 20px;
}

.reco-card-product {
    margin-bottom: 15px;
}

.reco-card-product-link {
    @extend %clearfix;
    color: $black;
    display: block;
    text-decoration: none;
}

.reco-card-product-cover {
    float: left;
    margin-right: 10px;
    max-width: 70px;
    position: relative;
    width: 100%;
}

.reco-card-product-cover__new {
    background-color: $medium-green;
    border-radius: 50%;
    color: $sand-lighter;
    display: block;
    @include font-size(12px);
    font-weight: 700;
    height: 25px;
    left: 3px;
    line-height: 25px;
    position: absolute;
    text-align: center;
    top: 10px;
    transform: translate(-50%);
    width: 25px;
}

.reco-card-product-cover__img {
    max-width: 100%;
}

.reco-card-product__title {
    @include font-size(15px);
    font-weight: 700;
    margin: 0;
}

.reco-card-product__author {
    @include font-size(15px);
    font-weight: 300;
    margin: 0;
}

.reco-card__quote {
    p {
        @include font-size(18px);
        font-style: italic;
        font-weight: 700;
        line-height: 1.30434782609em;
        margin: 0;

        @include bp(40) {
            @include font-size(23px);
        }
    }
}

.reco-overlay-link {
    color: $black;
    display: block;
    @include font-size(15px);
    font-weight: 300;
    margin-top: 15px;
}

.reco-card-employee {
    @extend %clearfix;
    margin-top: 15px;
}

.reco-card-employee-figure {
    float: left;
}

.reco-card-employee-figure__img {
    border-radius: 50%;
    height: 65px;
    width: 65px;
}

.reco-card-employee-info {
    float: left;
    padding: 10px 0 0 10px;
    width: calc(100% - 65px);
}

.reco-card-employee-info__name {
    @include font-size(15px);
    font-weight: 600;
    margin: 0;
}

.reco-card-employee-info__link {
    color: $black;
}

.reco-card-employee-info__store {
    @include font-size(15px);
    font-weight: 300;
    margin: 0;
}

