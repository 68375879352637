.reservation-items__container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray;
    padding: 10px 0;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 300px;
    
    @media (min-width: 340px) {
        max-height: 350px;
    }
}

.reservation-items__content {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.reservation-items__heading-text {
    font-weight: 700;
    margin: 0;
}

.reservation-items__summary {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.reservation-items__total-price {
    margin: 0;
}

.reservation-items__total-number {
    margin: 0;
    font-weight: 550;
}

.reservation-items__error-message {
    font-weight: bold;
    color: $red;
}