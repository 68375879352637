.message {
    @include font-size(15px);
    @include line-height(15);
    background: $sand-light;
    color: $black;
    border-color: $black;
    padding: 15px;
    margin: 0 0 10px;
    border-radius: 5px;
    margin-bottom: 20px;

    a {
        color: $black;
        font-weight: 600;
        text-decoration: underline;
    }
}

.message--success {
    background: $green-lighter;
    color: $green-dark;
    border-color: $green-dark;

    a {
        color: $green-dark;
    }
}

.message--error {
    background: $red-light;
    color: $red;
    border-color: $red;

    a {
        color: $red;
    }
}

.message--small {
    padding: 5px 8px;
}

.message--bordered {
    border-width: 1px;
    border-style: solid;
}
