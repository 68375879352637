.proposed-hits__item {
    background: $orange-lighter;
    text-align: center;
    padding: 20px;
    margin-bottom: 10px;
    @include font-size(15px);

    @include bp(40) {
        padding: 24px 0 29px;
        @include font-size(23px);
    }
}

.proposed-hit__title {
    @include font-size(15px);
    margin: 0;

    @include bp(40) {
        @include font-size(23px);
    }
}

.proposed-hit-link {
    color: $red;
    text-decoration: underline;
    position: relative;
    display: inline-block;

    @include bp(40) {
        text-decoration: none;

        &:after {
            background-color: $red;
            bottom: 3px;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            right: 0;

            @include bp(40) {
                bottom: 5px;
            }
        }
    }
}

.proposed-hit-wrapper {
    text-align: center;
}

.proposed-hit__text {
    margin-top: 5px;
    display: inline-block;
    padding: 0 20px;
    word-break: break-word;

    @include bp(75) {
        max-width: 1000px;
    }
    @include bp(120) {
        max-width: 1200px;
    }
}