/* Grid (defined in variables.scss)
* ex. Usage: @include column_style($breakpoint, $columns)
* --------------------------------------------------------------------
*/

@mixin column_style($breakpoint, $columns) {

    box-sizing: border-box;
    width: 100%;

    @if($breakpoint == 0) {
        @if map-has-key($grid__2, $columns) {
            max-width: #{map-get($grid__2, $columns)};
            padding: 0 20px;
        } @else {
            @warn 'No column styles retrieved from `#{$columns}`. '
            + 'There are only 2 columns defined on this breakpoint. Check `$grid__2` map. ';
        }
    } @else if($breakpoint == 48) {
        @if map-has-key($grid__3, $columns) {
            max-width: #{map-get($grid__3, $columns)};
            padding: 0 30px;
        } @else {
            @warn 'No column styles retrieved from `#{$columns}`. '
            + 'There are only 3 columns defined on this breakpoint. Check `$grid__3` map. ';
        }
    } @else if($breakpoint == 75) {
        @if map-has-key($grid__9, $columns) {
            max-width: #{map-get($grid__9, $columns)};
            padding: 0 15px;
        } @else {
            @warn 'No column styles retrieved from `#{$columns}`. '
            + 'There are only 9 columns defined on this breakpoint. Check `$grid__9` map. ';
        }
    } @else if($breakpoint == 120) {
        @if map-has-key($grid__12, $columns) {
            max-width: #{map-get($grid__12, $columns)};
            padding: 0 15px;
        } @else {
            @warn 'No column styles retrieved from `#{$columns}`. '
            + 'There are only 12 columns defined on this breakpoint. Check `$grid__12` map. ';
        }
    }
}

/* Breakpoints (defined in variables.scss)
* ex. Usage: @include bp(40)
* --------------------------------------------------------------------
*/

@mixin bp($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
            @content;
        }
    } @else {
        @warn 'no value retrieved from `#{$breakpoint}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin bpf($breakpoint) {
    @media (min-width: $breakpoint) {
        @content;
    }
}

@mixin bp-max($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        $singlePixelInEm: 1em / $font-size--base-unitless;
        @media (max-width: #{map-get($breakpoints, $breakpoint) - $singlePixelInEm}) {
            @content;
        }
    } @else {
        @warn 'no value retrieved from `#{$breakpoint}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin bpf-max($breakpoint) {
    @media (max-width: $breakpoint) {
        @content;
    }
}


/* Define current breakpoint on body:after
* Usage: Makes it possible to detect media queries with javascript
* --------------------------------------------------------------------
*/

@mixin current-breakpoint($name) {
    &:after {
        content: $name;
        display: none;
    }
}


/* Calculate font sizes
* Usage: @include font-size(16px);
* --------------------------------------------------------------------
*/

@function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

/* Lineheight
* Usage: @include lineheight(font-size)
* --------------------------------------------------------------------
*/

@mixin line-height($font-size) {
    @if map-has-key($lineheights, $font-size) {
        line-height: #{map-get($lineheights, $font-size)};
    } @else {
        @warn 'Font size not defined `#{$lineheights}`.';
    }
}

/* Line clamp
* Usage: @include line-clamp(lines)
* --------------------------------------------------------------------
*/

@mixin line-clamp($lines) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -moz-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


/* Input styles
* Usage: @include input-[type]()
* --------------------------------------------------------------------
*/

@mixin input-text() {
    line-height: 1em;
    margin: 40px 0 0;
    position: relative;

    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='tel'] {
        background: $sand-lighter;
        border: 0;
        border-radius: 5px;
        height: 40px;
        padding: 0 15px;
        width: 100%;

        @include bp(75) {
            height: 55px;
            @include font-size(23px);
            padding: 0 20px;
        }

        &[value=''],
        &.input__field--blank {
            & + label {
                left: 20px;
                top: 50%;
                transform: translateY(-50%);

                @include bp(75) {
                    @include font-size(23px);
                    font-weight: 600;
                    line-height: 1em;
                }

                &:before {
                    top: -2px;
                    height: 15px;
                    width: 15px;

                    @include bp(75) {
                        height: 23px;
                        width: 22px;
                    }
                }
            }
        }

        &:focus {
            & + label {
                @include font-size(15px);
                font-weight: 600;
                left: 0;
                padding-left: 25px;
                top: -5px;
                transform: translateY(-100%);

                &:before {
                    top: -2px;
                    height: 15px;
                    width: 15px;
                }
            }
        }

        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px $sand-lighter inset;

            & + label {
                @include font-size(15px);
                font-weight: 600;
                left: 0;
                padding-left: 25px;
                top: -5px;
                transform: translateY(-100%);

                &:before {
                    top: -2px;
                    height: 15px;
                    width: 15px;
                }
            }
        }
    }

    > label {
        color: $black;
        @include font-size(15px);
        font-style: italic;
        font-weight: 600;
        left: 0;
        line-height: 1em;
        padding-left: 25px;
        position: absolute;
        top: -5px;
        transition: all .2s ease;
        transform: translateY(-100%);
    }
}

@mixin label-icon() {

    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='tel'] {
        &[value=''],
        &.input__field--blank {
            + label {
                left: 15px;
                padding-left: 25px;

                @include bp(75) {
                    left: 20px;
                    padding-left: 35px;
                }
            }
        }
    }

    > label {

        &:before {
            background-position: top left;
            background-repeat: no-repeat;
            background-size: 100% auto;
            content: '';
            display: inline-block;
            height: 15px;
            left: 0;
            position: absolute;
            top: -2px;
            width: 15px;
        }

    }
}

@mixin input-email() {
    @include label-icon();

    > label {
        &:before {
            background-image: url('../img/icons/envelop--black.svg');
        }
    }
    @include input-text();
}

@mixin input-phone() {
    @include label-icon();

    > label {
        &:before {
            background-image: url('../img/icons/mobil--black.svg');
        }
    }
    @include input-text();
}

@mixin input-password() {
    @include label-icon();

    > label {
        &:before {
            background-image: url('../img/icons/key.svg');
        }
    }

    @include input-text();

    input {
        &::-ms-reveal,
        &::-ms-clear {
            display: none;
        }
    }
}

@mixin clear-button-style() {
    border: 0;
    box-shadow: none;
    background-color: transparent;
    padding: 0;
}

@mixin button-styles() {
    background: transparent;
    border: 2px solid $black;
    border-radius: 5px;
    display: inline-block;
    @include font-size(15px);
    font-weight: 600;
    height: 40px;
    margin: 15px 5px 0 0;
    padding: 5px;
    transition: all .2s ease-in;
    max-width: 150px;
    width: 50%;
    text-decoration: none;
    color: $black;
    line-height: 1.6rem;
    text-align: center;

    @include bp(48) {
        margin-top: 0;
        width: 100%;
    }
    @include bp(75) {
        border: 3px solid $black;
        @include font-size(23px);
        height: 55px;
        max-width: 200px;
        margin-top: 30px;
    }

    &:hover {
        .no-touch & {
            background: $black;
            color: $sand-lighter;
        }
    }
}

@mixin button-styles-small() {
    @include button-styles();

    @include bp(48) {
        border: 3px solid $black;
    }

    @include bp(75) {
        height: 40px;
        @include font-size(15px);
        max-width: 150px;
    }
}

@mixin button-icon() {
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    position: absolute;
    left: 10px;
    margin-right: 5px;
    top: 45%;
    transform: translateY(-50%);
}

@mixin input-error() {
    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='tel'] {
        background-color: $form-error-background;
        color: $red;

        & + label {
            color: $red;
        }

        &:focus {
            background-color: $sand-lighter;
            color: $black;

            & + label {
                color: $black;
            }
        }
    }

    input[type='email'] {
        & + label:before {
            background-image: url('../img/icons/envelop--error.svg');
        }

        &:focus + label:before {
            background-image: url('../img/icons/envelop--black.svg');
        }
    }

    input[type='tel'] {
        & + label:before {
            background-image: url('../img/icons/mobil--error.svg');
        }

        &:focus + label:before {
            background-image: url('../img/icons/mobil--black.svg');
        }
    }
}

@mixin styled-checkbox() {
    position: relative;

    .hidden-checkbox {

        @extend %visually-hidden;

        + .checkbox-label {
            padding-left: 34px;
            display: inline-block;

            @include bp(75) {
                padding-left: 38px;
            }

            &:before {
                border: 2px solid $black;
                border-radius: 5px;
                content: '';
                cursor: pointer;
                display: inline-block;
                height: 25px;
                left: 0;
                margin-right: 10px;
                position: absolute;
                width: 25px;
                transform: translateY(-50%);
                top: 50%;

                @include bp(75) {
                    border: 3px solid $black;
                    height: 30px;
                    width: 30px;
                }
            }
        }

        &:checked + .checkbox-label {

            &:before {
                background-image: url('../img/icons/0273-checkmark.svg');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 80%;
            }
        }
    }
}

@mixin chevron-styles {
    text-decoration: none;
    color: $black;
    display: inline-block;
    font-weight: 700;
    @include font-size(15px);
    vertical-align: top;

    &:after {
        content: '';
        background-image: url('../img/icons/chevron_right_black.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        display: inline-block;
        right: -20px;
        position: relative;
        height: 15px;
        width: 10px;
        transform: translateY(-50%);
        top: 3px;
        left: 10px;
        transform: rotate(90deg);
        transition: transform .2s ease-in;
    }

    &.open-toggle {
        &:after {
            transform: rotate(-90deg);
            transition: transform .2s ease-in;
        }
    }
}

@mixin member-icon {
    background-image: url('../img/icons/heart.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    content: '';
    display: inline-block;
    height: 22px;
    margin-right: 5px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
}

@mixin expand-transition {
    opacity: 0;
    max-height: 0;
    margin: 0;
    overflow: hidden;
    transition: max-height .3s ease-in, opacity .3s linear .2s;

    &--expanded {
        display: block;
        opacity: 1;
        max-height: 500px;
        overflow: visible;
    }
}

@mixin spinner ($size: 50px, $color: $black) {
    display: inline-block;
    width: $size;
    height: $size;
    border-radius: 50%;
    border: ($size / 5) solid $color;
    border-top-color: rgba($color, 0);
    box-sizing: border-box;
    margin-top: -2px;
    vertical-align: middle;
    animation: spinner .7s linear infinite;
}

@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@-webkit-keyframes spinner {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}


/* Clearfix
* Usage: @extend %clearfix;
* --------------------------------------------------------------------
*/

%clearfix {
    zoom: 1;

    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

%full-width-content {
    @include column_style(0, 2);

    @include bp(48) {
        @include column_style(48, 3);
    }
    @include bp(75) {
        @include column_style(75, 9);
    }
    @include bp(120) {
        @include column_style(120, 12);
    }
}

%page-content {
    @extend %clearfix;
    margin: 0 auto;
    max-width: $content-width;
    width: 100%;

    @include bp(48) {
        max-width: $content-width__percentage;
    }
    @include bp(75) {
        max-width: $content-width__percentage;
    }
    @include bp(120) {
        max-width: $content-width__percentage;
    }
}

%store-admin-page-content {
    @extend %clearfix;
    margin: 0 auto;
    max-width: $content-width;
    width: 100%;

    @include bp(48) {
        max-width: $store-admin-content-width__percentage;
    }
    @include bp(75) {
        max-width: $store-admin-content-width__percentage--large;
    }
}

%visually-hidden,
.visually-hidden {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
}

/* Article page grid mixins
* -------------------------------------------------------------------
*/

.base-article-grid,
%base-article-grid {
    @include column_style(0, 2);
    margin: 15px 0 0;

    @include bp(48) {
        @include column_style(48, 3);
        margin: 30px 0 0;
    }
    @include bpf(1060px) {
        @include column_style(75, 7);
        margin: 30px auto 0;
        max-width: 1000px;
    }
    @include bpf(2000px) {
        @include column_style(120, 8);
        margin: 35px auto 0;
        max-width: 1200px;
    }
}

/* Shared styles for My pages
* --------------------------------------------------------------------
*/

%my-pages-header__heading {
    @include font-size(28px);
    font-weight: 700;
    line-height: 1.1666666667em;
    margin: 0;
    word-wrap: break-word;

    @include bp(48) {
        @include font-size(53px);
        line-height: 1.1320754717em;
    }
    @include bp(75) {
        @include font-size(60px);
        line-height: 1.08333333333em;
    }
    @include bp(120) {
        @include font-size(75px);
        line-height: 1.06666666667em;
    }
}

%my-pages-header__sub-heading {
    @include font-size(30px);
    font-weight: 700;
    line-height: 1.1666666667em;
    margin: 0;
    word-wrap: break-word;

    @include bp(48) {
        margin-bottom: 30px;
        @include font-size(37px);
        line-height: 1.216216216em;
    }
    @include bp(120) {
        @include font-size(45px);
        line-height: 1.222222222em;
    }
}

%my-pages-header__preamble {
    color: $black;
    @include font-size(15px);
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 1.66666666667;
    margin: 5px 0 0;

    @include bp(48) {
        @include font-size(23px);
        line-height: 1.30434782609em;
        margin: 20px 0;
    }
    @include bp(75) {
        line-height: 1.52173913043em;
    }
    @include bp(120) {
        @include font-size(30px);
        line-height: 1.5em;
    }
}
