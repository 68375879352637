.register-info{

}
.register-info__content{
    @extend %page-content;
    padding: 25px 20px;

    @include bp(48){
        padding: 50px 30px;
    }
    @include bp(75){
        padding: 50px 15px;
    }
    @include bp(120){
        padding: 105px 20px 65px 20px;
        max-width: 1500px;
    }
}
.register-info__heading{
    @extend %my-pages-header__heading;
}
.register-info__subheading{
    @include font-size(15px);
    font-weight: 600;
    line-height: 1.66666667em;
    margin: 0;

    @include bp(48){
        @include font-size(23px);
    }

    @include bp(75){
        @include font-size(30px);
    }
}
.register-description{
    @include font-size(15px);
    font-weight: 300;
    line-height: 1.66666667em;
    margin: 10px 0;

    ul{
        list-style: none;
        margin: 0;
        padding: 0 5px;

        li{
            position: relative;

            &:before{
                content: "\2022 ";
                display: inline-block;
                position: absolute;
                left: -15px;
            }
        }
    }

    @include bp(75){
        @include font-size(23px);
        font-weight: 400;
    }
}
.register-info__link{
    background: transparent;
    border: 2px solid $black;
    border-radius: 5px;
    color: $black;
    display: block;
    @include font-size(15px);
    font-weight: 600;
    margin-top: 15px;
    padding: 9px;
    text-align: center;
    text-decoration: none;
    transition: all 0.2s ease-in;
    max-width: 150px;
    width: 100%;

    @include bp(75){
        border: 3px solid $black;
        @include font-size(23px);
        height: 55px;
        max-width: 200px
    }

    &:hover{
        background: $black;
        color: $sand-lighter;
    }

}
