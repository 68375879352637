.member_store_receipt_detail-wrapper {
    text-align: center;
}

.member_store_receipt_detail-header {
    @extend %page-content;
    padding: 20px 20px 10px;
    @extend %clearfix;
    position: relative;
    text-align: left;
}

.member_store_receipt_detail-questions {
    @include font-size(15px);
    font-weight: 400;

    @include bp(48) {
        float: right;
        text-align: right;
        @include font-size(18px);
    }

    @include bp(75) {
        @include font-size(23px);
    }
}

.member_store_receipt_detail-customer-service__link {
    display: block;
    font-weight: 700;
    @include font-size(15px);

    @include bp(48) {
        @include font-size(18px);
    }

    @include bp(75) {
        @include font-size(23px);
    }
}

.member_store_receipt_detail__link {
    color: $black;
    @include font-size(15px);
    font-weight: 600;
}

.member_store_receipt_detail-header__top-links {
    @extend %clearfix;
    margin-top: 10px;
    margin-bottom: 15px;

    @include bp(48) {
        margin-top: 15px;
        margin-bottom: 20px;
    }

    @include bp(75) {
        margin-top: 40px;
        margin-bottom: 20px;
    }
}

.member_store_receipt_detail-go-back__link {
    display: block;
    color: $checkouttext;
    text-decoration: none;
    font-weight: 600;
    @include font-size(15px);
    position: relative;
    padding-left: 15px;
    margin-bottom: 15px;

    @include bp(48) {
        @include font-size(18px);
        padding-left: 25px;
        float: left;
        margin-bottom: 0;
    }

    @include bp(75) {
        @include font-size(23px);
    }

    &:before {
        background-image: url('../img/icons/chevron_right.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 95%;
        content: '';
        display: inline-block;
        height: 11px;
        position: absolute;
        left: 0;
        top: 20%;
        transform: rotate(180deg);
        width: 7px;

        @include bp(48) {
            height: 15px;
            width: 10px;
            left: 0;
        }
    }
}

.member_store_receipt_detail-header__info {
    padding-bottom: 15px;
    padding-top: 20px;
    border-bottom: 1px solid $border-checkout;
    border-top: 1px solid $border-checkout;

    @include bp(48) {
        padding-top: 0;
        border-top: none;
    }
}

.member_store_receipt_detail-order-number__wrapper {
    display: inline-block;
    vertical-align: top;
}

.member_store_receipt_detail-order-number__label {
    @include font-size(15px);
    font-weight: 300;

    @include bp(40) {
        display: inline-block;
    }

    @include bp(48) {
        display: block;
        @include font-size(23px);
    }

    @include bp(75) {
        @include font-size(30px);
    }
}

.member_store_receipt_detail-order-number__number {
    @include font-size(18px);
    font-weight: 700;

    @include bp(40) {
        display: inline-block;
    }
    @include bp(48) {
        display: block;
        @include font-size(30px);
    }
}

.member_store_receipt_detail-order__receiptinfo {
    @include bp(48) {
        margin-top: 5px;
    }
}

.member_store_receipt_detail-order-date__wrapper {
    display: block;
    margin-bottom: 5px;
    position: relative;
    margin-top: 5px;
    @include font-size(23px);
    line-height: 30px;

    @include bp(48) {
        padding-left: 15px;
        margin-top: 0;
        display: inline-block;
        vertical-align: bottom;
        height: 35px;

        &:before {
            content: '';
            background-color: $border-checkout;
            display: inline-block;
            height: 110%;
            width: 1px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    @include bp(75) {
        margin-bottom: 11px;
    }
}

.member_store_receipt_detail-order-date__label {
    font-weight: 300;
    @include font-size(15px);
    line-height: 20px;

    @include bp(40) {
        display: inline-block;
    }
    @include bp(48) {
        display: block;
    }

    @include bp(75) {
        display: inline-block;
        @include font-size(18px);
    }
}

.member_store_receipt_detail-order-date__timestamp {
    font-weight: 700;
    @include font-size(18px);
    line-height: 20px;

    @include bp(40) {
        display: inline-block;
    }
    @include bp(48) {
        display: block;
    }
    @include bp(75) {
        display: inline-block;
    }
}

.member_store_receipt_detail-order-store__wrapper {
    display: block;
    margin-bottom: 5px;
    position: relative;
    margin-top: 5px;
    @include font-size(23px);
    line-height: 30px;

    @include bp(48) {
        padding-left: 15px;
        margin-left: 30px;
        margin-top: 0;
        display: inline-block;
        vertical-align: bottom;
        height: 35px;

        &:before {
            content: '';
            background-color: $border-checkout;
            display: inline-block;
            height: 110%;
            width: 1px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    @include bp(75) {
        margin-bottom: 11px;
    }
}

.member_store_receipt_detail-order-store__label {
    font-weight: 300;
    @include font-size(15px);
    line-height: 20px;

    @include bp(40) {
        display: inline-block;
    }
    @include bp(48) {
        display: block;
    }

    @include bp(75) {
        display: inline-block;
        @include font-size(18px);
    }
}

.member_store_receipt_detail-order-store__store {
    font-weight: 700;
    @include font-size(18px);
    line-height: 20px;

    @include bp(40) {
        display: inline-block;
    }
    @include bp(48) {
        display: block;
    }
    @include bp(75) {
        display: inline-block;
    }
}

.member_store_receipt_detail-container {
    @extend %page-content;

    padding: 20px;
    padding-top: 0;
    padding-bottom: 20px;

    @extend %clearfix;
    position: relative;
    text-align: left;

    @include bp(75) {
        padding-top: 20px;
    }
}

.member_store_receipt_detail-cart {
    @include column_style(0, 2);
    padding: 0;

    @include bp(48) {
        max-width: 768px;
    }
}

.member_store_receipt_detail-cart__content {
    position: relative;
}

.member_store_receipt_detail-cart__content--expanded {
    position: relative;
}

.member_store_receipt_detail-cart-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.member_store_receipt_detail-cart-list__item {
    border-bottom: 2px solid $sand-light;
    padding: 15px 0;
    @extend %clearfix;

    @include bp(48) {
        justify-content: space-between;
    }
    @include bp(120) {
        padding: 30px 0;
    }

    &:first-child {
        display: none;
        @include bp(48) {
            &:before {
                content: none;
            }

            &:after {
                content: none;
            }

            display: flex;
            padding: 10px 0;
            width: 100%;
        }
    }

    &:last-child {
        border: 0;
    }
}

.member_store_receipt_detail-cart-item__heading {

    &:first-child {
        width: 58%;
    }

    &:nth-child(n+2) {
        width: 14%;
        text-align: right;
    }

    @include bp(120) {
        @include font-size(23px);
        font-weight: 300;
    }
}

.member_store_receipt_detail-cart-item__details {
    display: flex;
    flex-direction: column;

    position: relative;
    width: 100%;

    @include bp(48) {
        justify-content: space-between;
        flex-direction: row;
    }

}

.member_store_receipt_detail-cart-info {
    display: flex;
    width: 100%;

    .member_store_receipt_detail-cart-image {
        width: 80px;
        display: inline-block;
        margin-right: 20px;
    }

    @include bp(48) {
        width: 58%;

        .member_store_receipt_detail-cart-image {
            width: 30%;
        }
    }
}

.member_store_receipt_detail-cart-image {
    display: inline-block;
    overflow: hidden;
}

.member_store_receipt_detail-cart-image--nonbook {
    background: $white;
    border: 1px solid $sand-light;
    border-radius: 5px;
    // padding: 10px;

    .member_store_receipt_detail-cart-image__container {
        display: block;
        margin-left: 10px;
        width: calc(100% - 20px);
    }
}

.member_store_receipt_detail-cart-image__container {
    display: none;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 8/7 * 100%;
}

.member_store_receipt_detail-cart-image__img {

    display: block;
    width: 100%;

    .member_store_receipt_detail-cart-image--nonbook & {
        @extend %visually-hidden;
        height: 1px; width: 1px;
        margin: -1px; padding: 0; border: 0;
    }
}

.member_store_receipt_detail-cart-info__img {
    display: block;
    margin-right: 10px;
    width: 35px;

    @include bp(120) {
        width: 50px;
    }
}

.member_store_receipt_detail-cart-info__details {
    display: inline-block;
    width: 100%;
    padding-right: 70px;

    @include bp(48) {
        padding-right: 10%;
    }

    .member_store_receipt_detail-cart-item__status--processing & {
        min-height: 65px;
    }
}

.member_store_receipt_detail-cart-item__title {
    @include font-size(13px);
    font-weight: 700;
    margin: 0;

    @include bp(48) {
        @include font-size(18px);
    }
}

.member_store_receipt_detail-cart-item__summary {
    display: flex;
    font-weight: 700;
    border-top: 1px solid $sand-light;
    width: calc(100% - 80px);
    margin-left: 80px;
    padding-top: 15px;
    justify-content: space-between;

    @include bp(48) {
        width: 42%;
        margin-left: 0;

        flex-grow: 1;
        justify-content: space-between;
        align-items: flex-end;

        border-top: 0;

        & > .summary__child {
            width: 33.333333%;
            text-align: right;
        }
    }
}

.member_store_receipt_detail-cart-item-qty {
    display: inline-block;
    @include font-size(15px);
    padding-right: 10px;
    font-weight: 700;

    &:after {
        content: ' st';
        font-weight: 100;
    }

    @include bp(48) {
        vertical-align: super;
        padding-top: 2px;
        width: 29%;

        &:after {
            content: none;
            display: none;
        }
    }

    @include bp(120) {
        @include font-size(23px);
        padding-top: 3px;
    }
}

.member_store_receipt_detail-cart-item-price {
    display: inline-block;
    @include font-size(15px);
    font-weight: 300;
    padding-left: 10px;
    text-align: center;

    @include bp(48) {
        vertical-align: super;
        padding-top: 2px;
        width: 29%;
        text-align: left;

    }

    @include bp(120) {
        @include font-size(23px);
        padding-top: 3px;
    }
}


.member_store_receipt_detail-cart-item-price__large {
    display: inline-block;

    &:before {
        content: 'á (';
        font-weight: 100;
    }

    &:after {
        content: ')';
        font-weight: 100;
    }

    @include bp(48) {
        &:before,
        &:after {
            content: none;
            display: none;
        }
    }
}

.member_store_receipt_detail-total {
    @extend %clearfix;
    margin: 0;
    padding: 0;
    padding-top: 5px;
    position: relative;
    display: flex;
    align-items: center;

    padding: 16px 0 11px;
    margin: 0 0 13px;
    border-top: 15px solid $black;
}

.member_store_receipt_detail-total__label {
    @include font-size(23px);
    font-weight: 700;

    @include bp(75) {
        @include font-size(30px);
    }

    @include bp(120) {
        @include font-size(45px);
    }
}

.member_store_receipt_detail-price-divider {
    flex-grow: 100;
    background-color: $border-total;
    height: 1px;
    margin: 0 10px;
}

.member_store_receipt_detail-total__price {
    @include font-size(23px);
    font-weight: 700;

    @include bp(120) {
        @include font-size(45px);
    }
}

