.search-filter {
    border-top: 1px solid map-get($project-color, color-12);
    position: relative;
}

.search-filter--loading {
    height: 51px;
}

.search-filter-books {
    background-color: map-get($project-color, color-3);;
}

.search-filter__breadcrumbs {
    border-bottom: 1px solid $sand;
}

.search-filter__breadcrumbs-inner {
    @extend %page-content;
    padding: 20px;
}

.search-filter__sorting-wrapper {
    display: flex;
    justify-content: flex-start;
    padding: 0 10px;

    @include bp(40) {
        justify-content: flex-end;
    }
}