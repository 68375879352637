.touch {
  .qq-upload-drop-area {
    display: none;
  }
}

.contact-us {
  .info-article__content {
    padding-bottom: 0;

    @include bp(48) {
      padding-bottom: 30px;
    }
    @include bp(75) {
      padding-bottom: 45px;
    }

    &--contact-us-form {
      padding-top: 30px;

      @include bp(48) {
        padding-bottom: 100px;
      }
      @include bp(75) {
        padding-bottom: 100px;
        padding-top: 45px;
      }
    }
  }
}

.customer-service-form__captcha {
  margin-top: 30px;
}

.customer-service-mandatory-fields {
  position: relative;
}

.customer-service-mandatory-fields__text {
  position: absolute;
  right: 20px;
  top: 32px;
  @include font-size(15px);
  font-weight: 400;

  @include bp(48) {
    right: 70px;
  }
  @include bp(75) {
    right: 170px;
  }
  @include bp(120) {
    right: 250px;
    top: 55px;
  }
}

#customer-service-form {
  padding: 60px 20px 40px 20px;
  background-color: $sand-light;

  @include bp(48) {
    max-width: 648px;
    margin: 0 auto;
    padding: 60px 40px 40px 40px;
  }
  @include bp(75) {
    max-width: 970px;
    margin: 0 auto;
    padding: 80px;
  }

  @include bp(120) {
    max-width: 1200px;
    padding: 100px;
    //margin: 0 auto;
  }

  .errorlist {
    list-style: none;
    position: relative;
    text-align: right;
    @include font-size(13px);
    margin: 0;
    color: red;

    li {
      position: absolute;
      right: 0;
      top: 5px;
    }
  }

  .customer-service-form__field {
    position: relative;
    margin-bottom: 40px;
    margin-top: 0;
  }

  .customer-service-form__field:first-of-type {
    border: 2px solid $black;
    border-radius: 5px;
    position: relative;
    height: 40px;
    margin-bottom: 40px;
    margin-right: 10px;
    margin-top: 0;
    width: 100%;
    background-color: $sand-light;

    @include bp(75) {
      height: 55px;
      border: 3px solid $black;
    }

    > label {
      position: absolute;
      top: -25px;
      left: 0;
      @include font-size(15px);

      @include bp(75) {
        font-weight: 600;
      }
    }

    &:after {
      background-image: url(../img/icons/chevron_down_white_contrast.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 40%;
      border-left: 2px solid $black;
      content: "";
      display: block;
      height: 100%;
      padding-top: 3px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 40px;
      z-index: 0;
      background-color: $black;

      @include bp(75) {
        width: 55px;
        border-left: 3px solid $black;
      }
    }
  }
  .customer-service-form__field:last-of-type {
    margin-bottom: 30px;
  }

  select {
    -webkit-appearance: none;
    background-color: transparent;
    border: 0;
    font-weight: 600;
    position: absolute;
    z-index: 1;
    box-sizing: border-box;
    transform: translateY(-50%);
    top: 50%;
    width: 101%;
    padding: 0;
    margin-left: 15px;
    @include font-size(15px);
    height: 100%;
    cursor: pointer;

    @include bp(75) {
      @include font-size(23px);
      font-weight: 600;
      margin-left: 20px;
    }
  }

  label {
    font-weight: 600;
    position: absolute;
    left: 15px;
    @include font-size(15px);
    transition: all 0.1s linear;
    color: $black;
    top: 10px;
    font-style: italic;

    @include bp(75) {
      @include font-size(23px);
      font-weight: 600;
      top: 15px;
      left: 20px;
    }
  }

  label.show {
    top: -25px;
    @include font-size(15px);
    transition: all 0.1s linear;
    left: 0;
    font-weight: 600;
  }

  label.on {
    font-weight: 600;
    top: -25px;
    @include font-size(15px);
    transition: all 0.1s linear;
    left: 0;
  }

  input, textarea {
    border: none;
    width: 100%;
    border-radius: 5px;
    padding-left: 15px;
    background-color: $sand-lighter;
    @include font-size(15px);
    font-weight: 400;

    @include bp(75) {
      @include font-size(23px);
      padding-left: 20px;
    }
  }

  textarea {
    resize: none;
    padding: 15px;

    @include bp(75) {
      padding: 20px;
    }
  }

  .customer-service-form__field:last-of-type {
    label {
      position: static;
    }
  }

  input {
    height: 40px;

    @include bp(75) {
      height: 55px;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px $sand-lighter inset;
    }
  }
  .customer-service-form__field.error {
    margin-bottom: 50px;
    input, textarea {
      background-color: $form-error-background;
    }
    label {
      color: #EF4023;
    }
    label.on, label.show {
      color: $black;
    }

  }
  .customer-service-form-select-label-wrapper {
    position: relative;

    label {
      top: -25px;
      left: 0;
      @include font-size(15px);
      font-weight: 600;
    }
    .errorlist {
      li {
        top: 45px;
        @include bp(75) {
          top: 60px;
        }
      }
    }
  }
  .qq-upload-drop-area {
    border: 1px dashed $black;
    height: 170px;
    position: relative;
    background-color: transparent;
    margin-top: 20px;

    &-active {
      background-color: $form-upload-success-background;
    }
    > span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &:before {
        content: "";
        display: block;
        background-image: url(../img/icons/upload.svg);
        height: 60px;
        width: 64px;
        background-size: 100%;
        background-repeat: no-repeat;
        margin: 0 auto 10px auto;
      }
    }
  }
  .qq-upload-list-selector {
    margin-top: 20px;
    position: relative;

    margin-bottom: 20px;

    li {
      border-radius: 5px;
      margin-bottom: 20px;
      color: $black;
      height: 40px;
      padding: 13px 15px;
      position: relative;

      @include bp(75) {
        height: 55px;
        padding: 20px 20px;
      }
    }

    .qq-upload-success {
      background-color: $form-upload-success-background;
    }
    .qq-upload-error {
      background-color: $form-error-background;
    }
  }
  .qq-upload-delete-selector {

    &:after {
      content: "";
      background-image: url(../img/icons/cross--inverted.svg);
      background-repeat: no-repeat;
      background-size: 100%;
      height: 10px;
      width: 10px;
      display: inline-block;
      background-position: center;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);

      @include bp(75) {
        width: 20px;
        height: 20px;
      }
    }
  }
  .qq-upload-button, #submit_contact_form {
    background-color: transparent;
    border: 2px solid $black;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    color: $black;
    @include font-size(15px);
    font-weight: 600;
    height: 40px;
    margin: 0;
    transition: all 0.2s ease-in;

    @include bp(75) {
      height: 55px;
      @include font-size(23px);
      border: 3px solid $black;
    }

    &:hover {
      background-color: $black;
      color: $sand-light;
    }
  }
  .qq-upload-button {
    max-width: 250px;

    @include bp(75) {
      max-width: 340px;
    }

    > div {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 43px;
      &:after {
        content: "";
        background-image: url(../img/icons/upload.svg);
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        display: inline-block;
        right: -24px;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        background-size: 100%;

        @include bp(75) {
          width: 32px;
          height: 30px;
          right: -40px;
        }
      }
    }
  }
  .qq-upload-button-hover {
    &:hover {
      > div {
        &:after {
          background-image: url(../img/icons/upload--inverted.svg);
        }
      }
    }
  }
  .qq-upload-file {
    @include font-size(15px);
    font-weight: 400;

    @include bp(75) {
      @include font-size(23px);
    }
  }
  #submit_contact_form {
    max-width: 150px;
    display: block;
    padding: 5px;
  }

  .custom-button {
    background: transparent;
    border: 2px solid $black-admin;
    border-radius: 5px;
    display: block;
    @include font-size(15px);
    font-weight: 600;
    height: 40px;
    padding: 9px 30px;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    margin: 20px auto 0 auto;
    width: 100%;
    text-decoration: none;
    text-align: center;
    color: $black-admin;
    cursor: pointer;
    line-height: 20px;

    &.cancel-recommendation {
      border: none;
      text-decoration: underline;
      margin: 10px auto;
      &:hover {
        color: $black-admin;
        background: none;
      }
      @include bp(40) {
        margin: 20px auto;
        max-width: none;
        display: inline;
        padding-left: 0;
        padding-right: 0;
      }
    }

    @include bp(40) {
      display: inline-block;
      //width: inherit;
      max-width: 240px;
    }
    @include bp(75) {
      border: 3px solid $black-admin;
    }

    &--large {
      @include bp(75) {
        @include font-size(23px);
        height: 55px;
        padding: 16px 30px;
        max-width: 345px;
      }
    }

    &:hover {
      background: $black-admin;
      color: $sand-lighter;
    }
  }
  .file-upload-btn {
    padding: 4px 30px;
    display: inline-block;
    &:after {
      content: "";
      background-image: url("../img/icons/upload.svg");
      background-repeat: no-repeat;
      width: 22px;
      height: 20px;
      display: inline-block;
      background-size: 100%;
      margin-left: 10px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      @include bp(75){
        width: 28px;
        height: 28px;
      }
    }
    &:hover {
      &:after {
        background-image: url("../img/icons/upload--inverted.svg");
      }
    }
    @include bp(48) {
      margin: 0;
    }
  }

  #id_attachment {
    display: none;
  }

  .attachment_file_name--wrapper {
    position: relative;
    &__active {
      .remove-file {
        display: inline-block;
        background-image: url(../img/icons/cross--inverted.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        position: absolute;
        right: 0;
        height: 15px;
        top: 13px;
        cursor: pointer;
        width: 15px;

        @include bp(75) {
          height: 20px;
          top: 21px;
          width: 20px;
        }
      }

      .attachment_file_name {
        margin-top: 20px;
        background-color: $form-upload-success-background;
        padding: 10px 15px;
        border-radius: 5px;
        width: 93%;
        &:after {
          content: "";
          background: url(../img/icons/0273-checkmark-green.svg) no-repeat;
          display: inline-block;
          height: 23px;
          width: 25px;
          position: absolute;
          right: 10%;
          background-size: 80%;
          @include bp(40){
            right: 38px;
          }
          @include bp(75) {
            background-size: 100%;
            right: 60px;
          }

        }
        @include bp(40) {
          width: 95%;
        }

        @include bp(75) {
          padding: 17px 20px;
        }

      }
    }
  }
  .customer-service-form__attachment--label {
    position: static;
  }
}
