.no-touch {
    .carousel-list__item {
        visibility: hidden;
    }
}

.touch {
    .carousel {
        overflow: visible;
    }

    .carousel-section-content {
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }

    .carousel-list,
    .employee-carousel-list {
        @extend %clearfix;
    }

    .carousel-list__item {
        visibility: visible;
    }
}

.carousel-section--no-border-top {
    border-top: none;
    padding-top: 20px;
}

.carousel-section-content {
    overflow-x: hidden;
    overflow-y: hidden;
}

.carousel {
    @extend %page-content;
}

.carousel-header {
    @extend %page-content;
}

.carousel-header-content {
    color: $black;
    @extend %clearfix;
    @extend %full-width-content;
    padding: 0 15px 15px;

    @include bp(48) {
        padding-bottom: 15px;
    }
}

.carousel-header__heading {
    @include font-size(18px);
    line-height: 1.2em;
    margin: 0;

    @include bp(48) {
        float: left;
        @include font-size(23px);
    }
    @include bp(75) {
        @include font-size(30px);
    }
}

.carousel-header__show-all {
    color: $black;
    display: inline-block;
    @include font-size(13px);
    font-weight: 600;
    float: right;
    margin-top: 3px;
    margin-bottom: 15px;
    padding-right: 16px;
    margin-left: 10px;
    position: relative;
    text-decoration: none;

    &:after {
        background-image: url('../img/icons/chevron_right.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 95%;
        content: ' ';
        display: inline-block;
        height: 10px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;

        @include bp(48) {
            height: 20px;
            width: 22px;
        }
    }

    @include bp(48) {
        float: right;
        @include font-size(18px);
        line-height: 25px;
        margin-top: 0;
        padding-right: 30px;
    }
    @include bp(75) {
        line-height: 35px;
    }
}

.carousel-content {

    @include column_style(0, 2);
    padding-bottom: 20px;

    @include bp(48) {
        @include column_style(48, 3);
        padding-bottom: 20px;
    }
    @include bp(75) {
        @include column_style(75, 9);
        padding-bottom: 20px;
    }
    @include bp(120) {
        @include column_style(120, 12);
        padding-bottom: 40px;
    }
}

.carousel-list,
.employee-carousel-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    &.slick-initialized {
        .carousel-list__item {
            visibility: visible;
        }
    }
}

.carousel-list__item {
    float: left;
    visibility: hidden;
    width: 100%;

    &:first-child {
        visibility: visible;
    }
    @include bp(40) {
        width: 50%;

        &:nth-child(-n+2) {
            visibility: visible;
        }
    }
    @include bp(75) {
        width: 33.3333333333%;

        &:nth-child(-n+3) {
            visibility: visible;
        }
    }

    @include bp(120) {

        width: 25%;

        &:nth-child(-n+4) {
            visibility: visible;
        }
    }
}

.carousel-link {
    color: $black;
    @extend %clearfix;
    display: block;
    text-decoration: none;
}

.carousel-figure {
    float: left;
    position: relative;
    width: 35%;
}

.carousel-sale-item {
    left: -7px;
    line-height: 0;
    position: absolute;
    top: 20px;
    width: 60px;

    @include bp(48) {
        left: -9px;
        width: 80px;
    }

    @include bp(120) {
        left: -10px;
        width: 90px;
    }

    .recommendation-figure & {
        display: none;

        @include bp(40) {
            display: block;
        }
    }
}

.carousel-sale-item__img {
    width: 100%;
}

.carousel-figure__img {
    box-shadow: 0 5px 35px 0 rgba(0, 0, 0,
        .1);
    width: 100%;
}

.carousel-location-number {
    bottom: 0;
    color: $black;
    @include font-size(60px);
    font-weight: 700;
    left: -15px;
    line-height: 0;
    position: absolute;

    @include bp(48) {
        @include font-size(60px);
    }
    @include bp(75) {
        transform: translateX(-50%);
        left: 0;
    }
}

.carousel-product {
    float: left;
    padding: 0 10px;
    width: 65%;
}

.carousel-product__title {
    color: $black;
    @include font-size(15px);
    @include line-height(15);
    line-height: 1.08695652174em;
    margin: 0;
    word-wrap: break-word;

    @include bp(75) {
        @include font-size(18px);
        @include line-height(18);
    }
    @include bp(120) {
        @include font-size(20px);
        @include line-height(20);
    }
}

.carousel-product__author {
    color: $black;
    @include font-size(13px);
    @include line-height(13);
    font-weight: 300;
    margin: 5px 0 0;
    word-wrap: break-word;

    @include bp(48) {
        margin: 5px 0 0;
    }
    @include bp(75) {
        @include font-size(15px);
        @include line-height(15);
    }
}

.carousel-product-price {
    display: block;
    @include font-size(18px);
    font-weight: 700;
    line-height: 1em;
    margin-top: 7px;

    &--special {
        .carousel-product-price__large {
            color: $red;
            position: relative;
            padding-left: 35px;
            display: inline-block;

            @include bp(75) {
                padding-left: 40px;
            }

            @include bp(120) {
                padding-left: 45px;
            }

            &--1 {
                color: $friend-price;

                &:before {
                    background-image: url('../img/icons/heart.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    content: '';
                    display: inline-block;
                    height: 30px;
                    left: 0;
                    margin-right: 10px;
                    position: absolute;
                    width: 30px;
                    transform: translateY(-50%);
                    top: 50%;
                    @include bp(75) {
                        height: 35px;
                        width: 35px;
                    }
                    @include bp(120) {
                        height: 40px;
                        width: 40px;
                    }
                }
            }

            &--3,
            &--2 {
                padding-left: 0;
            }
        }
    }

    &__small {

        @include font-size(13px);
        font-weight: 300;
        line-height: 1em;
        display: inline-block;

        @include bp(120) {
            @include font-size(15px);
        }
    }

    @include bp(48) {
        @include font-size(18px);
        margin-top: 10px;
    }

    @include bp(75) {
        @include font-size(23px);
        margin-top: 10px;
    }

    @include bp(120) {
        @include font-size(30px);
    }

}

.carousel-product__type {
    color: $black;
    @include font-size(13px);
    @include line-height(13);
    font-weight: 600;
    margin: 7px 0 0;

    @include bp(48) {
        margin-top: 5px;
    }
    @include bp(75) {
        @include font-size(15px);
        @include line-height(15);
    }
}

/* Custom slick styles */

.carousel-list,
.employee-carousel-list,
.reco-carousel-list,
.teaser-current-carousel,
.receipt-list,
.discount-list,
.booked-events-list,
.order-carousel-list,
.puff-slider,
.book-list-carousel {
    .slick-list {
        overflow: visible;
    }

    .slick-slide {
        opacity: .2;

        &.slick-active {
            opacity: 1;
        }
    }

    .touch & .slick-slide {
        opacity: 1;
    }

    &:hover {
        .slick-prev {
            opacity: 1;

            &.slick-disabled {
                opacity: .5;
            }
        }

        .slick-next {
            opacity: 1;

            &.slick-disabled {
                opacity: .5;
            }
        }
    }

    .slick-prev {
        background-color: transparent;
        border: 0;
        color: transparent;
        display: none !important;
        font-size: 0;
        height: 60px;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translate(-10px, -50%);
        transition: opacity .3s;
        z-index: 1;
        width: 40px;

        &:before {
            background-image: url('../img/icons/slider_prev.svg');
            background-position: left center;
            background-repeat: no-repeat;
            background-size: auto 90%;
            content: ' ';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .no-touch & {
            display: block !important;
        }

        @include bp(48) {
            width: 80px;
            height: 120px;
            transform: translate(-50%, -50%);
        }

        @include bp(75) {
            display: block !important;
        }

        @include bp(120) {
            height: 160px;
            width: 100px;
        }
    }

    .slick-next {
        background-color: transparent;
        border: 0;
        color: transparent;
        display: none !important;
        font-size: 0;
        height: 60px;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(10px, -50%);
        transition: opacity .3s;
        z-index: 1;
        width: 40px;

        &:before {
            background-image: url('../img/icons/slider_next.svg');
            background-position: right center;
            background-repeat: no-repeat;
            background-size: auto 90%;
            color: $black;
            content: ' ';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .no-touch & {
            display: block !important;
        }

        @include bp(48) {
            width: 80px;
            height: 120px;
            transform: translate(50%, -50%);
        }

        @include bp(75) {
            display: block !important;
        }
        @include bp(120) {
            height: 160px;
            width: 100px;
        }
    }
}

.order-carousel-list {
    .slick-slide {
        opacity: .6;
    }
}
