.filter-breadcrumb2__label {
    @include font-size(15px);
    font-weight: 600;
    margin-right: 5px;

    @include bp(48) {
        margin-right: 10px;
    }
}

.filter-breadcrumb2__item {
    background: transparent;
    border: none;
    color: $black;
    display: inline-block;
    @include font-size(15px);
    font-weight: 600;
    margin: 0 20px 5px 0;
    padding: 0;
    position: relative;

    &:after {
        display: inline-block;
        background-image: url('../img/icons/cross--inverted.svg');
        content: '';
        margin-left: 5px;
        width: 10px;
        height: 10px;
    }

    &:before {
        background-color: $black;
        bottom: -1px;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        right: 0;
        transform: scale(0);
        transition: transform .2s ease-in;
    }

    &:hover {

        &:before {
            transform: scale(1);
        }

    }
}



