/* Navigation
 * ----------------------------------------------------
 */

.active-navigation .navigation-overlay {
    display: block;
    visibility: visible;

    @include bp(75) {
        display: none;
        visibility: hidden;
    }
}

.navigation-overlay {
    background: rgba(0, 0, 0, .85);
    display: none;
    height: 100%;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 5;
}

.navigation {
    padding: 0;
    position: absolute;
    transform: translateX(-100%);
    transition: .3s;
    width: 100%;
    z-index: 50;
    top: 55px;

    &--discount-banner-displayed {
        top: 55px;
    }

    &--active {
        transform: translateX(0);
    }

    @include bp(75) {
        background: $bg-megamenu;
        height: auto;
        padding: 0;
        position: relative;
        transform: translateX(0);
        top: auto;
        z-index: 10;
    }
}

.navigation__content {
    background-color: $bg-menu--default;
    color: #fff;
    overflow: auto;

    @include bp(75) {
        height: auto;
        margin: 0 auto;
        max-width: $content-width__percentage;
        overflow: visible;
        width: 100%;
    }
    @include bp(120) {
        max-width: $content-width__percentage;
    }
}

.navigation-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.navigation-list--1-level {
    background: $bg-menu--default;

    @include bp(75) {
        background: $bg-megamenu;
        @include column_style(75, 9);
    }
    @include bp(120) {
        @include column_style(120, 12);
    }
}

.subnavigation {
    display: none;
    z-index: 1;

    &--active {
        display: block;

        @include bp(75) {
            left: 0;
            position: absolute;
            top: 100%;
            width: 100%;
        }
    }

    @include bp(75) {
        background-color: $black-dark;
        padding-bottom: 30px;
        padding-top: 15px;
    }
}

.navigation-list--2-level {

    @include bp(75) {
        margin: 0 auto;
        padding: 0 15px;
        max-width: $content-width__percentage;
        width: 100%;

        .navigation-link--anchor {

            &:hover {
                cursor: default;
                text-decoration: none;
            }
        }
    }
    @include bp(120) {
        max-width: $content-width__percentage;
    }
}

.navigation-list__item--our-non-books {
    .navigation-list--2-level {
        max-width: none;

        @include bp(75) {
            columns: 4;
            width: 93%;
        }
    }

    .navigation-list__item--2 {
        width: 100%;
        float: none;
        break-inside: avoid;

        &:first-child {
            width: 100%;
        }
    }

    .navigation-list--3-level {
        columns: auto;
    }
}

.navigation-list__item--2-popular {

    > .navigation-link {
        cursor: default;
        padding-bottom: 5px;

        &--active {
            background-color: $bg-menu--second-lvl;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .navigation-list--3-level {
        display: block;

        @include bp(75) {
            columns: auto;
            width: 50%;
        }
    }

    .navigation-list__item--3 {
        > .navigation-link {
            @include font-size(15px);
            @include column_style(0, 2);
            padding: 0 60px;
            background: $bg-menu--third-lvl;
            color: $sand-lighter;
            font-weight: 400;
            line-height: 1.2em;
            padding-top: 15px;
            padding-bottom: 15px;

            @include bp(75) {
                @include font-size(20px);
                background: transparent;
                letter-spacing: 1px;
                font-weight: 600;
                padding: 0 20px;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-right: 5px;
            }
        }

        @include bp(75) {
            display: inline-block;
        }
    }
}

.navigation-list--3-level {
    display: none;

    &.navigation-list--active {
        display: block;
    }

    @include bp(75) {
        columns: 2;
        display: block;
    }
}

.navigation-list__item {

    &--children {
        > .navigation-link {
            padding-left: 90px;
            position: relative;

            &:before {
                background-image: url('../img/icons/children-logo-2.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
                content: '';
                display: inline-block;
                height: 50px;
                left: 20px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 60px;
            }
        }
    }

    &--members {
        > .navigation-link {
            padding-left: 55px !important;
            position: relative;

            @include bp(75) {
                padding-left: 13px !important;
            }

            &:before {
                background-image: url('../img/icons/heart.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
                content: '';
                display: inline-block;
                height: 30px;
                width: 30px;
                left: 13px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);

                @include bp(75) {
                    display: none;
                }
            }
        }
    }

    &--has-children {

        &.navigation-list__item--2-popular {
            > .navigation-link {
                &:after {
                    display: none;
                }
            }
        }

        > .navigation-link {

            position: relative;

            &:after {
                background-image: url('../img/icons/plus-large--inverted.svg');
                background-position: center right;
                background-repeat: no-repeat;
                background-size: 100%;
                content: '';
                display: inline-block;
                height: 20px;
                width: 20px;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                transition: transform .2s ease-in;
                transform-origin: top;

                .navigation-list--2-level & {
                    background-image: url('../img/icons/plus-small--inverted.svg');
                    height: 15px;
                    width: 15px;
                }
            }

            &.navigation-link--active {
                &:after {
                    transform: rotate(45deg) translateY(-50%);
                    transform-origin: top;
                    right: 20px;
                }
            }

            @include bp(75) {
                &:after {
                    display: none;
                }
            }
        }

    }
}

.navigation-list__item--members.navigation-list__item--active {
    @include bp(75) {
        margin-bottom: 50px;
    }
}

.navigation-list__item--1 {

    border-top: 1px solid $border-menu--first-lvl;

    > .navigation-link {
        @include font-size(13px);
        padding: 16px 13px;
        color: $sand-lighter;
        font-weight: 600;
        line-height: 18px;
        padding-top: 16px;
        padding-bottom: 16px;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 1px;

        &--active {
            @include bp(75) {
                background: $black-dark;
            }
        }

        &--bokrea {
            color: $red;
        }
    }

    @include bp(75) {
        display: inline-block;
        border: 0;
    }

    @media (max-width: 1199px) {

        &.can-have-subnav {
            position: relative;

            .navigation-link:after {
                display: none;
            }

            .toggle-subnavigation {
                display: block;
                height: 50px;
                position: absolute;
                right: 0;
                top: 0;
                width: 30%;

                &:after {
                    background-image: url('../img/icons/plus-large--inverted.svg');
                    background-position: center right;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    content: '';
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: transform .2s ease-in;
                    transform-origin: top;
                }

                &.navigation-link--active {
                    &:after {
                        transform: rotate(45deg) translateY(-50%);
                        transform-origin: top;
                        right: 20px;
                    }

                }
            }
        }
    }
    @include bp(75) {

        &.mobile-only {
            display: none;
        }

        &.can-have-subnav {
            .toggle-subnavigation {
                display: none;
            }
        }

        &.can-have-subnav.navigation-list__item--active {

            .subnavigation {
                background-color: $sand-lighter;
                display: block;
                left: 0;
                padding: 0;
                position: absolute;
                width: 100%;
                z-index: 0;
                border-bottom: 1px solid $sand-light;

                &--hidden {
                    display: none;
                }

                .navigation-list {
                    margin: 0 auto;
                    padding: 0 15px;
                    text-align: left;
                    max-width: 93%;
                    width: auto;
                }

                .navigation-list__item {
                    display: inline-block;
                    float: none;
                    padding: 10px 0;
                    width: auto;

                    &:first-child {
                        .navigation-link {
                            border: none;
                        }
                    }
                }

                .navigation-link {
                    color: $black;
                    @include font-size(18px);
                    font-weight: 300;
                    text-decoration: none;
                    padding: 10px;

                    &:hover {
                        .navigation-link__inner:after {
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
}

.navigation-list__item--2 {

    &.desktop-only {
        display: none;

        @include bp(75) {
            display: list-item;
        }
    }

    > .navigation-link {
        @include font-size(15px);
        @include column_style(0, 2);
        padding: 0 40px;
        background: $bg-menu--second-lvl;
        color: $sand-lighter;
        font-weight: 700;
        line-height: 1.2em;
        padding-top: 23px;
        padding-bottom: 23px;

        @include bp(75) {
            @include font-size(18px);
            padding: 0 20px;
            font-weight: 600;
            background: transparent;
            color: white;
            letter-spacing: 1px;
        }
    }

    @include bp(75) {
        border-top: 0;
        float: left;
        width: 50%;

        > .navigation-link {
            padding-top: 15px;
            padding-bottom: 5px;
        }

        &:first-child {
            > .navigation-link {
                display: block;
            }
        }
    }

    &.navigation-list__item--has-children {
        @include bp(75) {
            &:first-child {
                border-right: 0;
                float: none;
                width: 100%;
            }
        }

        > .navigation-link {
            @include bp(75) {
                color: $color-megamenu-heading;
            }
        }
    }
}

.navigation-list__item--3 {
    > .navigation-link {
        @include font-size(15px);
        @include column_style(0, 2);
        padding: 0 60px;
        background: $bg-menu--third-lvl;
        color: $sand-lighter;
        font-weight: 400;
        line-height: 1.2em;
        padding-top: 15px;
        padding-bottom: 15px;

        @include bp(75) {
            padding: 0 20px;
            font-weight: 700;
            background: transparent;
            display: inline-block;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
}

.navigation-link {
    display: block;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.navigation-boxes {
    @include bp(75) {
        position: absolute;
        left: 50%;
        height: 100%;
        top: 0;
        border-left: 1px solid $border-megamenu;
        padding-top: 31px;
    }

    .navigation-boxes__links {
        list-style: none;
        padding-left: 0;

        .navigation-list__item {
            @include bp(75) {
                border-top: 0;
            }

            .navigation-link {
                @include font-size(15px);
                @include column_style(0, 2);
                padding: 0 40px;
                background: $bg-menu--second-lvl;
                color: $sand-lighter;
                font-weight: 700;
                line-height: 1.2em;
                padding-top: 23px;
                padding-bottom: 23px;

                &--rentandread {
                    font-weight: 300;
                }

                .navigation-link__rent {
                    font-weight: 700;

                    @include bp(75) {
                        font-weight: 600;
                    }
                }

                .navigation-link__read {
                    font-weight: 700;
                    color: $red;

                    @include bp(75) {
                        font-weight: 600;
                    }
                }

                @include bp(75) {
                    @include font-size(20px);
                    font-weight: 600;
                    padding-top: 0;
                    background: transparent;
                    color: $sand-lighter;
                    letter-spacing: 1px;

                    &--rentandread {
                        font-weight: 300;
                    }
                }
            }
        }

    }
}

.multi-level-menu--globalmenu {
    .navigation-list--1-level {
        @include bp(75) {
            padding: 0;
            margin: 0;
            height: 0;
        }
    }

    .navigation-list__item--1 {
        &:first-child {
            border-top: 1px solid $border-menu--first-lvl;
        }

        > .navigation-link {
            @include bp(75) {
                display: none;
            }
        }
    }
}

.navigation-link__inner {
    position: relative;

    &:after {
        background-color: $black;
        bottom: -1px;
        content: '';
        display: block;
        height: 1px;
        left: 2px;
        position: absolute;
        right: 2px;
        transform: scaleX(0);
        transition: transform .2s ease-in-out;
    }
}

.navigation-link__current {
    position: relative;
    display: inline-block;

    &:after {
        content: '';
        background: $red;
        height: 2px;
        bottom: -1px;
        position: absolute;
        width: 100%;
        display: block;
        transform: scaleX(1);
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-80px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}


//A/B TESTING VARIANT STYLES

.active-navigation .navigation-overlay--variant {
    display: block;
    visibility: visible;

    @include bp(75) {
        display: none;
        visibility: hidden;
    }
}

.active-navigation .navigation-overlay--variant__cross {
    display: block;

    @include bp(75) {
        display: none;
    }
}

.navigation-overlay--variant {
    background: rgba(0, 0, 0, .85);
    display: none;
    height: 100%;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 500;
}

.navigation-overlay--variant__cross {
    height: 50px;
    width: 50px;
    background-image: url('../img/icons/cross-rundad--inverted.svg');
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1000;
    position: absolute;
    top: 1%;
    right: 7%;
    top: 20px;
    display: none;

    @include bp(40) {
        right: 10%;
    }
}

.navigation--variant {
    padding: 0;
    position: absolute;
    transform: translateX(-100%);
    transition: .3s;
    width: 75%;
    z-index: 1000;
    top: 0;
    max-height: 100vh;
    overflow-y: scroll;

    &--active {
        transform: translateX(0);
    }

    @include bp(75) {
        background: $bg-megamenu;
        height: auto;
        padding: 0;
        position: relative;
        transform: translateX(0);
        width: 100%;
        z-index: 10;
        max-height: unset;
        overflow-y: visible;
    }
}

.navigation__content--variant {
    background-color: $bg-menu--default;
    color: #fff;
    overflow: auto;

    @include bp(75) {
        height: auto;
        margin: 0 auto;
        max-width: $content-width__percentage;
        overflow: visible;
        width: 100%;
    }
    @include bp(120) {
        max-width: $content-width__percentage;
    }
}

.multi-level-menu--globalmenu--variant {
    .navigation-list--1-level--variant {
        @include bp(75) {
            padding: 0;
            margin: 0;
            height: 0;
        }
    }

    .navigation-list__item--1--variant {
        &:first-child {
            border-top: 1px solid $border-menu--first-lvl;
        }

        > .navigation-link {
            @include bp(75) {
                display: none;
            }
        }
    }
}

.navigation-list--1-level--variant {
    background: white;

    @include bp(75) {
        background: $bg-megamenu;
        @include column_style(75, 9);
    }
    @include bp(120) {
        @include column_style(120, 12);
    }
}

.navigation-list__item--variant {

    &--children {
        > .navigation-link {
            padding-left: 90px;
            position: relative;

            &:before {
                background-image: url('../img/icons/children-logo-2.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
                content: '';
                display: inline-block;
                height: 50px;
                left: 20px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 60px;
            }
        }
    }

    &--members {
        > .navigation-link {
            padding-left: 55px !important;
            position: relative;

            &:before {
                background-image: url('../img/icons/heart.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
                content: '';
                display: inline-block;
                height: 30px;
                width: 30px;
                left: 13px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &--has-children {

        &.navigation-list__item--2-popular {
            > .navigation-link {
                &:after {
                    display: none;
                }
            }
        }

        > .navigation-link {

            position: relative;

            &:after {
                background-image: url('../img/icons/SVG/0317-arrow-right2.svg');
                background-position: center right;
                background-repeat: no-repeat;
                background-size: 100%;
                content: '';
                display: inline-block;
                height: 20px;
                width: 20px;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                transition: transform .2s ease-in;
                transform-origin: top;

                .navigation-list--2-level & {
                    background-image: url('../img/icons/SVG/0317-arrow-right2.svg');
                    height: 15px;
                    width: 15px;
                }
            }

            @include bp(75) {
                &:after {
                    display: none;
                }
            }
        }

    }
}

.navigation-list__item--1--variant {

    border-top: 1px solid $border-menu--first-lvl;

    &--subnavigation-active {
        display: none;

        @include bp(75) {
            display: block;
        }
    }

    > .navigation-link {
        @include font-size(13px);
        padding: 16px 13px;
        color: black;
        font-weight: 600;
        line-height: 18px;
        padding-top: 16px;
        padding-bottom: 16px;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 1px;

        &--active {
            @include bp(75) {
                background: $black-dark;
            }
        }

        &--subnavigation-active {
            display: none;

            @include bp(75) {
                display: block;
            }
        }

        @include bp(75) {
            color: $sand-lighter;
        }

        &--bokrea {
            color: $red;
        }
    }

    @include bp(75) {
        display: inline-block;
        border: 0;
    }

    @media (max-width: 1199px) {

        &.can-have-subnav {
            position: relative;

            .navigation-link:after {
                display: none;
            }

            .toggle-subnavigation {
                display: block;
                height: 50px;
                position: absolute;
                right: 0;
                top: 0;
                width: 30%;

                &:after {
                    background-image: url('../img/icons/SVG/0317-arrow-right2.svg');
                    background-position: center right;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    content: '';
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: transform .2s ease-in;
                    transform-origin: top;
                }

                &.navigation-link--subnavigation-active {
                    display: none;
                }
            }
        }
    }
    @include bp(75) {

        &.can-have-subnav {
            .toggle-subnavigation {
                display: none;
            }
        }

        &.can-have-subnav.navigation-list__item--variant--active {

            .subnavigation {
                background-color: $sand-lighter;
                display: block;
                left: 0;
                padding: 0;
                position: absolute;
                width: 100%;
                z-index: 0;
                border-bottom: 1px solid $sand-light;

                &--hidden {
                    display: none;
                }

                .navigation-list {
                    margin: 0 auto;
                    padding: 0 15px;
                    text-align: left;
                    max-width: 93%;
                    width: auto;
                }

                .navigation-list__item {
                    display: inline-block;
                    float: none;
                    padding: 10px 0;
                    width: auto;

                    &:first-child {
                        .navigation-link {
                            border: none;
                        }
                    }
                }

                .navigation-link {
                    color: $black;
                    @include font-size(18px);
                    font-weight: 300;
                    text-decoration: none;
                    padding: 10px;

                    &:hover {
                        .navigation-link__inner:after {
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
}

.navigation-boxes--variant {

    &--subnavigation-active {
        display: none;

        @include bp(75) {
            display: block;
        }
    }
    @include bp(75) {
        position: absolute;
        left: 50%;
        height: 100%;
        top: 0;
        border-left: 1px solid $border-megamenu;
        padding-top: 31px;
    }

    .navigation-boxes__links {
        list-style: none;
        padding-left: 0;

        .navigation-list__item {
            border-top: 1px solid $border-menu--first-lvl;

            @include bp(75) {
                border-top: 0;
            }

            .navigation-link {
                @include font-size(15px);
                @include column_style(0, 2);
                padding: 0 40px;
                background: white;
                color: black;
                font-weight: 700;
                line-height: 1.2em;
                padding-top: 23px;
                padding-bottom: 23px;

                &--rentandread {
                    font-weight: 300;
                }

                .navigation-link__rent {
                    font-weight: 700;

                    @include bp(75) {
                        font-weight: 600;
                    }
                }

                .navigation-link__read {
                    font-weight: 700;
                    color: $red;

                    @include bp(75) {
                        font-weight: 600;
                    }
                }

                @include bp(75) {
                    @include font-size(20px);
                    font-weight: 600;
                    padding-top: 0;
                    background: transparent;
                    color: $sand-lighter;
                    letter-spacing: 1px;

                    &--rentandread {
                        font-weight: 300;
                    }
                }
            }
        }

    }
}

.navigation-list__item--2--variant {

    border-top: 1px solid $border-menu--first-lvl;

    &--subnavigation-active {
        display: none;

        @include bp(75) {
            display: block;
        }
    }

    > .navigation-link {
        @include font-size(15px);
        @include column_style(0, 2);
        padding: 0 40px;
        background: white;
        color: black;
        font-weight: 700;
        line-height: 1.2em;
        padding-top: 23px;
        padding-bottom: 23px;

        @include bp(75) {
            @include font-size(18px);
            padding: 0 20px;
            font-weight: 600;
            background: transparent;
            color: $color-megamenu-heading;
            letter-spacing: 1px;
        }

        &--subnavigation-active {
            display: none;

            @include bp(75) {
                display: block;
            }
        }
    }

    @include bp(75) {
        border-top: 0;
        float: left;
        width: 50%;

        > .navigation-link {
            padding-top: 15px;
            padding-bottom: 5px;
        }

        &:first-child {
            border-right: 0;
            float: none;
            width: 100%;

            > .navigation-link {
                display: block;
            }
        }
    }
}

.navigation-list__item--2-popular--variant {

    > .navigation-link {
        cursor: default;
        padding-bottom: 23px;

        &:hover {
            text-decoration: none;
        }
    }

    .navigation-list--3-level {
        display: block;

        @include bp(75) {
            columns: auto;
            width: 50%;
        }
    }

    .navigation-list__item--3--variant {
        > .navigation-link {
            @include font-size(15px);
            @include column_style(0, 2);
            padding: 0 60px;
           
            
            font-weight: 400;
            line-height: 1.2em;
            padding-top: 15px;
            padding-bottom: 15px;

            @include bp(75) {
                @include font-size(20px);
                background: transparent;
                color: $sand-lighter;
                letter-spacing: 1px;
                font-weight: 600;
                padding: 0 20px;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-right: 5px;
            }
        }

        @include bp(75) {
            display: inline-block;
        }
    }
}

.navigation-list__item--variant--our-non-books {
    .navigation-list--2-level {
        max-width: none;

        @include bp(75) {
            columns: 4;
            width: 93%;
        }
    }

    .navigation-list__item--2--variant {
        width: 100%;
        float: none;
        break-inside: avoid;

        &:first-child {
            width: 100%;
        }
    }

    .navigation-list--3-level {
        columns: auto;
    }
}

.navigation-list__item--3--variant {
    border-top: 1px solid $border-menu--first-lvl;

    > .navigation-link {
        @include font-size(15px);
        @include column_style(0, 2);
        padding: 0 60px;
        background: white;
        color: black;
        font-weight: 400;
        line-height: 1.2em;
        padding-top: 15px;
        padding-bottom: 15px;

        @include bp(75) {
            border-top: 0;
            padding: 0 20px;
            font-weight: 700;
            background: transparent;
            color: $sand-lighter;
            display: inline-block;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    @include bp(75) {
        border-top: 0;
    }
}

.navigation__back-button--variant {
    display: none;
    @include font-size(13px);
    width: 100%;
    background: white;
    color: black;
    text-decoration: none;
    padding: 16px 13px;
    text-align: left;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid black;

    &:before {
        background-image: url('../img/icons/SVG/0321-arrow-left2.svg');
        background-position: center right;
        background-repeat: no-repeat;
        background-size: 100%;
        content: '';
        display: inline-block;
        height: 20px;
        width: 20px;
        position: relative;
        top: 5px;
        margin-right: 10px;
    }

    @include bp(75) {
        display: none;
    }

    &--active {
        display: block;

        @include bp(75) {
            display: none;
        }
    }
}

.navigation__home-button--variant {
    display: block;
    @include font-size(13px);
    width: 100%;
    background: white;
    color: black;
    text-decoration: none;
    padding: 16px 13px;
    text-align: left;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid black;

    &:before {
        background-image: url('../img/logo.svg');
        background-position: center right;
        background-repeat: no-repeat;
        background-size: 100%;
        content: '';
        display: inline-block;
        height: 20px;
        width: 20px;
        position: relative;
        top: 5px;
        margin-right: 10px;
    }

    @include bp(75) {
        display: none;
    }
}