.simple-header {
    display: block;
    width: 100%;
    border-bottom: 1px solid $gray-light;
    position: relative;

    height: 55px;
    padding: 0 20px;

    @include bp(48) {
        padding: 0 30px;
    }
}

.simple-header__container {
    display: block;
    height: 100%;
    width: 100%;

    @include bpf(1060px) {
        max-width: 1000px;
        margin: 0 auto;
        padding: 0 15px;
    }

    @include bpf(2000px) {
        margin: 0 auto;
        max-width: 1200px;
    }
}

.simple-header-logo {
    display: block;
    height: 100%;
    position: relative;
}

.simple-header-logo__img {
    display: inline-block;
    max-width: 100%;

    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    &--small {
        margin-left: 0;
        width: 165px;

        @include bp(48) {
            width: 240px;
        }
    }
}
