.no-touch{
	.discount-list__item{
		visibility: hidden;
	}
}
.touch{

	.discount-item{
		overflow: visible;
	}
	.discount-list{
		@extend %clearfix;
	}
	.discount-list__item{
		visibility: visible;
	}
}
.my-pages-carousel-section{
	.discount-item{
		float: left;
  		margin-bottom: 0;

  		@include bp(48){
    		margin-bottom: 0;
  		}
  		@include bp(75){
    		max-width: inherit;
  		}
	}
	.discount-item__content{
		border-radius: 6px;
	}
}
.discount-carousel{
  @extend %page-content;
}
.discount-list{
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    &.slick-initialized{
		.discount-list__item{
			visibility: visible;
		}
	}
}
.discount-list__item{

    float: left;
	visibility: hidden;
    padding-right: 30px;

	&:first-child {

		visibility: visible;
	}

    .discount-item {
        width: 100%;
    }

    @include bp(40){
		width: 50%;
		&:nth-child(-n+2){
			visibility: visible;
		}
	}
	@include bp(75){
		width: 33.3333333333%;

		&:nth-child(-n+3){
			visibility: visible;
		}
	}

	@include bp(120){
		width: 25%;

		&:nth-child(-n+4){
			visibility: visible;
		}
	}
}
