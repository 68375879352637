.my-orders-carousel__content {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    padding: 0 20px 30px 20px;

    @include bp(48) {
        padding: 0 30px 40px 30px;
    }
    @include bp(75) {
        padding: 0 15px 40px 15px;
    }
}
.touch{
  .my-orders-carousel-section__content{
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }
}
.no-touch {
    .order-carousel-list__item {
        visibility: hidden;
    }
}
.touch {
    .order-carousel-order {
        overflow: visible;
    }
    .order-carousel-list {
        @extend %clearfix;
    }
    .order-carousel-list__item{
        visibility: visible;
    }
}
.my-orders-carousel-section__content{
    overflow-x: hidden;
    overflow-y: hidden;
}
.my-orders-carousel-section {
    overflow: hidden;

    @include bp(48){
        overflow: visible;
    }
}
.order-carousel{
    @extend %page-content;
}
.order-carousel-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    &.slick-initialized {
        .order-carousel-list__item {
            visibility: visible;
        }
    }
}
.order-carousel-list__item {
    padding-right: 30px;
    float: left;
    visibility: hidden;

    &:first-child {
        visibility: visible;
    }

    @include bp(40) {
        width: 50%;
        &:nth-child(-n+2) {
            visibility: visible;
        }
    }
    @include bp(75) {
        width: 33.3333333333%;

        &:nth-child(-n+3) {
            visibility: visible;
        }
    }

    @include bp(120) {
        width: 25%;

        &:nth-child(-n+4) {
            visibility: visible;
        }
    }
}
.order-carousel-order {
    @include font-size(15px);
    background-color: $sand-lighter;
    border-radius: 5px;
    box-shadow: 0 0 20px $border-product-filter;
    padding: 0;
}
.order-carousel-list-item__header-link{
    color: $black;
    text-decoration: none;
}
.order-carousel__content {
    padding: 20px;
    overflow-x: hidden;
    overflow-y: hidden;
}
.order-carousel-meta-list {
  padding: 20px 0;
  margin: 0;
  list-style: none;
}
.order-carousel-meta-list__item {
    display: inline-block;
    margin: 5px 25px 5px 0;
    font-weight: 600;
    @include font-size(15px);
    position: relative;
    padding-left: 25px;

    &:before {
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: auto 100%;
        content: "";
        display: inline-block;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
    }

    &--date {
        &:before {
            background-image: url(../img/icons/calendar.svg);
            width: 20px;
        }
    }
    &--time {
        &:before {
            background-image: url(../img/icons/clock.svg);
            width: 20px;
        }
    }
    &--store {
        &:before {
            background-image: url(../img/icons/store.svg);
            width: 20px;
        }
    }
}
.order-carousel-number {
    border-bottom: 1px solid $disabled-element;
    padding-bottom: 20px;
    @include font-size(15px);

    &__label{
        font-weight: 300;
    }

    &__number{
        display: inline-block;
        font-weight: 700;
    }
}
.order-carousel-btn {
    text-decoration: none;
    color: $black;
    border-radius: 5px;
    border: 2px solid $black;
    padding: 5px 15px;
    line-height: 28px;
    @include font-size(15px);font-weight: 600;
    transition: all 0.2s ease-in;
    display: inline-block;

    @include bp(75) {
        border: 3px solid $black;
    }

    &:before {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        content: "";
        display: inline-block;
        height: 20px;
        margin-right: 5px;
        position: relative;
        top: 5px;
    }

    &--show {

        &:before {
            transition: all 0.2s ease-in;
            background-image: url(../img/icons/eye--base-black.svg);
            width: 20px;
        }
    }
}
.order-carousel-list-item--status {
    padding: 14px 10px;
    background-color: $sand-light;
    border-radius: 5px;
    text-align: center;
    display: inline-block;
    margin-right: 10px;

    .my-orders-list__has-tracking &{
        display: block;
        margin-right: 0;
    }

    @include bp(48) {
        padding-left: 14px;
        padding-right: 14px;
        margin-right: 20px;
    }
}
.order-carousel-list-item--status-text {
    font-weight: 700;
}
.no-touch {
    .order-carousel-btn {
        &:hover {
            background-color: $black;
            color: $sand-lighter;
        }
        &--show {
            &:hover {
                &:before {
                    transition: all 0.2s ease-in;
                    background-image: url(../img/icons/eye--base-white.svg);
                }
            }
        }
    }
}
