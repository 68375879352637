.no-touch{
	.receipt-list__item{
		visibility: hidden;
	}
}
.touch{

	.receipt{
		overflow: visible;
	}
	.receipt-list{
		@extend %clearfix;
	}
	.receipt-list__item{
		visibility: visible;
	}
}
.my-pages-carousel-section{
    .receipt{
      border: 3px solid $black;
      border-radius: 6px;
    }
    .receipt-price{
      border-bottom: 2px solid $black;
    }
    .receipt-price__description{
        @include bp(48){
            margin-right: 0;
            float: left;
            display: inline;
            vertical-align: inherit;
        }
        @include bp(75){
            @include font-size(23px);
        }
    }
    .receipt-price__price{
      @include bp(48){
        float: right;
        display: inline;
      }
    }
    .receipt-btn {
      padding: 5px 10px;
      @include bp(48){
        padding: 5px 15px;
      }

      &--receipt {
        margin-right: 15px;
        @include bp(48){
          margin-right: 20px;
        }
      }
    }
}
.receipt-carousel{
  @extend %page-content;
}
.receipt-list{
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    &.slick-initialized{
		.receipt-list__item{
			visibility: visible;
		}
	}
}
.receipt-list__item{
    float: left;
	visibility: hidden;
    padding-right: 30px;

	&:focus{
	}

	&:first-child{
		visibility: visible;
	}
    @include bp(40){
		width: 50%;
		&:nth-child(-n+2){
			visibility: visible;
		}
	}
	@include bp(75){
		width: 33.3333333333%;

		&:nth-child(-n+3){
			visibility: visible;
		}
	}

	@include bp(120){
		width: 25%;

		&:nth-child(-n+4){
			visibility: visible;
		}
	}
}
.receipt__content{
    padding: 20px;
}
.receipt-meta-list {
  padding: 20px 0;
  margin: 0;
  list-style: none;
}
.receipt-meta-list__item{
  display: inline-block;
  margin: 5px 20px 5px 0;
  font-weight: 600;
  @include font-size(15px);
  position: relative;
  padding-left: 25px;

  @include bp(48){
    vertical-align: text-top;
  }
  @include bp(75){
    max-width: inherit;
  }

  &:before{
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: auto 100%;
    content: "";
    display: inline-block;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &--date{
    &:before{
      background-image: url(../img/icons/calendar.svg);
      width: 20px;
    }
  }
  &--time{
    &:before{
      background-image: url(../img/icons/clock.svg);
      width: 20px;
    }
  }
  &--store{
    &:before{
      background-image: url(../img/icons/store.svg);
      width: 20px;
    }
  }
}
