// This class is set on the body while storepicker is open to fix iOS bug
.store-picker-open {
    overflow: hidden;
}

.store-picker__overlay {
    position: fixed;
    z-index: 5000;
    background: rgba(0, 0, 0, .4);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.store-picker__pop-up {
    position: fixed;
    z-index: 10000;
    background-color: $sand-light;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 10px;
    -webkit-backface-visibility: hidden;
    -webkit-filter: blur(0);

    @include bp(48) {
        max-width: 530px;
    }
    @include bp(120) {
        max-width: 410px;
    }
}

.store-picker__content {
    @extend %full-width-content;
    background-color: $sand-light;
    padding: 20px 0 10px;
}

.store-picker__heading {
    @include font-size(23px);
    display: block;
    text-align: center;
    position: relative;
    margin: 0;
    font-weight: 600;
    margin-bottom: 20px;
}

.store-picker__close {
    @include clear-button-style();
    position: absolute;
    right: 20px;
    top: 27px;
    background-image: url('../img/icons/0272-cross.svg');
    background-repeat: no-repeat;
    background-size: auto 95%;
    height: 15px;
    width: 15px;
}
