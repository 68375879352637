//LANDSCAPE IMAGE STYLES//
.landscape-puff__container {
    width: 100%;
    position: relative;
    display: flex;
    margin-bottom: 30px;

    &:visited {
        color: unset;
    }
}

.landscape-puff__image {
    width: 100%;
}

.landscape-puff__header-text {
    position: absolute;
    margin: 0;
    text-align: center;
    font-size: 2.8vmin;
    font-weight: 600;
    top: 50%;
    max-width: 45%;

    p, 
    h1, 
    h2, 
    h3, 
    h4, 
    h5, 
    h6 {
        margin: 0;
    }

    &--left {
        right: 25%;
        transform: translate(35%, -50%);
    }

    &--right {
        left: 25%;
        transform: translate(-35%, -50%);
    }

    @include bp(48) {
        @include font-size(20px);
        letter-spacing: 1px;
    }

    @include bp(75) {
        @include font-size(28px)
    }
}

.landscape-puff__cta {
    position: absolute;
    padding: 2px 4px;
    background-color: black;
    color: white;
    border-radius: 2px;
    margin: 0;
    font-size: 1.3vmin;
    font-weight: 600;
    bottom: 12%;

    &--left {
        right: 25%;
    }

    &--right {
        left: 25%;
    }

    @media (min-width: 600px) {
        padding: 4px 6px 3px;
    }

    @include bp(48) {
        @include font-size(9px);
        border-radius: 3px;
    }

    @include bp(75) {
        @include font-size(13px);
        border-radius: 5px;
        padding: 5px 8px;
    }
}

//SALE HEADER STYLES

.sale-header-image__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    padding: 10px;
    background-size: contain;
    text-decoration: none;

    @include bp(48) {
        padding: 20px;
    }

    &:hover {
        cursor: pointer;

        .sale-header-image__sub-text {
            @include bp(48) {
                text-shadow: none;
                transform: translateY(2px);

                &:before {
                    opacity: 1;
                }
            }
        }
    }
}

.sale-header-image__header-text {
    color: white;
    text-align: center;
    @include font-size(18px);
    margin: 0;

    h1 {
        margin: 0;
    }

    @include bp(48) {
        @include font-size(30px);
        text-shadow: 0 15px 5px rgba(0, 0, 0, .1), 10px 20px 5px rgba(0, 0, 0, .05), -10px 20px 5px rgba(0, 0, 0, .05);
    }

    @include bp(75) {
        @include font-size(40px);
    }
}

@keyframes pointer {
    0% {left: 0;}
    50% {left: -5px;}
    100% {left: 0;}
}

.sale-header-image__sub-text {
    color: black;
    transition: all .2s ease-in;
    text-align: center;
    margin: 0;
    font-weight: lighter;
    @include font-size(20px);

    @include bp(48) {
        @include font-size(24px);
        text-shadow: 0 15px 5px rgba(0, 0, 0, .1), 10px 20px 5px rgba(0, 0, 0, .05), -10px 20px 5px rgba(0, 0, 0, .05);
    }

    &:before {
        display: none;

        @include bp(48) {
            content: ' ';
            height: 20px;
            width: 20px;
            position: relative;
            background-image: url('../img/icons/SVG/0317-arrow-right2.svg');
            background-size: contain;
            opacity: 0;
            margin-right: 10px;
            display: inline-block;
            animation: pointer .5s linear infinite;
            top: 2px;
            transition: opacity .3s ease-in;
        }
    }
}