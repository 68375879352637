.discount-banner {
    background-color: $red;
    padding: 0 $discount-banner-size 0 15px;
    visibility: hidden;
    width: 100%;
    height: 0;
    overflow: hidden;
    position: relative;
    @include font-size(16px);

    @include bp(48) {
        padding-right: 15px;
    }

    &.discount-banner--show {
        height: $discount-banner-size;
        visibility: visible;
    }

    &.discount-banner--hide {
        transition: opacity .4s, height .4s, visibility .4s;
        opacity: 0;
        height: 0;
    }

    .discount-banner__text {
        color: $white;
        height: $discount-banner-size;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .discount-banner__text__item {
            display: block;
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
        }

        .discount-banner__link {
            color: $white;
            font-weight: 700;
        }

        .discount-banner__title {
            font-weight: 700;
        }

        .discount-banner__code {
            font-size: inherit;
            font-weight: 700;
        }
    }

    .discount-banner__remove-button {
        width: $discount-banner-size;
        height: $discount-banner-size;
        background-color: transparent;
        background-image: url('../img/icons/cross-rundad--inverted.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 11px;
        position: absolute;
        right: 0;
        top: 0;
        border: 0;
    }
}

