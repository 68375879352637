.tag-section {
    @extend %clearfix;
}

.tag-section__tag-list {
    @extend %clearfix;
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: -10px;
}

.tag-section__tag {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;

    @include bp(75) {
        &:nth-child(n + 7) {
            visibility: hidden;
            position: absolute;
            opacity: 0;
        }

        .tag-section--expanded & {
            visibility: visible;
            position: relative;
            transition: opacity .4s linear;
            opacity: 1;
        }
    }
}

.tag-section__toggle-expand {
    display: none;
    float: left;

    @include bp(75) {
        display: block;
    }
}

.tag-section__arrow {
    background-image: url('../img/icons/chevron_right_black.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    transform: rotate(90deg);
    transition: transform .2s ease-in-out;

    .tag-section--expanded & {
        transform: rotate(-90deg);
    }
}
