.tag {
    border: 2px solid black;
    border-radius: 5px;
    background: transparent;
    padding: 8px;
}

.tag--active {
    background-color: black;
    color: white;
}

.tag--disabled {
    border-color: gray;
    color: gray;
}
