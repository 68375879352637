.reservation-receipt__container {
    padding: 20px;
}

.reservation-receipt__store-info-container {
    display: flex;
    margin-bottom: 20px;
}

.reservation-receipt__store-label {
    font-weight: 550;
    margin: 0;
    margin-right: 10px;
}

.reservation-receipt__store-info {
    margin: 0;
}

.reservation-receipt__success-message-container {
    background: white;
    color: #418141;
    padding: 20px;
}

.reservation-receipt__success-message {
    &--bold {
        font-weight: 550;
    }
}

.reservation-receipt__button {
    text-align: center;
    margin-top: 20px;
}