.bambuser-card__container {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 2%;
    position: relative;
    border-radius: 5px;
    margin-top: 20px;

    @include bp(48) {
        width: 46%;
        margin-top: 2%;
    }

    @include bp(64) {
        width: 29%;
    }

    @include bp(75) {
        width: 21%;
    }
}


.bambuser-card__image-wrapper {
    width: 100%;
    z-index: -1;
    background-color: transparent;
    border-radius: 5px;
    position: relative;
}

.bambuser-card__image {
   width: 100%;
   opacity: 1;
   border-radius: 5px;
   filter: brightness(50%);
}

.bambuser-card__text-container {
    position: absolute;
    color: white;
    word-wrap: break-word;
    width: 100%;
    height: 100%;
    top: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.bambuser-card__title {
    @include font-size(25px);
    font-weight: 800;
    padding: 15px;
}

.bambuser-card__record-badge {
    background-color: $product-cover-coming-plate;
    display: flex;
    border-radius: 5px;
    padding: 8px 12px 8px 8px;
    position: absolute;
    top: 20px;
    left: -16px;
    align-items: center;
    line-height: 20px;
    color: $sand-light;
    font-weight: 600;
    @include font-size(20px);

    &:before {
        border-top: 15px solid $black;
        border-left: 15px solid transparent;
        top: 35px;
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        position: absolute;
        left: 1px;

    }

}
.bambuser-card__record-circle {
    background-color: $sand-light;
    height: 20px;
    width:20px;
    border-radius: 50%;
    margin-right: 10px;

}

.bambuser-card__date {
    font-weight: bold;
}

.bambuser-card__description {
    margin: 0;

    @include bp(48) {
        height: 70px;
    }

    @include bp(75) {
        height: 85px;
    }
    @include bp(100) {
        height: 80px;
    }
    
}