.event-details {
    @extend %clearfix;
    //@extend %page-content;
    padding: 30px 0;

    @include bp(48) {
        padding: 30px 0;
    }

    @include bp(75) {
        padding: 60px 0;
    }

    @include bp(120) {
        padding: 80px 0;
        max-width: 93%;
        margin: 0 auto;
    }
}

.event-image {
    margin: 0 auto 20px;
    text-align: center;

    @include bp(48) {
        @include column_style(48, 1);
        margin: 0 0 30px;
        float: left;
        padding: 0 30px 0 60px;
    }
    @include bp(75) {
        @include column_style(75, 3);
        padding: 0 15px 0 60px;
    }
    @include bp(120) {
        @include column_style(120, 3);
    }
}

.event-image__img {
    border-radius: 100%;
    max-width: 100%;
}

.event-body {

    @include column_style(0, 2);

    @include bp(40) {
        @include column_style(75, 8);
    }

    @include bp(48) {
        @include column_style(48, 3);
        float: left;
        padding: 0 60px;
    }
    @include bp(75) {
        @include column_style(75, 6);
        padding: 0 80px 0 15px;
    }
    @include bp(120) {
        @include column_style(120, 5);
    }

    .event-body-info-wrapper & {
        @include bp(75) {
            padding: 0 30px 0 80px;
            max-width: 55%;
        }
    }
}

.event-body__place {
    @include font-size(23px);
    font-weight: 300;
    line-height: 1.08695652em;
    margin: 0;

    @include bp(120) {
        @include font-size(30px);
        line-height: 1.16666667em;
        margin: 0 0 5px;
    }
}

.event-info {
    text-align: center;
    @include column_style(0, 2);

    @include bp(40) {
        text-align: left;
        @include column_style(75, 8);
    }

    @include bp(48) {
        float: left;
        @include column_style(48, 3);
        //padding-top: 15px;
        padding: 15px 60px 0;
    }
    @include bp(75) {
        @include column_style(75, 6);
        float: right;
        //padding-top: 0;
        padding: 0 80px 0 15px;
    }
    @include bp(120) {
        @include column_style(120, 4);
        float: left;
        padding-top: 56px;
    }

    .event-body-info-wrapper & {
        @include bp(75) {
            max-width: 45%;
            padding: 0 80px 0 30px;
      }
    }
}

.event-body__heading {
    @include font-size(30px);
    font-weight: 700;
    margin: 0 0 10px;

    @include bp(48) {
        @include font-size(53px);
        line-height: 1.13207547em;
        margin: 0 0 10px;
    }
    @include bp(75) {
        @include font-size(60px);
        line-height: 1.08333333em;
    }
    @include bp(120) {
        @include font-size(75px);
    }
}

.event-body__subheading {
    @include font-size(23px);
    font-weight: 300;
    margin: 0 0 20px;

    @include bp(48) {
        @include font-size(30px);
    }
    @include bp(75) {
        @include font-size(37px);
        line-height: 1.21621622em;
    }
    @include bp(120) {
        @include font-size(53px);
        line-height: 1.22641509em;
    }
}

.event-body__preamble {
    @include font-size(15px);
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 1.66666666667em;
    margin: 0;

    @include bp(48) {
        @include font-size(23px);
        line-height: 1.30434783em;
    }

    @include bp(75) {
        line-height: 1.52173913em;
    }
}

.event-editor {
    text-align: left;
    padding: 15px 0;

    h1,h2,h3,h4,h5,h6{
        font-weight: bold;
        font-family: $font-family--primary;
    }
    h3{
        @include font-size(23px);
        line-height: 1.30434782609em;
        margin: 45px 0 0;

        @include bp(48){
            margin-top: 45px;
        }
        @include bp(120){
            @include font-size(30px);
            line-height: 1.16666666667em;
        }
    }
    p{
        line-height: 1.5625em;
        font-family: $font-family--secondary;
        margin-bottom: 20px;

        @include bp(48){
            @include font-size(17px);
            line-height: 1.76470588em;
        }
        @include bp(75){
            @include font-size(18px);
            line-height: 1.94444444em;
            margin-bottom: 40px;
        }
        @include bp(120){
            @include font-size(20px);
            line-height: 1.7em;
        }
    }
    a{
        color: $black;
        font-weight: 600;
        text-decoration: underline;

        &:hover{
            color: $red;
        }
    }
    img{
        max-width: 100%;
    }
    ul{
        @include font-size(16px);
        list-style: none;
        padding: 0 20px;

        @include bp(48){
            @include font-size(17px);
            line-height: 1.76470588235em;
            padding: 0;
        }
        @include bp(75){
            @include font-size(18px);
            line-height: 1.94444444444em;
        }
        @include bp(120){
            @include font-size(20px);
            line-height: 1.7em;
        }

        ul,ol{
            padding: 0 0 0 20px;
            list-style-type: circle;
        }

        li{
            position: relative;

            &:before{
                content: "\2022 ";
                display: inline-block;
                position: absolute;
                left: -20px;

            }
            li{
                &:before{
                    display: none;
                }
            }
        }
    }

    ol{
        @include font-size(16px);
        padding: 0 20px;

        @include bp(48){
            @include font-size(17px);
            line-height: 1.76470588235em;
            padding: 0;
        }
        @include bp(75){
            @include font-size(18px);
            line-height: 1.94444444444em;
        }
        @include bp(120){
            @include font-size(20px);
            line-height: 1.7em;
        }

        ol,ul{
            padding: 0 0 0 20px;
        }
    }


    blockquote{
        font-style: italic;
        @include font-size(30px);
        font-weight: 700;
        line-height: 1.16666666667em;
        margin: 45px 0 15px;
        text-align: center;

        font-family: $font-family--primary;

        &:before,
        &:after{
            content: "\201D";
            display: inline-block;
        }


        @include bp(48){
            @include font-size(37px);
            line-height: 1.08108108108em;
        }
        @include bp(75){
            @include font-size(45px);
            line-height: 1.11111111111em;
        }
        @include bp(120){
            @include font-size(60px);
            line-height: 1.08333333333em;
            margin-top: 60px;
        }
    }

    @include bp(48){
        padding: 0 0;
    }
    @include bp(75){
    }
    @include bp(120){
        margin: 0 auto;
    }
}


.event-info__time{
    @include font-size(20px);
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 1.30434783em;
    margin: 0;

    &:after{
        background: $sand-light;
        content: "";
        display: block;
        height: 1px;
        margin: 25px auto 0;
        width: 50%;

        @include bp(40){
            display: none;
        }
    }

    &--friends{

        &:before{
            background-image: url('../img/icons/akademibokhandelnsvanner.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            content: '';
            display: block;
            height: 50px;
            margin: 0 auto 10px;
            width: 50px;

            @include bp(40){
                display: inline-block;
                margin: 0 10px 0 0;
                transform: translateY(25%);
            }
        }
    }
}
.event-info__weekday {
    text-transform: capitalize;
}
.registration-need{
    display: block;

    @include bp(48){
        display: inline;
    }
    &:before{
        content: "\2022";
        display: inline-block;
        margin-right: 5px;

        @include bp(75){
            margin-right: 8px;
        }
    }

}
.event-info__subheading{
    @include font-size(20px);
    font-weight: 600;
    margin: 30px 0 10px;

    @include bp(75){
        margin-bottom: 0;
    }
}
.event-info__location{
    color: $black;
    display: inline;
    font-family: $font-family--secondary;
    font-weight: 700;
    line-height: 1.5625em;
    position: relative;
    padding-left: 18px;

    @include bp(48){
        @include font-size(17px);
        line-height: 1.76470588em;
    }
    @include bp(75){
        @include font-size(18px);
        line-height: 1.94444444em;
    }
    @include bp(120){
        @include font-size(20px);
        line-height: 1.7;
        padding-left: 23px;
    }

    &:before {
      background-image: url(../img/icons/0073-location2.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100%;
      content: "";
      display: inline-block;
      height: 18px;
      left: 0;
      position: absolute;
      transform: translateY(25%);
      width: 18px;

      @include bp(120){
        width: 22px;
        height: 22px;
      }
    }
}
.event-info__extra{
    margin: 0;
    line-height: 1.5625em;

    p {
      margin-top: 5px;
    }

    font-family: $font-family--secondary;

    @include bp(48){
        @include font-size(17px);
        line-height: 1.76470588em;
    }
    @include bp(75){
        @include font-size(18px);
        line-height: 1.94444444em;
    }
    @include bp(120){
        @include font-size(20px);
        line-height: 1.7;
        margin: 0 auto;
    }
}
.event-info__isfriend{
    display: none;
}
.registration-button-wrapper{
  position: relative;
  display: inline-block;
}
.event-info__registration{
    border: 2px solid $black;
    border-radius: 5px;
    color: $black;
    display: inline-block;
    @include font-size(15px);
    font-weight: 600;
    margin-top: 20px;
    text-decoration: none;
    transition: all .2s ease-in;
    padding: 10px 30px;

    @include bp(48){
        border: 3px solid $black;
    }

    &.disabled {
        pointer-events: none;
        cursor: none;
        opacity: .3;
    }

    &:hover{
        background: $black;
        color: $sand-lighter;
    }

    &.spinner {
        span {
            opacity: 0;
        }

        &:after {
            @include spinner(20px);
            content: '';
            z-index: 30;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            bottom: 0;
            margin: 0 auto;
        }

        &:hover {
            background: $sand-lighter;
            color: $black;
        }

      &:hover{
        background: $sand-lighter;
        color: $disabled-element;
      }

        &:hover {
            background: $sand-lighter;
            color: $disabled-element;
        }

        @include bp(48) {
            border: 3px solid $disabled-element;
        }
    }

    @keyframes pulse_animation {
        0% { transform: scale(1); }
        30% { transform: scale(1); }
        40% { transform: scale(1.08); }
        50% { transform: scale(1); }
        60% { transform: scale(1); }
        70% { transform: scale(1.05); }
        80% { transform: scale(1); }
        100% { transform: scale(1); }
    }
    &.pulse{
        animation-name: pulse_animation;
        animation-duration: 3000ms;
        transform-origin: 70% 70%;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
.event-body-info-wrapper {
  @extend %clearfix;

  //@include bp(75){
  //  max-width: 93%;
  //  margin: 0 auto;
  //}
  //@include bp(120){
  //  max-width: 80%;
  //  margin: 0 auto;
  //}

}

.event-image-wrapper{
  position: relative;
  max-width: 200px;
  margin: 0 auto;

   @include bp(48){
        max-width: 100%;
    }

    @include bp(75){
        max-width: 90%;
    }
    @include bp(120){
        max-width: 90%;
    }

}

.event-image-byline {
  color: $color-byline;
  display: inline-block;
  @include font-size(10px);
  font-weight: 700;
  margin: 3px;
  padding: 0 5px;
  position: absolute;
  right: -25px;
  transform: rotate(90deg) translate(50%, 0%);
  top: 50%;
  transform-origin: top right;

  &--light {
    font-weight: 300;
  }
}

/* Register event form */

.register-event-section{
  margin-top: 30px;
  background: $sand-light;
  width: 100%;

  @include bp(48){
    float: left;
  }
  @include bp(120){
    max-width: 33.333%;
    padding: 0 15px;
    background: $sand-lighter;
  }
  .event-body-info-wrapper & {
    @include bp(120){
      max-width: 45%;
      padding: 0 80px 0 30px;
    }
  }
}
.register-event__content{
  @extend %clearfix;
  padding: 30px 20px;

  @include bp(48){
    padding: 30px 60px;
  }
  @include bp(75){
    padding: 30px 80px;
  }
  @include bp(120){
    padding: 30px;
    background: $sand-light;
  }
}
.register-event-intro__heading{
  margin: 0;
  @include font-size(23px);
  @include line-height(23);
}
.register-event-intro__info{
  @include font-size(15px);
  @include line-height(15);
  font-weight: 400;
  margin: 15px 0;

  a{
    color: $black;
    font-weight: 600;
  }
  a:visited{
    color: $black;
  }
  @include bp(75){
    margin-top: 25px;
  }
  @include bp(120){
    margin-top: 15px;
  }
}
.register-event-form__legend{
  @include font-size(23px);
  font-weight: 300;
}
.register-event-form__pwd-info{
  @include font-size(15px);
  font-style: italic;
  font-weight: 300;
  margin: 10px 0 0;
}
.register-event-intro{
  @include bp(75){
    width: 32.333%;
    float: left;
    padding-right: 30px;
  }
  @include bp(120){
    width: 100%;
    float: none;
    padding-right: 0;
  }
}
.register-event-form{
  @include bp(75){
    width: 33.333%;
    float: left;
  }
  @include bp(120){
    width: 100%;
    float: none;
  }

  .errorlist{
    margin: 10px 0 0;

    @include bp(48){
      width: 100%;
    }
    @include bp(120){
      max-width: inherit;
      padding: 0;
    }
  }
  .errorlist__item{
    @include bp(75){
      max-width: inherit;
    }
  }
}
.register-event-extra-info{
  @include bp(75){
    width: 33.333%;
    float: left;
    padding-left: 30px;
  }
  @include bp(120){
    width: 100%;
    float: none;
    padding-left: 0;
  }
}
.register-event-extra-info__info{
  @include font-size(15px);
  @include line-height(15);
  font-weight: 400;
  margin: 25px 0 0;

  a{
    color: $black;
    font-weight: 600;
  }
  a:visited{
    color: $black;
  }

  @include bp(75){
    margin-top: 55px;
  }
  @include bp(120){
    margin-top: 25px;
  }
}
.register-event-item{
    &--email{
        @include input-email();
    }
    &--password{
        @include input-password();
    }
    @include bp(75){
        input[type="text"],
        input[type="email"],
        input[type="password"]{
            height: 40px;
            padding: 0 15px;
            @include font-size(15px);

            &[value=""]{
                & + label{
                    padding-left: 25px;
                    @include font-size(15px);

                    &:before{
                        height: 15px;
                        width: 15px;
                    }
                }
            }
        }
        .toggle-password__label{
            padding: 5px 25px 5px 5px;

            &:after{
                right: 5px;
            }
        }
    }
}
.register-event-item:first-of-type{
  margin-top: 30px;
}
.register-event-form__forgot{
  color: $black;
  display: inline-block;
  @include font-size(15px);
  @include line-height(15);
  font-weight: 600;
  margin-top: 10px;
}
.register-event-form__submit{
    @include button-styles();
    margin-right: 20px;

    @include bp(48){
      border: 3px solid $black;
    }

    @include bp(75){
      height: 40px;
      @include font-size(15px);
      max-width: 150px;
    }
}
.register-event-form__actions{
  margin-top: 10px;

  @include bp(48){
    margin-top: 30px;
  }
   @include bp(75){
     margin-top: 0;
  }
}

.register-event-thank-you{
  @include bp(48){
    max-width: 70%;
  }
}
.register-event-thank-you__heading{
  margin: 0;
  @include font-size(23px);
  @include line-height(23);

  @include bp(75){
    @include font-size(30px);
    @include line-height(30);
    margin: 20px 0;
  }
  @include bp(120){
    @include font-size(23px);
    @include line-height(23);
    margin: 0;
  }
}
.register-event-thank-you__info{
  @include font-size(15px);
  @include line-height(15);
  font-weight: 400;
  margin: 15px 0;

  a{
    color: $black;
    font-weight: 600;
  }
  a:visited{
    color: $black;
  }
  @include bp(120){
    @include font-size(15px);
    @include line-height(15);
  }
}
