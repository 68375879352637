.store-info {
    position: relative;
    padding: 10px;
    margin: 0 0 15px;
    overflow: hidden;

    @include bp(120) {
        padding: 15px;
        margin-bottom: 30px;
    }

    &.store-info--hide-mobile {
        display: none;

        @include bp(48) {
            display: block;
        }
    }

    &:before {
        position: absolute;
        content: '';
        background-image: url('../img/openinghours_background_min.jpg');
        background-size: cover;
        width: calc(200%);
        height: calc(200%);
        filter: blur(5px);
        top: -5%;
        background-position: center center;
    }
}

// Required to put content above background that is absolute positioned
.store-info__inner {
    position: relative;
}

.store-info__store-selection {
    @include font-size(15px);
    text-align: center;
    font-weight: 700;

    @include bp(48) {
        @include font-size(18px);
        line-height: 30px;
    }

    @include bp(120) {
        @include font-size(20px);
    }

    .store-info--store-selected & {
        display: none;
    }
}

.store-info__store-info {
    @extend %page-content;
    display: none;
    text-align: center;

    @include bp(40) {
        line-height: 25px;
    }

    @include bp(75) {
        line-height: 30px;
    }

    .store-info--store-selected & {
        display: block;
    }
}

.store-info__no-selected-store-text {
    margin: 0;
}

.store-info__choose-store {
    @include clear-button-style();
    text-decoration: underline;
    font-weight: 700;
    margin-right: 5px;
}

.store-info__open-hours {
    font-weight: 700;
    margin-bottom: 2px;
    white-space: nowrap;

    @include bp(40) {
        display: inline-block;
        margin-bottom: 0;
        padding-right: 10px;
        border-right: 1px solid $white;
        margin-right: 10px;
    }
}

.store-info__open-hours-label {
    margin-right: 5px;

    @include bp(48) {
        @include font-size(18px);
    }

    @include bp(120) {
        @include font-size(20px);
        margin-right: 8px;
    }
}

.store-info__open-hours-time {
    @include font-size(15px);
    position: relative;
    padding-left: 20px;

    @include bp(48) {
        @include font-size(18px);
    }

    @include bp(120) {
        @include font-size(20px);
        padding-left: 23px;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 1px;
        background-image: url('../img/icons/SVG/0079-clock.svg');
        background-size: 100% 100%;
        width: 16px;
        height: 16px;

        @include bp(75) {
            top: 2px;
        }

        @include bp(120) {
            width: 20px;
            height: 20px;
            top: 1px;
        }
    }
}

.store-info__name,
.store-info__change-store {
    display: inline-block;
    font-weight: 700;
}

.store-info__name {
    @include font-size(13px);
    padding-right: 10px;
    border-right: 1px solid $white;
    margin-right: 10px;
    margin-top: 0;
    margin-bottom: 0;

    @include bp(40) {
        @include font-size(15px);
    }

    @include bp(48) {
        @include font-size(18px);
    }

    @include bp(120) {
        @include font-size(20px);
    }
}

.store-info__store-link {
    color: $black;
    text-decoration: underline;
}

.store-info__change-store {
    @include font-size(13px);
    @include clear-button-style();
    text-decoration: underline;

    @include bp(40) {
        @include font-size(15px);
    }

    @include bp(120) {
        @include font-size(18px);
    }
}
