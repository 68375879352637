.reservation-pop-up__overlay {

    display: none;
    @include bp(48) {
        display: block;
        position: fixed;
        z-index: 3000;
        background: rgba(0, 0, 0, .4);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

}

.reservation-pop-up__container {
    width: 100%;

    @include bp(75) {
        position: static;
    }
}

.reservation-pop-up__content {
    background-color: $sand-light;
    width: 100%;
    -webkit-backface-visibility: hidden;
    max-height: 0;
    top: 90px;
    z-index: 5000;
    position: fixed;
    overflow: hidden;

    @include bp(48) {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &--expanded {
        padding: 0 10px 10px;
        max-height: 100vh;
        @include bp(48) {
            max-width: 530px;
            padding: 20px;
        }
    }
}

.reservation-pop-up__close-button {
    display: none;
    appearance: none;
    background-color: transparent;
    background-image: url('../img/icons/cross-rounded--small.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
    border: 0;
    height: 15px;
    width: 15px;
    float: right;

    @include bp(48) {
        display: block;
    }
}

.reservation-pop-up__buttons-container {
    padding: 20px 0;
    display: none;

    &--expanded {
        display: flex;
        justify-content: space-around;
    }
}

.reservation-pop-up__button {
    width: 45%;
}
