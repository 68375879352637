.article-hero {
    background: $black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 110px;

    @include bp(48) {
        height: 270px;
    }
    @include bp(75) {
        height: 350px;
    }
    @include bp(120) {
        height: 650px;
    }
}

.article-hero-byline {
    background: $sand-lighter;
    color: $color-byline;
    display: inline-block;
    float: right;
    @include font-size(10px);
    font-weight: 700;
    margin: 3px;
    padding: 0 5px;

    &--light {
        font-weight: 300;
    }
}

.info-article {
    @extend %clearfix;
    @extend %page-content;


    &--hero {
        @include bp(48) {
            background-color: $sand-lighter;
            margin-top: -130px;
        }
        @include bp(75) {
            margin-top: -230px;
        }
        @include bp(120) {
            margin-top: -325px;
        }
    }
    @include bp(48) {
        max-width: 768px;
    }
    @include bp(75) {
        max-width: 1150px;
    }
    @include bp(120) {
        width: 75%;
        max-width: 1500px;
    }
}

.info-article__content {
    padding-top: 25px;
    padding-bottom: 15px;

    @include bp(48) {
        padding-left: 30px;
        padding-right: 30px;
    }
    @include bp(75) {
        padding-left: 0;
        padding-right: 0;
    }
}

.info-article__heading {
    @include column_style(0, 2);
    @include font-size(29px);
    font-weight: 800;
    line-height: 1.16666666667em;
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
    word-wrap: break-word;

    @include bp(48) {
        @include column_style(48, 3);
        @include font-size(40px);
        line-height: 1.1320754717em;
        padding-bottom: 0;
        padding-top: 0;
        letter-spacing: 1px;
    }
    @include bp(75) {
        @include column_style(75, 7);
        max-width: 1000px;
        @include font-size(50px);
        line-height: 1.08333333333em;
        margin: 0 auto;
        padding-bottom: 0;
        padding-top: 0;
    }
    @include bp(120) {
        @include column_style(120, 8);
        max-width: 1200px;
        @include font-size(60px);
        float: none;
        line-height: 1.06666666667em;
        margin: 0 auto;
        padding-bottom: 0;
        padding-top: 0;
    }

}

.info-article__subheading {
    @include column_style(0, 2);
    @include font-size(23px);
    font-weight: 300;
    line-height: 1.30434782609em;
    margin: 5px 0 0;
    padding-bottom: 0;
    padding-top: 0;
    word-wrap: break-word;

    @include bp(48) {
        @include column_style(48, 3);
        @include font-size(28px);
        line-height: 1.16666666667em;
        padding-bottom: 0;
        padding-top: 0;
    }
    @include bp(75) {
        @include column_style(75, 7);
        max-width: 1000px;
        @include font-size(32px);
        line-height: 1.21621621622em;
        margin: 5px auto 0;
        padding-bottom: 0;
        padding-top: 0;
    }
    @include bp(120) {
        @include column_style(120, 8);
        max-width: 1200px;
        @include font-size(35px);
        float: none;
        line-height: 1.22641509434em;
        padding-bottom: 0;
        padding-top: 0;
    }
}

.info-article__preamble {
    @include column_style(0, 2);
    @include font-size(18px);
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 1.66666666667;
    margin: 10px 0 0;
    padding-bottom: 0;
    padding-top: 0;

    @include bp(48) {
        @include column_style(48, 3);
        @include font-size(23px);
        line-height: 1.30434782609em;
        margin: 15px 0 0;
        padding-bottom: 0;
        padding-top: 0;
    }
    @include bp(75) {
        @include column_style(75, 7);
        max-width: 1000px;
        line-height: 1.52173913043em;
        margin: 15px auto 0;
        padding-bottom: 0;
        padding-top: 0;
    }
    @include bp(120) {
        @include column_style(120, 8);
        max-width: 1200px;
        @include font-size(30px);
        line-height: 1.5em;
        margin: 15px auto 0;
        padding-bottom: 0;
        padding-top: 0;
    }
}

.info-article__body {
    @include column_style(0, 2);
    @include font-size(16px);
    font-family: $font-family--secondary;
    line-height: 1.5625em;
    margin: 20px 0 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: Arial, sans-serif;
        font-weight: bold;
        font-family: $font-family--primary;
    }

    h3 {
        @include font-size(23px);
        line-height: 1.30434782609em;

        @include bp(120) {
            @include font-size(30px);
            line-height: 1.16666666667em;
        }
    }

    p {
        font-family: $font-family--secondary;

        @include bp(48) {
            @include font-size(17px);
            line-height: 1.76470588235em;
        }

        @include bp(75) {
            @include font-size(18px);
            line-height: 1.94444444444em;
		}

        @include bp(120) {
            @include font-size(20px);
            line-height: 1.7em;
		}
    }

    a {
        color: $black;
        font-weight: 600;
        text-decoration: underline;

        &:hover {
            color: $red;
		}
    }

    img {
        max-width: 100%;
    }

    ul {
        @include font-size(16px);
        list-style: none;
        padding: 0 20px;

        @include bp(48) {
            @include font-size(17px);
            line-height: 1.76470588235em;
            padding: 0;
        }
        @include bp(75) {
            @include font-size(18px);
            line-height: 1.94444444444em;
        }
        @include bp(120) {
            @include font-size(20px);
            line-height: 1.7em;
        }

        ul,
        ol {
            padding: 0 0 0 20px;
            list-style-type: circle;
        }

        li {
            position: relative;

            &:before {
                content: '\2022';
                display: inline-block;
                position: absolute;
                left: -20px;

            }

            li {
                &:before {
                    display: none;
                }
            }
        }
	}

    ol {
        @include font-size(16px);
        padding: 0 20px;

        @include bp(48) {
            @include font-size(17px);
            line-height: 1.76470588235em;
        }
        @include bp(75) {
            @include font-size(18px);
            line-height: 1.94444444444em;
        }
        @include bp(120) {
            @include font-size(20px);
            line-height: 1.7em;
        }

        ol,
        ul {
            padding: 0 0 0 20px;
        }
    }

    .sub-menu-list {
        margin-bottom: 35px;
        padding: 0;
        border-bottom: 1px solid $border-calendar-filter;
        padding-bottom: 20px;
	}

    .sub-menu-list__item {
        font-family: $font-family--primary;
        display: inline-block;
        margin-right: 30px;
        @include line-height(30);
        margin-bottom: 10px;

        &:before {
            content: '';
        }
    }


    blockquote > p {
        font-style: italic;
        @include font-size(30px);
        font-weight: 700;
        line-height: 1.16666666667em;
        margin: 45px 0 15px;
        text-align: center;
        font-family: $font-family--primary;

        &:before,
        &:after {
            content: '\201D';
            display: inline-block;
        }


        @include bp(48) {
            @include font-size(37px);
            line-height: 1.08108108108em;
        }
        @include bp(75) {
            @include font-size(45px);
            line-height: 1.11111111111em;
        }
        @include bp(120) {
            @include font-size(60px);
            line-height: 1.08333333333em;
            margin-top: 60px;
        }

    }

    @include bp(48) {
        @include column_style(48, 3);
        margin: 20px auto 0;
    }

    @include bp(75) {
        @include column_style(75, 7);
        max-width: 1000px;
    }

    @include bp(120) {
        box-sizing: border-box;
        max-width: 1200px;
        width: 100%;
    }
}

.info-article__text-puffers {
    @include column_style(0, 2);
    margin: 45px 0 0;

    @include bp(48) {
        @include column_style(48, 3);
        margin: 45px auto 0;
    }

    @include bp(75) {
        @include column_style(75, 7);
        max-width: 900px;
        margin: 40px auto 0;
    }

    @include bp(120) {
        box-sizing: border-box;
        margin: 105px auto 0;
        max-width: 900px;
        width: 100%;
    }
}