.store-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.store-list__item {
    @include font-size(15px);
    @include clear-button-style();
    padding: 20px;
    text-decoration: none;
    font-weight: 600;
    display: block;
    width: 100%;
    border-top: 1px solid $sand-light;
    position: relative;
    text-align: left;
    padding-left: 50px;
    transition: background .2s linear;

    &:last-child {
        border-bottom: 1px solid $sand-light;
    }

    &:hover,
    &:focus {
        background: $sand-light;
    }

    &:before {
        position: absolute;
        left: 20px;
        width: 20px;
        height: 20px;
    }
}

.store-list__item--green {
    &:before {
        content: url('../img/icons/book--yes.svg');
    }
}

.store-list__item--yellow {
    &:before {
        content: url('../img/icons/book--nja.svg');
    }
}

.store-list__item--red {
    &:before {
        content: url('../img/icons/book--no.svg');
    }
}

.store-list__item--gray {
    &:before {
        content: url('../img/icons/book--missing.svg');
    }
}
