.active-navigation .main-header {
    border-bottom: 0;
}

.main-header {
    background-color: $sand-lighter;
    border-bottom: 1px solid $sand-light;
    position: relative;
    position: sticky;
    top: 0;
    z-index: 11;

    @extend %clearfix;

    @include bp(75) {
        border-bottom: 0;
        margin: 0 auto;
        max-width: $content-width__percentage;
        width: 100%;
        position: relative;
        padding: 0;
    }
    @include bp(120) {
        max-width: $content-width__percentage;
    }
}

.main-header__content {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;

    @include bp(75) {
        flex-direction: column-reverse;
        justify-content: unset;
        padding: 0;
   }   
}

.main-header__left-hand-side-wrapper {
    display: flex;
    align-items: center;
    width: 80%;
    
    @include bp(75) {
        width: 100%;
        justify-content: space-between;
    }
}

.main-header__navigation-wrapper {
    display: flex;
    justify-content: flex-end;
}

.menu-button {
    background: transparent;
    border: 0;
    float: left;
    height: 55px;
    margin: 0;
    line-height: 1em;
    padding: 0;
    width: 50px;
    margin-left: -13px;
    margin-right: -10px;

    @include bp(75) {
        display: none;
    }
}

.menu-button--active {
    background: $bg-menu--default;
}

.menu-button__icon {
    background-color: $black;
    display: inline-block;
    height: 3px;
    position: relative;
    top: -3px;
    transition: .1s;
    width: 20px;

    .menu-button--active & {
        background-color: transparent;

        &:before,
        &:after {
            background: $sand-lighter;
            top: 0;
            transform-origin: 50% 50%;
        }

        &:before {
            transform: rotate3d(0, 0, 1, -45deg);
        }

        &:after {
            transform: rotate3d(0, 0, 1, 45deg);
        }
    }

    &:before,
    &:after {
        background-color: $black;
        content: '';
        display: inline-block;
        height: 3px;
        left: 0;
        position: absolute;
        transition: .2s;
        width: 20px;
    }

    &:before {
        top: -6px;
    }

    &:after {
        top: 6px;
    }
}

.main-header-logo {  

    @include bp(75) {
        padding-bottom: 10px;
    }
}

.main-header-logo__img {
    display: inline-block;
    max-width: 100%;
}

.main-header-logo__img--small {
    margin-left: 10px;
    width: 165px;
    display: block;
    @include bp(48) {
        width: 240px;
    }
    
    @include bp(75) {
        display: none;
    }
}

.main-header-logo__img--large {
    display: none;
    width: 50px;

    @include bp(75) {
        display: inline-block;
        width: 96px;
    }
}

.main-header__logo-and-search-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
}

.main-header__search-bar--desktop {
    display: none;

    @include bp(75) {
        display: block;
        width: 100%;
    }
}

.main-header__search-bar--mobile {
    padding: 0 10px 10px;

    @include bp(75) {
        display: none;
    }
}

.my-account-button {
    background-color: transparent;
    background-image: url('../img/icons/my-account.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px;
    border: 0;
    float: right;
    height: 25px;
    margin: 15px 10px 0 0;
    line-height: 1em;
    padding: 0;
    width: 25px;

    @include bp(75) {
        display: none;
    }
}

.find-store-button {
    background-color: transparent;
    background-image: url('../img/icons/SVG/0492-geotag.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px;
    border: 0;
    float: right;
    height: 25px;
    margin: 15px 5px 0 0;
    line-height: 1em;
    padding: 0;
    width: 25px;

    @include bp(75) {
        display: none;
    }
}

.cart-button {
    background: none;
    border: 0;
    float: right;
    @include font-size(13px);
    letter-spacing: 1px;
    padding: 0 0 0 28px;
    position: relative;
    height: 55px;

    @include bp(75) {
        height: auto;
        margin-top: 10px;
        margin-left: 15px;
        margin-right: 0;
        padding-left: 30px;
    }

    &:before {
        background-image: url('../img/icons/cart.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        content: '';
        display: block;
        height: 20px;
        left: 0;
        position: absolute;
        top: 47%;
        transform: translateY(-50%);
        width: 20px;
    }

    &:disabled {
        opacity: .2;
        pointer-events: none;
    }
}

.cart-button__text {
    display: none;

    @include bp(75) {
        display: inline-block;
        position: relative;

        &:after {
            background: $red;
            content: '';
            display: block;
            height: 2px;
            margin-left: 3px;
            margin-top: 2px;
            opacity: 0;
            position: absolute;
            transition: opacity .2s ease-in;
            width: 100%;
        }

        .active-cart & {
            &:after {
                opacity: 1;
            }
        }
    }
}

.cart-button__counter {
    background: $red;
    border-radius: 50%;
    color: $sand-lighter;
    display: none;
    @include font-size(15px);
    font-weight: 700;
    height: 20px;
    left: 12px;
    position: absolute;
    text-align: center;
    line-height: 20px;
    width: 20px;


    @include bp(75) {
        bottom: -9px;
    }

    &--active {
        display: block;
    }

    &--double {
        border-radius: 30px;
        padding: 0 5px;
        width: auto;
    }
}