
.new-pwd__content {
    padding-top: 20px;

    @include bp(48) {
        padding-top: 65px;
    }
    @include bp(75) {
        padding-top: 80px;
    }
    @include bp(120) {
        padding-top: 105px;
    }
}

.new-pwd-header {
    @extend %page-content;
    padding: 20px;

    @include bp(120) {
        max-width: 1500px;
    }
}

.new-pwd-header__heading {
    @extend %my-pages-header__heading;
}

.new-pwd-header__preamble {
    @extend %my-pages-header__preamble;

    a {
        color: $black;
    }

    a:visited {
        color: $black;
    }
}

.new-pwd-header__countdown {
    @extend %my-pages-header__preamble;
    font-weight: 400;

    span {
        font-weight: 800;
    }
}

.new-pwd-header__cta {
    @extend %my-pages-header__preamble;

    color: $black;

    &:visited {
        color: $black;
    }
}

.new-pwd-form {
    background: $sand-light;
    @extend %clearfix;
    margin: 0 auto 30px;
    padding: 20px;
    position: relative;

    @include bp(48) {
        padding: 30px;
    }
    @include bp(75) {
        padding: 30px;
    }
}

.new-pwd-form__content {

    @include bp(48) {
        margin: 0 auto;
        width: 93%;
    }
    @include bp(120) {
        max-width: 1500px;
        padding: 0 20px;
    }
}

.new-pwd-form__submit {
    background: transparent;
    border: 2px solid $black;
    border-radius: 5px;
    display: inline-block;
    @include font-size(15px);
    font-weight: 600;
    height: 40px;
    margin: 15px 5px 0 0;
    padding: 5px;
    transition: all .2s ease-in;
    max-width: 150px;
    width: 50%;

    @include bp(48) {
        float: left;
        margin-top: 30px;
        width: 100%;
    }
    @include bp(75) {
        border: 3px solid $black;
        @include font-size(23px);
        height: 55px;
        max-width: 200px;
        margin-top: 30px;
    }

    &:hover {
        background: $black;
        color: $sand-lighter;
    }
}

.new-pwd-form__info {
    display: block;
    float: left;
    @include font-size(15px);
    font-style: italic;
    font-weight: 300;
    margin: 0;
    width: 100%;
}

.new-pwd-form__item {

    @include bp(48) {
        float: left;
        margin-right: 30px;
        width: calc(40% - 20px);
    }
}
