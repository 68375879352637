.order-detail-cart {
    @include column_style(0, 2);
    padding: 0;

    @include bp(48){
        float: left;
        width: 50%;
    }
}
.order-detail-cart-list{
    list-style: none;
    margin: 0;
    padding: 0;
}
.order-detail-cart-list__item{
    border-bottom: 2px solid $sand-light;
    padding: 15px 0;
    @extend %clearfix;

    @include bp(75){
        padding: 15px 0;
    }
    @include bp(120){
        padding: 30px 0;
    }

    &:first-child{
        display: none;

        @include bp(75){
            display: block;
            padding: 10px 0;
            width: 100%;
        }
    }

    &:last-child{
        border: 0;
    }
}
.order-detail-cart-item__heading{
    float: left;
    width: 15%;

    &:first-child{
        width: 40%;
    }

    &:last-child{
        text-align: right;
    }

    @include bp(120){
        @include font-size(23px);
        font-weight: 300;
    }
}
.order-detail-cart-info__img{
    float: left;
    margin-right: 10px;
    width: 35px;

    @include bp(120){
        width: 50px;
    }
}
.order-detail-cart-info__details{
    float: left;
    width: calc(100% - 75px);
    margin-right: 30px;

    @include bp(120) {
        margin-right: 0;
    }

    @include bp(120){
        width: calc(100% - 60px);
    }

    .order-detail-cart-item__status--processing & {
        min-height: 65px;
    }
}
.order-detail-cart-item__details{
    float: left;
    position: relative;
    width: 100%;
}
.order-detail-cart-item__title{
    @include font-size(13px);
    font-weight: 700;
    margin: 0;
    max-width: 65%;

    @include bp(48){
        max-width: 75%;
    }

    @include bp(75){
        @include font-size(15px);
        max-width: none;
    }

    @include bp(120){
        @include font-size(23px);
        padding-right: 30px;
        font-weight: 700;
        margin-top: -5px;
    }
}

.order-detail-cart-item__title-link {
    text-decoration: none;
    color: inherit;
    &:visited {
        color: initial;
    }
}
.order-detail-cart-item__author{
    @include font-size(13px);
    font-weight: 300;
    margin: 0;

    @include bp(75){
        @include font-size(15px);
    }
}
.order-detail-cart-item__author-truncated{
    display: inline-block;
    @include bp(48){
        display: none;
    }
}
.order-detail-cart-item__author-full{
    display: none;
    @include bp(48){
        display: inline-block;
    }
}
.order-detail-cart-item__format{
    @include font-size(13px);
    font-weight: 600;
    margin: 0;

    @include bp(75){
        @include font-size(15px);
        margin-top: 3px;
    }
}
.order-detail-cart-info{
    @extend %clearfix;

    @include bp(75){
        float: left;
        width: 40%;
    }
}
.order-detail-cart-item__summary{
    border-top: 1px solid $sand-light;
    margin-top: 15px;
    margin-left: 45px;
    padding-top: 15px;

    @include bp(75){
        border-top: 0;
        margin-top: 0;
        margin-left: 0;
        float: left;
        padding-left: 0;
        width: 50%;
    }

    @include bp(75){
        padding-top: 0;
    }
}
.order-detail-cart-item-qty {
    display: inline-block;
    @include font-size(15px);
    font-weight: 300;
    width: 32%;
    padding-top: 1px;
    font-weight: 700;

    &:after{
        content:' st';
        font-weight: 100;
    }

    @include bp(75){
        vertical-align: super;
        padding-top:2px;
        width: 29%;
        &:after{
            content:none;
            display: none;
        }
    }

    @include bp(120){
        @include font-size(23px);
        padding-top:3px;
    }
}
.order-detail-cart-item-price{
    display: inline-block;
    @include font-size(15px);
    font-weight: 300;
    width: 32%;
    padding-top: 1px;
    text-align: center;



    @include bp(75){
        vertical-align: super;
        padding-top:2px;
        width: 29%;
        text-align: left;

    }

    @include bp(120){
        @include font-size(23px);
        padding-top:3px;
    }
}


.order-detail-cart-item-price__large{
    padding-top: 3px;
    display: inline-block;
    &:before{
        content:'á (';
        font-weight: 100;
    }
    &:after{
        content:')';
        font-weight: 100;
    }

    @include bp(75){
        padding-top: 0;

        &:before,
        &:after{
            content:none;
            display: none;
        }
    }
}

.order-detail-cart-item__totalprice{
    display: inline-block;
    @include font-size(15px);
    font-weight: 700;
    text-align: right;
    width: 32%;
    vertical-align: top;
    padding-top: 4px;
    float: right;


    @include bp(75){
        text-align: left;
        padding-top: 2px;
        width: 29%;
        float: none;
    }

    @include bp(120){
        @include font-size(23px);
    }
}
.order-detail-cart-item__status {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
}
.order-detail-cart-item__processing {
    font-weight: 300;
    @include font-size(13px);
    max-width: 85px;
    margin-bottom: 5px;

    @include bp(75){
        @include font-size(15px);
        max-width: 100px;
        margin-bottom: 10px;
    }
}
.order-detail-cart-item__nocancelling {
    font-weight: 300;
    @include font-size(13px);
    max-width: 85px;

    @include bp(75){
        @include font-size(15px);
        max-width: 100px;
    }
}
.order-detail-cart-item__cancelled {
    font-weight: 300;
    @include font-size(13px);

    @include bp(75){
        @include font-size(15px);
        padding-top: 2px;
    }
}
.order-detail-cart-item__cancelOrder{
    appearance: none;
    background-color: transparent;
    border: 2px solid $black;
    border-radius: 5px;
    @include font-size(13px);
    height: 40px;
    margin-top: 0;
    font-weight: 600;
    width: 85px;

    @include bp(75){
        @include font-size(15px);
        width: 100px;
    }
}
.order-detail-cart-item__partial {

    &--sent {
        font-weight: 300;
        @include font-size(13px);

        @include bp(75){
            @include font-size(15px);
            padding-top: 2px;
        }
    }

    &--processing {
        font-weight: 300;
        @include font-size(13px);

        @include bp(75){
            @include font-size(15px);
            padding-top: 2px;
        }
    }

    &--qty {
        font-weight: 700;
    }
}
.order-detail-total__wrapper {
    padding: 16px 0 11px;
    margin: 0 0 13px;
    border-top: 10px solid $black;
    border-bottom: 15px solid $black;
}
.order-detail-total-info{
    margin: 0;
    padding: 7px 0;
    position: relative;
    @extend %clearfix;
    display: flex;
    align-items: center;
}
.order-detail-price-divider{
    flex-grow: 100;
    background-color: $border-total;
    height: 1px;
}
.order-detail-discount__info {
    @include font-size(15px);
    font-weight: 600;
    clear: both;
    margin-top: -5px;
    margin-bottom: 5px;
}
.order-detail-total-info__label{
    margin-right: 10px;
    @include font-size(18px);
    font-weight: 700;

    @include bp(75){
        @include font-size(23px);
    }

    @include bp(120){
        @include font-size(30px);
    }
}
.order-detail-total-info__price{
    margin-left: 10px;
    @include font-size(18px);
    font-weight: 700;

    @include bp(75){
        @include font-size(23px);
    }

    @include bp(120){
        @include font-size(30px);
    }
}
.order-detail-total{
    @extend %clearfix;
    margin: 0;
    padding: 5px 0 0 0;
    position: relative;
    display: flex;
    align-items: center;
}
.order-detail-vat {
    padding: 0 0 5px 0;
}
.order-detail-vat__label{
    @include font-size(15px);
    font-weight: 300;

    @include bp(75){
        @include font-size(18px);
    }

    @include bp(120){
        @include font-size(23px);
    }
}
.order-detail-vat__price{
    @include font-size(15px);
    font-weight: 300;

    @include bp(75){
        @include font-size(18px);
    }

    @include bp(120){
        @include font-size(23px);
    }
}
.order-detail-total__label{
    @include font-size(23px);
    font-weight: 700;

    @include bp(75){
        @include font-size(30px);
    }

    @include bp(120){
        @include font-size(45px);
    }
}
.order-detail-total__price{
    @include font-size(23px);
    font-weight: 700;

    @include bp(120){
        @include font-size(45px);
    }
}
