.no-touch {
    .autocomplete-list__item {
        &:hover {
            cursor: pointer;
            transition: none;
        }
    }
}

// sass-lint:disable no-ids
.search-block__autocomplete {
    opacity: 0;
    transition: all .5s ease-in-out;
    position: absolute;
    z-index: 20;
    width: calc(100% - 40px);

    &.autocomplete-list--active {
        opacity: 1;
    }

    @include bp(75) {
        width: 90%;
    }
}

// sass-lint:disable no-ids
.search-block__autocomplete-list {
    list-style: none;
    padding: 0;
    margin: 0;
    color: $black;
    background: white;

    .match {
        font-weight: 700;
    }
}

.autocomplete-list__item {
    @extend %full-width-content;

    font-size: 1.4375rem;
    @include font-size(15px);

    line-height: 1em;

    border-bottom: 1px solid #63615a;
    transition: color .33s ease-out, background-color .33s ease-out;

    padding-top: 12px;
    padding-bottom: 12px;

    > span {
        pointer-events: none;
        margin: 0 auto;
        display: block;

        @include bp(48) {
            max-width: 93%;
        }

        @include bp(75) {
            padding-left: 20px;
        }
    }

    &--active {
        background: $black-dark;
        color: $sand-lighter;
    }

    @include bp(75) {
        @include font-size(23px);
        line-height: 1em;
        padding-top: 11px;
        padding-bottom: 11px;
        border-bottom-color: $border-autocomplete;

        &--active {
            background: $black;
        }
    }
}
