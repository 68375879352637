.profile-header{
    border-bottom: 1px solid $sand-light;
    padding-top: 20px;

    @include bp(48){
        padding-top: 50px;
    }
    @include bp(75){
        padding-top: 80px;
    }
    @include bp(120){
        padding-top: 100px;
    }
}
.profile-header__content{
    @extend %page-content;
    padding: 0 20px 20px;
}
.profile-header__heading{
    @extend %my-pages-header__heading;
    @include font-size(30px);
    @include line-height(30);

    @include bp(48){
        margin-bottom: 10px;
        @include font-size(53px);
        @include line-height(53);
    }
    @include bp(75){
        @include font-size(60px);
        @include line-height(60);
    }
    @include bp(120){
        @include font-size(75px);
        @include line-height(75);
    }
}
.profile-header__preamble{
    @extend %my-pages-header__preamble;
    margin-top: 0;

    a {
        color: $black;
    }
    a:visited{
        color: $black;
    }
}
