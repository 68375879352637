.add-item-popup__main-container {
    display: unset;
    display: contents;
}

.add-item-popup {
    position: static;
    position: sticky;
    width: 100%;
    display: flex;
    top: 111px;
    z-index: 10;
    background-color: white;
    display: none;

    &--visible {
        display: block;
    }

    @include bp(75) {
        top: 0;
    }
}

.add-item-popup__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 20px 10px;
    align-items: center;
    border-bottom: 1px solid $black;
}

.add-item-popup__cross-button {
    align-self: flex-end;
    background-color: transparent;
    background-image: url('../img/icons/cross-rounded--small.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
    border: 0;
    height: 12px;
    width: 12px;

    @include bp(40) {
        height: 15px;
        width: 15px;
   }
}

.add-item-popup__text {
    font-size: 16px;
    text-align: center;
    @include bp(48) {
        max-width: 80%;
    };

    &--error {
        color: $red;
        font-weight: bold;
        font-size: 22px;
    }
}

.add-item-popup__title-text {
    font-weight: bold;
    font-size: 18px;
}

.add-item-popup__buttons-container {
    display: flex;
    justify-content: center; 
    width: 100%;
}

.add-item-popup__button {
    width: calc(50% - 8px);
    display: flex;
    align-items: center;
    justify-content: center;

    @include bp(48) {
        width: 250px;
        height: 48px;
    }
}

.add-item-popup__button--checkout {
    margin: 0 10px;
    background-color: #0271b0;
    border: none;

    &:hover {
        background: #0271b0;
    }
}