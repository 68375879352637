.product-grid-pagination__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-grid-pagination__desktop {
    display: none;

    @include bp(48) {
        display: block;
    }
}

.product-grid-pagination__link {
    margin-right: 8px;
    color: $black;
    @include font-size(17px);
    padding: 10px;
    text-decoration: none;
    cursor: pointer;

    &--selected {
        background-color: $black;
        color: $sand-lighter;
    }

    &:hover {
        background-color: $black;
        color: $sand-lighter;
    }
  }

.product-grid-pagination__desktop-navigation {
    color: $black;
    margin-right: 8px;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
  }

.product-grid-pagination__dots {
    margin-right: 8px;
}

.product-grid-pagination__mobile {
    display: flex;
    justify-content: space-around;
    width: 100%;
    background-color: $sand-light;
    padding: 8px 0;

    @include bp(48) {
        display: none;
    }
}

.product-grid-pagination__text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-page-pagination__mobile-text {
    display: inline-block;
    margin: 0;
}

.product-page-pagination__text {
    display: inline-block;
    margin: 0;
}

.product-grid-pagination__mobile-navigation {
    @include font-size(40px);
    text-decoration: none;
    color: $black;

    &--disabled {
        opacity: .5;
    }

}