.saldo {
    @extend %page-content;
    margin-top: 3.5em;

    @include bp(48) {
        border-radius: 5px;
        max-width: $content-width__percentage;
    }
    @include bp(75) {
        max-width: 970px;
    }
    @include bp(120) {
        width: 75%;
        max-width: 1200px;
    }
}

.saldo-heading {
    background: $giftcard-gray;
    color: $sand-lighter;
    @include font-size(23px);
    margin: 0;
    padding: 0.5em 20px;

    @include bp(48) {
        border-radius: 5px 5px 0 0;
        padding: 0.5em 30px;
    }
    @include bp(75) {
        padding: 0.5em 30px;
    }
}

.saldo-content {
    border: 1px solid $border-checkout;
    border-top: 0;
    padding: 20px 20px;
    border-radius: 0 0 5px 5px;
    background-color: $bg-checkout;

    @include bp(48) {
        padding: 30px 30px;
    }
    @include bp(75) {
        padding: 30px 30px;
    }
}

.saldo-form {
    margin-bottom: 30px;

    .card-detail{
        @include bp(48) {
            float: left;
            width: 50%;
        }
    }
}
.saldo-type__radio {
    @extend %visually-hidden;

    &:checked + .saldo-type__label {
        background: $giftcard-gray;
        color: $sand-lighter;
    }
}
.saldo-type__label {
    border: 2px solid $giftcard-gray;
    border-radius: 5px;
    color: $giftcard-gray;
    cursor: pointer;
    display: inline-block;
    @include font-size(15px);
    font-weight: 600;
    line-height: 1.2em;
    margin: 0px 10px 10px 0;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    transition: all 0.2s ease-in;
    min-width: 100px;

    &:hover {
        background: $giftcard-gray;
        color: $sand-lighter;
    }

    @include bp(75) {
        border: 3px solid $giftcard-gray;
        @include font-size(23px);
        padding: 10px 20px;
        margin-right: 30px;
        min-width: 120px;
    }

}
.saldo-form__fields {
    &--phone {
        @extend %clearfix;
    }
}
.card-detail {
    &--cardnumber {
        .card-detail__input {
            &[value=""] {
                & + .card-detail__label {
                    padding-left: 25px;

                    @include bp(75) {
                        padding-left: 35px;
                    }
                    &:before {
                        top: 0;
                        height: 20px;
                        width: 20px;

                        @include bp(75) {
                            background-position: center center;
                            height: 27px;
                            width: 29px;
                        }
                    }
                }
            }
            &:focus {
                & + .card-detail__label {
                    padding-left: 20px;
                    &:before{
                        top: 2px;
                        height: 15px;
                        width: 15px;
                    }
                }
            }
        }
        .card-detail__label {
            padding-left: 20px;

            &:before {
                background-image: url('../img/icons/card.svg');
                background-position: top center;
                background-repeat: no-repeat;
                background-size: 100%;
                content: "";
                display: inline-block;
                height: 15px;
                left: 0;
                position: absolute;
                top: 2px;
                width: 15px;
            }
        }

        .saldo-form--phone & {
            display: none;
        }

        .saldo-form & {
            @include bp(48){
                float: none;
                width: 100%;
            }
        }
    }
    &--cardphone {
        display: none;

        &:first-child {
            margin-top: 25px;
        }

        .card-detail__input {
            &[value=""] {
                & + .card-detail__label {
                    padding-left: 15px;

                    @include bp(75) {
                        padding-left: 20px;
                    }

                    &:before {
                        top: 0;
                        height: 18px;
                        width: 18px;

                        @include bp(75){
                            height: 25px;
                        }
                    }
                }
            }
            &:focus {
                & + .card-detail__label {
                    padding-left: 15px;

                    &:before {
                        top: 0;
                        height: 15px;
                        width: 15px;
                    }
                }
            }
        }

        .card-detail__label {
            padding-left: 15px;

            &:before {
                background-image: url('../img/icons/mobile--black.svg');
                background-position: top left;
                background-repeat: no-repeat;
                background-size: auto 100%;
                content: "";
                display: inline-block;
                height: 15px;
                left: 0;
                position: absolute;
                top: 0px;
                width: 15px;
            }
        }

        .saldo-form--phone & {
            display: block;
        }

        @include bp(48) {
            padding-right: 20px;
        }
        @include bp(75) {
            padding-right: 30px;
        }
    }
    &--pin {
        display: none;
        margin-top: 25px;

        .card-detail__input {
            &[value=""] {
                & + .card-detail__label {
                    padding-left: 25px;

                    @include bp(75){
                        padding-left: 35px;
                    }
                    &:before {
                        top: 0;
                        height: 18px;
                        width: 18px;

                        @include bp(75){
                            height: 27px;
                            width: 27px;
                        }
                    }
                }
            }

            &:focus {
                & + .card-detail__label {
                    padding-left: 20px;

                    &:before {
                        top: 0;
                        height: 15px;
                        width: 15px;
                    }
                }
            }
        }

        .card-detail__label {
            padding-left: 20px;

            &:before {
                background-image: url('../img/icons/key.svg');
                background-position: top left;
                background-repeat: no-repeat;
                background-size: auto 100%;
                content: "";
                display: inline-block;
                height: 15px;
                left: 0;
                position: absolute;
                top: 0;
                width: 15px;
            }
        }

        .saldo-form--phone & {
            display: block;
        }
    }
}

.saldo__captch {
    margin-top: 30px;
    transform: scale(0.925);
    transform-origin: 0 0;

    @include bp(40) {
        float: left;
        margin-right: 20px;
        transform: scale(1);
        transform-origin: 50% 50%;
    }

    @include bp(75) {
        margin-right: 30px;
    }
}

.saldo-preview {
    background-color: $sand-lighter;
    border-radius: 5px;
    box-shadow: 0 4px 0 $drop-shadow;
    margin: 0 20px;
    max-width: 490px;
    padding: 15px;
    position: relative;
    text-align: center;

    @include bp(75) {
        padding: 20px;
    }
}
.saldo-preview__title {
    @include font-size(15px);
    font-weight: 700;
    letter-spacing: 5px;
    margin: 0;
    text-transform: uppercase;

    @include bp(75) {
        @include font-size(23px);
    }
}
.saldo-preview__img {
    max-width: 150px;
    padding: 30px 0 15px;
    width: 50%;
}
.saldo-preview__value {
    display: block;
    @include font-size(15px);
    font-weight: 600;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);

    @include bp(75) {
        right: 20px;
        @include font-size(23px);
    }

    &--amount {
        @include font-size(23px);
        font-weight: 700;

        @include bp(75) {
            @include font-size(45px);
        }
    }
}
.saldo-preview__text {
    margin: 0;
    @include font-size(15px);

    &--bold {
        font-weight: 700;
    }

    @include bp(75) {
        @include font-size(23px);
    }
}
.card-status-error {
  margin: 0 auto;
  text-align: center;
  color: $red;
  @include font-size(23px);
  font-weight: 600;
}

.saldo__captcha {
    margin-top: 30px;
    transform: scale(0.925);
    transform-origin: 0 0;

    @include bp(48) {
        float: left;
        margin-right: 20px;
        transform: scale(1);
        transform-origin: 50% 50%;
    }

    @include bp(75) {
        margin-right: 30px;
    }
}
