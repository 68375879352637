.calendar {
    border-top: 1px solid $sand-light;
    padding-top: 22px;
    padding-bottom: 22px;

    @include bp(75) {
        padding-top: 60px;
        padding-bottom: 20px;
    }
}

.calendar__content {
    @extend %page-content;

    @include bp(48) {
        margin-top: -10px;
    }
}

.calendar__right-content {
    @include column_style(0, 2);

    @include bp(48) {
        background-color: $sand-lighter;
        @include column_style(48, 3);
    }
    @include bp(75) {
        float: right;
        @include column_style(75, 8);
    }
    @include bp(120) {
        @include column_style(120, 11);
    }
}

.calendar__heading {
    color: $black;
    @include font-size(37px);
    font-weight: 800;
    line-height: 1.1em;
    margin: 0;

    @include bp(48) {
        @include font-size(60px);
        line-height: 1em;
    }
    @include bp(75) {
        @include font-size(75px);
        line-height: 1.13333333em;
    }
    @include bp(120) {
        @include font-size(106px);
        line-height: 1em;
    }
}

.calendar__preamble {
    @include font-size(16px);
    line-height: 1.5;
    font-weight: 600;
    margin: 0;
    margin-top: 10px;

    @include bp(75) {
        @include font-size(20px);
    }
}

.event-link {
    color: inherit;
    float: right;
    font-style: italic;
} 


.calendar-month {
    border-bottom: 1px solid $sand-light;
}

.calendar-month__content {
    @extend %page-content;
    padding-top: 30px;
    padding-bottom: 30px;

    @include bp(120) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.calendar-month__heading {
    @include font-size(30px);
    font-weight: 300;
    line-height: 1.30434783em;
    margin: 0;
    @extend %full-width-content;

    @include bp(75) {
        @include font-size(37px);
        line-height: 1.21621622em;
    }
    @include bp(120) {
        @include font-size(53px);
        line-height: 1.22641509em;
    }
    @include bp(120) {
        @include font-size(53px);
    }    
}

.calendar-events {
    @extend %clearfix;
}
