.watch-list__overlay {
    display: block;
    position: fixed;
    z-index: 3000;
    background: rgba(0, 0, 0, .4);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

//MAIN POPUP

.watch-list-popup__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $sand-light;
    width: 100%;
    -webkit-backface-visibility: hidden;
    z-index: 5000;
    position: fixed;
    overflow: hidden;
    max-height: 100vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include bp(48) {
        max-width: 530px; 
    }

}

.watch-list-popup__content {
    max-width: 400px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 40px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.watch-list-popup__loader {
    display: none;
    width: 100px;
    height: 100px;
    border: 16px solid grey;
    border-top: 16px solid powderblue;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.watch-list-popup__close-button {
    display: block;
    appearance: none;
    background-color: transparent;
    background-image: url('../img/icons/cross-rounded--small.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
    border: 0;
    height: 16px;
    width: 16px;
    align-self: flex-end;
    padding: 28px;
}

.watch-list-popup__header-text {
    margin-top: 0;
}

.watch-list-popup__info-text {
    margin-bottom: 0;
}

.watch-list-popup__submit-error {
    color: red;
    font-weight: bold;
}

//FORM

.watch-list-form__button {
    &:disabled {
        opacity: .7;
    }
}

.watch-list-form__input {
    padding-top: 15px;
    padding-bottom: 10px;
}

.watch-list-form__captcha {
    margin-bottom: 10px;
    position: relative;
    height: 78px;
}

//RECEIPT PAGE
.watch-list-receipt__container {
    padding: 20px;
}

.watch-list-receipt__store-info {
    margin: 0;
}

.watch-list-receipt__success-message-container {
    background: white;
    color: #418141;
    padding: 20px;
}

.watch-list-receipt__success-message {
    &--bold {
        font-weight: 550;
    }
}

.watch-list-receipt__button {
    text-align: center;
    margin-top: 20px;
}

.watch-list-receipt__link {
    color: #418141;
    font-weight: bold;
}

//PRODUCT CARD

.watch-list-product-card__container {
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
}

.watch-list-product-card__product-image {
    width: 60px;
    display: inline-block;
    margin-right: 10px;

    @media (min-width: 350px) {
        width: 70px;
    }
}

.watch-list-product-card__product-info {
    display: inline-block;
    vertical-align: top;
    width: 70%;
}

.watch-list-product-card__title {
    margin-top: 0;
    font-weight: bold;
    text-decoration: underline;
}

.watch-list-product-card__author {
    margin-top: 0;
}

.watch-list-product-card__product-format {
    margin-top: 0;
}