.our-stores{
    padding: 25px 0;

    @include bp(48){
        padding: 40px 0;
    }
}
.our-stores-header{
    @extend %page-content;
}
.our-stores-header__heading{
    @extend %full-width-content;
    @include font-size(30px);
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 1.16666666667em;
    margin: 0;

    @include bp(48){
        @include font-size(53px);
        font-weight: 800;
        line-height: 1.1320754717em;
    }

    @include bp(75){
        @include font-size(60px);
        line-height: 1.08333333333em;
    }

    @include bp(120){
        @include font-size(75px);
        line-height: 1.06666666667em;
    }
}

.our-stores-header__sub-heading {
    @include font-size(23px);
    font-weight: 400;
    line-height: 1.30434782609em;
    margin: 15px 0;
    word-wrap: break-word;
    padding: 0 20px;

    @include bp(48) {
        @include font-size(28px);
        line-height: 1.16666666667em;
        padding: 0 30px;
    }
    @include bp(75) {
        @include font-size(32px);
        line-height: 1.21621621622em;
        padding: 0 15px;
    }
    @include bp(120) {
        @include font-size(35px);
        line-height: 1.22641509434em;
    }
}

.our-stores-header__info-text {
    @include font-size(16px);
    font-family: $font-family--secondary;
    line-height: 1.5625em;
    margin: 20px 0;
    padding: 0 20px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: Arial, sans-serif;
        font-weight: bold;
        font-family: $font-family--primary;
    }

    h3 {
        @include font-size(23px);
        line-height: 1.30434782609em;

        @include bp(120) {
            @include font-size(30px);
            line-height: 1.16666666667em;
        }
    }

    p {
        font-family: $font-family--secondary;
        margin: 5px 0;
        @include bp(48) {
            @include font-size(17px);
            line-height: 1.76470588235em;
        }

        @include bp(75) {
            @include font-size(18px);
            line-height: 1.94444444444em;
		}

        @include bp(120) {
            @include font-size(20px);
            line-height: 1.7em;
		}
    }

    a {
        color: $black;
        font-weight: 600;
        text-decoration: underline;

        &:hover {
            color: $red;
		}
    }

    img {
        max-width: 100%;
    }
}
.stores-list-wrapper{
    @extend %page-content;
    padding-top: 25px;

    @include bp(75){
        padding-top: 20px;
    }
    @include bp(120){
        padding-top: 50px;
    }
}
.stores-list-heading{
    @extend %full-width-content;
    @include font-size(30px);
    font-weight: 300;
    line-height: 1.30434783em;
    margin: 0;

    @include bp(75){
        @include font-size(37px);
        line-height: 1.21621622em;
    }
    @include bp(120){
        @include font-size(53px);
        line-height: 1.22641509em;
    }
}
.stores-list{
    list-style: none;
    margin: 0;
    padding: 0;
}
.stores-list__item{
    @include column_style(0, 2);
    border-bottom: 1px solid $sand-light;
    padding-top: 20px;
    padding-bottom: 20px;


    &:last-child{
        border-bottom: 0;
    }

    @include bp(40){
        float: left;
        max-width: 50%;
        padding: 30px;
        height: 250px;

        &:nth-last-child(-n+2){
            border-bottom: 0;
        }
    }
    @include bp(48){
        max-width: 33.33333%;
        padding: 30px 0;
    }
    @include bp(75){
        padding: 15px 0;
        &:nth-last-child(-n+3){
            border-bottom: 0;
        }

    }
}
.store-item__city{
    @include font-size(23px);
    font-weight: 300;
    line-height: 1.08695652em;
    margin: 0;

    @include bp(120){
        @include font-size(30px);
        line-height: 1.16666667em;
    }
}
.store-item__name{
    @include font-size(23px);
    font-weight: 700;
    line-height: 1.30434782609em;
    margin: 0;

    @include bp(120){
        @include font-size(30px);
        line-height: 1.16666667em;
    }

    a{
        color: $black;
    }
}
.store-item__hours{

    @include font-size(15px);
    line-height: 1.2em;
    position: relative;
    margin-top: 10px;

    p{
        margin: 0;
    }

    @include bp(120){
        @include font-size(23px);
        margin-top: 15px;
    }

}
.store-item-hours__day{
    background-color: $sand-lighter;
    display: inline-block;
    font-weight: 700;
    padding-right: 5px;
    position: relative;
    z-index: 1;
}
.store-item-hours__time{
    left: 0;
    padding-left: 100px;
    position: absolute;


    &:before{
        background: $border-store-hours;
        content: "";
        display: inline-block;
        height: 1px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 95px;
    }
}
.store-item__address{
    color: $black;
    display: inline-block;
    @include font-size(15px);
    line-height: 1.2em;
    margin-top: 10px;
    text-indent: 18px;
    position: relative;

    &:before{
        background-image: url(../img/icons/0073-location2.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
        content: "";
        display: inline-block;
        height: 18px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
    }

    @include bp(120){
        @include font-size(23px);
        margin-top: 15px;
    }
}
.store-item__phone{
    display: block;
    @include font-size(15px);
    line-height: 1.2em;
    margin-top: 10px;

    @include bp(120){
        @include font-size(23px);
        margin-top: 15px;
    }
}
