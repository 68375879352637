.product-videos__container {
    margin-top: 20px;

    @include bp(48) {
        margin-top: 40px;
    }
}

.product-videos__half-width-wrapper {
    @include bp(75) {
        width: 48%;
        display: inline-block;
    }
}

.product-videos__iframe-wrapper {
    float: none;
    clear: both;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    margin-top: 20px;

    &--no-margin-top {
        @include bp(75) {
            margin-top: 0;
            padding-top: 0;
        }

    }
}

.product-videos__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}