.no-touch {

    // .carousel-list__item{
    //  visibility: hidden;
    // }

}

.touch {
    .event-carousel {
        overflow: visible;
    }

    .event-carousel-content {
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }
}

.event-carousel {
    border-top: 1px solid $sand-light;
    overflow: hidden;
    padding-top: 20px;
    padding-bottom: 20px;

    @include bp(120) {
        padding: 40px 0;
    }
}

.event-carousel-header {
    @extend %page-content;
}

.event-carousel-header-content {
    color: $black;
    @extend %clearfix;
    @extend %full-width-content;
    padding-top: 0;
    padding-bottom: 25px;
}

.event-carousel-header__heading {
    @include font-size(18px);
    line-height: 1.2em;
    margin: 0;

    @include bp(40) {
        float: left;
    }

    @include bp(48) {
        @include font-size(23px);
    }
    @include bp(75) {
        @include font-size(30px);
    }
}

.event-carousel-header__heading-link {
    color: $black;
    text-decoration: none;

    &:visited {
        color: $black;
    }
}

.event-carousel-header__show-all {
    color: $black;
    display: inline-block;
    @include font-size(13px);
    font-weight: 600;
    margin-top: 3px;
    padding-right: 16px;
    position: relative;
    text-decoration: none;

    &:after {
        background-image: url('../img/icons/chevron_right.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 95%;
        content: ' ';
        display: inline-block;
        height: 10px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;

        @include bp(48) {
            height: 20px;
            width: 22px;
        }
    }

    @include bp(40) {
        float: right;
        margin-top: 0;
    }

    @include bp(48) {
        @include font-size(18px);
        line-height: 25px;
        padding-right: 30px;
    }
    @include bp(75) {
        line-height: 35px;
    }
}

.event-carousel-nav {
    @extend %clearfix;
}

.event-carousel-content {
    @extend %clearfix;
    @extend %page-content;
    overflow: visible;

    .slick-list {
        overflow: visible;
    }

    &.slick-slider .slick-list {
        overflow: visible;
    }

    .slick-slide {
        opacity: .2;

        &.slick-active {
            opacity: 1;
        }
    }

    &:hover {
        .slick-prev {
            opacity: 1;

            &.slick-disabled {
                opacity: .5;
            }
        }

        .slick-next {
            opacity: 1;

            &.slick-disabled {
                opacity: .5;
            }
        }
    }

    .slick-prev {
        background-color: transparent;
        border: 0;
        color: transparent;
        display: none !important;
        font-size: 0;
        height: 120px;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -105px);
        transition: opacity .3s;
        z-index: 1;
        width: 80px;

        &:before {
            background-image: url('../img/icons/slider_prev.svg');
            background-position: left center;
            background-repeat: no-repeat;
            background-size: auto 90%;
            content: ' ';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        @include bp(75) {
            display: block !important;
        }
        @include bp(120) {
            height: 160px;
            width: 100px;
        }
    }

    .slick-next {

        background-color: transparent;
        border: 0;
        color: transparent;
        display: none !important;
        font-size: 0;
        height: 120px;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(50%, -105px);
        transition: opacity .3s;
        z-index: 1;
        width: 80px;

        &:before {
            background-image: url('../img/icons/slider_next.svg');
            background-position: right center;
            background-repeat: no-repeat;
            background-size: auto 90%;
            color: $black;
            content: ' ';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        @include bp(75) {
            display: block !important;
        }
        @include bp(120) {
            height: 160px;
            width: 100px;
        }
    }
}
