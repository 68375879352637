.profile-inspiration{
  @extend %page-content;
  padding: 20px;

  @include bp(48){
    padding-bottom: 30px;
    padding-top: 30px;
  }
  @include bp(75){
    padding-bottom: 50px;
    padding-top: 50px;
  }
}
.profile-inspiration__heading{
  @extend %my-pages-header__sub-heading;
  margin-bottom: 15px;
  display: inline-block;
}
.profile-inspiration__extra-info{
  @include font-size(15px);
  @include line-height(15);
  margin-top: 0;
  font-weight: 300;

  @include bp(75){
    @include font-size(23px);
    @include line-height(23);
  }
}
.type-of-inspiration-wrapper{
  @extend %clearfix;
}
.type-of-inspiration {
  padding: 15px 0;

  @include bp(48){
    width: 50%;
    min-height: 70px;
    float: left;
  }
  @include bp(75){
    //width: 33.3333%;
    padding-bottom: 30px;
  }
}
.profile-inspiration__checkbox{
  @extend %visually-hidden;

  &:checked + .profile-inspiration__label{

    &:before{
      background-image: url(../img/icons/0273-checkmark.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 80%;
    }
  }
}
.profile-inspiration__label{
  cursor: pointer;
  display: inline-block;
  @include font-size(15px);
  padding-left: 34px;
  position: relative;
  max-width: 300px;

  &:before {
    border: 2px solid $black;
    border-radius: 5px;
    content: "";
    cursor: pointer;
    display: inline-block;
    height: 25px;
    left: 0;
    margin-right: 10px;
    position: absolute;
    width: 25px;
    transform: translateY(-50%);
    top: 50%;

    @include bp(75){
      border: 3px solid $black;
      height: 35px;
      width: 35px;
      top: 0;
      transform: none;
    }
  }
  @include bp(48){
    max-width: inherit;
    padding-right: 15px;
  }
  @include bp(75){
    @include font-size(23px);
    padding-left: 44px;
    padding-right: 20px;
  }
}
.profile-inspiration-from-store-wrapper{
  padding: 15px 0;

  @include bp(48){
    width: 50%;
    min-height: 70px;
    float: left;
  }
  @include bp(75){
    padding-top: 0;
    padding-bottom: 30px;
  }
}
.update-inspiration__submit{
  background: transparent;
  border: 2px solid $black;
  border-radius: 5px;
  display: inline-block;
  @include font-size(15px);
  font-weight: 600;
  height: 40px;
  padding: 5px;
  transition: all 0.2s ease-in;
  max-width: 100px;
  width: 100%;

  @include bp(75){
    border: 3px solid $black;
    @include font-size(23px);
    height: 55px;
    max-width: 150px;
  }

  &:hover{
    background: $black;
    color: $sand-lighter;
  }
}
.profile-inspiration-from-store__legend {
  margin-bottom: 5px;
  font-style: italic;
  @include font-size(15px);
  font-weight: 300;
  line-height: 1.33333333em;

  @include bp(75){
    @include font-size(23px);
  }
}
.inspiration-form__reset{
  color: $black;
  position: absolute;
  right: 0;
  transform: translateY(-50%);
  top: 50%;
  @include font-size(15px);

  @include bp(48){
    position: static;
    transform: none;
    margin-left: 20px;
  }
  @include bp(75){
    @include font-size(23px);
  }
}
.profile-inspiration-submit-wrapper{
  @extend %clearfix;
  margin-top: 15px;
  position: relative;

  @include bp(48){
    margin-top: 10px;
  }
  @include bp(75){
    margin-top: 5px;
  }
}
.profile-inspiration-form{
  max-width: 750px;

  @include bp(75){
    max-width: 1200px;
  }
  .custom-select-wrapper{
    width: 100%;
    margin-right: 0;
    min-width: inherit;
    float: none;
    height: 40px;
    margin-bottom: 0;

    @include bp(75){
      height: 55px;

      &:after{
        width: 55px;
        border-left: 3px solid #2B2A27;
      }
    }
  }
}
.inspiration-stores{
  @include font-size(15px);

  @include bp(75){
    @include font-size(23px);
    margin-left: 20px;
  }
}