.forgot-pwd{

}
.forgot-pwd__content{
    padding-top: 20px;

    @include bp(48){
        padding-top: 65px;
    }
    @include bp(75){
        padding-top: 80px;
    }
    @include bp(120){
        padding-top: 105px;
    }
}
.forgot-pwd-header{
    @extend %page-content;
    padding: 20px;

    @include bp(120){
        max-width: 1500px;
    }
}
.forgot-pwd-header__heading{
    @extend %my-pages-header__heading;
}
.forgot-pwd-header__preamble{
    @extend %my-pages-header__preamble;

    a{
        color: inherit;
    }
}
.forgot-pwd-form{
    background: $sand-light;
    @extend %clearfix;
    margin: 0 auto 30px;
    padding: 20px 20px 20px;
    position: relative;

    @include bp(48){
        padding: 30px;
    }
    @include bp(75){
        padding: 30px 30px;
    }
}
.forgot-pwd-form__content{

    @include bp(48){
        margin: 0 auto;
        width: 93%;
    }
    @include bp(120){
        max-width: 1500px;
        padding: 0 20px;
    }
}
.forgot-pwd-form__submit{
    background: transparent;
    border: 2px solid $black;
    border-radius: 5px;
    display: inline-block;
    @include font-size(15px);
    font-weight: 600;
    height: 40px;
    margin: 15px 5px 0 0;
    padding: 5px;
    transition: all 0.2s ease-in;
    max-width: 150px;
    width: 50%;

    @include bp(48){
        float: left;
        margin-top: 30px;
        width: 100%;
    }
    @include bp(75){
        border: 3px solid $black;
        @include font-size(23px);
        height: 55px;
        max-width: 200px;
        margin-top: 30px;
    }

    &:hover{
        background: $black;
        color: $sand-lighter;
    }
}
