.sales-badge {
    background-color: #ffed00;
    font-weight: bold;
    padding: 4px 6px;
    font-size: 14px;
    width: auto;
    text-align: left;
}

.sales-badge--compact {
    font-size: 10px;
    line-height: 1;
}

.sales-badge__title {
    color: #e63312;
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 1px;
    @include bp(75) {
        font-size: 20px;
        line-height: 24px;
    }
}

.sales-badge__desc {
    color: #41403d;
    font-size: 14px;
    line-height: 16px;
    @include bp(75) {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
    }
}
