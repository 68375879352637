.product-grid {
    @extend %page-content;
    margin-top: 30px;
    margin-bottom: 30px;
    position: relative;

    @include bp(100) {
        width: 70%;
    }
}

.product-grid--loading {
    .product-grid__items {
        @extend %visually-hidden;
    }

    height: 200px;

    &:after {
        content: '';
        @include spinner(50px);
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -25px 0 0 -25px;
    }
}

.product-grid__items {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.product-grid__item {
    display: flex;
    margin-bottom: 30px;
    padding: 0 20px;
    width: 50%;
    float: left;

    @include bp(48) {
        width: 25%;
    }
    @include bp(75) {
        width: 20%;
    }
}
