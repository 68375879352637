.order-tracking-popup{
	max-height: 100vh;

	@include bp(40){
		max-height: calc(100vh - 200px);
	}
}

.order-tracking-popup .akb-popup-body{
	font-family: $font-family--primary;
}

.akb-track-order-popup__summary{
	@include font-size(18px);
	margin-bottom: 15px;

	@include bp(40) {
		@include font-size(20px);
	}

	p{
		margin-bottom: 15px;
	}
}

.akb-track-order-popup--order-number,
.akb-track-order-popup--order-number-label{
	@include font-size(18px);

	@include bp(40) {
		@include font-size(23px);
	}
}

.akb-track-order-popup--order-number-label{
	font-weight: 100;
	margin-right: 10px;
}
.akb-track-order-popup--order-number{
	font-weight: 600;
}

.akb-track-order-popup__package-list{
	list-style-position: outside;
	padding-left: 0;

	.my-orders-list-item--track{
		margin-left: 0;
		margin-top: 20px;
	}
}

.order-tracking-popup .akb-track-order-popup__package-description{
	margin: 0;
}

.akb-track-order-popup__package-list-item{
	margin-bottom: 30px;
	padding-top: 15px;
	border-top: 1px solid $border-total;
}

.akb-track-order-popup__article-list{
	list-style: none;
	padding-left: 0;

	li{
		margin:10px 0;
	}
}
