.reservation-card__container {
    padding: 15px 0;
    border-bottom: 1px solid $gray;
    position: relative;

    &--last-product {
        border-bottom: none;
    }
}

.reservation-card__product-image {
    width: 60px;
    display: inline-block;
    margin-right: 10px;

    @media (min-width: 350px) {
        width: 70px;
    }
}

.reservation-card__info-text {
    display: inline-block;
    vertical-align: top;
    width: 62%;
}

.reservation-card__title-link {
    color: $black;
    @include font-size(15px);
    font-weight: 700;
    line-height: 1.4em;

    &--out-of-stock {
        color: red;
        text-decoration: line-through;
    }
}

.reservation-card__author {
    @include font-size(15px);
    font-weight: 300;
    line-height: 1.4em;
    min-height: 1.4em;
}

.reservation-card__product-format {
    margin: 0;
    @include font-size(15px);
    font-weight: 600;
    line-height: 1em;
    display: inline-block;
}

.reservation-card__price {
    @include font-size(18px);
    font-weight: 700;
    display: inline-block;
    position: absolute;
    right: 0;
}

.reservation-card__price-normal {
    order: 2;
}

.reservation-card__price-normal--small {
    @include font-size(15px);
    order: 0;
    float: left;
    margin-right: 5px;
    font-weight: 300;

    &:before {
        content: '(';
    }

    &:after {
        content: ')';
    }
}

.reservation-card__price-discount {
    order: 1;
    color: $red;
    margin-right: 7px;
}

.reservation-card__price-discount--member {
    @include font-size(18px);
    float: left;
    color: $friend-price;
    padding-left: 27px;
    position: relative;

    &.reservation-card__price-discount--is-member {
        @include font-size(18px);
        margin-right: 0;
    }

    &:before {
        @include member-icon();
    }
}

.reservation-card__unit-price {
    @include font-size(13px);
    line-height: 25px;
    position: absolute;
    top: 30px;
    right: 0;

    @include bp(40) {
        float: right;
        position: relative;
        top: 0;
        margin-left: 5px;
        margin-right: 5px;
    }
}

.reservation-card__select {
    position: absolute;
    right: 0;
    top: 50px;
}

.reservation-card__remove-button {
    appearance: none;
    background-color: transparent;
    background-image: url('../img/icons/SVG/0174-bin2.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
    border: 0;
    height: 15px;
    width: 15px;
    float: right;
    margin-right: 8px;

    &:hover {
        cursor: pointer;
    }
}