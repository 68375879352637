.search-block {
    position: static; //fallback for internet explorer
    position: relative;
    padding: 0;
    width: 100%;
   
    @include bp(75) {
        background-color: white;
        margin-left: 20px;
        max-width: 550px;
    }
}

.search-input__label {
    display: inline-block;
    font-weight: 600;
    line-height: 30px;
    position: absolute;
    z-index: 1;
    color: $white;
    padding-left: 30px;
    top: -2px;

    &:hover {
        cursor: pointer;
    }

    &:before {
        background-color: transparent;
        background-image: url('../img/icons/0135-search--inverted.svg');
        background-position: 0 center;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        content: '';
        height: 30px;
        width: 20px;

        position: absolute;
        left: -2px;
        top: 0;

        transition: all .3s ease-in;
        transition-delay: .2s;
        width: 30px;

    }

    > span {
        transition: all .3s ease-in;
        transition-delay: .2s;
        display: none;
    }

    @include bp(48) {
        &:before {
            left: 0;
        }
    }

    @include bp(75) {
        color: $black;

        &:before {
            background-image: url('../img/icons/0135-search.svg');
            background-position: center center;
            height: 50px;
            left: -40px;
            width: 40px;
            top: -30px;
        }

        position: relative;
        top: 0;
        transform: translateY(0%);
        @include font-size(18px);
        line-height: 50px;
        padding-left: 0;
    }
}

.search-topic__button {
    font-weight: 600;
    position: relative;
    z-index: 1;
    display: inline-block;
    border: 0;
    width: 40px;
    height: 100%;
    background: transparent;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;

    @include bp(75)  {
        width: 50px;
        background: $black;
        position: absolute;
        right: 0;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    &:hover {
        cursor: pointer;
    }

    &:after {
        content: '';
        display: inline-block;
        background-image: url('../img/icons/0135-search.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        height: 20px;
        transition: all .3s ease-in;
        transition-delay: .2s;
        width: 20px;
        left: 50%;
        top: 50%;
        margin-top: -10px;
        margin-left: -10px;
        position: absolute;

        @include bp(75)  {
            position: absolute;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            background-image: url('../img/icons/0135-search--inverted.svg');
        }
    }
}

.search-wrapper {
    width: 100%;
    height: 45px;
    display: flex;

    @include bp(75) {
        height: 40px;
    }
}

.search-input {
    border: 1px solid $black;
    padding: 0;
    width: 100%;
    border-right: 0;
    border-radius: 3px 0 0 3px;
    padding: 10px;
   
    @include font-size(16px);

    &:focus::-webkit-input-placeholder {
        color: transparent;
    }

    &:focus::-moz-placeholder {
        color: transparent;
    }

    &:focus:-ms-input-placeholder {
        color: transparent;
    }

    &:focus:-moz-placeholder {
        color: transparent;
    }

    &::-ms-reveal,
    &::-ms-clear {
        display: none;
    }

    &:hover {
        cursor: pointer;
    }

    &:focus {
        cursor: auto;
    }

    &:focus + .clear-search-button {
        opacity: 1;
    }

    &::-webkit-input-placeholder {
        color: black;
        font-weight: 400;
        @include font-size(16px);

        @include bp(75) {
            @include font-size(18px);
            width: 95%;
        }
    }

    &:-moz-placeholder {
        color: black;
        font-weight: 400;
        @include font-size(16px);

        @include bp(75) {
            @include font-size(18px);
        }
    }

    &::-moz-placeholder {
        color: black;
        font-weight: 400;
        @include font-size(16px);

        @include bp(75) {
            @include font-size(18px);
        }
    }

    &:-ms-input-placeholder {
        color: black;
        font-weight: 400;
        @include font-size(16px);

        @include bp(75) {
            @include font-size(18px);
        }
    }

    @include bp(75) {
        color: $black;
        width: 90%;

        @include font-size(18px);

        &:focus {
            padding-right: 60px;
        }
    }
}

.search-topic-fieldset {
    border: 0;
    margin: 0;
    padding: 0;
    position: relative;
    transition: transform .3s ease-in;
    height: 100%;

    &--active {
        .search-input-topic__label:before {
            opacity: 0;
            transform: translateX(50%);
            transition-delay: 0s;
        }

        .search-button {
            transition-delay: .2s;
        }

        .clear-search-button {
            transition-delay: .2s;
        }
    }
}

.search-topic__input {
    background-color: transparent;
    border: 0;
    position: absolute;
    width: calc(100% - 40px);
    top: 50%;
    transform: translateY(-50%);
    padding: 0 20px 0 0;
    margin-left: -5px;

    &[type='search'] {
        box-sizing: border-box;
    }

    @include bp(75) {
        margin-left: 0;
    }

    &:hover {
        cursor: pointer;
    }

    &:focus {
        cursor: auto;
    }

    &:focus + .search-button {
        opacity: 1;
        transform: translateX(0%);
    }

    &:focus + .clear-search-button {
        opacity: 1;
    }

    &::-webkit-input-placeholder {
        color: $black;
        font-weight: 600;
        font-style: italic;
    }

    &:-moz-placeholder {
        color: $black;
        font-weight: 600;
        font-style: italic;
    }

    &::-moz-placeholder {
        color: $black;
        font-weight: 600;
        font-style: italic;
    }

    &:-ms-input-placeholder {
        color: $black;
        font-weight: 600;
        font-style: italic;
    }

    @include font-size(23px);
}

.search-button {
    background-color: $red;
    background-image: url('../img/icons/0135-search--inverted.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 40% auto;
    height: 100%;
    width: 45px;
    border-radius: 0 3px 3px 0;
    
    @include bp(75) {
        background-image: url('../img/icons/0135-search--inverted.svg');
        width: 40px;
    }
}

.clear-search-button {
    position: absolute;
    top: 50%;
    right: 52px;
    display: block;
    opacity: 0;
    transition: all .3s ease-in;
    transition-delay: 0s;
    transform: translateY(-50%);
    border: 0;
    width: 15px;
    height: 15px;
    border-radius: 10px;

    &:before {
        content: '';
        display: block;
        background-image: url('../img/icons/cross-rounded--small.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-color: transparent;
        background-size: 27%;
        height: 45px;
        width: 45px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    @include bp(75) {
        width: 20px;
        height: 20px;
        background: $black;
        right: 12%;

        &:before {
            background-image: url('../img/icons/cross-rundad--inverted.svg');
            background-size: 41%;
            width: 20px;
            height: 20px;
            transform: translateX(-50%) translateY(-50%);
        }
    }
}

.search-result-text {
    @include font-size(30px);
    font-weight: 300;
    margin: 20px 0 15px;
    padding: 0 20px;
    text-align: center;
    word-wrap: break-word;

    @include bp(40) {
        padding: 0 30px;
        margin: 30px 0 15px;
    }

    @include bp(75) {
        @include font-size(37px);
        margin: 30px 0;
        padding: 0 15px;
    }

    &.no-results {
        margin-top: 0;

        @include bp(75) {
            margin-top: 20px;
        }

        &--hidden {
            display: none;
        }
    }

    a {
        color: #2b2a27;
        font-weight: 600;
        text-decoration: underline;
    }

    .total-number-search-hits {
        font-weight: 600;
    }

    .query-string {
        font-weight: 600;
    }
}

.no-results-extra {
    font-family: $font-family--secondary;

    @include font-size(16px);
    font-weight: normal;
}

.search-topic-wrapper {
    display: inline-block;
    position: relative;
    width: 100%;
    background: $sand-lighter;
    border-radius: 5px;
    height: 32px;
    float: left;
    margin-bottom: 20px;

    > label {
        margin-left: 50px;
    }

    @include bp(75) {
        height: 40px;
        width: calc(40% - 32px);
        margin-right: 30px;
        margin-bottom: 20px;
        padding-left: 10px;
    }

    .search-topic__input,
    .search-topic__label {
        @include font-size(16px);

        &:before {
            background-size: 50% auto;
        }
    }
}

.search-block__autocomplete {
    position: relative;
}
