.text-module-section {
  @extend %clearfix;
  @extend %page-content;

  @include bp(48){
    max-width: 768px;
  }
  @include bp(75){
    max-width: 1150px;
  }
  @include bp(120){
    width: 75%;
    max-width: 1500px;
  }
}
.text-module__content {
  padding: 15px 0;

  @include bp(48){
    padding: 15px 30px;
  }
  @include bp(75){
    padding: 30px 0;
  }
  @include bp(120){
    padding: 40px 0;
  }
}
.info-article__body {
  &--text-module {
    margin-top: 0;
  }
}