#cookie-consent-modal {
    display: none;
}

.consent-modal__overlay {
    display: block;
    position: fixed;
    z-index: 3000;
    background: rgba(0, 0, 0, .4);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.consent-modal__wrapper {
    background-color: $sand-light;
    -webkit-backface-visibility: hidden;
    top: 30%;
    z-index: 5000;
    position: fixed;
    overflow: hidden;
    padding: 50px 10px 20px 10px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: content-box;

    @include bp(48) {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 350px;
        width: 450px;
        padding: 50px 30px 30px 30px;
    }
}

.consent-modal__content {
    background-color: $sand-lighter;
    -webkit-backface-visibility: hidden;
    z-index: 5000;
    overflow: hidden;
    padding: 0 10px 10px;
    height: 300px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include bp(48) {
        height: 350px;
        width: 450px;
    }
}

.consent-modal__close-button {
    display: block;
    appearance: none;
    background-color: transparent;
    background-image: url(../img/icons/0272-cross.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
    border: 0;
    height: 15px;
    width: 15px;
    align-self: flex-end;
    margin-right: 10px;
    position: absolute;
    top: 20px;
    right: 10px;
}

.consent-modal__settings-link {
    cursor: pointer;
    text-decoration: underline;
    color: #007eb6;

}

.consent-modal__cookie-icon {
    width: 30%;
}