.gift {
    padding-top: 25px;
    padding-bottom: 60px;

    @include bp(48) {
        padding-top: 65px;
        padding-bottom: 120px;
    }
    @include bp(75) {
        padding-top: 80px;
    }
    @include bp(120) {
        padding-top: 105px;
    }
}

.gift__header {
    padding: 0 20px;
    margin: 0 auto;
    text-align: center;

    @include bp(48) {
        padding: 0;
        max-width: 648px;
    }
    @include bp(75) {
        padding: 0;
        max-width: 970px;
    }
    @include bp(120) {
        padding: 0;
        max-width: 1200px;
        width: 75%;
    }
}

.gift__heading {
    @include font-size(30px);
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 1.16666666667em;
    margin: 0;
    padding: 0;
    word-wrap: break-word;

    @include bp(48) {
        @include font-size(53px);
        line-height: 1.1320754717em;
    }
    @include bp(75) {
        @include font-size(60px);
        line-height: 1.08333333333em;
        margin: 0 auto;
    }
    @include bp(120) {
        @include font-size(75px);
        line-height: 1.06666666667em;
        margin: 0 auto;
    }
}

.gift__subheading {
    @include font-size(23px);
    font-weight: 300;
    line-height: 1.30434782609em;
    margin: 15px 0 0;
    word-wrap: break-word;

    @include bp(48) {
        @include font-size(30px);
        line-height: 1.16666666667em;
        margin: 5px 0 0;
    }
    @include bp(75) {
        @include font-size(37px);
        line-height: 1.21621621622em;
        margin: 5px auto 0;
    }
    @include bp(120) {
        @include font-size(53px);
        line-height: 1.22641509434em;
        margin: 30px auto 0;
    }
}

.gift__preamble {
    @include font-size(15px);
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 1.66666666667;
    margin: 15px 0 0;
    padding: 0 20px;
    text-align: center;

    @include bp(48) {
        @include font-size(23px);
        line-height: 1.30434782609em;
        margin: 30px auto 0;
        max-width: 648px;
        padding: 0;
    }
    @include bp(75) {
        line-height: 1.52173913043em;
        margin: 30px auto 0;
        max-width: 970px;
        padding: 30px 0;
        position: relative;

        &:before {
            background: $sand-light;
            content: '';
            display: block;
            height: 1px;
            left: 50%;
            max-width: 320px;
            position: absolute;
            top: 0;
            transform: translateX(-50%);
            width: 100%;
        }
    }
    @include bp(120) {
        @include font-size(30px);
        line-height: 1.5em;
        margin: 35px auto 0;
        width: 75%;
        max-width: 1200px;
    }

}

.giftcard {
    @extend %page-content;
    margin-top: 1.5em;

    @include bp(48) {
        border-radius: 5px;
        margin-top: 50px;
        max-width: $content-width__percentage;
    }
    @include bp(75) {
        max-width: 970px;
    }
    @include bp(120) {
        width: 75%;
        max-width: 1200px;
    }
}

.giftcard--disabled > * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    opacity: .2;
}

.giftcard-heading {
    background: $giftcard-gray;
    color: $sand-lighter;
    @include font-size(23px);
    margin: 0;
    padding: .5em 20px;

    @include bp(48) {
        border-radius: 5px 5px 0 0;
        padding: .5em 30px;
    }
    @include bp(75) {
        padding: .5em 30px;
    }
}

.giftcard-form {
    padding: 20px;
    border: 1px solid $border-checkout;
    border-top: 0;
    border-radius: 0 0 5px 5px;
    background-color: $bg-checkout;

    @include bp(48) {
        padding: 30px;
    }
    @include bp(75) {
        padding: 30px;
    }
}

.card-type__radio {
    @extend %visually-hidden;

    &:checked + .card-type__label {
        background: $giftcard-gray;
        color: $sand-lighter;
    }
}

.card-type__label {
    border: 2px solid $giftcard-gray;
    border-radius: 5px;
    color: $giftcard-gray;
    cursor: pointer;
    display: inline-block;
    @include font-size(15px);
    font-weight: 600;
    line-height: 1.2em;
    margin: 0 10px 5px 0;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    transition: all .2s ease-in;
    min-width: 100px;

    &:hover {
        background: $giftcard-gray;
        color: $sand-lighter;
    }

    @include bp(75) {
        border: 3px solid $giftcard-gray;
        @include font-size(23px);
        margin-right: 30px;
        padding: 10px 20px;
        min-width: 120px;
    }

}

.card-details {
    position: relative;
}

.card-details__info {
    @include font-size(12px);
    margin: 2px 0 0;

    @include bp(48) {
        max-width: 50%;
    }
}

.card-detail {
    position: relative;
    margin: 40px 0 0;
    width: 100%;

    &:first-child {
        margin-top: 35px;
    }

    @include bp(48) {
        width: 50%;
    }

    &--mail {
        max-height: 4em;
        opacity: 1;

        .giftcard-form--sms & {
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            display: none;
        }

        .giftcard-form--mail & {
            animation-name: inputSlideUp;
            animation-duration: 400ms;
            animation-timing-function: cubic-bezier(.175, .885, .32, 1.275);
            animation-fill-mode: backwards;
        }
    }

    &--phone {
        display: none;
        max-height: 0;
        opacity: 0;
        overflow: hidden;

        .giftcard-form--sms & {
            animation-name: inputSlideUp;
            animation-duration: 400ms;
            animation-timing-function: cubic-bezier(.175, .885, .32, 1.275);
            animation-fill-mode: backwards;
            max-height: 4em;
            opacity: 1;
            overflow: visible;
            display: block;
        }
    }

    &--text {
        @include bp(48) {
            width: 75%;
        }
    }

    &--date,
    &--time {
        .card-detail__input {
            background-position: center right 10px;
            background-repeat: no-repeat;
            background-size: 20px;
        }
    }

    &--date {
        .card-detail__input {
            background-image: url('../img/icons/calendar.svg');
        }
    }

    &--time {
        .card-detail__input {
            background-image: url('../img/icons/clock.svg');
        }
    }
}

.card-detail__input {
    border-radius: 5px;
    height: 40px;
    padding: 0 15px;
    width: 100%;

    background-color: #fff;
    border: 1px solid $border-checkout;

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px #fff inset;
    }

    @include bp(75) {
        @include font-size(23px);
        height: 55px;
        padding: 0 20px;
    }

    &[value=''] {
        & + .card-detail__label {
            left: 15px;
            top: 50%;
            transform: translateY(-50%);

            @include bp(75) {
                @include font-size(23px);
                font-weight: 600;
                left: 20px;
            }
        }
    }

    &:focus {

        & + .card-detail__label {
            @include font-size(15px);
            font-weight: 600;
            left: 0;
            top: -5px;
            transform: translateY(-100%);
        }
    }

    &--text {
        height: auto;
        min-height: 220px;
        resize: none;
        top: 15px;
        padding: 15px;

        &[value=''] {
            &:focus {
                & + .card-detail__label {
                    top: -5px;
                    transform: translateY(-100%);
                }
            }

            & + .card-detail__label {
                top: 15px;
                transform: translateY(0);

            }
        }

        @include bp(48) {
            width: 100%;
        }
        @include bp(75) {
            padding: 20px;
        }
    }

    &--error {
        background-color: $form-error-background;
        border: 2px solid $red;

        & + .card-detail__label {
            color: $red;
        }
    }
}

.card-detail__counter {
    bottom: 15px;
    color: $black;
    @include font-size(15px);
    font-weight: 700;
    position: absolute;
    right: 15px;

    @include bp(75) {
        @include font-size(23px);
    }

    &--negative {
        color: $red;
    }
}

.card-detail__label {
    color: $black;
    @include font-size(15px);
    font-style: italic;
    font-weight: 600;
    left: 0;
    position: absolute;
    top: -5px;
    transition: all .2s ease;
    transform: translateY(-100%);
}

.card-detail-preamble {
    color: $black;
    @include font-size(15px);
    font-weight: 400;
    top: -5px;
    margin-bottom: 20px;
}

.giftcard-form__submit {
    background: transparent;
    border: 2px solid $giftcard-gray;
    border-radius: 5px;
    display: block;
    @include font-size(15px);
    font-weight: 600;
    height: 40px;
    margin-top: 30px;
    padding: 5px;
    transition: all .2s ease-in;
    max-width: 150px;
    width: 100%;

    @include bp(75) {
        border: 3px solid $giftcard-gray;
        @include font-size(23px);
        height: 55px;
        max-width: 200px;
    }

    &:hover {
        background: $giftcard-gray;
        color: $sand-lighter;
    }

    &:disabled {
        background: $giftcard-gray;
        color: $sand-lighter;
    }
}

.card-preview {
    margin-top: 30px;
    overflow: hidden;
    max-width: 490px;
    width: 100%;

    @include bp(48) {
        margin-top: 0;
        padding-left: 20px;
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
    }

    @include bp(75) {
        padding-left: 30px;
    }
}

.card-preview__content {
    width: 200%;
}

.card-preview-mail {
    background: $sand-lighter;
    border-radius: 5px;
    border: 1px solid $border-checkout;
    box-shadow: inset 0 -4px 0 $drop-shadow;
    float: left;
    padding: 15px;
    position: relative;
    text-align: center;
    transition: transform .3s ease-in;
    width: 50%;
    z-index: 1;

    .giftcard-form--sms & {
        transform: translateX(100%);
        z-index: 0;
    }

    @include bp(75) {
        min-height: 220px;
        padding: 20px;
    }
    @include bp(120) {
        padding: 40px 20px;
    }

}

.card-preview-mail__title {
    @include font-size(15px);
    font-weight: 700;
    letter-spacing: 5px;
    margin: 0;
    text-transform: uppercase;

    @include bp(75) {
        @include font-size(23px);
    }
}

.card-preview__img {
    width: 40%;
    padding: 20px 0 15px;

    @include bp(75) {
        padding-top: 30px;
    }
    @include bp(120) {
        padding-top: 20px;
    }
}

.card-preview__value {
    display: block;
    @include font-size(15px);
    font-weight: 600;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);

    @include bp(75) {
        right: 20px;
        @include font-size(23px);
    }

    &--amount {
        @include font-size(23px);
        font-weight: 700;

        @include bp(75) {
            @include font-size(45px);
        }
    }
}

.card-preview-sms {
    background: $giftcard-gray;
    border-radius: 20px 20px 0 0;
    float: left;
    padding: 50px 15px 0;
    position: relative;
    transition: transform .3s ease-in;
    z-index: 0;
    width: 50%;

    &:before {
        background-image: url('../img/icons/lens.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 50%;
        content: '';
        display: block;
        height: 50px;
        left: 50%;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: 50px;
    }

    .giftcard-form--sms & {
        transform: translateX(-100%);
        z-index: 1;
    }
}

.card-preview-sms__content {
    background: $sand-lighter;
    border-radius: 10px 10px 0 0;
    min-height: 180px;
    padding: 15px;
    word-wrap: break-word;
    border-bottom: 1px solid $border-checkout;
}

@keyframes inputSlideUp {
    0% {
        display: block;
        opacity: 0;
    }

    10% {
        transform: translateY(-30px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.buy-card-errors {
    p {
        color: $red;
        @include font-size(15px);
    }
}

.gift-go-back {
    @extend %page-content;
    padding: 0 20px;

    @include bp(48) {
        padding: 0;
    }
}

.gift-go-back__btn {
    margin: 30px auto 0;
    text-align: center;
    display: block;
    max-width: 220px;
    border-radius: 5px;
    padding: 10px 5px;
    text-decoration: none;
    height: 40px;
    @include font-size(15px);
    color: $black;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    border: 2px solid $black;
    font-weight: 600;
    line-height: 1.2em;

    &:hover {
        background: $black;
        color: $sand-lighter;
    }

    @include bp(75) {
        @include font-size(23px);
        height: 55px;
        border: 3px solid $black;
        padding: 13px 5px;
        max-width: 300px;
    }
}

.giftcard__payment {
    display: none;
    max-width: 970px;
    margin: 0 auto;

    &.active {
        display: block;
    }
}

.giftcard__payment-container {
    @extend %page-content;

    margin-top: 30px;

    @include bp(48){
        border-radius: 5px 5px;
        max-width: $content-width__percentage;
    }
    @include bp(75){
        max-width: 970px;
    }
    @include bp(120){
        width: 75%;
        max-width: 1200px;
    }
}

.giftcard__payment-heading {
    background: $giftcard-gray;
    color: $sand-lighter;
    @include font-size(23px);
    margin: 0;
    padding: 0.5em 20px;

    @include bp(48){
        border-radius: 5px 5px 0 0;
        padding: 0.5em 30px;
    }
    @include bp(75){
        padding: 0.5em 30px;
    }
}

.giftcard__payment-service-container {
    border: 1px solid $border-checkout;
    border-top: 0;
    padding: 20px 20px;
    border-radius: 0 0 5px 5px;
    background-color: $bg-checkout;

    @include bp(48){
        padding: 30px 30px;
    }
    @include bp(75){
        padding: 30px 30px;
    }
}

.giftcard__payment-go-back-btn {
    background: transparent;
    border: 2px solid $giftcard-gray;
    border-radius: 5px;
    display: block;
    @include font-size(15px);
    font-weight: 600;
    height: 40px;
    margin: 30px auto 0;
    padding: 5px;
    transition: all .2s ease-in;
    max-width: 150px;
    width: 100%;

    @include bp(75) {
        border: 3px solid $giftcard-gray;
        @include font-size(23px);
        height: 55px;
        max-width: 200px;
    }

    &:hover {
        background: $giftcard-gray;
        color: $sand-lighter;
    }

    &:disabled {
        background: $giftcard-gray;
        color: $sand-lighter;
    }
}
