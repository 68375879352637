.register{
    .info-article__body{
        padding: 0;
        margin-top: 30px;

        @include bp(75){
            max-width: none;
        }
    }
}
.register__content{
    padding-top: 20px;

	@include bp(48){
		padding-top: 65px;
	}
	@include bp(75){
		padding-top: 80px;
	}
	@include bp(120){
		padding-top: 105px;
	}

}
.register-header{
    @extend %page-content;
    padding: 20px;

    @include bp(120){
        max-width: 1500px;
    }
}
.register-header__heading{
    @extend %my-pages-header__heading;
}
.register-header__preamble{
    @extend %my-pages-header__preamble;
}
.register-form{
    background: $sand-light;
    @extend %clearfix;
    margin: 0 auto;
    padding: 20px 20px 20px;
    position: relative;

    @include bp(48){
        padding: 30px;
    }
    @include bp(75){
        padding: 10px 30px 30px;
    }
    .custom-select-wrapper{
        height: 40px;

        @include bp(75){
            height: 55px;

            &:after{
                width: 55px;
            }
        }
    }
    .custom-select{
        @include font-size(15px);
        margin-left: 15px;

        @include bp(75){
            @include font-size(23px);
            margin-left: 20px;
        }
    }
}
.register-details{
    background: $sand-light;
    border-top: 1px solid $border-secondary;
    border-bottom: 1px solid $border-secondary;
    @extend %clearfix;
    margin: 0 auto;
    padding: 20px 20px 20px;
    position: relative;

    @include bp(48){
        padding: 30px;
    }
    @include bp(75){
        padding: 30px 30px;
    }
}
.register-form__content{
    @include bp(48){
        margin: 0 auto;
        width: 93%;
    }
    @include bp(120){
        max-width: 1500px;
        padding: 0 20px;
    }
}
.register-details__content{
    @include bp(48){
        margin: 0 auto;
        width: 93%;
    }
    @include bp(120){
        max-width: 1500px;
        padding: 0 20px;
    }
}
.register-details__heading{
    @include font-size(23px);

    @include bp(75){
        @include font-size(37px);
    }
}
.register-item{
    margin: 40px 0 10px;
    line-height: 1em;
    position: relative;

    @include bp(48){
        width: 50%;
    }
}
.register-item__input{
    background: $sand-lighter;
    border: 0;
    border-radius: 5px;
    height: 40px;
    padding: 0 15px;
    width: 100%;

    @include bp(75){
        @include font-size(23px);
        height: 55px;
        padding: 0 20px;
    }

    .register-item--password & {
        padding-right: 80px;

        @include bp(75){
            padding-right: 90px;
        }
    }

    &::-ms-reveal,
    &::-ms-clear{
        display: none;
    }
    &[value=""]{
        & + .register-item__label{
            left: 15px;
            top: 50%;
            transform: translateY(-50%);

            @include bp(75){
                @include font-size(23px);
                font-weight: 600;
                left: 20px;
            }
        }
    }

    &:focus{

        outline: none;

        & + .register-item__label{
            @include font-size(15px);
            font-weight: 600;
            left: 0;
            top: -5px;
            transform: translateY(-100%);
        }
    }
    &:-webkit-autofill{
        -webkit-box-shadow: 0 0 0px 1000px $sand-lighter inset;
    }
    &:-webkit-autofill{
       & + .register-item__label{
          @include font-size(15px);
          font-weight: 600;
          left: 0;
          top: -7px;
          transform: translateY(-100%);
       }
    }

}
.register-item__label{
    color: $black;
    @include font-size(15px);
    font-style: italic;
    font-weight: 600;
    left: 0;
    position: absolute;
    top: -5px;
    transition: all 0.2s ease;
    transform: translateY(-100%);
}
.register-item__submit{
    background: transparent;
    border: 2px solid $black;
    border-radius: 5px;
    display: block;
    @include font-size(15px);
    font-weight: 600;
    height: 40px;
    margin-top: 15px;
    padding: 5px;
    transition: all 0.2s ease-in;
    max-width: 150px;
    width: 100%;

    @include bp(75){
        border: 3px solid $black;
        @include font-size(23px);
        height: 55px;
        max-width: 200px;
        margin-top: 40px;
    }

    &:hover{
        background: $black;
        color: $sand-lighter;
    }
    &--get-address{
        max-width: 185px;
        @include bp(75){
            max-width: 295px;
        }
    }
}
.register-select{
    @extend %clearfix;
    display: block;
    margin-top: 30px;

    @include bp(48){
        width: 50%;
    }
    @include bp(75){
        margin-bottom: 20px;
    }

    .custom-select-wrapper{
        width: 100%;
    }
}
.register-terms__checkbox{
    @extend %visually-hidden;

    &:checked + .register-terms__label{

		&:before{
			background-image: url(../img/icons/0273-checkmark.svg);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 80%;
		}
	}
}
.register-terms__label{
    cursor: pointer;
    display: inline-block;
    @include font-size(15px);
    padding-left: 34px;
    position: relative;
    max-width: 300px;
    padding-top: 0;

    &:before{
        border: 2px solid $black;
        border-radius: 5px;
        content: "";
        cursor: pointer;
        display: inline-block;
        height: 25px;
        left: 0;
        margin-right: 10px;
        position: absolute;
        width: 25px;
        transform: translateY(-50%);
        top: 50%;


        @include bp(75){
            border: 3px solid $black;
            height: 35px;
            width: 35px;
        }
    }

    @include bp(48){
        max-width: inherit;
        margin-bottom: 0;
        padding-top: 0;
    }

    @include bp(75){
        line-height: 30px;
        padding-left: 45px;
        @include font-size(23px);
    }

    a{
        color: inherit;
    }
}
.register-inspiration-store__legend{
    margin-bottom: 5px;
  font-style: italic;
  @include font-size(15px);
  font-weight: 300;
  line-height: 1.33333333em;

  @include bp(75){
    @include font-size(23px);
  }
}
