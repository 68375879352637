
.list-show-more {
    margin: 0 0 30px;
    padding: 0 20px;
    text-align: center;

    @include bp(48) {
        padding: 0 30px;
    }
    @include bp(75) {
        padding: 0 15px;
    }
    @include bp(120) {
        padding: 0 30px;
    }
}

.list-show-more__button {
    background: $sand-lighter;
    border: 3px solid $black;
    border-radius: 5px;
    color: $black;
    display: inline-block;
    @include font-size(15px);
    font-weight: 600;
    height: 40px;
    max-width: 330px;
    width: 100%;
}
