.hero-carousel-section {
    overflow: hidden;
}

.hero-carousel {
    @extend %clearfix;
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;

    @include bp(48) {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    visibility: hidden;
    opacity: 0;

    &.slick-initialized {
        visibility: visible;
        opacity: 1;
    }

    .slick-track {
        width: 50000px;
    }

    .slick-list {
        height: auto !important;
        padding: 0 12% !important;
        overflow: hidden;

        @include bp(48) {
            padding: 0 10% !important;
        }

        @include bp(75) {
            padding: 0 9% !important;
        }

        @include bp(120) {
            padding: 0 8% !important;
        }
    }

    .slick-prev,
    .slick-next {
        background-color: transparent;
        border: 0;
        color: transparent;
        font-size: 0;
        height: 0;
        opacity: 0;
        position: absolute;
        top: 40%;
        transform: translateY(-40%);
        transition: opacity .3s;
        z-index: 1;
        width: 80px;

        &:before {
            background-position: left center;
            background-repeat: no-repeat;
            background-size: auto 90%;
            content: ' ';
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .no-touch & {
        &:hover .slick-prev,
        &:hover .slick-next {
            @include bp(75) {
                opacity: 1;
            }
        }
    }

    .slick-prev {
        left: 0;

        &:before {
            background-image: url('../img/icons/slider_prev.svg');

            @include bp(48) {
                left: 30px;
            }
        }

        .no-touch & {
            @include bp(48) {
                height: 120px;
            }

            @include bp(120) {
                height: 160px;
                width: 100px;
            }
        }
    }

    .slick-next {
        right: 0;

        &:before {
            background-image: url('../img/icons/slider_next.svg');

            @include bp(48) {
                right: 30px;
            }
        }

        .no-touch & {
            @include bp(48) {
                height: 120px;
            }

            @include bp(120) {
                height: 160px;
                width: 100px;
            }
        }
    }
}

.hero-carousel__image-link {
    position: relative;
    /* We use the padding-bottom hack to avoid browser reflows when images
       are loaded.
       A detailed explaination can be found here:
       https://www.smashingmagazine.com/2013/09/responsive-images-performance-problem-case-study/#the-padding-bottom-hack */
    height: 0;
    padding-bottom: percentage(340/560);
    display: block;
    border-radius: 5px;
    border: 1px solid $sand-light;

    @include bp(75) {
        padding-bottom: percentage(360/1650);
    }

    .hero-carousel--small-images & {
        padding-bottom: percentage(340/560);
    }

    .hero-carousel--large-images & {
        padding-bottom: percentage(360/1650);
    }

    &:after {
        background: rgba(43, 42, 39, .2);
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 10;
        border-radius: 5px;
        transition: background .25s ease-in-out;

        @include bp(75) {
            transition: background .45s ease-in-out;
        }

        .slick-center & {
            background: none;
        }
    }
}

.hero-carousel__image {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: none;
}

.hero-carousel__image--initialized {
    display: block;
}

/* To load mobile image faster */
.hero-carousel__dummy-image {
    display: none !important;
}

.hero-carousel__item-wrapper {
    position: relative;
    @extend %page-content;
    margin: 0 -.45%;

    .slick-initialized & {

        &.slick-slide {
            transform: scale(.8);
            transition: opacity .25s ease-in-out, transform .25s ease-in-out;
            opacity: .4;

            @include bp(75) {
                transition: opacity .45s ease-in-out, transform .45s ease-in-out;
            }
        }

        &.slick-center {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.hero-carousel__item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 0;
    visibility: visible;

    .slick-initialized & {
        height: auto;
        visibility: visible;
    }
}

.hero-carousel__item--first {
    height: auto;
    visibility: visible;
}

.hero-carousel__item-body {
    @extend %clearfix;
    position: relative;
}

.hero-carousel__item-content {
    position: relative;
    text-align: right;
}