//.order-complete-page {
//    background-color: #fff;
//}

.order-complete__header {
    border-bottom: 1px solid $border-checkout;
}

.order-complete {
    .checkout-header-column {
        width: 100%;

        @include bp(40) {
            width: 50%;
        }
    }

    .checkout-header-column--right {
        > * {
            float: left;

            @include bp(40) {
                @include font-size(18px);
                float: right;
            }
        }
    }

    .checkout-container__heading {
        @include bp(120) {
            @include font-size(60px);
        }
    }
}

.order-complete__container {
    padding: 30px 0 60px;
    text-align: center;
}

.order-complete__inner {
    // @extend %clearfix;
    max-width: $content-width__120;
    display: block;
    text-align: left;
    //padding: 0 20px;
    width: 100%;
    margin: 0 auto;

    background-color: $bg-checkout;
    padding: 30px;
    border: 1px solid $border-checkout;
    max-width: 640px;

    @media (max-width: 660px) {
        padding: 20px;
        border-left: 0;
        border-right: 0;
    }

    //@include bp(48) {
    //    width: 93%;
    //}

    //@include bp(75) {
    //    padding: 0;
    //    width: 50%;
    //}
}

.order-complete__order-confirmation {
    margin: -15px;
}

.order-complete__column {
    float: left;
    width: 100%;

    @include bp(75) {
        width: 50%;
    }
}

.order-complete__heading {
    @include font-size(20px);
    font-weight: 300;

    @include bp(48) {
        @include font-size(30px);
    }
}

.order-complete__link {
    @include font-size($font-size--base);
    //font-family: $font-family--secondary;
    color: $black;
    font-weight: 700;
    display: block;
    //margin: 20px 0 40px;
    margin: 20px 0;

    @include bp(48) {
        @include font-size(18px);
    }
}

.order-complete__body {
    @include font-size($font-size--base);
    //font-family: $font-family--secondary;
    //line-height: 25px;
    color: rgb(120, 120, 120);

    //@include bp(48) {
    //    @include font-size(20px);
    //    line-height: 34px;
    //}
}
//
//.order-complete__order-confirmation {
//    border: solid 1px $border-checkout;
//    border-top: 0;
//    background: #fff;
//}
