.bokus-puff {
    position: relative;
    background: $bokus-orange;
    color: $white;

    display: block;
    padding: 30px;

    &:after {
        content: '';
        display: none;
        opacity: .2;
        background-image: url('../img/bokusplay_gfx1.png');
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right center;
    }

    @include bp(48) {
        display: flex;
        flex-direction: column;
    }

    @include bp(75) {
        display: block;

        &:after {
            display: block;
        }
    }

    @include bp(100) {
        &:after {
            opacity: 1;
        }
    }
}

.bokus-puff--isconnected {
    &:after {
        background-image: url('../img/bokusplay_gfx2.png');
    }
}

.bokus-puff__header {
    @include font-size(23px);

    margin: 0;
    margin-bottom: 20px;

    @include bp(75) {
        @include font-size(30px);

        .bokus-puff--isconnected & {
            @include font-size(23px);
            margin-bottom: 10px;
        }
    }
}

.bokus-puff__ingress {
    @include font-size(15px);
    font-weight: 600;
    margin: 0;

    @include bp(75) {
        @include font-size(20px);
    }
}

.bokus-puff__list {
    @include font-size(15px);
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;

    @include bp(48) {
        flex-grow: 1;
    }

    @include bp(75) {
        @include font-size(20px);

        .bokus-puff--isconnected & {
            @include font-size(15px);
        }

        max-width: 60%;
    }
}

.bokus-puff__list-item {
    margin: 10px 0;
    padding-left: 15px;
    position: relative;

    &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        display: inline-block;
        background: $white;

        position: absolute;
        left: 0;
        top: 6px;
    }

    .bokus-puff--isconnected & {
        &:before {
            top: 6px;
        }
    }

    @include bp(75) {
        &:before {
            top: 9px;
        }
    }
}

.bokus-puff__list-disclaimer {
    display: block;
    margin-top: 0;
    font-size: 15px;
    font-weight: 300;
    font-style: italic;
    margin-bottom: 20px;

    @include bp(75) {
        margin-bottom: 60px;
    }

    @include bp(100) {
        max-width: 35%;
        margin-bottom: 0;

        .bokus-puff--isconnected & {
            max-width: 65%;
        }
    }
}

.bokus-puff__bp-logo {
    display: block;
    position: absolute;
    right: 40px;
    bottom: 85px;

    display: none;
}

.bokus-puff__btn {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;

    z-index: 1;

    color: $black;

    &:visited {
        color: $black;
    }

    @include font-size(18px);
    font-weight: 600;
    background: $white;
    padding: 10px 20px;
    padding-right: 35px;
    text-decoration: none;

    &:after {
        content: '';
        background-image: url('../img/icons/chevron_right.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        display: block;
        height: 20px;
        width: 20px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    @include bp(75) {
        position: absolute;
        right: 40px;
        bottom: 30px;
        width: auto;
        text-align: left;
    }
}
