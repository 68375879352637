.sale-header {
    background-color: $sand-lighter;
    background-position: left bottom 15px;
    background-repeat: repeat-x;
    background-size: auto 50px;
    overflow: hidden;

    @include bp(48) {
        background-size: auto 70px;
    }
    @include bp(75) {
        background-size: auto 70px;
    }
    @include bp(120) {
        background-size: auto 100px;
    }
}

.sale-header__preamble {
    @include font-size(16px);
    line-height: 1.5;
    font-weight: 600;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;

    @include bp(48) {
        @include font-size(20px);
    }

    @include bp(75) {
        @include font-size(20px);
    }

    a {
        color: $black;
    }
}
