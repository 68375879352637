// this class is added to body when cart is expanded
.active-cart {
    overflow: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
}

.cart {
    display: flex;
    flex-direction: column;
    background: $sand-lighter;
    border-top: 1px solid $sand-light;
    box-shadow: 0 8px 15px rgba(0, 0, 0, .1);
    max-width: 450px;
    height: calc(100% - 55px);
    opacity: 0;
    position: fixed;
    right: 0;
    bottom: 0;
    transform: translateX(100%);
    transition: transform .3s ease-in, opacity 1.5s ease-in;
    z-index: 100;
    width: 100%;

    @include bp(75) {
        border-top: none;
        bottom: 0;
        box-shadow: 0 0 15px rgba(0, 0, 0, .1);
        height: calc(100vh - 75px);
        width: 33%;
    }

    &.cart--expanded {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        transition: transform .3s ease-in, opacity .2s ease-in;
        transform: translateX(0%);
        opacity: 1;

        @media only screen and (min-height: 600px) {
            overflow-y: auto;
        }
    }
}

.cart__header {
    flex-shrink: 0;
    border-top: 2px solid $sand-light;
    border-bottom: 1px solid $sand-light;
    border-radius: 5px;
    padding: 10px 20px;

    @include bp(75) {
        border-bottom: 2px solid $sand-light;
        display: block;
        padding: 20px;
    }
}

.cart__header-title {
    @include font-size(15px);
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0;
    text-transform: uppercase;
}

.cart__close {
    appearance: none;
    background-color: transparent;
    background-image: url('../img/icons/cross-rounded.svg');
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    float: right;
    height: 15px;
    width: 15px;
    margin-top: 2px;

    @include bp(75) {
        margin-top: 0;
    }
}

.cart__notification {
    background-color: #f8eecd;
    @include font-size(15px);
    font-style: italic;
    font-weight: 600;
    margin: 0;
    padding: 20px;
}

.cart__notification--error {
    background-color: $red-light;
    color: $red;
}

.cart__list {
    position: relative;
    flex: 1 0 auto;
    list-style: none;
    margin: 0;
    padding: 0;

    @media only screen and (min-height: 600px) {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        flex: 1 1 0;
    }
}

.cart__list-item--empty {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
}

.cart__total {
    @extend %clearfix;
    border-top: 10px solid $black;
    margin: 0 20px;
    padding: 10px 0;
    position: relative;
}

.cart__total-inner {
    @extend %clearfix;
    position: relative;

    &:before {
        background-color: map_get($project-color, color-24);
        content: '';
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }
}

.cart__total-label {
    @include font-size(23px);
    position: relative;
    background: $sand-lighter;
    display: inline-block;
    font-weight: 600;
    padding-right: 10px;
    float: left;
}

.cart__total-price {
    @include font-size(23px);
    background: $sand-lighter;
    position: relative;
    display: inline-block;
    font-weight: 700;
    padding-left: 10px;
    float: right;
}

.cart__checkout {
    flex-shrink: 0;
    background: $sand-lighter;
    padding: 10px 0;
    width: 100%;

    .cart-inverted & {
        background: $black;
        color: $sand-lighter;
    }
}

.cart__checkout-link {
    background: $black;
    border-radius: 5px;
    color: $sand-lighter;
    display: block;
    @include font-size(23px);
    line-height: 1em;
    margin: 0 20px;
    padding: 15px;
    text-align: center;
    text-decoration: none;

    &:visited {
        color: $sand-lighter;
    }

    &:after {
        content: url('../img/icons/arrow_right_cart.svg');
        transition: transform .2s ease-in;
        position: relative;
        top: -1px;
        left: 10px;
    }
}

.cart__discount-info {
    @include font-size(13px);
    margin: 0 20px 5px;
}

.cart__shipping-info {
    @include font-size(13px);
    margin: 5px 20px 0;
}
