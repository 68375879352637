.product--before-sale {
    .carousel-sale-item {
        left: -7px;
        width: auto;
        top: 10px;

        @include bp(48) {
            left: -10px;
            top: 20px;
        }
        @include bp(75) {
            left: -11px;
            top: 20px;
        }
    }


    .module-item {
        .carousel-sale-item {
            left: -13px;
            width: auto;
            top: 20px;

            @include bp(48) {
                left: -15px;
            }
        }
    }

    .double-module-figure {
        .carousel-sale-item {
            @include bp(120) {
                left: -15px;
                width: 140px;
            }
        }
    }

    &.product-details {
        .carousel-sale-item {
            left: -7px;
            width: auto;
            top: 35px;

            @include bp(40) {
                left: -10px;
                top: 45px;
            }
            @include bp(75) {
                top: 55px;
                left: -14px;
            }
            @include bp(120) {
                left: -16px;
                top: 70px;
            }
        }

        .product-price {
            color: $red;
            background-color: $yellow;
            border-radius: 5px;
            padding: 10px 20px;
            border: none;
            margin-bottom: 10px;

            @include bp(75) {
                margin-bottom: 20px;
            }

        }
    }

    .recommendation-figure {
        .carousel-sale-item {
            top: 10px;
        }
    }
}

.product--during-sale {
    .carousel-sale-item {
        left: -6px;
        width: 50px;
        top: 10px;

        @include bp(48) {
            left: -7px;
            width: 60px;
            top: 20px;
        }
    }

    .module-item {
        .carousel-sale-item {
            left: -8px;
            width: 70px;
            top: 20px;

            @include bp(48) {
                left: -10px;
                width: 90px;
            }
            @include bp(120) {
                left: -12px;
                width: 110px;
            }
        }
    }

    .double-module-figure {
        .carousel-sale-item {
            @include bp(75) {
                left: -10px;
                width: 90px;
                top: 20px;
            }
            @include bp(120) {
                left: -12px;
                width: 110px;
            }
        }
    }

    &.product-details {
        .carousel-sale-item {
            left: -5px;
            width: 50px;
            top: 35px;

            @include bp(48) {
                left: -6px;
                width: 60px;
                top: 45px;
            }
            @include bp(75) {
                left: -9px;
                width: 80px;
                top: 55px;
            }
            @include bp(120) {
                left: -11px;
                width: 100px;
                top: 70px;
            }
        }

        .product-price {
            color: $red;
            background-color: $yellow;
            border-radius: 5px;
            padding: 10px 20px;
            border: none;
            margin-bottom: 10px;

            @include bp(75) {
                margin-bottom: 20px;
            }
        }
    }

    .recommendation-figure {
        .carousel-sale-item {
            top: 10px;
        }
    }
}

.product-before-sale {
    @include font-size(15px);
    font-weight: 300;
    color: $productdiscount;
    display: block;

    @include bp(48) {
        @include font-size(15px);
        vertical-align: middle;
        display: inline-block;
    }
    @include bp(120) {
        @include font-size(18px);
    }

    &__date {
        font-weight: 700;
        color: $productdiscount;
    }
}
