.teaser-images {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;

    @include bp(40) {
        display: flex;
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
        max-width: $content-width__percentage;
    }

    .teaser-images__link {
        display: block;
        margin-top: 15px;

        @include bp(40) {
            flex-basis: 48%;
            margin-left: 1%;
            margin-right: 1%;

            &:nth-child(1),
            &:nth-child(2) {
                margin-top: 0;
            }
        }

        @include bp(64) {
            flex-basis: 23%;
            margin-left: 1%;
            margin-right: 1%;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                margin-top: 0;
            }
        }

        .teaser-images__link__image {
            width: 100%;
            height: auto;
        }
    }
}

.teaser-images__campaign-page {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;

    @include bp(64) {
        justify-content: center;
    }
   

    .teaser-images__link {
        display: block;
        margin-top: 15px;
        flex-basis: 48%;
        margin-left: 1%;
        margin-right: 1%;

        &:nth-child(1),
        &:nth-child(2) {
            margin-top: 0;
        }

        @include bp(40) {
            flex-basis: 30%;
            margin-left: 1%;
            margin-right: 1%;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                margin-top: 0;
            }
        }

        @include bp(64) {
            flex-basis: 23%;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                margin-top: 0;
            }
        }

        .teaser-images__link__image {
            width: 100%;
            height: auto;
        }
    }
}

.teaser-heading {
    @extend %clearfix;
    @extend %page-content;

    @include bp(48) {
        max-width: 768px;
    }
    @include bp(75) {
        max-width: 1150px;
    }
    @include bp(120) {
        width: 75%;
        max-width: 1500px;
    }

    .teaser-heading__spacing {
        padding-top: 25px;
        padding-bottom: 15px;
    
        @include bp(48) {
            padding-left: 30px;
            padding-right: 30px;
        }
        @include bp(75) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .teaser-heading__text {

        @include column_style(0, 2);
        margin: 0;
        padding-bottom: 0;
        padding-top: 0;
        word-wrap: break-word;
    
        @include bp(48) {
            @include column_style(48, 3);
            padding-bottom: 0;
            padding-top: 0;
        }
        @include bp(75) {
            @include column_style(75, 7);
            max-width: 1000px;
            margin: 0 auto;
            padding-bottom: 0;
            padding-top: 0;
        }
        @include bp(120) {
            @include column_style(120, 8);
            max-width: 1200px;
            float: none;
            margin: 0 auto;
            padding-bottom: 0;
            padding-top: 0;
        }
    
    }

    h2 {
        font-weight: 800;
        @include bp(48) {
            @include font-size(28px);
            letter-spacing: 1px;
        }
    
        @include bp(75) {
            @include font-size(36px)
        }       
    }
}