.cart-item {
    border-bottom: 1px solid $sand-light;
    @extend %clearfix;
    padding: 20px;
}

.cart-item__img {
    float: left;
    margin-right: 20px;
    width: 35px;
    height: auto;
}

.cart-item__details {
    float: left;
    line-height: 1em;
    position: relative;
    width: calc(100% - 55px);
    // Make space for unit price
    min-height: 80px;

    @include bp(40) {
        min-height: 55px;
    }

    a {
        text-decoration: none;
        color: $black;
    }
}

.cart-item__title {
    @include font-size(15px);
    font-weight: 700;
    line-height: 1.4em;
    margin: 0;
    margin-right: 10px;
    // Make space for unit price
    padding-right: 50px;

    @include bp(40) {
        padding-right: 0;
    }
}

.cart-item__short-description {
    @include font-size(15px);
    font-weight: 300;
    line-height: 1.4em;
    min-height: 1.4em; // take up space even if element is empty
    margin: 0;
    padding-right: 100px;
    word-break: break-word;

    @include bp(120) {
        padding-right: 120px;
    }
}

.cart-item__format {
    @include font-size(15px);
    font-weight: 600;
    line-height: 1em;
    min-height: 1em;
    margin: 2px 0 0;
}

.cart-item__price {
    @include font-size(18px);
    font-weight: 700;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
}

.cart-item__price-normal {
    order: 2;
}

.cart-item__price-normal--small {
    @include font-size(15px);
    order: 0;
    float: left;
    margin-right: 5px;
    font-weight: 300;

    &:before {
        content: '(';
    }

    &:after {
        content: ')';
    }
}

.cart-item__price-discount {
    order: 1;
    color: $red;
    margin-right: 7px;
}

.cart-item__price-discount--member {
    @include font-size(18px);
    float: left;
    color: $friend-price;
    padding-left: 23px;
    position: relative;

    &.cart-item__price-discount--is-member {
        @include font-size(18px);
        margin-right: 0;
    }

    &:before {
        @include member-icon();
    }
}

.cart-item__unit-price {
    @include font-size(13px);
    line-height: 25px;
    position: absolute;
    top: 30px;
    right: 0;

    @include bp(40) {
        float: right;
        position: relative;
        top: 0;
        margin-left: 5px;
        margin-right: 5px;
    }
}

.cart-item__select {
    float: right;
    margin-left: 5px;
    margin-right: 10px;
}

.cart-item__remove-btn {
    appearance: none;
    background-color: transparent;
    background-image: url('../img/icons/cross-rounded--small.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 11px;
    border: 0;
    height: 15px;
    width: 15px;
    float: right;
}
