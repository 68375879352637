
%article-header {
    // @include column_style(0, 2);
    // @include font-size(30px);
    // font-weight: 800;
    // line-height: 1.16666666667em;
    // margin: 0;
    // padding-bottom: 0;
    // padding-top: 0;
    // word-wrap: break-word;

    // @include bp(48) {
    //     @include column_style(48, 3);
    //     @include font-size(53px);
    //     line-height: 1.1320754717em;
    //     padding-bottom: 0;
    //     padding-top: 0;
    //     letter-spacing: 1px;
    // }
    // @include bpf(1060px) {
    //     @include column_style(75, 7);
    //     max-width: 1000px;
    //     @include font-size(60px);
    //     line-height: 1.08333333333em;
    //     margin: 0 auto;
    //     padding-bottom: 0;
    //     padding-top: 0;
    // }
    // @include bpf(2000px) {
    //     @include column_style(120, 8);
    //     max-width: 1200px;
    //     @include font-size(75px);
    //     float: none;
    //     line-height: 1.06666666667em;
    //     margin: 0 auto;
    //     padding-bottom: 0;
    //     padding-top: 0;
    // }
    @include column_style(0, 2);
    @include font-size(29px);
    font-weight: 800;
    line-height: 1.16666666667em;
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
    word-wrap: break-word;

    @include bp(48) {
        @include column_style(48, 3);
        @include font-size(53px);
        line-height: 1.1320754717em;
        padding-bottom: 0;
        padding-top: 0;
        letter-spacing: 1px;
    }
    @include bp(75) {
        @include column_style(75, 7);
        max-width: 1000px;
        @include font-size(60px);
        line-height: 1.08333333333em;
        margin: 0 auto;
        padding-bottom: 0;
        padding-top: 0;
    }
    @include bp(120) {
        @include column_style(120, 8);
        max-width: 1200px;
        @include font-size(75px);
        float: none;
        line-height: 1.06666666667em;
        margin: 0 auto;
        padding-bottom: 0;
        padding-top: 0;
    }
}

%article-subheading {
    @include column_style(0, 2);
    @include font-size(23px);
    font-weight: 300;
    line-height: 1.30434782609em;
    margin: 15px 0 0;
    padding-bottom: 0;
    padding-top: 0;
    word-wrap: break-word;

    @include bp(48) {
        @include column_style(48, 3);
        @include font-size(30px);
        line-height: 1.16666666667em;
        margin: 5px 0 0;
        padding-bottom: 0;
        padding-top: 0;
    }
    @include bp(75) {
        @include column_style(75, 7);
        max-width: 1000px;
        @include font-size(37px);
        line-height: 1.21621621622em;
        margin: 5px auto 0;
        padding-bottom: 0;
        padding-top: 0;
    }
    @include bp(120) {
        @include column_style(120, 8);
        max-width: 1200px;
        @include font-size(53px);
        float: none;
        line-height: 1.22641509434em;
        margin: 30px auto 0;
        padding-bottom: 0;
        padding-top: 0;
    }
}

%article-ingress {
    @include column_style(0, 2);
    @include font-size(23px);
    font-weight: 300;
    letter-spacing: .5px;
    line-height: 1.66666666667;
    margin: 15px 0 0;
    padding-bottom: 0;
    padding-top: 0;

    @include bp(48) {
        @include column_style(48, 3);
        @include font-size(30px);
        line-height: 1.30434782609em;
        margin: 30px 0 0;
        padding-bottom: 0;
        padding-top: 0;
    }
    @include bpf(1060px) {
        @include column_style(75, 7);
        max-width: 1000px;
        line-height: 1.52173913043em;
        margin: 30px auto 0;
        padding-bottom: 0;
        padding-top: 0;
    }
    @include bpf(2000px) {
        @include column_style(120, 8);
        max-width: 1200px;
        @include font-size(37px);
        line-height: 1.5em;
        margin: 35px auto 0;
        padding-bottom: 0;
        padding-top: 0;
    }
}

%article-preamble {
    @include column_style(0, 2);
    @include font-size(15px);
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 1.66666666667;
    margin: 15px 0 0;
    padding-bottom: 0;
    padding-top: 0;

    @include bp(48) {
        @include column_style(48, 3);
        @include font-size(23px);
        line-height: 1.30434782609em;
        margin: 30px 0 0;
        padding-bottom: 0;
        padding-top: 0;
    }
    @include bp(75) {
        @include column_style(75, 7);
        max-width: 1000px;
        line-height: 1.52173913043em;
        margin: 30px auto 0;
        padding-bottom: 0;
        padding-top: 0;
    }
    @include bp(120) {
        @include column_style(120, 8);
        max-width: 1200px;
        @include font-size(30px);
        line-height: 1.5em;
        margin: 35px auto 0;
        padding-bottom: 0;
        padding-top: 0;
    }
}

%article-body {
    @include column_style(0, 2);
    @include font-size(15px);
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 1.66666666667;
    margin: 15px 0 0;
    padding-bottom: 0;
    padding-top: 0;

    @include bp(48) {
        @include column_style(48, 3);
        @include font-size(18px);
        line-height: 1.30434782609em;
        margin: 30px 0 0;
        padding-bottom: 0;
        padding-top: 0;
    }
    @include bpf(1060px) {
        @include column_style(75, 7);
        max-width: 1000px;
        line-height: 1.52173913043em;
        margin: 30px auto 0;
        padding-bottom: 0;
        padding-top: 0;
    }
    @include bpf(2000px) {
        @include column_style(120, 8);
        max-width: 1200px;
        @include font-size(23px);
        line-height: 1.5em;
        margin: 35px auto 0;
        padding-bottom: 0;
        padding-top: 0;
    }
}
