.puff-slider {
    overflow: hidden;
    padding-top: 20px;
    border-top: 1px solid $sand-light;
    padding-bottom: 20px;

    @include bp(120) {
        padding: 40px 0;
    }
}

.puff-slider--no-border-top {
    border-top: none;
    padding-top: 0;
}

.puff-slider__header {
    @extend %page-content;
}

.puff-slider__header-inner {
    color: $black;
    @extend %clearfix;
    @extend %full-width-content;
    padding: 0 15px 15px;

    @include bp(48) {
        padding-bottom: 15px;
    }
}

.puff-slider__heading {
    @include font-size(18px);
    line-height: 1.2em;
    margin: 0;

    @include bp(48) {
        float: left;
        @include font-size(23px);
    }
    @include bp(75) {
        @include font-size(30px);
    }
}

.puff-slider__carousel {
    @extend %clearfix;
    @extend %page-content;
    @extend %full-width-content;

    //Max-widths overwrite property inherited from page-content. They are an attempt to copy the width of the hero carousel
    //which is controlled by slick slider's javascript.

    max-width: 95%;

    @media (min-width: 350px) {
        max-width: 93%;
    }

    @media (min-width: 400px) {
        max-width: 89%;
    }

    @media (min-width: 530px) {
        max-width: 87%;
    }

    @include bp(48) {
        max-width: 92%;
    }

    @include bp(64) {
        max-width: calc(89% + 20px);
    }

    @include bp(75) {
        max-width: calc(89% + 5px);
    }

    // All other slick-specific styles used by .puff-slider are the generic ones,
    // defined in the 'Custom slick styles' section in _.carousel.scss.
}

.puff-slider__item {
    margin-right: 10px;
    transition: opacity .2s linear;

    .slick-initialized & {
        opacity: .2;
    }
}

.slick-uninitialized .puff-slider__item {
    width: 100%;
    float: left;

    &:nth-child(n + 2) {
        display: none;
    }

    @include bp(40) {
        width: calc(50% - 20px);

        &:nth-child(n + 2) {
            display: block;
        }

        &:nth-child(n + 3) {
            display: none;
        }
    }

    @include bp(75) {
        width: calc(33% - 20px);

        &:nth-child(n + 3) {
            display: block;
        }

        &:nth-child(n + 4) {
            display: none;
        }
    }

    @include bp(120) {
        width: calc(25% - 20px);

        &:nth-child(n + 4) {
            display: block;
        }

        &:nth-child(n + 5) {
            display: none;
        }
    }
}

.puff-slider__image {
    width: 100%;
    height: auto;
    display: block;
}
