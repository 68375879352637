.order-detail-wrapper {
    text-align: center;
}
.order-detail-container{
    @extend %page-content;
    padding: 20px;
    @extend %clearfix;
    position: relative;
    text-align: left;
}
.order-detail-info__wrapper {
    color: $checkouttext;
    position: relative;
    @include font-size(15px);

    &--loading {
        pointer-events: none;

        > * {
            opacity: .2;
        }

        &:before {
            content: '';
            z-index: 1;
            position: absolute;
            left: 0;
            top: 30vh;
            right: 0;
            bottom: 0;
            background-image: url("../img/icons/spinner.svg");
            background-repeat: no-repeat;
            background-size: 100%;
            display: block;
            height: 25px;
            margin: 0 auto;
            width: 25px;

            @include bp(75){
                height: 50px;
                width: 50px;
            }
        }
    }

    @include bp(48){
        float: left;
        width: 50%;
    }
}
.order-detail__link {
    color: $black;
    @include font-size(15px);
    font-weight: 600;
}
.order-detail-header{
    @extend %page-content;
    padding: 20px 20px 10px;
    @extend %clearfix;
    position: relative;
    text-align: left;
}
.order-detail-header__top-links {
    @extend %clearfix;
    margin-top: 10px;
    margin-bottom: 15px;

    @include bp(48){
        margin-top: 15px;
        margin-bottom: 20px;
    }

    @include bp(75){
        margin-top: 40px;
        margin-bottom: 20px;
    }
}
.order-detail-go-back__link {
    display: block;
    color: $checkouttext;
    text-decoration: none;
    font-weight: 600;
    @include font-size(15px);
    position: relative;
    padding-left: 15px;
    margin-bottom: 15px;

    @include bp(48){
        @include font-size(18px);
        padding-left: 25px;
        float: left;
        margin-bottom: 0;
    }

    @include bp(75){
        @include font-size(23px);
    }

    &:before{
        background-image: url(../img/icons/chevron_right.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 95%;
        content: " ";
        display: inline-block;
        height: 11px;
        position: absolute;
        left: 0;
        top: 20%;
        transform: rotate(180deg);
        width: 7px;

        @include bp(48){
            height: 15px;
            width: 10px;
            left: 0;
        }
    }
}
.order-detail-questions {
    @include font-size(15px);
    font-weight: 400;

    @include bp(48){
        float: right;
        text-align: right;
        @include font-size(18px);
    }

    @include bp(75){
        @include font-size(23px);
    }
}
.order-detail-customer-service__link {
    display: block;
    font-weight: 700;
    @include font-size(15px);

    @include bp(48){
        @include font-size(18px);
    }

    @include bp(75){
        @include font-size(23px);
    }
}
.order-detail-header__info {
    padding-bottom: 15px;
    padding-top: 20px;
    border-bottom: 1px solid $border-checkout;
    border-top: 1px solid $border-checkout;


    @include bp(48){
        padding-top: 0;
        border-top: none;
    }
}
.order-detail-order-number__wrapper {
    display: inline-block;
    vertical-align: top;
}
.order-detail-order-number__label {
    @include font-size(15px);
    font-weight: 300;

    @include bp(40){
        display: inline-block;
    }

    @include bp(48){
        display: block;
        @include font-size(23px);
    }

    @include bp(75){
        @include font-size(30px);
    }
}
.order-detail-order-number__number {
    @include font-size(18px);
    font-weight: 700;

    @include bp(40){
        display: inline-block;
    }
    @include bp(48){
        display: block;
        @include font-size(30px);
    }

    @include bp(75){
        @include font-size(45px);
    }
}
.order-detail-order-date__wrapper {
    display: block;
    margin-bottom: 5px;
    position: relative;
    margin-top: 5px;  
    @include font-size(23px);
    line-height: 30px;

    @include bp(48) {
        padding-left: 15px;
        margin-left: 30px;
        margin-top: 0;
        display: inline-block;
        vertical-align: bottom;
        height: 35px;

        &:before {
            content: "";
            background-color: $border-checkout;
            display: inline-block;
            height: 31px;
            width: 1px;
            position: absolute;
            bottom: 4px;
            left: 0;
        }
    }

    @include bp(75) {
        margin-bottom: 11px;
    }
}
.order-detail-order-date__label {
    font-weight: 300;
    @include font-size(15px);
    line-height: 20px;

    @include bp(40){
        display: inline-block;
    }
    @include bp(48){
        display: block;
    }

    @include bp(75) {
        display: inline-block;
        @include font-size(18px);
    }
}
.order-detail-order-date__timestamp {
    font-weight: 700;
    @include font-size(18px);
    line-height: 20px;

    @include bp(40){
        display: inline-block;
    }
    @include bp(48){
        display: block;
    }
    @include bp(75) {
        display: inline-block;
    }

}
.order-detail-info__content {
    @include bp(48){
        margin-right: 30px;
    } 
}
.order-detail-section-heading {
    border-bottom: 1px solid $border-checkout;
    padding-bottom: 10px;
    font-weight: 300;
}
.order-detail-delivery-status-section {
    border-top: 1px solid $border-checkout;
    padding-top: 20px;
}
.order-detail-section__content {
    margin-bottom: 30px;

    @include bp(48) {
        margin-bottom: 40px;
        max-width: 90%;
    } 
}
.order-detail-status {
    background-color: $sand-light;
    border-radius: 5px;
    font-weight: 300;
    padding: 15px 20px;
    display: inline-block;
    margin-bottom: 10px;
    @include font-size(15px);

    @include bp(75) {
        padding: 15px 30px;
        @include font-size(23px);
    }

    &--bold {
        font-weight: 700;
    }
}
.order-detail-status__info {
    line-height: 20px;
    @include font-size(15px);
    font-weight: 400;

    a {
        color: $black;
        @include font-size(15px);
        font-weight: 600;
    }
}
.order-detail-delivery-address-container {
    @include font-size(15px);
    font-weight: 400;

    @include bp(120){
        @include font-size(18px);
        line-height: 25px;
    }
}
.order-detail-delivery-item__name {
    font-weight: 600;
}
.order-detail-delivery-status__info {
    @include font-size(15px);
    line-height: 20px;

    @include bp(120){
        @include font-size(18px);
        line-height: 25px;
    }
}
.order-detail-delivery-estimate {
    @include font-size(15px);
    font-weight: 300;

    @include bp(75){
        @include font-size(18px);
    }

    @include bp(120){
        @include font-size(23px);
    }

    &:before{
        background-image: url(../img/icons/clock.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        content: "";
        display: inline-block;
        margin-right: 5px;
        position: relative;
        top: 5px;
        height: 20px;
        width: 20px;

        @include bp(75){
            height: 25px;
            width: 25px;
            margin-right: 10px;
        }

        @include bp(120){
            height: 35px;
            width: 35px;
            margin-right: 15px;
            top: 9px;
        }
    }

    &--bold {
        font-weight: 700;
    }
}
.order-detail-tracking__button {
    @include button-styles-small();
    width: auto;
    cursor: pointer;
    position: relative;
    padding-left: 45px;
    padding-right: 10px;
    max-width: none;
    margin-top: 20px;

    @include bp(48) {
        max-width: none;
        border: 2px solid $black;
        width: auto;
        margin-top: 20px;
    }

    @include bp(75) {
        max-width: none;
        border: 2px solid $black;
        margin-top: 20px;
    }

    @include bp(120) {
        max-width: none;
        border: 3px solid $black;
        margin-top: 20px;
    }

    &:before {
        @include button-icon();
        background-image: url('../img/icons/paket--black.svg');
        background-size: 26px 22px;
        height: 22px;
        width: 26px;
        padding-left: 26px;
        top: 52%;   
    }

    &:hover {

        &:before {
            background-image: url('../img/icons/paket--white.svg');
        }
    }

}
.order-detail-delivery-status__number {
    @include font-size(15px);
    line-height: 20px;

    @include bp(120){
        @include font-size(18px);
        line-height: 25px;
    }

    &--bold {
        font-weight: 700;
    }
}
.order-detail-customer-info__email {
    font-weight: 600;
    @include font-size(15px);

    @include bp(120){
        @include font-size(18px);
        line-height: 25px;
    }
}
.order-detail-payment-details__info {
    font-weight: 600;
    @include font-size(15px);

    @include bp(120){
        @include font-size(18px);
        line-height: 25px;
    }
}