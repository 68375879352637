.fb-login{
    border-bottom: 1px solid $sand-light;
}
.fb-login__content{
    @extend %page-content;
    padding: 25px 20px;

    @include bp(48){
        padding: 50px 30px;
    }
    @include bp(75){
        padding: 90px 15px;
    }
    @include bp(120){
        padding: 105px 15px;
    }
}
.fb-login__heading{
    margin: 0;
}
