.product-list-link {
    color: $black;
    text-decoration: none;
}

.book-list-section {
    background: $sand-lighter;

    @include bp(40) {
        padding-top: 20px;
        border-top: 1px solid $sand-light;

        &.book-list-section--no-border-top {
            border-top: none;
            padding-top: 0;
        }
    }
}

.book-list-section__inner {
    @extend %clearfix;
    @extend %page-content;
    margin: 0 auto;

    .product-list-cover__new {
        top: 15px;
        @include bp(75) {
            top: 30px;
        }
    }
}

.book-list-section__store-selector {
    @extend %clearfix;
    margin-bottom: 20px;
    padding: 0 20px;

    @include bp(48) {
        padding: 0 30px;
    }
}

.book-list-section__store-selector-inner {
    @include bp(40) {
        float: right;
    }
}

.product-list-cover__new {
    background-color: $medium-green;
    border-radius: 50%;
    color: $sand-lighter;
    display: block;
    @include font-size(18px);
    font-weight: 700;
    height: 40px;
    left: 3px;
    line-height: 40px;
    position: absolute;
    text-align: center;
    top: 10px;
    transform: translate(-50%);
    width: 40px;
    z-index: 2;

    @include bp(48) {
        top: 20px;
	}
}

.product-list-cover__coming {
    background-color: $product-cover-coming-plate;
    color: $sand-lighter;
    display: block;
    @include font-size(15px);
    font-weight: 600;
    letter-spacing: 1px;
    position: absolute;
    left: -15px;
    height: 35px;
    line-height: 35px;
    padding: 0 10px;
    top: 20px;
    z-index: 2;

    &:before {
        border-top: 15px solid $black;
        border-left: 15px solid transparent;
        top: 35px;
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        position: absolute;
        left: 1px;

    }
    @include bp(48) {
        top: 20px;
        @include font-size(18px);
        font-weight: 700;
        height: 40px;
        left: -20px;
        line-height: 40px;

        &:before {
            border-top: 20px solid $black;
            border-left: 20px solid transparent;
            top: 40px;
        }
    }
}

.book-list__visual-bar {
    display: none;
    @include bp(75) {
        display: block;
    }
}

.book-list__category-top__content {
    margin: 0;
    @include bp(75) {
        margin: -10px auto 0;
    }
}

.category-top-preamble--booklist {
    @include font-size(15px);
    font-weight: 600;
    line-height: 25px;
    margin: 10px 0 0;

    @include bp(48) {
        @include font-size(23px);
        line-height: 30px;
        font-weight: 600;
        margin-top: 30px;
        max-width: 1000px;
    }
    @include bp(75) {
        line-height: 35px;
        margin-top: 40px;
        max-width: 1000px;
    }
    @include bp(120) {
        line-height: 45px;
        @include font-size(30px);
        margin-top: 35px;
        max-width: 1200px;
    }
}

.book-list-section-content {
    @extend %clearfix;
}

.book-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.book-list__wrapper {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
}

.book-list-link {
    color: $black;
    text-decoration: none;
}

.book-list-figure {
    position: relative;
}

.book-list__item {
    display: flex;
    margin-bottom: 30px;
    padding: 0 20px;
    width: 50%;
    float: left;

    @include bp(40) {
        width: 33.3333333333%;
    }
    @include bp(48) {
        width: 25%;
    }
    @include bp(64) {
        width: 20%;
    }
    @include bp(75) {
        width: 16.6666666%;
    }
    @include bp(100) {
        width: 14.285714%;
    }
    @include bp(120) {
        margin-bottom: 45px;
        width: 12.5%;
    }
}

.book-list__rank-number {
    bottom: 0;
    color: $black;
    @include font-size(60px);
    font-weight: 700;
    left: -15px;
    line-height: 0;
    position: absolute;

    @include bp(75) {
        transform: translateX(-50%);
        left: 0;
    }
    @include bp(120) {
        @include font-size(75px);
    }
}

.book-list-product {
    position: relative;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.book-list-product__overlay {
    display: flex;
    min-height: 150px;
    align-items: center;
    background: $sand-light;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: opacity .2s linear, visibility 0s linear .2s;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;

    .book-list__item:hover & {
        visibility: visible;
        pointer-events: auto;
        opacity: 1;
        transition: opacity .2s linear;
    }

    .touch & {
        display: none;
    }
}

.book-list-product__overlay--before-sale {
    background: $yellow;
}

.book-list-product__overlay--coming {
    background: $orange-lighter;
}

.book-list-product__overlay-inner {
    border-top: 1px solid $sand;
    border-bottom: 1px solid $sand;
    width: 100%;

    .book-list-product__overlay--before-sale &,
    .book-list-product__overlay--coming & {
        border-top: none;
        border-bottom: none;
    }
}

.book-list-product__title {
    @include font-size(15px);
    @include line-clamp(2);
    font-weight: 700;
    line-height: 1.2em;
    margin: 0;
    word-wrap: break-word;

    @include bp(48) {
        @include font-size(19px);
    }

    @include bp(100) {
        @include font-size(23px);
    }
}

.book-list-product__author {
    @include font-size(13px);
    @include line-height(13);
    @include line-clamp(1);
    color: $black;
    font-weight: 300;
    margin: 5px 0 0;
    word-wrap: break-word;

    @include bp(75) {
        @include font-size(15px);
        @include line-height(15);
    }
}

.book-list-product-price {
    @include font-size(18px);
    display: block;
    font-weight: 700;
    line-height: 1em;
    margin-top: 5px;

    @include bp(75) {
        @include font-size(23px);
        line-height: 1.2em;
    }

    &:last-child {
        margin-bottom: 35px;

        @include bp(48) {
            margin-bottom: 37px;
        }
    }

    &--special {
        .book-list-product-price__large {
            color: $red;
            position: relative;
            padding-left: 27px;
            line-height: 24px;
            display: inline-block;

            @include bp(75) {
                line-height: 35px;
                padding-left: 40px;
            }

            &--1 {
                color: $friend-price;

                &:before {
                    background-image: url('../img/icons/heart.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    content: '';
                    display: inline-block;
                    height: 24px;
                    width: 24px;
                    left: 0;
                    margin-right: 10px;
                    position: absolute;
                    transform: translateY(-50%);
                    top: 50%;

                    @include bp(75) {
                        height: 35px;
                        width: 35px;
                    }
                }
            }

            &--3,
            &--2 {
                padding-left: 0;
            }
        }
    }

    &__large {
        margin-right: 5px;
        line-height: 24px;

        @include bp(75) {
            line-height: 35px;
        }
    }

    &__small {
        @include font-size(15px);
        font-weight: 300;
        line-height: 1em;
        display: inline-block;
    }
}

.book-list-product__type {
    @include font-size(15px);
    font-weight: 600;
    line-height: 1.2em;
    margin: 5px 0 0;
}

.book-list-product__add-to-cart-touch {
    margin-top: 5px;
}

.book-list-product__store-only-product-info {
    display: block;
    position: relative;
    padding: 6px 11px;
    border-radius: 5px;
    text-align: center;
    margin-top: 5px;

    &:hover {
        background: inherit;
    }
}

.category-top-preamble {
    @include font-size(23px);
    line-height: 2;
    font-weight: 600;
    max-width: 1000px;
}
