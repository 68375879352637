/*Set on body element*/
.akb-popup-open{
    overflow:hidden;
    position: fixed;

    @include bp(48){
        position: static;
    }
}

.akb-popup-overlay{
    width: 100%;
    height: 100vh;
    background:  linear-gradient(
                    45deg,
                    rgba(227, 224, 210, 0.8), rgba(241,239,230,0.8)
    );
    position: fixed;
    top: 0;
    left: 0;
    z-index:40;
}

.akb-popup-modal{
    position: fixed;
    width: 100%;
    background-color: $sand-lighter;
    z-index: 50;
    top: 0;
    left: 0;
    overflow: hidden;
    padding-bottom: 30px;
    border-radius: 4px;
    height: 100vh;
    box-shadow: 0 10px 20px $border-product-filter;

    transform: scale(0.7);
    opacity: 0;
    transition: all 0.3s;

    &.show{
        transform: scale(1);
        opacity: 1;
        transition: all 0.3s;
    }


    @include bp(48){
        left: calc(50% - 300px);
        max-width: 600px;
        max-height: 500px;
        height: auto;
        top: calc(50vh - 250px);
    }
    @include bp(75){
        left: calc(50% - 384px);
        max-width: 768px;
    }

    @include bp(120){
        left: calc(50% - 480px);
        max-width: 960px;
    }
}

.akb-popup-modal--large{
    @include bp(48) {
        height: auto;
        max-height: calc(100vh - 200px);
        top: 100px;
    }
}


.akb-popup-head{
    padding: 30px;
    position: relative;
    border-bottom: 1px solid $border-total;

}
.akb-popup-heading{
    margin:0;
    @include font-size(30px);
    font-weight: 700;
    padding-right: 30px;
}

.akb-catch-button {
    position: absolute;
    right: 30px;
    top: 35px;
    height: 1px;
    width: 1px;
    background: none;
    border: none;
    appearance: none;
}

.akb-popup-close{
    position: absolute;
    right: 30px;
    top: 35px;
    appearance: none;
    background-color: transparent;
    background-image: url('../img/icons/cross-rounded.svg');
    background-position: center;
    background-repeat: no-repeat;
    height: 15px;
    padding: 0;
    width: 15px;
    border: none;

    span{
        position: absolute;
        left: -999em;
    }
}

.akb-popup-body{
	font-family: $font-family--secondary;
	@include font-size(18px);
	padding: 0 20px 0 50px;
	overflow-y: scroll;
	word-wrap: break-word;
	-webkit-overflow-scrolling: touch;
	margin-right: 30px;
	margin-top: 30px;
	max-height: calc(100vh - 170px);


	@include bp(48){
		height: auto;
		max-height: 340px;
	}

	.akb-popup-modal--large &{
		@include bp(48){
			max-height: calc(100vh - 370px);
		}
	}

	&::-webkit-scrollbar {
		background-color: transparent;
		width: 7px;
	}

	&::-webkit-scrollbar-track {
		background-color: #fff;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #41403D;
		border-radius: 10px;
		height: 50px;
	}

	h1,h2,h3,h4,h5,h6{
		font-weight: bold;
		font-family: $font-family--primary;
		@include font-size(23px);
		line-height: 1.30434782609em;
		margin: 45px 0 0;

		@include bp(48){
			margin-top: 45px;
		}
		@include bp(120){
			@include font-size(30px);
			line-height: 1.16666666667em;
		}
	}

	p{
		@include font-size(16px);

		@include bp(48){
			@include font-size(17px);
			line-height: 1.76470588235em;
		}
		@include bp(75){
			@include font-size(18px);
			line-height: 1.94444444444em;
			margin-bottom: 40px;
		}
		@include bp(120){
			@include font-size(20px);
			line-height: 1.7em;
		}
	}

	img{
		width: 100%;

		@include bp(48){
			max-width: 500px;
			width: auto;
		}
		@include bp(75){
			max-width: 670px;
		}
		@include bp(120){
			max-width: 850px;
		}
	}
}

_::-webkit-:host:not(:root:root), .akb-popup-body{
    max-height: calc(100vh - 230px);

}

_::-webkit-:not(:root:root), .akb-popup-body {
    max-height: calc(100vh - 260px);

    @include bp(40){
        max-height: calc(100vh - 430px);
    }
}

_::-webkit-:not(:root:root), .akb-popup-open{
    overflow:hidden;
    position: fixed;

    @include bp(75){
        position: static;
    }
}
