.reco-list-header__content {
  position: relative;
}
.reco-list-header__heading {
  @include font-size(23px);
  line-height: 30px;
  margin: 0;
  margin-left: 50px;
  position: relative;
  display: inline-block;
  @include bp(48){
    line-height: 40px;
    @include font-size(30px);
    margin-left: 60px;
  }
}
.reco-list-header__figure-img {
  border-radius: 50%;
  max-width: 40px;
  display: inline-block;
  position: absolute;
  top: -5px;
  @include bp(48){
    max-width: 50px;
  }
}

.reco-list {
  list-style: none;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  padding: 0px 20px;

  @include bp(48){
    padding: 0px 30px;
  }
  @include bp(75){
    padding: 0px 15px;
  }
}
.reco-list__item {
  width: 100%;
  margin-bottom: 30px;

  @include bp(40){
    float: left;
    padding-right: 30px;
    width: 50%;
    margin-bottom: 0;
    min-height: 270px;
  }
  @include bp(75){
    width: 33.3333333333%;
    min-height: 350px;
  }
  @include bp(120){
    width: 25%;
  }
}